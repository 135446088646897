export const TRANSLATIONS_Arabic = {
  WEB_LABELS: {
    Secure_Video_Call_Session_Expired:
      "انتهت صلاحية جلسة مكالمة الفيديو الآمنة",
    Secure_Video_Call_Ended: "تم إنهاء مكالمة الفيديو الآمنة",
    Thank_you_for_connecting_with_us: "شكرا لتواصلك معنا!",
    Connected_Waiting_for_Other_Participants:
      "متصل. في انتظار مشاركين آخرين ...",
    Powered_by: "مشغل بواسطة",
    Connecting: "توصيل...",
    Local_Pike: "محلي بايك",
    Would_you_like_to_save_image: "هل ترغب في حفظ الصورة؟",
    Message: "رسالة",
    Location_Not_shared: "الموقع: غير مشترك",
    Location: "موقع",
    Tools: "أدوات",
    Ask_PO_to_Turn_on_Location: "اطلب من مالك العقار تمكين الموقع",
    Preparing_image_for_markup: "جارٍ تحضير الصورة للترميز ...",
    Download_Message: "قم بتنزيل تطبيق التقييم عن بعد",
    Disclaimer:
      "من الضروري تنزيل تطبيق RemoteVal من متجر التطبيقات. بمجرد اكتمال التنزيل ، يرجى إعادة فتح دعوة الرسائل النصية / بالبريد الإلكتروني والنقر فوق الرابط مرة أخرى للانضمام تلقائيًا إلى المكالمة.",
    MeasurementLinkDisclaimer:
      "من الضروري تنزيل تطبيق RemoteVal من متجر التطبيقات. بمجرد اكتمال التنزيل ، يرجى إعادة فتح دعوة الرسائل النصية / بالبريد الإلكتروني والنقر على الرابط مرة أخرى لبدء الفحص تلقائيًا.",
    Zoom_Level: "مستوى التكبير",
    enter_cell_phone: "أدخل الهاتف الخليوي",
    Email: "بريد الالكتروني",
    Cell_Phone: "الهاتف الخلوي",
    Participant_Name: "اسم المشارك",
    Invite_Participant: "ادعُ مشاركًا",
    Join_secure_video_call_by_clicking_on_the_following_link:
      "انضم إلى مكالمة فيديو آمنة من خلال النقر على الرابط التالي:",
    There_are_no_Attachments_available: "لا توجد مرفقات متاحة.",
    Drop_Or_Select_Your_File: "قم بإسقاط الملف أو تحديده",
    Attach_Document: "إرفاق المستندات",
    Enter_Your_Name: "أدخل أسمك",
    I_confirm_that_I_have_read_and_agree_to_the: "أؤكد أنني قد قرأت وأوافق على",
    Search_or_Add_Captured_Image_Label: "البحث أو إضافة تسمية الصورة الملتقطة",
    Press_Enter_or_Click_on_Add_New_Label_to_add_new_Label:
      "اضغط على Enter أو انقر فوق Add New Label إلى الصورة الملتقطة بتسمية جديدة",
    To: "إلى",
    Everyone: "الجميع",
    Type_a_message: "اكتب رسالة",
    Are_you_sure_you_want_to_delete: "هل أنت متأكد أنك تريد حذف ؟",
    Room_is_full_Please_try_again_later:
      "الغرفة ممتلئة. الرجاء معاودة المحاولة في وقت لاحق",
    Comment: "تعليق",
    Room_2_Measurement: "غرفة 2 القياس",
    Room_1_Measurement: "غرفة 1 القياس",
    Room_2_Image: "غرفة 2 صورة",
    Room_1_Image: "غرفة 1 صورة",
    No_of_Rooms: "عدد الغرف",
    Property_Type: "نوع الملكية",
    House: "منزل",
    Inspection_Form: "استمارة التفتيش",
    Instructions: "تعليمات",
    Guide_user_to_position_camera_on_the_target:
      "دليل المستخدم لوضع الكاميرا على الهدف",
    Click_on_the_Label_once_to_capture_labeled_image:
      "انقر فوق التسمية مرة واحدة لالتقاط الصورة المسماة.",
    Select_the_floor_of_the_Home_Room_Type_and_a_Room_Name:
      "حدد أرضية المنزل ونوع الغرفة واسم الغرفة.",
    Choose_GLA_or_Non_GLA: "اختر GLA أو Non-GLA.",
    Click_on_the_Start_Measurement_button: "انقر فوق الزر بدء القياس",
    Click_the_Auto_Corner_Detection_to_On_Off:
      'انقر فوق "الكشف التلقائي عن الزاوية" لتشغيل / إيقاف',
    Position_vertical_Green_Floor:
      "ضع علامة أرضية خضراء رأسية في زاوية الغرفة وتأكد من أنها تلامس الأرض.",
    App_will_auto_mark_a_corner:
      'سيقوم التطبيق بتحديد الزاوية تلقائيًا أو النقر على زر "زاوية الالتقاط يدويًا"',
    Guide_the_user_to_walk_to_each_corner_of_the_room_to_mark_each_corner:
      "أرشد المستخدم للسير إلى كل ركن من أركان الغرفة لتحديد كل ركن",
    When_last_line_connects:
      'عندما يتصل الخط الأخير بنقطة البداية ، سيتحول إلى اللون الأحمر. سيقوم التطبيق بالاتصال التلقائي وإكمال الرسم أو يمكنك استخدام زر "Connect Corner & Finish Sketch" لإكمال القياس.',
    button_to_view_final_sketch:
      'انقر فوق الزر "إظهار رسم مخطط الطابق النهائي" لعرض المخطط النهائي.',
    Are_You_Sure_You_Want_to_Leave_This_Call:
      "هل أنت متأكد أنك تريد ترك هذه المكالمة؟",
    Street_View: "منظر الشارع",
    Select_Measurement_Type: "حدد نوع القياس",
    Floor: "أرضية",
    Ceiling: "سقف",
    View_Sketches_by_Floor: "عرض اسكتشات من الطابق",
    Uploading_Image: "تحميل الصورة",
    Call_Setting: "اعدادات الاتصال",
    Allow_access_to_your_Microphone_Click_the_padlock:
      "السماح بالوصول إلى الميكروفون الخاص بك. انقر فوق القفل",
    to_the_left_of_the_address_bar: "على يسار شريط العنوان.",
    Speakers: "مكبرات الصوت",
    Allow_access_to_your_Camera_Click_the_padlock:
      "اسمح بالوصول إلى الكاميرا الخاصة بك. انقر فوق القفل",
    to_the_address_bar: "إلى شريط العنوان.",
    Failed_to_start_the_video_camera_Please_select_another_video_camera:
      "فشل بدء تشغيل كاميرا الفيديو. الرجاء تحديد كاميرا فيديو أخرى.",
    Title: "عنوان",
    New_Note: "ملاحظة جديدة...",
    There_are_no_Notes_available: "لا توجد ملاحظات متاحة.",
    Are_you_sure_you_want_to_delete_this_note:
      "هل أنت متأكد أنك تريد حذف هذه الملاحظة؟",
    Network_Type: "نوع الشبكة",
    Netword_Strength: "قوة Netword",
    To_share_live_video:
      "لمشاركة فيديو مباشر. يرجى تشغيل إذن الكاميرا في إعدادات المتصفح الخاص بك بعد ذلك",
    click_here: "انقر هنا",
    to_reload_the_page: "لإعادة تحميل الصفحة.",
    we_cant_access_your_microphone:
      "لا يمكننا الوصول إلى الميكروفون الخاص بك. لمنح حق الوصول ، انتقل إلى إعدادات المستعرض الخاص بك ثم",
    Questionnaire_Form: "استمارة الاستبيان",
    No_images_found: "لم يتم العثور على صور!",
    Image: "صورة",
    Captured_Images: "الصور الملتقطة",
    Share_your_screen: "شارك شاشتك",
    Share_your_live_video: "شارك الفيديو المباشر الخاص بك؟",
    This_will_stop_your_video:
      "هذا سوف يوقف الفيديو الخاص بك. هل تريد الاستمرار؟",
    This_will_stop_screen_share:
      "سيؤدي هذا إلى إيقاف مشاركة الشاشة. هل تريد الاستمرار؟",
    Room_Area_Label: "تسمية الغرفة / المنطقة",
    Room_Area_Name:
      "ااسم _منطقة الغرفة (مثل غرفة النوم 1 والحمام 2 وما إلى ذلك)",
    Room_Area_Name_Placeholder: "اسم الغرفة / المنطقة",
    You: "أنت",
    Are_you_sure_you_want_to_delete_captured_image:
      "هل أنت متأكد أنك تريد حذف الصورة الملتقطة؟",
    Start_Over: "ابدأ من جديد - هل أنت متأكد من أنك تريد حذف مخططات الطوابق؟",
    Are_You_Sure_You_Want_to_Exit_Measurement_process:
      "هل أنت متأكد أنك تريد الخروج من عملية القياس؟",
    Labels: "ملصقات",
    Select_Language: "اختار اللغة",
    This_call_is_being_recorded: "يتم تسجيل مكالمة الفيديو هذه",
    This_call_is_being_recorded_by_your_appraiser_for_their_private_work_file:
      "يتم تسجيل هذه المكالمة من قبل المثمن الخاص بك لملف العمل الخاص بهم.",
    It_will_not_be_shared: `لن يتم تقاسمها مع أي طرف خارجي بما في ذلك الرهن العقاري الخاص بك
    المُقرض ، ولن يتمكن المثمن من تنزيله من ملف
    موقع RemoteVal. سيتم استخدامه فقط لإكمال المثمن
    تقديرك.`,
    If_you_do_not_wish_your_screen_to_be_recorded_please_inform_your_appraiser_now:
      "إذا كنت لا ترغب في تسجيل شاشتك ، فيرجى إبلاغ المثمن الآن.",
    Measurement: "قياس",
    Create_Measurement: "إنشاء قياس",
    Fencing: "سياج",
    Show: "تبين",
    Hide: "إخفاء",
    Live_Floor_Plan: "خطة الطابق الحية",
    Expand: "وسعت",
    Collapse: "انهيار",
    Instruction_for_Call_Settings: "تعليمات لإعدادات الاتصال",
    Speaker_Mic_Camera_can_be_tested_via_call_settings:
      'يمكن اختبار مكبر الصوت وميكروفون والكاميرا عبر إعدادات المكالمة من قائمة "المزيد"',
    To_Check_the_call_settings_now_click_on_the_below_button:
      "للتحقق من إعدادات الاتصال الآن انقر فوق الزر أدناه.",
    Call_recording_by_virtual_inspector:
      "يتم تسجيل مكالمة الفيديو هذه من قبل المفتش الظاهري لملف العمل الخاص بهم ولأغراض التدقيق الداخلي من قبل المؤسسة التي طلبتها. لن تتم مشاركتها مع أي حفلة خارجية.",
    Call_and_Screen_Recording_Opt_Out:
      "إذا كنت لا ترغب في تسجيل شاشتك وهذه المكالمة ، فيرجى إبلاغ المفتش الظاهري الآن.",
    Test_Audio_Video_Components_Button:
      "يمكن اختبار مكبر الصوت والميكروفون والكاميرا بالنقر فوق الزر أدناه.",
  },
  BUTTONS: {
    Re_Connect: "إعادة الاتصال",
    End_Session: "جلسة نهاية",
    Chat: "دردشة",
    Participants: "مشاركون",
    Notes: "ملحوظات",
    Attach_Document: "إرفاق المستندات",
    Questionnaire: "استبيان",
    Labels: "ملصقات",
    Pointer: "المؤشر",
    Markup: "وضع علامة على",
    Flash_Off: "إيقاف تشغيل الفلاش",
    Flash_On: "تشغيل الفلاش",
    Call_Recording: "تسجيل المكالمات",
    Capture_Image: "التقاط الصورة",
    Screen_Share: "حصة الشاشة",
    Stop_Screen_Share: "وقف مشاركة الشاشة",
    Call_Settings: "اعدادات الاتصال",
    Clear_All: "امسح الكل",
    Exit: "مخرج",
    Save: "يحفظ",
    Close: "قريب",
    Save_and_Exit: "حفظ وخروج",
    Continue: "يكمل",
    Street_View: "منظر الشارع",
    Open_in_App: "فتح في التطبيق",
    Privacy_Policy: "سياسة خاصة",
    Invite: "يدعو",
    Join: "انضم",
    Add_Captured_Image_Label: "أضف تسمية الصورة الملتقطة",
    No: "لا",
    Yes: "نعم",
    Delete: "حذف",
    Cancel: "يلغي",
    RETRY: "أعد المحاولة",
    OK: "نعم",
    Back: "خلف",
    Leave_Call: "اترك الاتصال",
    Got_It: "فهمتها",
    Start_New_Inspection: "بدء فحص جديد",
    Resume_Existing_Inspection: "استئناف التفتيش الحالي",
    View_All_Current_Sketch: "عرض كل الرسومات الحالية",
    Open_Sketch: "افتح سكتش",
    Auto_Corner_Detection_ON: "كشف الزاوية التلقائي قيد التشغيل",
    Auto_Corner_Detection_OFF: "كشف الزاوية التلقائي معطّل",
    Manually_Capture_Corner: "زاوية الالتقاط يدويًا",
    Undo_Last_Corner_Capture: "تراجع عن آخر لقطة زاوية",
    Connect_Corner_Finish_Sketch: "ربط الزاوية والانتهاء من الرسم",
    Capture_3D_Room_Height: "التقاط ارتفاع غرفة 3D",
    Set_Fixed_3D_Room_Height: "تعيين ارتفاع ثابت للغرفة ثلاثية الأبعاد",
    Capture_Door: "القبض على الباب",
    Set_Door: "اضبط الباب",
    Show_Finished_Floor_Plan_Sketch: "إظهار مخطط الطابق النهائي",
    Back_to_Previous_Screen: "العودة إلى الشاشة السابقة",
    Add_Room: "أضف غرفة",
    Publish_the_floor_plan: "انشر مخطط الطابق",
    Exit_Return_to_Inspection: "الخروج والعودة للتفتيش",
    Audio: "صوتي",
    Video: "فيديو",
    Microphone: "ميكروفون",
    Microphone_not_found: "لم يتم العثور على الميكروفون",
    Microphone_Blocked: "الميكروفون محظور",
    Default: "تقصير",
    Grant_Permission: "يعطي الأذن",
    Speaker_Blocked: "مكبر الصوت محظور",
    Play_Test_Sound: "تشغيل اختبار الصوت",
    Preview: "معاينة",
    Camera: "الة تصوير",
    Camera_not_found: "الكاميرا غير موجودة",
    Camera_Blocked: "الكاميرا محظورة",
    Done: "منتهي",
    View: "رأي",
    Capture_Labeled_Images: "التقاط الصور المصنفة",
    Capture_Measurements: "قياسات الالتقاط",
    Instructions: "تعليمات",
    Start_Measurement_Process: "ابدأ عملية القياس",
    Flash_Light_Not_Supported:
      "مصباح يدوي بعيد غير مدعوم على جهاز الضيف أو غير جاهز",
  },
  TOOLTIP: {
    Mute_My_Audio: "كتم الصوت الخاص بي",
    Unmute_My_Audio: "إعادة الصوت الخاص بي",
    Stop_My_Video: "أوقف الفيديو الخاص بي",
    Start_My_Video: "ابدأ الفيديو الخاص بي",
    Capture_Images_with_No_Labels: "التقاط الصور بدون تسميات",
    Start_Inspection: "بدء الفحص",
    Chat: "دردشة",
    More_Options: "المزيد من الخيارات",
    End_Call: "إنهاء المكالمة",
    Unpin_Video: "قم بفك تثبيت الفيديو",
    Pin_Video: "تثبيت الفيديو",
    Add_New_Note: "أضف ملاحظة جديدة",
    Refresh: "ينعش",
    View_More: "عرض المزيد",
    Invite_Participant: "ادعُ مشاركًا",
    Delete_Image: "حذف صورة",
  },
  COMMON_STRING: {
    INVITATION_SENT_SUCCESS_MESSAGE: "تم إرسال الدعوة بنجاح",
    IOS_SAFARI_HELP: "للحصول على أفضل النتائج ، نوصي باستخدام Safari",
    UNEXPECTED_ERROR: "حدثت أخطاء غير متوقعة. حاول مرة أخرى في وقت لاحق.",
    CALL_LINK_NOT_FOUND: "لم يتم العثور على رابط الاتصال.",
    CREATE_BOOKMARK_MESSAGE: "من فضلك اطلب من المسؤول إضافة إشارة مرجعية",
    ATTACHMENT_UPLOAD_SIZE_EXCEEDED:
      "يجب أن يكون حجم الملف أقل من 10 ميغا بايت",
    ATTACHMENT_UPLOAD_UNSUPPORTED_FORMAT: "تنسيق ملف غير معتمد",
    LIVE_POINTER_STARTED: "بدأ المؤشر المباشر. يمكنك تحريك المؤشر بالسحب.",
    LIVE_POINTER_STOPPED: "توقف المؤشر المباشر",
    APPRAISAL_EXIT_APPROVED: "تم الاعتراف بالخروج بنجاح.",
    APPRAISAL_EXIT_DENIED: "خروج نفى.",
    MARKUP_CLOSED: "تم إيقاف الترميز",
    PRIVACY_POLICY_FAIL: "لم يتم العثور على عنوان URL صالح لسياسة الخصوصية!",
    SWITCH_CAMERA_REQUEST_REJECTED:
      "لم يقم مالك المنزل بمشاركة عرض الكاميرا المطلوب.",
  },
  ERROR_MESSAGES: {
    Please_Select_Cellphone_or_Email:
      "يرجى تحديد الهاتف المحمول أو البريد الإلكتروني",
    This_field_is_required: "هذه الخانة مطلوبه",
    Cell_Phone_Must_Be_Valid: "يجب أن يكون الهاتف الخليوي صالحًا",
    Name_is_Required: "مطلوب اسم",
    Name_Must_Be_Valid: "يجب أن يكون الاسم صالحًا",
    Invalid_Text: "نص غير صالح",
    Email_Must_Be_Valid: "يجب ان يكون البريد الاكتروني صحيح",
    Name_must_be: "يجب أن يكون الاسم",
    characters_max: "أحرف كحد أقصى",
    Please_select_the_Floor: "الرجاء تحديد الطابق",
    Please_enter_the_Room_Name: "الرجاء إدخال اسم الغرفة",
    You_need_to_use_HTTPS_for_selecting_audio_output_device:
      "تحتاج إلى استخدام HTTPS لاختيار جهاز إخراج الصوت",
    Something_went_wrong: "هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.",
    Oops_Call_duration_is_over_and_session_is_ended:
      "وجه الفتاة! انتهت مدة المكالمة وانتهت الجلسة.",
    No_participant_has_joined_yet_Ending_the_call_session:
      "لم ينضم أي مشارك حتى الآن! إنهاء جلسة الاتصال",
  },
  starting_inspection: "بدء التفتيش",
  starting_measurement: "بدء القياس",
  error: "خطأ",
  contact_us: "اتصل بنا",
  support_popup_msg:
    "عفوًا ، حدث خطأ ما ، يرجى المحاولة مرة أخرى بعد ذلك في بعض الأحيان أو الإبلاغ عن مشكلة لفريق دعمنا.",
  select_po: "حدد مالك العقار لبدء عملية الفحص",
  request_po_for_camera:
    "يرجى طلب صاحب العقار لتشغيل عرض الكاميرا لبدء عملية الفحص.",
  wait_until_po_joins:
    "يرجى الانتظار حتى ينضم مالك العقار إلى المكالمة لبدء عملية الفحص",
  select_measurement_method: "حدد طريقة القياس",
  inspection_inprogress: "التفتيش جار بالفعل",
  model_msg_retry:
    "تعذر إنشاء قناة اتصال مع مالك العقار ، يرجى إعادة المحاولة.",
  model_msg_leaveCall:
    "يبدو أن هناك مشكلة في الاتصال بالإنترنت على كلا الجانبين ، لذا يرجى فصل المكالمة الحالية وإعادة الاتصال مرة أخرى.",
  please_wait: "أرجو الإنتظار...",
  loading_sketch: "جارٍ تحميل Sketch ...",
  connection_timeout_msg:
    "انتهت مهلة الاتصال بها. حدث شيء غير عادي واستغرق الأمر وقتًا أطول من المتوقع للرد. حاول مرة اخرى.",
  previous_measurement_prblm_msg:
    "عذرًا ، حدث خطأ ما في القياس السابق. يرجى المحاولة مرة أخرى مع عملية القياس الجديدة.",
  measurement_retry_msg: "لم تبدأ عملية القياس. الرجاء اعادة المحاولة.",
  opensketch_retry_msg: "لم تبدأ عملية Open Sketch. الرجاء اعادة المحاولة.",
};
