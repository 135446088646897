import React, { Component } from "react";
import "./rightMenu.scss";

import {
  questionnaire_update,
  fill_up_inspection_form,
} from "./../../constants/Permission";
import RBAC from "./../RBAC";

export default function RightMenu(props) {
  return (
    <>
      <div className="top-right-menu">
        <ul>
          <RBAC
            action={questionnaire_update}
            yes={
              <li>
                <button>
                  <i className="icon-questionnaire"></i>
                </button>
              </li>
            }
          />

          <RBAC
            action={fill_up_inspection_form}
            yes={
              <li className="inspection-btn">
                <button>
                  <i className="icon-inspection-form"></i>
                </button>
              </li>
            }
          />

          <li
            onClick={props.flipCamera}
            className={
              "filp-camera" +
              (props.isLocalVideoTrackEnabled ? " " : " disable")
            }
            style={{
              display:
                props.isFrontAndBackCameraAvailable == true &&
                ((!!props.isLocalParticipantPinned &&
                  !!props.ispinnedViewActive) ||
                  !!!props.ispinnedViewActive)
                  ? "block"
                  : "none",
            }}
          >
            <button>
              <i className="icon-flip-camera"></i>
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}
