import React from "react";
import { useTranslation } from "react-i18next";
export default function DeleteModel(props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="error-popup open">
        <div className="error-info">
          <div className="content">
            <p>
              <b>
                {t(props.title) ||
                  t("WEB_LABELS.Are_you_sure_you_want_to_delete")}
              </b>
            </p>
          </div>
          <div className="bottom-btn">
            <button className="gray-btn" onClick={() => props.Cancel()}>
              {t("BUTTONS.Cancel")}
            </button>
            <button className="blue-btn" onClick={() => props.Confirm()}>
              {t("BUTTONS.Delete")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
