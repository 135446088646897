import api from "./api";

export default class AttachmentAPI {
  getAttachmentsByJobOrderId(jobOrderId) {
    return api.get(`/attachment/get/${jobOrderId}`, {});
  }

  addAttachment(formData) {
    return api.postMultipartFormData(`/attachment/add`, formData);
  }

  deleteAttachment(attachmentId) {
    return api.delete(`/attachment/delete/${attachmentId}`);
  }

  downloadAttachment(attachmentId) {
    return api.get(`/attachment/get/${attachmentId}`, {
      headers: {
        Accept: "*/*",
      },
      responseType: "blob",
    });
  }
}
