import React, { Component } from "react";
import "./errorModelMaxParticipant.scss";
import { withTranslation } from "react-i18next";
class ErrorModelMaxParticipant extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <div className="error-popup open">
          <div className="error-info">
            <div className="content">
              <button
                className="close-modal"
                onClick={this.props.disconnectCall}
              >
                <i className="icon-close-image"></i>
              </button>
              {t("WEB_LABELS.Room_is_full_Please_try_again_later")}
            </div>
            <div className="bottom-btn">
              <button className="gray-btn" onClick={this.props.retryConnect}>
                {t("BUTTONS.RETRY")}
              </button>
              <button className="blue-btn" onClick={this.props.disconnectCall}>
                {t("BUTTONS.OK")}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(ErrorModelMaxParticipant);
