import React from "react";
import "./shareScreenConfirmationModel.scss";
import { useTranslation } from "react-i18next";

export default function ShareScreenConfirmationModel(props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="error-popup open">
        <div className="error-info">
          <div className="content">
            <p>
              <b>
                {props.shareScreenAction == "START"
                  ? t("WEB_LABELS.Share_your_screen")
                  : t("WEB_LABELS.Share_your_live_video")}
              </b>
            </p>

            <p>
              {props.shareScreenAction == "START"
                ? t("WEB_LABELS.This_will_stop_your_video")
                : t("WEB_LABELS.This_will_stop_screen_share")}
            </p>
          </div>
          <div className="bottom-btn">
            <button
              className="red-btn"
              onClick={() => props.hideShareScreenConfirmModel()}
            >
              {t("BUTTONS.No")}
            </button>
            <button
              className="green-btn"
              onClick={() => {
                props.shareScreenHandler();
                if (props.shareScreenAction == "CLOSE") {
                  props.toggleCameraAction();
                }
              }}
            >
              {t("BUTTONS.Yes")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
