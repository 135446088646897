import _ from "lodash";

export function dehydrateBookmark(data) {
  return _.omitBy(
    {
      bookmarkId: data.id,
      bookmarkLabel: data.label,
    },
    _.isUndefined
  );
}

export const dehydrateBookmarks = (data) =>
  data.map((d) => dehydrateBookmark(d));
