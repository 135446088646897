import React from "react";
import { useTranslation } from "react-i18next";

const POInstructionModel = (props) => {
  const { t } = useTranslation();
  return (
    <div className="error-popup open">
      <div className="error-info text-left">
        <div className="content">
          <button
            className="close-modal"
            onClick={props.toggleRequestPOForCameraModel}
          >
            <i className="icon-close-image"></i>
          </button>
          <p>
            <b>{t("WEB_LABELS.Instructions")}</b>
          </p>
          <p>{props.msgForPOInstructionModel}</p>
        </div>
      </div>
    </div>
  );
};

export default POInstructionModel;
