export const TRANSLATIONS_Korean = {
  WEB_LABELS: {
    Secure_Video_Call_Session_Expired: "보안 화상 통화 세션 만료됨",
    Secure_Video_Call_Ended: "보안 영상 통화 종료",
    Thank_you_for_connecting_with_us: "저희와 연결해주셔서 감사합니다!",
    Connected_Waiting_for_Other_Participants:
      "연결되었습니다. 다른 참가자를 기다리는 중...",
    Powered_by: "에 의해 구동",
    Connecting: "연결 중...",
    Local_Pike: "로컬 파이크",
    Would_you_like_to_save_image: "이미지를 저장하시겠습니까?",
    Message: "메세지",
    Location_Not_shared: "위치: 공유되지 않음",
    Location: "위치",
    Tools: "도구",
    Ask_PO_to_Turn_on_Location: "속성 소유자에게 위치 활성화 요청",
    Preparing_image_for_markup: "마크업을 위한 이미지 준비 중...",
    Download_Message: "원격 평가 애플리케이션 다운로드",
    Disclaimer:
      "App Store에서 RemoteVal 애플리케이션을 다운로드해야 합니다. 다운로드가 완료되면 문자/이메일 메시지 초대를 다시 열고 링크를 한 번 더 클릭하면 자동으로 통화에 참여합니다.",
    MeasurementLinkDisclaimer:
      "App Store에서 RemoteVal 애플리케이션을 다운로드해야 합니다. 다운로드가 완료되면 문자/이메일 메시지 초대를 다시 열고 링크를 한 번 더 클릭하면 자동으로 검사가 시작됩니다.",
    Zoom_Level: "줌 레벨",
    enter_cell_phone: "휴대 전화를 입력",
    Email: "이메일",
    Cell_Phone: "휴대전화",
    Participant_Name: "참가자 이름",
    Invite_Participant: "참가자 초대",
    Join_secure_video_call_by_clicking_on_the_following_link:
      "다음 링크를 클릭하여 보안 영상 통화에 참여하세요.",
    There_are_no_Attachments_available: "사용 가능한 첨부 파일이 없습니다.",
    Drop_Or_Select_Your_File: "파일 삭제 또는 선택",
    Attach_Document: "문서 첨부",
    Enter_Your_Name: "당신의 이름을 입력",
    I_confirm_that_I_have_read_and_agree_to_the:
      "나는 읽었으며 동의함을 확인합니다",
    Search_or_Add_Captured_Image_Label: "캡처된 이미지 레이블 검색 또는 추가",
    Press_Enter_or_Click_on_Add_New_Label_to_add_new_Label:
      "Enter 키를 누르거나 새 레이블이 있는 캡처된 이미지에 새 레이블 추가를 클릭합니다.",
    To: "NS",
    Everyone: "모든 사람",
    Type_a_message: "메시지 입력",
    Are_you_sure_you_want_to_delete: "삭제 하시겠습니까 ?",
    Room_is_full_Please_try_again_later:
      "방이 가득 찼습니다. 나중에 다시 시도하십시오",
    Comment: "논평",
    Room_2_Measurement: "방 2 측정",
    Room_1_Measurement: "방 1 측정",
    Room_2_Image: "방 2 이미지",
    Room_1_Image: "방 1 이미지",
    No_of_Rooms: "객실 수",
    Property_Type: "속성 유형",
    House: "집",
    Inspection_Form: "검사 양식",
    Instructions: "지침",
    Guide_user_to_position_camera_on_the_target:
      "사용자가 대상에 카메라를 배치하도록 안내",
    Click_on_the_Label_once_to_capture_labeled_image:
      "레이블이 지정된 이미지를 캡처하려면 레이블을 한 번 클릭하십시오.",
    Select_the_floor_of_the_Home_Room_Type_and_a_Room_Name:
      "집의 층, 방 유형 및 방 이름을 선택하십시오.",
    Choose_GLA_or_Non_GLA: "GLA 또는 비 GLA를 선택합니다.",
    Click_on_the_Start_Measurement_button: "측정 시작 버튼 클릭",
    Click_the_Auto_Corner_Detection_to_On_Off:
      '"자동 모서리 감지"를 클릭하여 켜기/끄기',
    Position_vertical_Green_Floor:
      "룸 코너에 수직 그린 플로어 마커를 배치하고 바닥에 닿는지 확인합니다.",
    App_will_auto_mark_a_corner:
      '앱이 모서리를 자동으로 표시하거나 "수동으로 모서리 캡처" 버튼을 탭합니다.',
    Guide_the_user_to_walk_to_each_corner_of_the_room_to_mark_each_corner:
      "사용자가 방의 각 모서리로 걸어가 각 모서리를 표시하도록 안내합니다.",
    When_last_line_connects:
      '마지막 줄이 시작점에 연결되면 빨간색으로 바뀝니다. 앱이 자동으로 연결되어 스케치를 완료하거나 "코너 연결 및 스케치 완료" 버튼을 사용하여 측정을 완료할 수 있습니다.',
    button_to_view_final_sketch:
      '최종 스케치를 보려면 "완료된 평면도 스케치 표시" 버튼을 클릭하십시오.',
    Are_You_Sure_You_Want_to_Leave_This_Call: "이 전화를 끊으시겠습니까?",
    Street_View: "스트리트 뷰",
    Select_Measurement_Type: "측정 유형 선택",
    Floor: "바닥",
    Ceiling: "천장",
    View_Sketches_by_Floor: "층별 스케치 보기",
    Uploading_Image: "이미지 업로드 중",
    Call_Setting: "통화 설정",
    Allow_access_to_your_Microphone_Click_the_padlock:
      "마이크에 대한 액세스를 허용합니다. 자물쇠 클릭",
    to_the_left_of_the_address_bar: "주소 표시줄 왼쪽에",
    Speakers: "스피커",
    Allow_access_to_your_Camera_Click_the_padlock:
      "카메라에 대한 액세스를 허용합니다. 자물쇠 클릭",
    to_the_address_bar: "주소 표시줄에.",
    Failed_to_start_the_video_camera_Please_select_another_video_camera:
      "비디오 카메라를 시작하지 못했습니다. 다른 비디오 카메라를 선택하십시오.",
    Title: "제목",
    New_Note: "새 메모...",
    There_are_no_Notes_available: "사용 가능한 메모가 없습니다.",
    Are_you_sure_you_want_to_delete_this_note: "이 메모를 삭제하시겠습니까?",
    Network_Type: "네트워크 유형",
    Netword_Strength: "넷워드 강점",
    To_share_live_video:
      "라이브 영상을 공유합니다. 브라우저 설정에서 카메라 권한을 켜주세요.",
    click_here: "여기를 클릭",
    to_reload_the_page: "페이지를 새로고침합니다.",
    we_cant_access_your_microphone:
      "마이크에 액세스할 수 없습니다. 액세스 권한을 부여하려면 브라우저 설정으로 이동한 다음",
    Questionnaire_Form: "설문지 양식",
    No_images_found: "이미지를 찾을 수 없습니다!",
    Image: "영상",
    Captured_Images: "캡처한 이미지",
    Share_your_screen: "화면 공유",
    Share_your_live_video: "라이브 비디오를 공유하시겠습니까?",
    This_will_stop_your_video:
      "이렇게 하면 동영상이 중지됩니다. 계속하시겠습니까?",
    This_will_stop_screen_share:
      "이렇게 하면 화면 공유가 중지됩니다. 계속하시겠습니까?",
    Room_Area_Label: "방/면적 레이블",
    Room_Area_Name: "방/지역 이름(예: 침실 1, 욕실 2 등)",
    Room_Area_Name_Placeholder: "방/지역 이름",
    You: "너",
    Are_you_sure_you_want_to_delete_captured_image:
      "캡처한 이미지를 삭제하시겠습니까?",
    Start_Over: "다시 시작 - 평면도를 삭제하시겠습니까?",
    Are_You_Sure_You_Want_to_Exit_Measurement_process:
      "측정 프로세스를 종료하시겠습니까?",
    Labels: "라벨",
    Select_Language: "언어 선택",
    This_call_is_being_recorded: "이 화상 통화가 기록되고 있습니다",
    This_call_is_being_recorded_by_your_appraiser_for_their_private_work_file:
      "평가자가 개인 작업 파일에 대해 이 통화를 녹음하고 있습니다.",
    It_will_not_be_shared: `귀하의 모기지를 포함한 외부 당사자와 공유되지 않습니다.
    대출 기관 및 감정평가사는 다운로드할 수 없습니다.
    RemoteVal 사이트. 평가자가 완료하는 데만 사용됩니다.
    당신의 평가.`,
    If_you_do_not_wish_your_screen_to_be_recorded_please_inform_your_appraiser_now:
      "화면이 녹화되는 것을 원하지 않으면 지금 평가자에게 알리십시오.",
    Measurement: "측정",
    Create_Measurement: "측정 생성",
    Fencing: "펜싱",
    Show: "보여 주다",
    Hide: "숨다",
    Live_Floor_Plan: "라이브 평면도",
    Expand: "확장하다",
    Collapse: "무너지다",
    Instruction_for_Call_Settings: "통화 설정 지침",
    Speaker_Mic_Camera_can_be_tested_via_call_settings:
      '스피커, 마이크 및 카메라는 "더보기" 메뉴의 통화 설정을 통해 테스트할 수 있습니다.',
    To_Check_the_call_settings_now_click_on_the_below_button:
      "지금 통화 설정을 확인하려면 아래 버튼을 클릭하십시오.",
    Call_recording_by_virtual_inspector:
      "이 화상 통화는 가상 검사관이 개인 작업 파일과이를 요청한 조직의 내부 감사 목적으로 기록하고 있습니다. 외부 당사자와 공유되지 않습니다.",
    Call_and_Screen_Recording_Opt_Out:
      "화면 과이 호출을 녹음하기를 원하지 않으면 지금 가상 검사관에게 알려주십시오.",
    Test_Audio_Video_Components_Button:
      "스피커, 마이크 및 카메라는 아래 버튼을 클릭하여 테스트 할 수 있습니다.",
  },
  BUTTONS: {
    Re_Connect: "다시 연결",
    End_Session: "세션 종료",
    Chat: "채팅",
    Participants: "참가자들",
    Notes: "노트",
    Attach_Document: "문서 첨부",
    Questionnaire: "설문지",
    Labels: "라벨",
    Pointer: "바늘",
    Markup: "마크업",
    Flash_Off: "플래시 끄기",
    Flash_On: "플래시 켜기",
    Call_Recording: "통화 녹음",
    Capture_Image: "이미지 캡처",
    Screen_Share: "화면 공유",
    Stop_Screen_Share: "화면 공유 중지",
    Call_Settings: "통화 설정",
    Clear_All: "모두 지우기",
    Exit: "출구",
    Save: "구하다",
    Close: "닫다",
    Save_and_Exit: "저장 및 종료",
    Continue: "계속하다",
    Street_View: "스트리트 뷰",
    Open_in_App: "앱에서 열기",
    Privacy_Policy: "개인 정보 정책",
    Invite: "초대",
    Join: "가입하다",
    Add_Captured_Image_Label: "캡처된 이미지 레이블 추가",
    No: "아니요",
    Yes: "예",
    Delete: "삭제",
    Cancel: "취소",
    RETRY: "다시 해 보다",
    OK: "좋아요",
    Back: "뒤",
    Leave_Call: "전화 끊기",
    Got_It: "알았어요",
    Start_New_Inspection: "새 검사 시작",
    Resume_Existing_Inspection: "기존 검사 재개",
    View_All_Current_Sketch: "현재 스케치 모두 보기",
    Open_Sketch: "스케치 열기",
    Auto_Corner_Detection_ON: "자동 코너 감지 ON",
    Auto_Corner_Detection_OFF: "자동 코너 감지 끄기",
    Manually_Capture_Corner: "수동으로 코너 캡처",
    Undo_Last_Corner_Capture: "마지막 코너 캡처 실행 취소",
    Connect_Corner_Finish_Sketch: "모서리 연결 및 스케치 완료",
    Capture_3D_Room_Height: "3D 룸 높이 캡처",
    Set_Fixed_3D_Room_Height: "고정 3D 방 높이 설정",
    Capture_Door: "캡처 도어",
    Set_Door: "문을 설정",
    Show_Finished_Floor_Plan_Sketch: "완성된 평면도 스케치 표시",
    Back_to_Previous_Screen: "이전 화면으로 돌아가기",
    Add_Room: "방 추가",
    Publish_the_floor_plan: "평면도 게시",
    Exit_Return_to_Inspection: "종료 및 검사로 돌아가기",
    Audio: "오디오",
    Video: "동영상",
    Microphone: "마이크로폰",
    Microphone_not_found: "마이크를 찾을 수 없음",
    Microphone_Blocked: "마이크 차단",
    Default: "기본",
    Grant_Permission: "권한 부여",
    Speaker_Blocked: "스피커 차단됨",
    Play_Test_Sound: "테스트 사운드 재생",
    Preview: "시사",
    Camera: "카메라",
    Camera_not_found: "카메라를 찾을 수 없음",
    Camera_Blocked: "카메라가 차단됨",
    Done: "완료",
    View: "보다",
    Capture_Labeled_Images: "레이블이 지정된 이미지 캡처",
    Capture_Measurements: "측정 캡처",
    Instructions: "지침",
    Start_Measurement_Process: "측정 프로세스 시작",
    Flash_Light_Not_Supported:
      "게스트 장치에서 지원되지 않거나 준비되지 않은 원격 손전등",
  },
  TOOLTIP: {
    Mute_My_Audio: "내 오디오 음소거",
    Unmute_My_Audio: "내 오디오 음소거 해제",
    Stop_My_Video: "내 비디오 중지",
    Start_My_Video: "내 비디오 시작",
    Capture_Images_with_No_Labels: "레이블이 없는 이미지 캡처",
    Start_Inspection: "검사 시작",
    Chat: "채팅",
    More_Options: "추가 옵션",
    End_Call: "통화 종료",
    Unpin_Video: "비디오 고정 해제",
    Pin_Video: "핀 비디오",
    Add_New_Note: "새 메모 추가",
    Refresh: "새로 고치다",
    View_More: "더보기",
    Invite_Participant: "참가자 초대",
    Delete_Image: "이미지 삭제",
  },
  COMMON_STRING: {
    INVITATION_SENT_SUCCESS_MESSAGE: "초대장이 성공적으로 전송되었습니다.",
    IOS_SAFARI_HELP: "최상의 결과를 얻으려면 Safari를 사용하는 것이 좋습니다.",
    UNEXPECTED_ERROR:
      "예기치 않은 오류가 발생했습니다. 나중에 다시 시도하십시오.",
    CALL_LINK_NOT_FOUND: "통화 링크를 찾을 수 없습니다.",
    CREATE_BOOKMARK_MESSAGE: "관리자에게 책갈피 추가를 요청하십시오",
    ATTACHMENT_UPLOAD_SIZE_EXCEEDED: "파일 크기는 10MB 미만이어야 합니다.",
    ATTACHMENT_UPLOAD_UNSUPPORTED_FORMAT: "지원되지 않는 파일 형식",
    LIVE_POINTER_STARTED:
      "라이브 포인터를 시작했습니다. 드래그하여 포인터를 이동할 수 있습니다.",
    LIVE_POINTER_STOPPED: "중지된 라이브 포인터",
    APPRAISAL_EXIT_APPROVED: "종료가 성공적으로 확인되었습니다.",
    APPRAISAL_EXIT_DENIED: "종료가 거부되었습니다.",
    MARKUP_CLOSED: "마크업이 중지됨",
    PRIVACY_POLICY_FAIL: "유효한 개인정보 보호정책 URL을 찾을 수 없습니다!",
    SWITCH_CAMERA_REQUEST_REJECTED:
      "집 소유자가 요청한 카메라 보기를 공유하지 않았습니다.",
  },
  ERROR_MESSAGES: {
    Please_Select_Cellphone_or_Email: "핸드폰이나 이메일을 선택해주세요",
    This_field_is_required: "이 필드는 필수 항목입니다.",
    Cell_Phone_Must_Be_Valid: "휴대폰은 유효해야 합니다.",
    Name_is_Required: "이름은 필수 항목입니다.",
    Name_Must_Be_Valid: "이름은 유효해야 합니다.",
    Invalid_Text: "잘못된 텍스트",
    Email_Must_Be_Valid: "이메일은 유효해야 합니다",
    Name_must_be: "이름은 다음과 같아야 합니다.",
    characters_max: "최대 문자",
    Please_select_the_Floor: "층을 선택해 주세요",
    Please_enter_the_Room_Area_Name: "객실/지역 이름을 입력하세요.",
    You_need_to_use_HTTPS_for_selecting_audio_output_device:
      "오디오 출력 장치를 선택하려면 HTTPS를 사용해야 합니다.",
    Something_went_wrong: "문제가 발생했습니다. 나중에 다시 시도하십시오.",
    Oops_Call_duration_is_over_and_session_is_ended:
      "앗! 통화 시간이 종료되고 세션이 종료됩니다.",
    No_participant_has_joined_yet_Ending_the_call_session:
      "아직 참가한 참가자가 없습니다! 통화 세션 종료",
  },
  starting_inspection: "검사 시작",
  starting_measurement: "측정 시작",
  error: "오류",
  contact_us: "문의하기",
  support_popup_msg:
    "죄송합니다. 문제가 발생했습니다. 잠시 후 다시 시도하거나 지원팀에 문제를 신고하세요.",
  select_po: "부동산 소유자를 선택하여 검사 프로세스 시작",
  request_po_for_camera:
    "검사 프로세스를 시작하려면 부동산 소유자에게 카메라 보기를 켜달라고 요청하세요.",
  wait_until_po_joins:
    "부동산 소유자가 검사 프로세스를 시작하기 위해 통화에 참여할 때까지 기다리십시오.",
  select_measurement_method: "측정 방법 선택",
  inspection_inprogress: "검사는 이미 진행 중",
  model_msg_retry:
    "부동산 소유자와 통신 채널을 설정할 수 없습니다. 다시 시도하십시오.",
  model_msg_leaveCall:
    "양쪽에 인터넷 연결 문제가 있는 것 같으니 현재 통화를 끊고 다시 연결하십시오.",
  please_wait: "기다리세요...",
  loading_sketch: "스케치 로드 중...",
  connection_timeout_msg:
    "연결 시간이 초과되었습니다. 비정상적인 일이 발생했으며 응답하는 데 예상보다 시간이 오래 걸립니다. 다시 시도해 주세요.",
  previous_measurement_prblm_msg:
    "죄송합니다. 이전 측정에서 문제가 발생했습니다. 새로운 측정 프로세스로 다시 시도하십시오.",
  measurement_retry_msg:
    "측정 프로세스가 시작되지 않았습니다. 다시 시도하십시오.",
  opensketch_retry_msg:
    "스케치 열기 프로세스가 시작되지 않았습니다. 다시 시도하십시오.",
};
