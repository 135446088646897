import * as actionTypes from "./actionTypes";
import CallConfigService from "./../services/callConfigService";

export const startLoadCallConfig = () => {
  return {
    type: actionTypes.START_LOAD_CALL_CONFIG,
  };
};

export const successLoadCallConfig = (payload) => {
  return {
    type: actionTypes.LOAD_CALL_CONFIG_SUCCESS,
    payload,
  };
};

export const FailedLoadCallConfig = (payload) => {
  
  return {
    type: actionTypes.LOAD_CALL_CONFIG_ERROR,
    payload,
  };
};

export const LoadCallConfig = (inviteUUID) => {
  return (dispatch) => {
    dispatch(startLoadCallConfig());
    return CallConfigService.get(inviteUUID)
      .then((configs) => {
        return dispatch(successLoadCallConfig(configs));
      })
      .catch((err) => {
        dispatch(FailedLoadCallConfig(err));
      });
  };
};
