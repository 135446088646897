import React, { Component } from "react";
import "./galleryZoomModel.scss";
import { connect } from "react-redux";
import classnames from "classnames";
import AsyncImage from "../../common/AsyncImage";

const PREV_DIRECTION = "PREV";
const NEXT_DIRECTION = "NEXT";

class GalleryZoomModel extends Component {
  constructor(props) {
    super();
    this.state = {
      currentImageIndex: 0,
    };
  }

  componentDidMount() {
    this.setState({
      currentImageIndex: this.props.currentImageIndex ?? 0,
    });
  }

  updateCounter(direction) {
    let { currentImageIndex } = this.state;
    const { images } = this.props.captureImages;

    switch (direction) {
      case PREV_DIRECTION:
        currentImageIndex =
          currentImageIndex === images.length - 1
            ? currentImageIndex
            : currentImageIndex + 1;
        break;
      case NEXT_DIRECTION:
        currentImageIndex = currentImageIndex === 0 ? 0 : currentImageIndex - 1;
        break;
      default:
        break;
    }
    this.setState({
      currentImageIndex,
    });
  }

  render() {
    const { currentImageIndex } = this.state;
    const { images } = this.props.captureImages;
    return (
      <>
        <div className="download_images_sec">
          <div className="download_img_header">
            <div className="image_count">
              <p>
                {images?.length - currentImageIndex} / {images?.length}
              </p>
            </div>
            <div className="download_img_btn">
              <div className="download_img_icon">
                {/* <button type="button">
                  <i className="icon-play"></i>
                </button>
                <button type="button">
                  <i className="icon-full-screen"></i>
                </button>
                <button type="button">
                  <i className="icon-download2"></i>
                </button> */}
                <button
                  type="button"
                  onClick={() => {
                    this.props.hideGalleryZoomModel();
                  }}
                >
                  <i className="icon-close-image"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="left_arrow">
            <button
              type="button"
              className={classnames({
                arrowDisabled: images?.length - 1 === currentImageIndex,
              })}
              disabled={images?.length - 1 === currentImageIndex}
              onClick={() => this.updateCounter(PREV_DIRECTION)}
            >
              <i className="icon-previous-arrow"></i>
            </button>
          </div>
          <div className="right_arrow">
            <button
              type="button"
              className={classnames({
                arrowDisabled: currentImageIndex === 0,
              })}
              disabled={currentImageIndex === 0}
              onClick={() => this.updateCounter(NEXT_DIRECTION)}
            >
              <i className="icon-next-arrow"></i>
            </button>
          </div>
          <div className="image_content">
            {this.props.isAwsImage ? (
              <img
                src={images?.[currentImageIndex].original}
                alt="Loading Captured Pic..."
              />
            ) : (
              <AsyncImage imageUrl={images?.[currentImageIndex].original} />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = ({ captureImages }) => ({
  captureImages: captureImages || {},
});

const connectedGalleryZoomModel = connect(
  mapStateToProps,
  mapDispatchToProps
)(GalleryZoomModel);
export { connectedGalleryZoomModel as GalleryZoomModel };
