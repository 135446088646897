import * as actionTypes from "../actions/actionTypes";
import update from 'immutability-helper';

export const defaultState = {
  loading: false,
  data: [],
  error: null,
};

export default (state = defaultState, action = {}) => {




  switch (action.type) {




    case actionTypes.UPDATE_PARTICIPATN_TRACK_STATUS: {



      
      const index = state.data.findIndex(obj => obj.sid == action.payload.sid);

      if (state.data.length == 0 || index == -1) {


        return {
          ...state,
          loading: false,
          data: [
            ...state.data,
            action.payload
          ],
          error: null,
        };




      } else {

        return {
          ...state,
          loading: false,
          data: state.data.map((todo, index) => {



            if (action.payload.sid === todo.sid) {



              return Object.assign({}, todo, {
                [action.payload.track]: action.payload.status
              })
            }


            return todo

          }),

          error: null,
        };




      }








    }





    default:
      return state;
  }
};
