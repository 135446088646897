import questionnaireService from "../services/questionnaireService";
import * as actionTypes from "./actionTypes";

export const startGetQuestionnaire = () => {
  return {
    type: actionTypes.GET_QUESTIONNAIRE_START,
  };
};

export const getQuestionnaireSuccess = (payload) => {
  return {
    type: actionTypes.GET_QUESTIONNAIRE_SUCCESS,
    payload,
  };
};

export const getQuestionnaireError = (payload) => {
  return {
    type: actionTypes.GET_QUESTIONNAIRE_ERROR,
    payload,
  };
};

export function getQuestionnaire(jobOrderId) {
  return (dispatch) => {
    dispatch(startGetQuestionnaire());
    return questionnaireService
      .getQuestionnaire(jobOrderId)
      .then(function (data) {
        return dispatch(getQuestionnaireSuccess(data));
      })
      .catch((err) => {
        return dispatch(getQuestionnaireError(err));
      });
  };
}

export function saveQuestionnaire(data) {
  return (dispatch) => {
    return questionnaireService
      .saveQuestionnaire(data)
      .then(function (data) {
        return data;
      })
      .catch((err) => {
        return;
      });
  };
}
