import React, { Component } from "react";
import "./errorPage.scss";
export default class ErrorPage extends Component {

  constructor(props) {
    super();
  }

  render() {

    console.log("test");

    return (
      <section className="callend-pagenot-found">
        <div className="box-center">
          <div className="notfound-img">
            <i className="icon-no-page-found"></i>
          </div>
          <div className="content">
            <h2>{this.props.errorMessage}</h2>
          </div>
        </div>
      </section>
    );
  }
}
