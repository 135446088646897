import axios from "axios";
import AuthAPI from "./authApi";
import VideoCallAPI from "./videoCallApi";
import PictureAPI from "./pictureApi";
import MarkupAPI from "./markupApi";
import CallConfigAPI from "./callConfig";
import BookmarkAPI from "./bookmarkApi";
import NoteAPI from "./noteApi";
import AttachmentAPI from "./attachmentApi";
import QuestionnaireAPI from "./questionnaireApi";
import _ from "lodash";
const BASEURL = process.env.REACT_APP_API_END_POINT;
const TIMEOUTFORUPLOADFILE = 1800000;
class API {
  __auth = new AuthAPI();
  __bookmark = new BookmarkAPI();
  __videoCall = new VideoCallAPI();
  __picture = new PictureAPI();
  __callConfig = new CallConfigAPI();
  __markup = new MarkupAPI();
  __note = new NoteAPI();
  __attachment = new AttachmentAPI();
  __questionnaire = new QuestionnaireAPI();
  api = axios.create({
    baseURL: BASEURL,
    transformRequest: [(data) => JSON.stringify(data)],
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  formsApi = axios.create({
    baseURL: BASEURL,
    timeout: TIMEOUTFORUPLOADFILE,
    headers: { "Content-Type": "multipart/form-data" },
  });

  get auth() {
    return this.__auth;
  }

  get videoCall() {
    return this.__videoCall;
  }

  get bookmark() {
    return this.__bookmark;
  }

  get picture() {
    return this.__picture;
  }

  get markup() {
    return this.__markup;
  }

  get callConfig() {
    return this.__callConfig;
  }

  get note() {
    return this.__note;
  }

  get attachment() {
    return this.__attachment;
  }

  get questionnaire() {
    return this.__questionnaire;
  }

  get(url, ...args) {
    return this.sendRequestInternal(this.api.get, url, ...args);
  }

  post(url, ...args) {
    return this.sendRequestInternal(this.api.post, url, ...args);
  }

  patch(url, ...args) {
    return this.sendRequestInternal(this.api.patch, url, ...args);
  }

  delete(url, ...args) {
    return this.sendRequestInternal(this.api.delete, url, ...args);
  }

  put(url, ...args) {
    return this.sendRequestInternal(this.api.put, url, ...args);
  }

  postMultipartFormData(url, data, onUploadProgress) {
    return this.sendRequestInternal(
      (u, a) => this.formsApi.post(u, a, { onUploadProgress }),
      url,
      data
    );
  }

  // sendRequestInternal(requestFunc, url, ...args) {
  //   return requestFunc(url, ...args);
  // }
  sendRequestInternal(requestFunc, url, ...args) {
    let token;
    token = localStorage.getItem("token");
    if (token) {
      this.api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      this.formsApi.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
    }
    return requestFunc(url, ...args)
      .then((response) => response)
      .catch((error) => {
        if (error.response) {
          if (
            _.get(error, ["response", "data", "code"], 500) === 401 ||
            _.get(error, ["response", "data", "code"], "") === 500 ||
            _.get(error, ["response", "data", "code"], "") === 503
          ) {
            // localStorage.clearAll();
            window.location = "/unauthorised";
          }
        }
        throw error;
      });
  }
}

export default new API();
