import * as actionTypes from "../actions/actionTypes";

export const defaultState = {
  cachedCapturedImages: {},
};

export default function (state = defaultState, action = {}) {
  if (action.type === actionTypes.ADD_CACHED_CAPTURED_IMAGE) {
    const cached = {};
    cached[action.key] = action.value;
    return {
      ...state,
      cachedCapturedImages: {
        ...state.cachedCapturedImages,
        ...cached,
      },
    };
  } else {
    return {
      ...state,
    };
  }
}
