export const TRANSLATIONS_EN = {
  WEB_LABELS: {
    Secure_Video_Call_Session_Expired: "Secure Video Call Session Expired",
    Secure_Video_Call_Ended: "Secure Video Call Ended",
    Thank_you_for_connecting_with_us: "Thank you for connecting with us!",
    Connected_Waiting_for_Other_Participants:
      "Connected. Waiting for Other Participants...",
    Powered_by: "Powered by",
    Connecting: "Connecting...",
    Local_Pike: "Local Pike",
    Would_you_like_to_save_image: "Would you like to save image?",
    Message: "Message",
    Location_Not_shared: "Location: Not shared",
    Location: "Location",
    Tools: "Tools",

    Ask_PO_to_Turn_on_Location: "Ask Property Owner to Turn on Location",
    Preparing_image_for_markup: "Preparing image for markup...",
    Download_Message: "Download Remote Appraisal Application",
    Disclaimer: `It is necessary to download the RemoteVal application from the
        App Store. Once the download is completed, please reopen your
        text/email message invite and click the link once more to
        automatically join the call.`,
    MeasurementLinkDisclaimer:
      "It is necessary to download the RemoteVal application from the App Store. Once the download is completed, please reopen your text/email message invite and click the link once more to automatically start the inspection.",
    Zoom_Level: "Zoom Level",
    enter_cell_phone: "enter cell phone",
    Email: "Email",
    Cell_Phone: "Cell Phone",
    Participant_Name: "Participant Name",
    Invite_Participant: "Invite Participant",
    Join_secure_video_call_by_clicking_on_the_following_link:
      "Join secure video call by clicking on the following link:",
    There_are_no_Attachments_available: "There are no Attachments available.",
    Drop_Or_Select_Your_File: "Drop Or Select Your File",
    Attach_Document: "Attach Document",
    Enter_Your_Name: "Enter Your Name",
    I_confirm_that_I_have_read_and_agree_to_the:
      "I confirm that I have read and agree to the",
    Search_or_Add_Captured_Image_Label: "Search or Add Captured Image Label",
    Press_Enter_or_Click_on_Add_New_Label_to_add_new_Label:
      "Press Enter or Click on Add New Label to Captured Image with New Label",
    To: "To",
    Everyone: "Everyone",
    Type_a_message: "Type a message",
    Are_you_sure_you_want_to_delete: "Are you sure you want to delete ?",
    Room_is_full_Please_try_again_later: "Room is full. Please try again later",
    Comment: "Comment",
    Room_2_Measurement: "Room 2 Measurement",
    Room_1_Measurement: "Room 1 Measurement",
    Room_2_Image: "Room 2 Image",
    Room_1_Image: "Room 1 Image",
    No_of_Rooms: "No. of Rooms",
    Location: "Location",
    Property_Type: "Property Type",
    House: "House",
    Inspection_Form: "Inspection Form",
    Instructions: "Instructions",
    Guide_user_to_position_camera_on_the_target:
      "Guide user to position camera on the target",
    Click_on_the_Label_once_to_capture_labeled_image:
      "Click on the Label once to capture labeled image.",
    Select_the_floor_of_the_Home_Room_Type_and_a_Room_Name:
      "Select the floor of the Home, Room Type and a Room Name.",
    Choose_GLA_or_Non_GLA: "Choose GLA or Non-GLA.",
    Click_on_the_Start_Measurement_button:
      "Click on the Start Measurement button",
    Click_the_Auto_Corner_Detection_to_On_Off:
      'Click the "Auto Corner Detection" to On/Off',
    Position_vertical_Green_Floor:
      "Position vertical Green Floor Marker in a Room Corner and make sure it touches the floor.",
    App_will_auto_mark_a_corner:
      'App will auto-mark a corner or tap the "Manually Capture Corner" button',
    Guide_the_user_to_walk_to_each_corner_of_the_room_to_mark_each_corner:
      "Guide the user to walk to each corner of the room to mark each corner",
    When_last_line_connects: `When last line connects to starting point, it will turn red.
        App will auto-connect and complete sketch or you can use the
        "Connect Corner & Finish Sketch" button to complete,
        measurement.`,
    button_to_view_final_sketch:
      'Click on the "Show Finished Floor Plan Sketch" button to view final sketch.',
    Are_You_Sure_You_Want_to_Leave_This_Call:
      "Are You Sure You Want to Leave This Call?",
    Street_View: "Street View",
    Select_Measurement_Type: "Select Measurement Type",
    Floor: "Floor",
    Ceiling: "Ceiling",
    View_Sketches_by_Floor: "View Sketches by Floor",
    Uploading_Image: "Uploading Image",
    Call_Setting: "Call Setting",
    Allow_access_to_your_Microphone_Click_the_padlock:
      "Allow access to your Microphone. Click the padlock",
    to_the_left_of_the_address_bar: "to the left of the address bar.",
    Speakers: "Speakers",
    Allow_access_to_your_Camera_Click_the_padlock:
      "Allow access to your Camera. Click the padlock",
    to_the_address_bar: "to the address bar.",
    Failed_to_start_the_video_camera_Please_select_another_video_camera:
      "Failed to start the video camera. Please select another video camera.",
    Title: "Title",
    New_Note: "New Note...",
    There_are_no_Notes_available: "There are no Notes available.",
    Are_you_sure_you_want_to_delete_this_note:
      "Are you sure you want to delete this note?",
    Network_Type: "Network Type",
    Netword_Strength: "Netword Strength",
    To_share_live_video:
      "To share live video. please turn on the camera permission in your browser settings then",
    click_here: "click here",
    to_reload_the_page: "to reload the page.",
    we_cant_access_your_microphone:
      "we can't access your microphone. To give access, Go to your browser settings and then",
    Questionnaire_Form: "Questionnaire Form",
    No_images_found: "No images found!",
    Image: "Image",
    Captured_Images: "Captured Images",
    Share_your_screen: "Share your screen",
    Share_your_live_video: "Share your live video?",
    This_will_stop_your_video:
      "This will stop your video. Do you want to continue?",
    This_will_stop_screen_share:
      "This will stop screen share. Do you want to continue?",
    Floor: "Floor",
    Room_Area_Label: "Room/Area Label",
    Room_Area_Name: "Room/Area Name (e.g. Bedroom 1, Bath 2, etc.)",
    Room_Area_Name_Placeholder: "Room/Area Name",
    You: "You",
    Are_you_sure_you_want_to_delete_captured_image:
      "Are you sure you want to delete captured image?",
    Start_Over: "Start Over - Are You Sure You Want to Delete Floor Plans?",
    Are_You_Sure_You_Want_to_Exit_Measurement_process:
      "Are You Sure You Want to Exit Measurement process?",
    Labels: "Labels",
    Select_Language: "Select Language",
    This_call_is_being_recorded: "This video call is being recorded",
    This_call_is_being_recorded_by_your_appraiser_for_their_private_work_file:
      "This call is being recorded by your appraiser for their private work file.",
    It_will_not_be_shared: `It will not be shared with any outside party including your mortgage
    lender, and your appraiser will not be able to download it from the
    RemoteVal site. It will be used solely for the appraiser to complete
    your appraisal.`,
    If_you_do_not_wish_your_screen_to_be_recorded_please_inform_your_appraiser_now:
      "If you do not wish your screen to be recorded please inform your appraiser now.",
    Measurement: "Measurement",
    Create_Measurement: "Create Measurement",
    Fencing: "Fencing",
    Show: "Show",
    Hide: "Hide",
    Live_Floor_Plan: "Live Floor Plan",
    Expand: "Expand",
    Collapse: "Collapse",
    Instruction_for_Call_Settings: "Instruction for Call Settings",
    Speaker_Mic_Camera_can_be_tested_via_call_settings:
      'Speaker, Mic & Camera can be tested via call settings from the "more" menu',
    To_Check_the_call_settings_now_click_on_the_below_button:
      "To Check the call settings now click on the below button.",
    Call_recording_by_virtual_inspector:
      "This video call is being recorded by your virtual inspector for their private work file and for internal auditing purposes by the organization that requested it. It will not be shared with any outside party.",
    Call_and_Screen_Recording_Opt_Out:
      "If you do not wish your screen and this call to be recorded, please inform your virtual inspector now.",
    Test_Audio_Video_Components_Button:
      "Speaker, Microphone & Camera can be tested by clicking on the button below.",
  },
  BUTTONS: {
    Re_Connect: "Re-Connect",
    End_Session: "End Session",
    Chat: "Chat",
    Participants: "Participants",
    Notes: "Notes",
    Attach_Document: "Attach Document",
    Questionnaire: "Questionnaire",
    Labels: "Labels",
    Pointer: "Pointer",
    Markup: "Markup",
    Flash_Off: "Flash Off",
    Flash_On: "Flash On",
    Call_Recording: "Call Recording",
    Capture_Image: "Capture Image",
    Screen_Share: "Screen Share",
    Stop_Screen_Share: "Stop Screen Share",
    Call_Settings: "Call Settings",
    Clear_All: "Clear All",
    Exit: "Exit",
    Save: "Save",
    Close: "Close",
    Save_and_Exit: "Save and Exit",
    Continue: "Continue",
    Markup: "Markup",
    Street_View: "Street View",
    Open_in_App: "Open in App",
    Privacy_Policy: "Privacy Policy",
    Invite: "Invite",
    Join: "Join",
    Add_Captured_Image_Label: "Add Captured Image Label",
    No: "No",
    Yes: "Yes",
    Delete: "Delete",
    Cancel: "Cancel",
    RETRY: "RETRY",
    OK: "OK",
    Back: "Back",
    Leave_Call: "Leave Call",
    Got_It: "Got It",
    Start_New_Inspection: "Start New Inspection",
    Resume_Existing_Inspection: "Resume Existing Inspection",
    View_All_Current_Sketch: "View All Current Sketch(es)",
    Open_Sketch: "Open Sketch",
    Auto_Corner_Detection_ON: "Auto Corner Detection ON",
    Auto_Corner_Detection_OFF: "Auto Corner Detection OFF",
    Manually_Capture_Corner: "Manually Capture Corner",
    Undo_Last_Corner_Capture: "Undo Last Corner Capture",
    Connect_Corner_Finish_Sketch: "Connect Corner & Finish Sketch",
    Capture_3D_Room_Height: "Capture 3D Room Height",
    Set_Fixed_3D_Room_Height: "Set Fixed 3D Room Height",
    Capture_Door: "Capture Door",
    Set_Door: "Set Door",
    Show_Finished_Floor_Plan_Sketch: "Show Finished Floor Plan Sketch",
    Back_to_Previous_Screen: "Back to Previous Screen",
    Add_Room: "Add Room",
    Publish_the_floor_plan: "Publish Floor Plan",
    Exit_Return_to_Inspection: "Exit & Return to Inspection",
    Audio: "Audio",
    Video: "Video",
    Microphone: "Microphone",
    Microphone_not_found: "Microphone not found",
    Microphone_Blocked: "Microphone Blocked",
    Default: "Default",
    Grant_Permission: "Grant Permission",
    Speaker_Blocked: "Speaker Blocked",
    Play_Test_Sound: "Play Test Sound",
    Preview: "Preview",
    Camera: "Camera",
    Camera_not_found: "Camera not found",
    Camera_Blocked: "Camera Blocked",
    Done: "Done",
    View: "View",
    Capture_Labeled_Images: "Capture Labeled Images",
    Capture_Measurements: "Capture Measurements",
    Instructions: "Instructions",
    Start_Measurement_Process: "Start Measurement Process",
    Flash_Light_Not_Supported:
      "Remote flashlight not supported on guest device or not ready",
  },
  TOOLTIP: {
    Mute_My_Audio: "Mute My Audio",
    Unmute_My_Audio: "Unmute My Audio",
    Stop_My_Video: "Stop My Video",
    Start_My_Video: "Start My Video",
    Capture_Images_with_No_Labels: "Capture Images with No Labels",
    Start_Inspection: "Start Inspection",
    Chat: "Chat",
    More_Options: "More Options",
    End_Call: "End Call",
    Unpin_Video: "Unpin Video",
    Pin_Video: "Pin Video",
    Add_New_Note: "Add New Note",
    Refresh: "Refresh",
    View_More: "View More",
    Invite_Participant: "Invite Participant",
    Delete_Image: "Delete Image",
  },
  COMMON_STRING: {
    INVITATION_SENT_SUCCESS_MESSAGE: "Invititation Was Sent Succcessfully",
    IOS_SAFARI_HELP: "For best results, we recommend using Safari",
    UNEXPECTED_ERROR: "Unexpected Errors Occured. Try Again Later.",
    CALL_LINK_NOT_FOUND: "Call Link is not found.",
    CREATE_BOOKMARK_MESSAGE: "Please Ask Administrator to Add Bookmark",
    ATTACHMENT_UPLOAD_SIZE_EXCEEDED: "File size should be less than 10MB",
    ATTACHMENT_UPLOAD_UNSUPPORTED_FORMAT: "Unsupported File Format",
    LIVE_POINTER_STARTED:
      "Started live pointer. You can move the pointer by dragging.",
    LIVE_POINTER_STOPPED: "Stopped live pointer",
    APPRAISAL_EXIT_APPROVED: "Exit acknowledged successfully.",
    APPRAISAL_EXIT_DENIED: "Exit denied.",
    MARKUP_CLOSED: "Markup is Stopped",
    PRIVACY_POLICY_FAIL: "No valid Privacy Policy URL found!",
    SWITCH_CAMERA_REQUEST_REJECTED:
      "Home Owner did not share requested camera view.",
  },
  ERROR_MESSAGES: {
    Please_Select_Cellphone_or_Email: "Please Select Cellphone or Email",
    This_field_is_required: "This field is required",
    Cell_Phone_Must_Be_Valid: "Cell Phone Must Be Valid",
    Name_is_Required: "Name is Required",
    Name_Must_Be_Valid: "Name Must Be Valid",
    Invalid_Text: "Invalid Text",
    Email_Must_Be_Valid: "Email Must Be Valid",
    Name_must_be: "Name must be",
    characters_max: "characters max",
    Please_select_the_Floor: "Please select the Floor",
    Please_enter_the_Room_Area_Name: "Please enter the Room/Area Name",
    You_need_to_use_HTTPS_for_selecting_audio_output_device:
      "You need to use HTTPS for selecting audio output device",
    Something_went_wrong: "Something went wrong. Please try again later.",
    Oops_Call_duration_is_over_and_session_is_ended:
      "Oops! Call duration is over and session is ended.",
    No_participant_has_joined_yet_Ending_the_call_session:
      "No participant has joined yet! Ending the call session",
  },
  starting_inspection: "Starting Inspection",
  starting_measurement: "Starting Measurement",
  error: "Error",
  contact_us: "Contact US",
  support_popup_msg:
    "Oops, Something went wrong, Please try again after sometimes or report an issue to our support.",
  select_po: "Select Property Owner to Start the Inspection Process",
  request_po_for_camera:
    "Please request Property Owner to turn on the camera view to start the Inspection process.",
  wait_until_po_joins:
    "Please wait until Property Owner joins the call to start the Inspection process",
  select_measurement_method: "Select Measurement Method",
  inspection_inprogress: "Inspection is already going on",
  model_msg_retry:
    "Unable to establish a communication channel with the Property Owner, Please retry.",
  model_msg_leaveCall:
    "Looks like there is an internet connectivity problem on either side so please disconnect the current call and reconnect again.",
  please_wait: "Please wait...",
  loading_sketch: "Loading Sketch...",
  connection_timeout_msg:
    "The connection has timed out. Something unusual has happened and it is taking longer than expected to respond. Please try again.",
  previous_measurement_prblm_msg:
    "Ops, something went wrong with the previous measurement. Please try again with the new Measurement Process.",
  measurement_retry_msg: "Measurement process has not started. Please retry.",
  opensketch_retry_msg: "Open Sketch process has not started. Please retry.",
};
