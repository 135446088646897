import {
  APPRAISAL_START_APPRAISAL,
  APPRAISAL_MEASUREMENT,
  APPRAISAL_CORNER_MARKING,
  APPRAISAL_2D_FLOOR_PLAN,
} from "./actionTypes";

export const setAppraisalStepOne = () => {
  return {
    type: APPRAISAL_START_APPRAISAL,
  };
};

export const setAppraisalStepTwo = () => {
  return {
    type: APPRAISAL_MEASUREMENT,
  };
};

export const setAppraisalStepThree = () => {
  return {
    type: APPRAISAL_CORNER_MARKING,
  };
};

export const setAppraisalStepFour = () => {
  return {
    type: APPRAISAL_2D_FLOOR_PLAN,
  };
};
