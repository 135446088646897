import _ from "lodash";

export function dehydrateVideoCallToken(data) {
  return _.omitBy(
    {
      name: data.identity,
      inviteUuid: data.inviteUUID,
    },
    _.isUndefined
  );
}

export function hydrateVideoCallToken(data) {
  return {
    accessToken: data.accessToken,
    callUUID: data.callUuid,
    name: data.name,
    role: data.type,
    jobOrderId: data.jobOrderId,
    callRecording: data.callRecording,
    callStartTime:data.callStartTime,
  };
}
