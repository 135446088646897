import _ from "lodash";

export function dehydrateAttachment(data) {
  return _.omitBy(
    {
      attachmentId: data.id,
      fileName: data.fileName,
      location: data.location,
      updatedBy: data.updatedBy,
      updatedAt: data.updatedAt,
    },
    _.isUndefined
  );
}

export const dehydrateAttachments = (data) =>
  data.map((d) => dehydrateAttachment(d));
