import * as _ from "lodash";
import api from "./apis/api";
import { dehydrateBookmark, dehydrateBookmarks } from "./transformers";

class BookmarkService {
  addBookmark(payload) {
    const { label, callUUID } = payload;
    return api.bookmark
      .addBookmark(label, callUUID)
      .then((res) => dehydrateBookmark(_.get(res, "data.data", {})));
  }

  getBookmark(UUID) {
    return api.bookmark
      .getBookmarkByUUID(UUID)
      .then((res) => dehydrateBookmarks(_.get(res, "data.data.bookmarks", [])));
  }
}

export default new BookmarkService();
