import * as actionTypes from "./actionTypes";
import { appConstants } from "./../constants/app.constants";

const mediaPermission = (payload) => {
  return {
    type: actionTypes.MEDIA_PERMISSIONS,
    payload,
  };
};

const selectCamera = (payload) => {
  return {
    type: actionTypes.SELECT_CAMERA,
    payload,
  };
};

const selectMicrophone = (payload) => {
  return {
    type: actionTypes.SELECT_MICROPHONE,
    payload,
  };
};

const selectSpeaker = (payload) => {
  return {
    type: actionTypes.SELECT_SPEAKER,
    payload,
  };
};

export const updatePermission = (permissions) => {
  return (dispatch) => {
    dispatch(mediaPermission(permissions));
  };
};

export const selectDevice = (type, device) => {
  return (dispatch) => {
    if (type == appConstants.mediaType.camera) {
      dispatch(selectCamera(device));
    } else if (type == appConstants.mediaType.microphone) {
      dispatch(selectMicrophone(device));
    } else if (type == appConstants.mediaType.speaker) {
      dispatch(selectSpeaker(device));
    } else {
    }
  };
};
