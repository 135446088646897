import React, { Component } from "react";
import "./errorModelMaxParticipant.scss";
import { useTranslation } from "react-i18next";
const title = {
  camera: "Camera permission required",
  microphone: "Microphone permission required",
};

const PermissionPopup = (props) => {
  const { t } = useTranslation();
  const type = props.type == "camera" ? "camera" : "microphone";

  const cameramessage = (
    <>
      {t("WEB_LABELS.To_share_live_video")}{" "}
      <a
        href="javascript:void(0)"
        className="link-btn"
        onClick={() => {
          window.location.reload(false);
        }}
      >
        {t("WEB_LABELS.click_here")}{" "}
      </a>{" "}
      {t("WEB_LABELS.to_reload_the_page")}
    </>
  );

  const microphonemessage = (
    <>
      {t("WEB_LABELS.we_cant_access_your_microphone")}{" "}
      <a
        href="javascript:void(0)"
        className="link-btn"
        onClick={() => {
          window.location.reload(false);
        }}
      >
        {t("WEB_LABELS.click_here")}{" "}
      </a>{" "}
      {t("WEB_LABELS.to_reload_the_page")}
    </>
  );

  const message = type == "camera" ? cameramessage : microphonemessage;
  return (
    <>
      <div className="error-popup open">
        <div className="error-info">
          <div className="content">
            <p>
              <b>{title[type]}</b>
            </p>
            {message}
          </div>
          <div className="bottom-btn">
            <button className="blue-btn" onClick={props.close}>
              {t("BUTTONS.OK")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PermissionPopup;
