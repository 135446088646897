import api from "./api";

export default class VideoCallAPI {

  getVideoCallToken(requestData) {
    return api.post(`call/generatetoken`, requestData);
  }

  inviteParticipant(callUUID, requestData) {
    return api.post(`call/${callUUID}/participants/invite`, requestData);
  }

  listParticipant(callUUID) {
    return api.get(`call/${callUUID}/participants`);
  }

  callEnd(InviteUUID) {
    return api.get(`endcall/${InviteUUID}`);
  }

  getMeasurementJSON(jobOrderId) {
    return api.get(`measurementjson/${jobOrderId}`);
  }

  deleteFloorPlan(requestData) {
    return api.delete(`measurement/mobilejson/${requestData.jobOrderId}`);
  }


  

}
