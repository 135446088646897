import * as actionTypes from "../actions/actionTypes";
import { assign, get } from "lodash";
export const defaultState = {
  loading: false,
  data: {},
  error: null,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.ADD_CALL_PARTICIPANT:

      const tempdata = assign(
        { ...state.data },
        { [action.payload.participantSid]: { 
          
          ...action.payload 
        
        } }
      );
      return {
        ...state,
        loading: true,
        data: tempdata,
        error: null,
      };
      
    case actionTypes.UPDATE_CALL_PARTICIPANT:

      const participant = get(state.data, [action.payload.participantSid], {});
      
      const tempdata1 = assign(
        { ...state.data },
        {
          [action.payload.participantSid]: {
            ...participant,
            ...action.payload,
          },
        }
      );

      


      return {
        ...state,
        loading: true,
        data: tempdata1,
        error: null,
      };

      

    case actionTypes.REMOVE_CALL_PARTICIPANT:
      const tempdata2 = { ...state.data };
      if (tempdata2[action.payload]) {
        delete tempdata2[action.payload];
      }
      const tempdata3 = assign({ ...tempdata2 }, {});
      return {
        ...state,
        loading: false,
        data: tempdata3,
        error: null,
      };
    case actionTypes.CLEAR_CALL_PARTICIPANT:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
      };

    default:
      return state;
  }
};
