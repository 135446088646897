/* eslint-disable import/no-anonymous-default-export */
import {
  START_LOAD_NOTE,
  LOAD_NOTE_SUCCESS,
  LOAD_NOTE_ERROR,
  START_CREATE_NOTE,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_ERROR,
  START_DELETE_NOTE,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_ERROR,
  START_UPDATE_NOTE,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_ERROR,
} from "../actions/actionTypes";

export const createNoteDefaultState = {
  creating: false,
  created: {},
  creatingError: null,
};

export const deleteNoteDefaultState = {
  deleting: false,
  deleted: {},
  deletingError: null,
};

export const updateNoteDefaultState = {
  updating: false,
  updated: {},
  updatingError: null,
};

export const defaultState = {
  notes: [],
  loading: false,
  error: null,
  ...createNoteDefaultState,
  ...deleteNoteDefaultState,
  ...updateNoteDefaultState,
};

export default function (
  state = defaultState,
  action = { type: null, payload: {} }
) {
  switch (action.type) {
    case START_LOAD_NOTE: {
      return {
        ...state,
        loading: true,
      };
    }

    case LOAD_NOTE_SUCCESS: {
      return {
        ...state,
        notes: [...action.payload],
        loading: false,
      };
    }

    case LOAD_NOTE_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    case START_CREATE_NOTE: {
      return {
        ...state,
        creating: true,
      };
    }

    case CREATE_NOTE_SUCCESS: {
      return {
        ...state,
        created: {
          ...action.payload,
        },
        creating: false,
      };
    }

    case CREATE_NOTE_ERROR: {
      return {
        ...state,
        creatingError: action.payload,
        creating: false,
      };
    }

    case START_DELETE_NOTE: {
      return {
        ...state,
        deleting: true,
      };
    }

    case DELETE_NOTE_SUCCESS: {
      return {
        ...state,
        deleted: {
          ...action.payload,
        },
        deleting: false,
      };
    }

    case DELETE_NOTE_ERROR: {
      return {
        ...state,
        deletingError: action.payload,
        deleting: false,
      };
    }

    case START_UPDATE_NOTE: {
      return {
        ...state,
        updating: true,
      };
    }

    case UPDATE_NOTE_SUCCESS: {
      return {
        ...state,
        updated: {
          ...action.payload,
        },
        updating: false,
      };
    }

    case UPDATE_NOTE_ERROR: {
      return {
        ...state,
        updatingError: action.payload,
        updating: false,
      };
    }

    default: {
      return state;
    }
  }
}
