import _ from "lodash";

export function dehydrateQuestionnaire(data) {
  return {
    jobOrderId: data.jobOrderId,
    questionnaireAnswer: data.questionnaireAnswer
      ? JSON.parse(data.questionnaireAnswer)
      : null,
    questionnaireQuestion: JSON.parse(data.questionnaireQuestion),
  };
}
