import _, { get } from "lodash";
import { appConstants } from "../constants";
import { isAndroid, isIOS } from "react-device-detect";

export function placholderFunction() {}

export function getWindowLocation() {
  return window.location.protocol + "//" + window.location.host;
}

export function getCallURL(inviteUUID) {
  return `/joincall/${inviteUUID}`;
}
export const extractUserName = (identity) => {
  if (identity) {
    const contents = identity.split("|");
    return get(contents, ["2"], null);
  } else return null;
};

export const extractUserRole = (identity) => {
  if (identity) {
    const contents = identity.split("|");
    return get(contents, ["1"], null);
  } else return null;
};

export const isAppraiserOrAssistant = (role) => {
  return role === appConstants.USER_ROLES.APPRAISER ||
    role === appConstants.USER_ROLES.ASSISTANT
    ? true
    : false;
};

export const isAppraiser = (role) => {
  return role && role === appConstants.USER_ROLES.APPRAISER;
};

export const isOwner = (role) => {
  return role === appConstants.USER_ROLES.PROPERTY_OWNER ||
    role == appConstants.userRoles.PROPERTY_OWNER
    ? true
    : false;
};

export const extractUserUUID = (identity) => {
  if (identity) {
    const contents = identity.split("|");
    return get(contents, ["0"], null);
  } else return null;
};

export const getFileFormData = (data, name, extraFields) => {
  const formData = new FormData();
  formData.append("file", data, name);
  if (extraFields) {
    _(extraFields).forOwn((value, key) => {
      if (key && value) formData.append(key, value);
    });
  }
  return formData;
};

export const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const getDeviceType = () => {
  return isIOS
    ? appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_IOS
    : isAndroid
    ? appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_ANDROID
    : appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_PC;
};
