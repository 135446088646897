import * as actionTypes from "./actionTypes";

import BookmarkService from "../services/bookmarkService";

export const startLoadBookmark = () => {
  return {
    type: actionTypes.START_LOAD_BOOKMARK,
  };
};

export const loadBookmarkSuccess = (payload) => {
  return {
    type: actionTypes.LOAD_BOOKMARK_SUCCESS,
    payload,
  };
};

export const loadBookmarkError = (payload) => {
  return {
    type: actionTypes.LOAD_BOOKMARK_ERROR,
    payload,
  };
};

export function loadBookmark(callUUID) {
  return (dispatch) => {
    dispatch(startLoadBookmark());
    return BookmarkService.getBookmark(callUUID)
      .then(function (data) {
        return dispatch(loadBookmarkSuccess(data));
      })
      .catch((err) => {
        return dispatch(loadBookmarkError(err));
      });
  };
}

export const startCreatingBookmark = () => {
  return {
    type: actionTypes.START_CREATE_BOOKMARK,
  };
};

export const createBookmarkSuccess = (payload) => {
  return {
    type: actionTypes.CREATE_BOOKMARK_SUCCESS,
    payload,
  };
};

export const createBookmarkError = (payload) => {
  return {
    type: actionTypes.CREATE_BOOKMARK_ERROR,
    payload,
  };
};

export function createBookmark(payload) {
  return (dispatch) => {
    dispatch(startCreatingBookmark());
    return BookmarkService.addBookmark(payload)
      .then(function (data) {
        dispatch(createBookmarkSuccess(data));
        return data;
      })
      .catch((err) => {
        return dispatch(createBookmarkError(err));
      });
  };
}
