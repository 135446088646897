import React, { useEffect, useRef, useState } from "react";
import "./callend.scss";
import _ from "lodash";
import { connect } from "react-redux";
import { StorageUtil, getCallURL, history } from "../../helpers/";
import { isAppraiser } from "../../helpers/commonJSFunction";
import { callEnd as endSession } from "../../actions/videoCallAction";
import { useTranslation } from "react-i18next";
const CallEnd = (props) => {
  const { t } = useTranslation();

  const [, updateState] = useState();
  const [imageLoaded, setImageLoaded] = useState(false);
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const logo = props.configs && props.configs.callLogo;
  const style = {};

  if (props.configs && props.configs.backgroundColor && imageLoaded) {
    style.backgroundColor = props.configs.backgroundColor;
    style.padding = "10px";
  }

  const hasUUID = StorageUtil.hasInviteUUID();
  const InviteUUID = StorageUtil.getInviteUUID();
  const ROLE = StorageUtil.getRole();
  const isShowEndCall = ROLE && isAppraiser(ROLE) && hasUUID;
  const reConnect = () => {
    const callURL = getCallURL(InviteUUID);
    history.push(callURL);
    window.location.href = callURL;
  };

  const performcallEnd = () => {
    /*  call api to end the call */
    props.endSession(InviteUUID);
    StorageUtil.clearAll();
    forceUpdate();
    /* clear all Data */
  };

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  return (
    <section className="callend-pagenot-found">
      <div className="box-center">
        <div className="content">
          {logo && (
            <div className="company-logo" style={{ ...style }}>
              <img
                style={{ display: imageLoaded ? "block" : "none" }}
                src={logo}
                alt="Logo"
                onLoad={handleImageLoaded}
              />
            </div>
          )}
          <h2>{t("WEB_LABELS.Secure_Video_Call_Ended")}</h2>
          <p>{t("WEB_LABELS.Thank_you_for_connecting_with_us")}</p>
          <div className="reconnect">
            {hasUUID && (
              <button
                className="green-btn"
                onClick={() => {
                  reConnect();
                }}
              >
                {t("BUTTONS.Re_Connect")}
              </button>
            )}
            {isShowEndCall && (
              <button
                className="red-btn"
                onClick={() => {
                  performcallEnd();
                }}
              >
                {t("BUTTONS.End_Session")}
              </button>
            )}
          </div>
          {/* <div className="reconnect">
              <button
                className="red-btn"
                onClick={() => {
                  performcallEnd();
                }}
              >
                End Session
              </button>
            </div> */}
        </div>
      </div>
    </section>
  );
};
const mapDispatchToProps = (dispatch) => ({
  endSession: (data) => dispatch(endSession(data)),
});

const mapStateToProps = ({ me }) => ({
  configLoaded:
    me && me.configsLoaded && _.isEmpty(me.configsError) ? true : false,
  configs: _.get(me, ["configs"], {}),
});

const connectedCallEnd = connect(mapStateToProps, mapDispatchToProps)(CallEnd);

export default connectedCallEnd;
