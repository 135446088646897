import * as actionTypes from "../actions/actionTypes";

export const defaultState = {
  role: "",
  configs: {},
  configsLoading: false,
  configsLoaded: false,
  configsError: {},
};

export default function me(state = defaultState, action = {}) {
  switch (action.type) {
    case actionTypes.UPDATE_LOCALUSER_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case actionTypes.START_LOAD_CALL_CONFIG:
      return {
        ...state,
        configs: {},
        configsLoading: true,
        configsLoaded: false,
        configsError: {},
      };
    case actionTypes.LOAD_CALL_CONFIG_SUCCESS:
      return {
        ...state,
        configs: action.payload,
        configsLoading: false,
        configsLoaded: true,
        configsError: {},
      };
    case actionTypes.LOAD_CALL_CONFIG_ERROR:
      return {
        ...state,
        configs: {},
        configsLoading: false,
        configsLoaded: false,
        configsError: action.payload,
      };

    default:
      return state;
  }
}
