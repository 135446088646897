import api from "./apis/api";
import { dehydrateQuestionnaire } from "./transformers/questionnaireTransformer";

class QuestionnaireService {
  getQuestionnaire(jobOrderId) {
    return api.questionnaire
      .getQuestionnaire(jobOrderId)
      .then((res) => dehydrateQuestionnaire(res.data.data));
  }

  saveQuestionnaire(data) {
    return api.questionnaire.saveQuestionnaire(data);
  }
}

export default new QuestionnaireService();
