import _ from "lodash";

export function dehydrateCaptureImage(data) {
  return _.omitBy(
    {
      bookmarkLabel: _.isEmpty(data.bookmarkLabel) ? "" : data.bookmarkLabel,
      original: data.original,
      originalName: data.originalName,
      thumb: data.thumb,
      thumbName: data.thumbName,
    },
    _.isUndefined
  );
}

export const dyHydrateImageListForDelete = (data) => {
  return {
    callPictures: [...data],
  };
};
