import React, { useState, useEffect } from "react";
import "./questionnaireModel.scss";
import "survey-react/survey.css";
import _ from "lodash";
import MySurvey from "./survey";
import { useTranslation } from "react-i18next";

const QuestionnaireModel = (props) => {
  const { t } = useTranslation();
  const [questionnaire, setQuestionnaire] = useState();
  const [questionnaireAnswer, setQuestionnaireAnswer] = useState();
  useEffect(() => {
    getQuestionnaire();
  }, []);

  const getQuestionnaire = () => {
    props.getQuestionnaire(props.jobOrderId).then((res) => {
      if (res) {
        setQuestionnaire(_.get(res, ["payload", "questionnaireQuestion"], {}));
        setQuestionnaireAnswer(
          _.get(res, ["payload", "questionnaireAnswer"], null)
        );
      }
    });
  };

  const saveQuestionnaire = (data) => {
    const reqData = {
      jobOrderId: props.jobOrderId,
      questionnaireAnswer: JSON.stringify(data),
    };
    props.saveQuestionnaire(reqData).then((res) => {
      props.toggleQuestionnaire();
    });
  };

  return (
    <>
      <div className="comman-modal left-side open">
        <div className="comman-modal-main">
          <div className="side-head">
            {t("WEB_LABELS.Questionnaire_Form")}
            <div className="side-head-buttons">
              <button
                onClick={props.toggleQuestionnaire}
                className="close-modal modal-button-effect"
              >
                <i className="icon-close-image"></i>
              </button>
            </div>
          </div>
          <div className="comman-modal-body scroll-bar-style">
            {questionnaire && (
              <div className="questionnaire-form">
                <MySurvey
                  questionnaire={questionnaire}
                  saveQuestionnaire={saveQuestionnaire}
                  questionnaireAnswer={questionnaireAnswer}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(QuestionnaireModel);
