import * as actionTypes from "../actions/actionTypes";

const tokenState = {
  fetchingStart: false,
  token: null,
  errorData: null,
};
export const defaultState = {
  loading: false,
  data: null,
  error: null,
  ...tokenState,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_TOKEN_START:
      return {
        ...state,
        fetchingStart: true,
        token: null,
        errorData: null,
      };
    case actionTypes.FETCH_TOKEN_SUCCESS:
      return {
        ...state,
        fetchingStart: false,
        token: action.payload,
        errorData: null,
      };
    case actionTypes.FETCH_TOKEN_ERROR:
      return {
        ...state,
        fetchingStart: false,
        token: null,
        errorData: action.payload,
      };
    case actionTypes.DETAIL_START:
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      };
    case actionTypes.DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionTypes.DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };

    default:
      return state;
  }
};
