import { customToast } from "../helpers/customToast";
import attachmentService from "../services/attachmentService";
import {
  LOAD_ATTACHMENTS_ERROR,
  LOAD_ATTACHMENTS_SUCCESS,
  START_LOAD_ATTACHMENTS,
  START_UPLOAD_ATTACHMENT,
  UPLOAD_ATTACHMENT_SUCCESS,
  UPLOAD_ATTACHMENT_ERROR,
  START_DELETE_ATTACHMENT,
  DELETE_ATTACHMENT_SUCCESS,
  DELETE_ATTACHMENT_ERROR,
  ATTACHMENT_RECEIVED_SUCCESS,
  START_DOWNLOAD_ATTACHMENT,
  DOWNLOAD_ATTACHMENT_SUCCESS,
  DOWNLOAD_ATTACHMENT_ERROR,
} from "./actionTypes";

export const startLoadAttachments = () => {
  return {
    type: START_LOAD_ATTACHMENTS,
  };
};

export const loadAttachmentsSuccess = (payload) => {
  return {
    type: LOAD_ATTACHMENTS_SUCCESS,
    payload,
  };
};

export const loadAttachmentsError = (payload) => {
  return {
    type: LOAD_ATTACHMENTS_ERROR,
    payload,
  };
};

export function loadAttachments(jobOrderId) {
  return (dispatch) => {
    dispatch(startLoadAttachments());
    return attachmentService
      .getAttachmentsByJobOrderId(jobOrderId)
      .then(function (data) {
        return dispatch(loadAttachmentsSuccess(data));
      })
      .catch((err) => {
        return dispatch(loadAttachmentsError(err));
      });
  };
}

export const startUploadAttachment = () => {
  return {
    type: START_UPLOAD_ATTACHMENT,
  };
};

export const uploadAttachmentSuccess = (payload) => {
  return {
    type: UPLOAD_ATTACHMENT_SUCCESS,
    payload,
  };
};

export const uploadAttachmentError = (payload) => {
  return {
    type: UPLOAD_ATTACHMENT_ERROR,
    payload,
  };
};

export function uploadAttachment(file, fileName, extraPayload) {
  return (dispatch) => {
    dispatch(startUploadAttachment());
    return attachmentService
      .addAttachment(file, fileName, extraPayload)
      .then(function (data) {
        return dispatch(uploadAttachmentSuccess(data));
      })
      .catch((err) => {
        const { response } = err;
        const {
          data: { message },
        } = response;

        if (message) {
          customToast.error(message);
        }

        return dispatch(uploadAttachmentError(response.data));
      });
  };
}

export const startDeleteAttachment = () => {
  return {
    type: START_DELETE_ATTACHMENT,
  };
};

export const deleteAttachmentSuccess = (payload) => {
  return {
    type: DELETE_ATTACHMENT_SUCCESS,
    payload,
  };
};

export const deleteAttachmentError = (payload) => {
  return {
    type: DELETE_ATTACHMENT_ERROR,
    payload,
  };
};

export function deleteAttachment(attachmentId) {
  return (dispatch) => {
    dispatch(startDeleteAttachment());
    return attachmentService
      .deleteAttachment(attachmentId)
      .then(function (data) {
        return dispatch(deleteAttachmentSuccess(data));
      })
      .catch((err) => {
        return dispatch(deleteAttachmentError(err));
      });
  };
}

export const attachmentReceivedSuccess = (payload) => {
  return {
    type: ATTACHMENT_RECEIVED_SUCCESS,
    payload,
  };
};

export const startDownloadAttachment = () => {
  return {
    type: START_DOWNLOAD_ATTACHMENT,
  };
};

export const downloadAttachmentSuccess = (payload) => {
  return {
    type: DOWNLOAD_ATTACHMENT_SUCCESS,
    payload,
  };
};

export const downloadAttachmentError = (payload) => {
  return {
    type: DOWNLOAD_ATTACHMENT_ERROR,
    payload,
  };
};

export function downloadAttachment(attachmentId) {
  return (dispatch) => {
    dispatch(startDownloadAttachment());
    return attachmentService
      .downloadAttachment(attachmentId)
      .then((data) => {
        dispatch(downloadAttachmentSuccess(data));
        return data;
      })
      .catch((err) => {
        dispatch(downloadAttachmentError(err));
        return {};
      });
  };
}
