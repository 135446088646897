import React from "react";
import "./networkQualityModel.scss";

const NetworkQualityModel = (props) => {
  return (
    <div className="error-popup slow-connection open ">
      <div className="error-info">
        <div className="content">
          <button
            className="close-modal"
            onClick={props.toggleNetworkQualityModel}
          >
            <i className="icon-close-image"></i>
          </button>
          <p>
            <i className="icon-slow-connection mr-5"></i>{" "}
            <b>Slow Internet Connection Detected</b>
          </p>
          <p>
            Video or Images may lag or experience latency due to the slow
            connection.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NetworkQualityModel;
