import * as _ from "lodash";
import { getFileFormData } from "../helpers";
import api from "./apis/api";
import { dehydrateAttachment, dehydrateAttachments } from "./transformers";

class AttachmentService {
  getAttachmentsByJobOrderId(jobOrderId) {
    return api.attachment
      .getAttachmentsByJobOrderId(jobOrderId)
      .then((res) => dehydrateAttachments(_.get(res, "data.data", [])));
  }

  addAttachment(file, fileName, extraPayload) {
    const fileFormData = getFileFormData(file, fileName, extraPayload);
    return api.attachment
      .addAttachment(fileFormData)
      .then((res) => dehydrateAttachment(_.get(res, "data.data", {})));
  }

  deleteAttachment(attachmentId) {
    return api.attachment.deleteAttachment(attachmentId);
  }

  downloadAttachment(attachmentId) {
    return api.attachment.downloadAttachment(attachmentId);
  }
}

export default new AttachmentService();
