import React, { Component } from "react";
import "./showDownloadAppPage.scss";
import { withTranslation } from "react-i18next";
import AsyncImage from "../common/AsyncImage";
class ShowDownloadAppPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;
    console.log("this.props.isMobile - ", this.props.isMobile);

    return (
      <>
        <header className="po-header">
          {this.props.isMeasurementLink ? (
            <img src={this.props.logoPath} alt="Loading Logo..." />
          ) : (
            <AsyncImage
              imageUrl={this.props.logoPath}
              alt={"Loading Logo..."}
            />
          )}
        </header>
        <section
          className="po-wapper"
          style={{
            background: this.props.bodyBackgroundColor,
            color: this.props.bodyTextColor,
          }}
        >
          <div className="po-content">
            <div className="box">
              <div className="watch-video">
                <p>
                  <strong>{t("WEB_LABELS.Download_Message")}</strong>
                </p>
                <p>
                  {this.props.isMeasurementLink
                    ? t("WEB_LABELS.MeasurementLinkDisclaimer")
                    : t("WEB_LABELS.Disclaimer")}
                </p>
                <div className="app-icon open-in-app ">
                  <button
                    onClick={() => this.props.handleDownloadAppClicked()}
                    className="img-btn"
                  >
                    {this.props.isIOS ? (
                      <img src="/images/apple-store.svg" />
                    ) : (
                      <img src="/images/google-play.svg" />
                    )}
                  </button>
                  <button
                    className="blue-btn"
                    onClick={() => this.props.handleGoToAppClicked()}
                    style={{
                      background: this.props.buttonBackgroundColor,
                      color: this.props.buttonTextColor,
                    }}
                  >
                    {t("BUTTONS.Open_in_App")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="po-footer">
          <a className="privacy-href" href={this.props.privacyPolicyPath}>
            {t("BUTTONS.Privacy_Policy")}
          </a>
        </footer>
      </>
    );
  }
}
export default withTranslation()(ShowDownloadAppPage);
