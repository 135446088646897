import React from "react";
import * as Survey from "survey-react";
import { useTranslation } from "react-i18next";

import "survey-react/survey.css";

const MySurvey = (props) => {
  const { t } = useTranslation();

  const survey = new Survey.Model(props.questionnaire);
  window.survey = survey;

  survey.onComplete.add(function (sender, options) {
    props.saveQuestionnaire(sender.data);
  });

  survey.showPreviewBeforeComplete = "noPreview";
  survey.completeText = t("BUTTONS.Save");

  const loadSurvey = () => {
    survey.currentPageNo = 0;
    survey.data = props.questionnaireAnswer;
  };

  if (props.questionnaireAnswer) {
    loadSurvey();
  } else {
  }

  return (
    <>
      <Survey.Survey model={survey} />
    </>
  );
};

export default MySurvey;
