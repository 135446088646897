import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import IF from "../../common/IfCondition";
import {
  APPRAISAL_MEASUREMENT,
  APPRAISAL_CORNER_MARKING,
  APPRAISAL_2D_FLOOR_PLAN,
} from "../../../actions/actionTypes";
import "./measurementModal.scss";
import InstructionModel from "./instructionModel";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import Tooltip from "@mui/material/Tooltip";
import { appConstants } from "../../../constants";
import { BookmarkLabelsForAppraisal } from "./BookmarkLabelsForAppraisal";

//function MeasurementModal(props) {
class MeasurementModal extends Component {
  constructor() {
    super();
    this.state = {
      selectedFloor: null,
      floorError: {
        msg: i18n.t("ERROR_MESSAGES.Please_select_the_Floor"),
        error: false,
      },
      isAutoCornerDetectionEnable: true,
      isManualCaptureCornerEnable: true, // Tap on device
      isUndoLastCornerCapture: false,
      isConnectCornerAndFinishSketchEnable: false,
      isCapture3DRoomHeightEnable: false,
      isSetFixed3DRoomHeightEnable: false,
      isShowFinishedFloorPlanSketch: false,
      instructions: false,
      isCaptureDoorEnable: false,
      isSetDoorEnable: false,
      isShowFloorPlanFinishedButtonEnabled: true,
      isAddRoomEnable: false,
      isStartAddRoomEnable: false,
      isCancelAddRoomEnable: false,
    };
    this.toggleInstruction = this.toggleInstruction.bind(this);
  }
  _handleKeyDown = (event) => {
    if (
      this.state.isManualCaptureCornerEnable &&
      event.keyCode == 13 &&
      event.altKey &&
      this.props.measurements.currentStep === APPRAISAL_CORNER_MARKING
    ) {
      this.props.onMeasurementsModalEvent("TAP_ON_DEVICE");
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this._handleKeyDown);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      !(
        this.props.buttonType == prevProps.buttonType &&
        this.props.buttonState == prevProps.buttonState
      ) ||
      (this.props.buttonType == "6" &&
        this.state.isAutoCornerDetectionEnable == true &&
        this.props.buttonState == 0)
    ) {
      if (this.props.buttonType == "1") {
        this.setState(
          {
            isUndoLastCornerCapture:
              this.props.buttonState == "1" ? true : false,
          },
          () => {}
        );
      } else if (this.props.buttonType == "2") {
        this.setState(
          {
            isConnectCornerAndFinishSketchEnable:
              this.props.buttonState == "1" ? true : false,
          },
          () => {}
        );
      } else if (this.props.buttonType == "3") {
        this.setState(
          {
            isShowFinishedFloorPlanSketch:
              this.props.buttonState == "1" ? true : false,
          },
          () => {}
        );
      } else if (this.props.buttonType == "4") {
        this.setState(
          {
            isCapture3DRoomHeightEnable:
              this.props.buttonState == "1" ? true : false,
          },
          () => {}
        );
      } else if (this.props.buttonType == "5") {
        this.setState(
          {
            isSetFixed3DRoomHeightEnable:
              this.props.buttonState == "1" ? true : false,
          },
          () => {}
        );
      } else if (this.props.buttonType == "6") {
        this.setState(
          {
            isAutoCornerDetectionEnable:
              this.props.buttonState == "1" ? true : false,
          },
          () => {}
        );
      } else if (this.props.buttonType == "8") {
        this.setState(
          {
            isCaptureDoorEnable: this.props.buttonState == "1" ? true : false,
          },
          () => {}
        );
      } else if (this.props.buttonType == "9") {
        this.setState(
          {
            isSetDoorEnable: this.props.buttonState == "1" ? true : false,
          },
          () => {}
        );
      } else if (this.props.buttonType == "11") {
        this.setState(
          {
            isAddRoomEnable: this.props.buttonState == "1" ? true : false,
          },
          () => {}
        );
      } else if (this.props.buttonType == "12") {
        this.setState(
          {
            isStartAddRoomEnable: this.props.buttonState == "1" ? true : false,
          },
          () => {}
        );
      } else if (this.props.buttonType == "13") {
        this.setState(
          {
            isCancelAddRoomEnable: this.props.buttonState == "1" ? true : false,
          },
          () => {}
        );
      }
    }

    if (this.props.measurements.currentStep === APPRAISAL_2D_FLOOR_PLAN) {
      if (
        this.props.selectedIndex &&
        this.props.selectedIndex != "" &&
        this.props.selectedIndex != prevProps.selectedIndex
      ) {
        this.setState(
          {
            selectedFloor: this.props.selectedIndex,
          },
          () => {}
        );
      }
    }
  }

  disabledShowFinishedFloorPlan() {
    this.setState({ isShowFloorPlanFinishedButtonEnabled: false });
    setTimeout(() => {
      this.setState({ isShowFloorPlanFinishedButtonEnabled: true });
    }, 4000);
  }

  onChangeFloor(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });

    if (value == "Select Floor") {
      this.setState({
        floorError: {
          msg: i18n.t("ERROR_MESSAGES.Please_select_the_Floor"),
          error: true,
        },
      });
    } else {
      this.setState({
        floorError: { msg: "", error: false },
      });

      if (this.props.measurements.currentStep == "APPRAISAL_MEASUREMENT") {
        let selectedFloor = this.props.floorList.find(
          (i) => i.floorIndex === parseInt(value)
        );

        let floorPlanData = {
          floorIndex: "" + selectedFloor.floorIndex,
          floorName: "" + selectedFloor.floorName,
          areaName: "" + selectedFloor.areaName,
          areaType: "" + selectedFloor.areaType,
        };

        this.props.onResumeViewFloorPlanFloorSelection(floorPlanData);
      } else if (
        this.props.measurements.currentStep == "APPRAISAL_2D_FLOOR_PLAN"
      ) {
        let selectedFloor = this.props.floorList.find(
          (i) => i.floorIndex === value
        );

        let floorPlanData = {
          floorIndex: value,
          floorName: selectedFloor.floorName,
        };

        this.props.viweFloorPlanByFloorName(floorPlanData);
      }
    }
  }

  validateFloor(e) {
    if (e.target.value == "Select Floor") {
      this.setState({
        floorError: {
          msg: i18n.t("ERROR_MESSAGES.Please_select_the_Floor"),
          error: true,
        },
      });
    } else {
      this.setState({
        floorError: { msg: "", error: false },
      });
    }
  }

  toggleInstruction() {
    this.setState({ instructions: !this.state.instructions });
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div
          className={classnames(
            "measurement-appraisal start-appraisal-tab-modal comman-modal left-side",
            {
              open: this.props.isMeasurementModalCollapsed,
            }
          )}
        >
          <div className="comman-modal-main">
            <button
              className="side-toggle"
              onClick={() => this.props.toggleCollapseMeasurementModal()}
            >
              <i
                className={classnames({
                  "icon-arrow-left": this.props.isMeasurementModalCollapsed,
                  "icon-arrow-right": !this.props.isMeasurementModalCollapsed,
                })}
              ></i>
            </button>
            <div className="appraisal-modal">
              <div className="side-head">
                {this.props.measurements.currentStep === APPRAISAL_MEASUREMENT
                  ? t("WEB_LABELS.Tools")
                  : t("WEB_LABELS.Create_Measurement")}
                <div className="side-head-buttons">
                  <button
                    onClick={() => this.props.exitMeasurementProcess()}
                    className="close-modal modal-button-effect"
                    style={{
                      display:
                        this.props.measurements.currentStep ===
                          APPRAISAL_2D_FLOOR_PLAN ||
                        this.props.measurements.currentStep ===
                          APPRAISAL_CORNER_MARKING
                          ? "none"
                          : "",
                    }}
                  >
                    <i className="icon-close-image"></i>
                  </button>
                </div>
              </div>

              <div className="comman-modal-body scroll-bar-style">
                <div className="custom-tab orange-tab">
                  {this.props.measurements.currentStep !==
                    APPRAISAL_2D_FLOOR_PLAN && (
                    <ul className="tab-list">
                      <li
                        className={
                          this.props.isStartAppraisalCaptureImageTabVisible
                            ? "active"
                            : ""
                        }
                      >
                        <button
                          onClick={() =>
                            this.props.toggleCaptureImageMeasurement(true)
                          }
                        >
                          {t("BUTTONS.Capture_Labeled_Images")}
                        </button>
                      </li>
                      <li
                        className={
                          !this.props.isStartAppraisalCaptureImageTabVisible
                            ? "active"
                            : ""
                        }
                      >
                        <button
                          onClick={() =>
                            this.props.toggleCaptureImageMeasurement(false)
                          }
                        >
                          {t("BUTTONS.Capture_Measurements")}
                        </button>
                      </li>
                    </ul>
                  )}
                  <div
                    className={
                      this.props.isStartAppraisalCaptureImageTabVisible &&
                      this.props.measurements.currentStep !==
                        APPRAISAL_2D_FLOOR_PLAN
                        ? "tab-content active"
                        : "tab-content"
                    }
                  >
                    <div className="appriser-bookmark">
                      <BookmarkLabelsForAppraisal
                        images={this.props.images}
                        fetchBookmark={this.props.fetchBookmark}
                        callUUID={this.props.callUUID}
                        captureImage={this.props.captureImage}
                        showSavedImages={this.props.showSavedImages}
                        hideBookmarkViewModel={this.props.hideBookmarkViewModel}
                        isLocalParticipantPinned={
                          this.props.isLocalParticipantPinned
                        }
                      />
                      <div className="instructions-button-list">
                        <ul>
                          <li>
                            <button onClick={() => this.toggleInstruction()}>
                              <i className="icon-Instructions"></i>
                              <div className="text">
                                {t("BUTTONS.Instructions")}
                              </div>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      !this.props.isStartAppraisalCaptureImageTabVisible ||
                      this.props.measurements.currentStep ===
                        APPRAISAL_2D_FLOOR_PLAN
                        ? "tab-content active"
                        : "tab-content"
                    }
                  >
                    <div className="appraisal-menu">
                      <ul className="scroll-bar-style">
                        {/* <li
                          className="measurement-type"
                          style={{
                            display: !this.props.isLIDARViewVisible
                              ? ""
                              : "none",
                          }}
                        >
                          <label>
                            {t("WEB_LABELS.Select_Measurement_Type")}
                          </label>
                          <div className="form-group">
                            <div className="radio-btn">
                              <label>
                                <input
                                  type="radio"
                                  name="check"
                                  checked={
                                    this.props.lidarSelectedOption == "1"
                                      ? "checked"
                                      : ""
                                  }
                                  onClick={() =>
                                    this.props.selectLIDAROption("1")
                                  }
                                ></input>
                                <span>{t("WEB_LABELS.Floor")}</span>
                              </label>
                            </div>

                            <div className="radio-btn">
                              <label>
                                <input
                                  type="radio"
                                  name="check"
                                  checked={
                                    this.props.lidarSelectedOption == "2"
                                      ? "checked"
                                      : ""
                                  }
                                  onClick={() =>
                                    this.props.selectLIDAROption("2")
                                  }
                                />
                                <span>{t("WEB_LABELS.Ceiling")}</span>
                              </label>
                            </div>
                          </div>
                        </li> */}
                        {this.props.measurements.currentStep ===
                          APPRAISAL_CORNER_MARKING && (
                          <li className="form-select">
                            <label>{t("select_measurement_method")}</label>
                            <div>
                              <select
                                onChange={(e) =>
                                  this.props.selectLIDAROption(e.target.value)
                                }
                                value={this.props.lidarSelectedOption}
                                disabled={this.props.lidarSelectedOption != "0"}
                              >
                                <option value="0" selected>
                                  {t("select_measurement_method")}
                                </option>
                                {this.props.isLIDARViewVisible &&
                                  appConstants.MeasurementTypes.map(
                                    (type, i) => (
                                      <option key={i} value={type.value}>
                                        {type.label}
                                      </option>
                                    )
                                  )}
                                {!this.props.isLIDARViewVisible &&
                                  appConstants.MeasurementTypes.map(
                                    (type, i) =>
                                      !type.lidar && (
                                        <option key={i} value={type.value}>
                                          {type.label}
                                        </option>
                                      )
                                  )}
                              </select>
                            </div>
                          </li>
                        )}

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_MEASUREMENT
                          }
                        >
                          <li>
                            <button
                              className=""
                              onClick={() => {
                                this.setState({
                                  floorError: { msg: "", error: false },
                                });
                                this.props.onMeasurementsModalEvent("NEW");
                              }}
                            >
                              <i className="icon-appraisal-new"></i>
                              <div className="text">
                                {t("BUTTONS.Start_New_Inspection")}
                              </div>
                            </button>
                          </li>
                        </IF>
                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_MEASUREMENT
                          }
                        >
                          <li>
                            <button
                              className=""
                              onClick={() => {
                                this.setState({
                                  floorError: { msg: "", error: false },
                                });

                                this.props.onMeasurementsModalEvent("RESUME");
                              }}
                            >
                              <i className="icon-appraisal-resume"></i>
                              <div className="text">
                                {t("BUTTONS.Resume_Existing_Inspection")}
                              </div>
                            </button>
                          </li>
                        </IF>
                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_MEASUREMENT
                          }
                        >
                          <li>
                            <button
                              className=""
                              onClick={() =>
                                this.props.onMeasurementsModalEvent("EDIT")
                              }
                            >
                              <i className="icon-appraisal-floor-plan"></i>
                              <div className="text">
                                {t("BUTTONS.View_All_Current_Sketch")}
                              </div>
                            </button>
                          </li>
                          <li
                            className="border-none"
                            style={{
                              display: this.props.isResumeOrViewInspection
                                ? ""
                                : "none",
                            }}
                          >
                            <div className="view-form-group">
                              <div className="form-group">
                                <label>
                                  {t("WEB_LABELS.View_Sketches_by_Floor")}
                                </label>
                                <select
                                  className="form-control"
                                  onChange={this.onChangeFloor.bind(this)}
                                  value={this.state.selectedFloor}
                                  name="selectedFloor"
                                  onBlur={this.validateFloor.bind(this)}
                                >
                                  <option disabled selected>
                                    Select Floor
                                  </option>
                                  {this.props.floorList.map((tz, i) => (
                                    <option key={i} value={tz.floorIndex}>
                                      {tz.floorName}
                                    </option>
                                  ))}
                                </select>
                                {this.state.floorError.error && (
                                  <div className="form-error">
                                    {this.state.floorError.msg}
                                  </div>
                                )}
                              </div>
                              <div className="button-group text-center">
                                <button
                                  onClick={() =>
                                    this.props.viweOrResumeOpenSketch()
                                  }
                                  className="green-btn"
                                >
                                  {t("BUTTONS.Open_Sketch")}
                                </button>
                              </div>
                            </div>
                          </li>
                        </IF>

                        {/* APPRAISAL_CORNER_MARKING starts */}

                        {/* Auto Corner Detection */}

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_CORNER_MARKING
                          }
                        >
                          <li>
                            <button
                              className={classnames(
                                { active: this.props.autoCornerStatus },
                                this.state.isAutoCornerDetectionEnable &&
                                  this.props.lidarSelectedOption != "0"
                                  ? ""
                                  : "disable"
                              )}
                              onClick={() => {
                                if (this.state.isAutoCornerDetectionEnable) {
                                  const autoCornerStatus = !this.props
                                    .autoCornerStatus;
                                  this.props.onMeasurementsModalEvent(
                                    "AUTO_ON",
                                    {
                                      autoCornerStatus,
                                    }
                                  );
                                }
                              }}
                            >
                              <i className="icon-auto-on"></i>
                              <div className="text">
                                {!!this.props.autoCornerStatus
                                  ? t("BUTTONS.Auto_Corner_Detection_ON")
                                  : t("BUTTONS.Auto_Corner_Detection_OFF")}
                              </div>
                            </button>
                          </li>
                        </IF>

                        {/* Manually Capture Corner */}

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_CORNER_MARKING
                          }
                        >
                          <li>
                            <Tooltip
                              title={
                                t("BUTTONS.Manually_Capture_Corner") +
                                "(Alt + Enter)"
                              }
                              arrow
                            >
                              <button
                                className={
                                  this.state.isManualCaptureCornerEnable &&
                                  this.props.lidarSelectedOption != "0"
                                    ? ""
                                    : "disable"
                                }
                                onClick={() => {
                                  if (this.state.isManualCaptureCornerEnable) {
                                    this.props.onMeasurementsModalEvent(
                                      "TAP_ON_DEVICE"
                                    );
                                  }
                                }}
                              >
                                <i className="icon-tap-on-device"></i>
                                <div className="text">
                                  {t("BUTTONS.Manually_Capture_Corner")}
                                </div>
                              </button>
                            </Tooltip>
                          </li>
                        </IF>

                        {/* Undo Last Corner Capture */}

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_CORNER_MARKING
                          }
                        >
                          <li>
                            <button
                              className={
                                this.state.isUndoLastCornerCapture
                                  ? ""
                                  : "disable"
                              }
                              onClick={() => {
                                if (this.state.isUndoLastCornerCapture) {
                                  this.props.onMeasurementsModalEvent("UNDO");

                                  this.setState(
                                    {
                                      isSetFixed3DRoomHeightEnable: false,
                                    },
                                    () => {}
                                  );
                                }
                              }}
                            >
                              <i className="icon-appraisal-undo"></i>
                              <div className="text">
                                {t("BUTTONS.Undo_Last_Corner_Capture")}
                              </div>
                            </button>
                          </li>
                        </IF>

                        {/* Connect Corner & Finish Sketch */}

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_CORNER_MARKING
                          }
                        >
                          <li>
                            <button
                              className={
                                this.state.isConnectCornerAndFinishSketchEnable
                                  ? ""
                                  : "disable"
                              }
                              onClick={() => {
                                if (
                                  this.state
                                    .isConnectCornerAndFinishSketchEnable
                                ) {
                                  this.props.onMeasurementsModalEvent("DONE");
                                }
                              }}
                            >
                              <i className="icon-appraisal-done"></i>
                              <div className="text">
                                {t("BUTTONS.Connect_Corner_Finish_Sketch")}
                              </div>
                            </button>
                          </li>
                        </IF>

                        {/* Capture 3D Room Height */}

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_CORNER_MARKING
                          }
                        >
                          <li>
                            <button
                              className={
                                this.state.isCapture3DRoomHeightEnable
                                  ? ""
                                  : "disable"
                              }
                              onClick={() => {
                                if (this.state.isCapture3DRoomHeightEnable) {
                                  this.props.onMeasurementsModalEvent(
                                    "CAPTURE_3D_ROOM_HEIGHT"
                                  );

                                  this.setState(
                                    {
                                      isSetFixed3DRoomHeightEnable: true,
                                    },
                                    () => {}
                                  );
                                }
                              }}
                            >
                              <i className="icon-Capture-3D-Room-Height"></i>
                              <div className="text">
                                {t("BUTTONS.Capture_3D_Room_Height")}
                              </div>
                            </button>
                          </li>
                        </IF>

                        {/* Set Fixed 3D Room Height */}

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_CORNER_MARKING
                          }
                        >
                          <li>
                            <button
                              className={
                                this.state.isSetFixed3DRoomHeightEnable
                                  ? ""
                                  : "disable"
                              }
                              onClick={() => {
                                if (this.state.isSetFixed3DRoomHeightEnable) {
                                  //this.props.onMeasurementsModalEvent("SET_FIXED_3D_ROOM_HEIGHT");
                                  this.props.onMeasurementsModalEvent(
                                    "TAP_ON_DEVICE"
                                  );

                                  this.setState(
                                    {
                                      isSetFixed3DRoomHeightEnable: false,
                                    },
                                    () => {}
                                  );
                                }
                              }}
                            >
                              <i className="icon-Set-Fixed-3D-Room-Height"></i>
                              <div className="text">
                                {t("BUTTONS.Set_Fixed_3D_Room_Height")}
                              </div>
                            </button>
                          </li>
                        </IF>

                        {/* Capture Door */}

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_CORNER_MARKING
                          }
                        >
                          <li>
                            <button
                              className={
                                this.state.isCaptureDoorEnable ? "" : "disable"
                              }
                              onClick={() => {
                                if (this.state.isCaptureDoorEnable) {
                                  this.props.onMeasurementsModalEvent(
                                    "CAPTURE_DOOR"
                                  );
                                }
                              }}
                            >
                              <i className="icon-fix-door"></i>
                              <div className="text">
                                {t("BUTTONS.Capture_Door")}
                              </div>
                            </button>
                          </li>
                        </IF>

                        {/* Show Finished Floor Plan Sketch */}

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_CORNER_MARKING
                          }
                        >
                          <li>
                            <button
                              className={
                                this.state.isSetDoorEnable ? "" : "disable"
                              }
                              onClick={() => {
                                if (this.state.isSetDoorEnable) {
                                  this.props.onMeasurementsModalEvent(
                                    "SET_DOOR"
                                  );
                                }
                              }}
                            >
                              <i className="icon-Add-Door"></i>
                              <div className="text">
                                {t("BUTTONS.Set_Door")}
                              </div>
                            </button>
                          </li>
                        </IF>

                        {/* Show Finished Floor Plan Sketch */}

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_CORNER_MARKING
                          }
                        >
                          <li>
                            <button
                              className={
                                this.state.isAddRoomEnable ? "" : "disable"
                              }
                              onClick={() => {
                                if (this.state.isAddRoomEnable) {
                                  this.props.onMeasurementsModalEvent(
                                    "ADD_ROOM_AREA"
                                  );
                                }
                              }}
                            >
                              <i className="icon-addroom-area"></i>
                              <div className="text">Add Room/Area</div>
                            </button>
                          </li>
                        </IF>

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_CORNER_MARKING
                          }
                        >
                          <li>
                            <button
                              className={
                                this.state.isStartAddRoomEnable ? "" : "disable"
                              }
                              onClick={() => {
                                if (this.state.isStartAddRoomEnable) {
                                  this.props.onMeasurementsModalEvent(
                                    "START_ADD_ROOM"
                                  );
                                }
                              }}
                            >
                              <i className=""></i>
                              <div className="text">Start Add Room Process</div>
                            </button>
                          </li>
                        </IF>

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_CORNER_MARKING
                          }
                        >
                          <li>
                            <button
                              className={
                                this.state.isCancelAddRoomEnable
                                  ? ""
                                  : "disable"
                              }
                              onClick={() => {
                                if (this.state.isCancelAddRoomEnable) {
                                  this.props.onMeasurementsModalEvent(
                                    "CANCEL_ADD_ROOM"
                                  );
                                }
                              }}
                            >
                              <i className=""></i>
                              <div className="text">
                                Cancel Add Room Process
                              </div>
                            </button>
                          </li>
                        </IF>

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_CORNER_MARKING
                          }
                        >
                          <li>
                            <button
                              className={
                                this.state.isShowFinishedFloorPlanSketch &&
                                this.state.isShowFloorPlanFinishedButtonEnabled
                                  ? ""
                                  : "disable"
                              }
                              onClick={() => {
                                if (
                                  this.state.isShowFinishedFloorPlanSketch &&
                                  this.state
                                    .isShowFloorPlanFinishedButtonEnabled
                                ) {
                                  this.disabledShowFinishedFloorPlan();
                                  this.props.onMeasurementsModalEvent(
                                    "FLOOR_PLAN"
                                  );
                                }
                              }}
                            >
                              <i className="icon-appraisal-floor-plan"></i>
                              <div className="text">
                                {t("BUTTONS.Show_Finished_Floor_Plan_Sketch")}
                              </div>
                            </button>
                          </li>
                        </IF>
                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_CORNER_MARKING
                          }
                        >
                          <li>
                            <button
                              className={
                                !this.props.showhideLiveFloorPlanActions
                                  ? "appraisal-menu-button disable"
                                  : "appraisal-menu-button"
                              }
                            >
                              <div className="menu-button">
                                <i className="icon-Fencing"></i>
                                <div className="text">
                                  {t("WEB_LABELS.Fencing")}
                                </div>
                              </div>
                              <div className="toggle">
                                <div className="toggle-box">
                                  <label
                                    className={
                                      !this.props.showhideLiveFloorPlanActions
                                        ? "radio-button disable"
                                        : "radio-button"
                                    }
                                  >
                                    <input
                                      type="radio"
                                      name="yes1"
                                      checked={
                                        this.props.isFencingEnabled === "1"
                                      }
                                      onChange={(e) =>
                                        this.props.handleFencingVisibility("1")
                                      }
                                    />
                                    <span>{t("WEB_LABELS.Show")}</span>
                                  </label>
                                  <label
                                    className={
                                      !this.props.showhideLiveFloorPlanActions
                                        ? "radio-button disable"
                                        : "radio-button"
                                    }
                                  >
                                    <input
                                      type="radio"
                                      name="yes1"
                                      checked={
                                        this.props.isFencingEnabled === "0"
                                      }
                                      onChange={(e) =>
                                        this.props.handleFencingVisibility("0")
                                      }
                                    />
                                    <span>{t("WEB_LABELS.Hide")}</span>
                                  </label>
                                </div>
                              </div>
                            </button>
                          </li>
                        </IF>
                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_CORNER_MARKING
                          }
                        >
                          <li>
                            <button
                              className={
                                !this.props.showhideLiveFloorPlanActions
                                  ? "appraisal-menu-button disable"
                                  : "appraisal-menu-button"
                              }
                            >
                              <div className="menu-button">
                                <i className="icon-Live-Floor-Plan"></i>
                                <div className="text">
                                  {t("WEB_LABELS.Live_Floor_Plan")}
                                </div>
                              </div>
                              <div className="toggle">
                                <div className="toggle-box">
                                  <label
                                    className={
                                      !this.props.showhideLiveFloorPlanActions
                                        ? "radio-button disable"
                                        : "radio-button"
                                    }
                                  >
                                    <input
                                      type="radio"
                                      name="yes2"
                                      checked={
                                        this.props.isFloorPlanExpanded === "1"
                                      }
                                      onChange={(e) =>
                                        this.props.handleFloorPlanExpandOption(
                                          "1"
                                        )
                                      }
                                    />
                                    <span>{t("WEB_LABELS.Expand")}</span>
                                  </label>
                                  <label
                                    className={
                                      !this.props.showhideLiveFloorPlanActions
                                        ? "radio-button disable"
                                        : "radio-button"
                                    }
                                  >
                                    <input
                                      type="radio"
                                      name="yes2"
                                      checked={
                                        this.props.isFloorPlanExpanded === "0"
                                      }
                                      onChange={(e) =>
                                        this.props.handleFloorPlanExpandOption(
                                          "0"
                                        )
                                      }
                                    />
                                    <span>{t("WEB_LABELS.Collapse")}</span>
                                  </label>
                                </div>
                              </div>
                            </button>
                          </li>
                        </IF>
                        {/* Back to Previous screen - exit */}

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_CORNER_MARKING
                          }
                        >
                          <li>
                            <button
                              className=" "
                              onClick={() => {
                                this.props.onMeasurementsModalEvent(
                                  "BACK_TO_PREVIOUS"
                                );
                              }}
                            >
                              <i className="icon-Back-to-Previous-Screen"></i>
                              <div className="text">
                                {t("BUTTONS.Back_to_Previous_Screen")}
                              </div>
                            </button>
                          </li>
                        </IF>

                        {/* APPRAISAL_2D_FLOOR_PLAN */}

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_2D_FLOOR_PLAN
                          }
                        >
                          <li>
                            <button
                              className=""
                              onClick={() =>
                                this.props.onMeasurementsModalEvent("ADD_ROOM")
                              }
                            >
                              {/* <i className="icon-appraisal-add-room"></i>
                      <div className="text">Add Room</div> */}
                              <i className="icon-Exit--Return"></i>
                              <div className="text">
                                {t("BUTTONS.Exit_Return_to_Inspection")}
                              </div>
                            </button>
                          </li>
                        </IF>

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_2D_FLOOR_PLAN
                          }
                        >
                          <li>
                            <button
                              className=""
                              onClick={() =>
                                this.props.onMeasurementsModalEvent("PUBLISH")
                              }
                            >
                              <i className="icon-appraisal-published font14"></i>
                              <div className="text">
                                {t("BUTTONS.Publish_the_floor_plan")}
                              </div>
                            </button>
                          </li>
                        </IF>

                        {/* <IF
                  condition={
                    this.props.measurements.currentStep ===
                    APPRAISAL_2D_FLOOR_PLAN
                  }
                >
                  <li>
                    <button
                      className=""
                      onClick={() =>
                        this.props.onMeasurementsModalEvent("EXIT")
                      }
                    >
                      <i className="icon-close-image"></i>
                      <div className="text">{t("BUTTONS.Exit")}</div>
                    </button>
                  </li>
                </IF> */}

                        <IF
                          condition={
                            this.props.measurements.currentStep ===
                            APPRAISAL_2D_FLOOR_PLAN
                          }
                        >
                          <li className="border-none">
                            <div className="view-form-group">
                              <div className="form-group">
                                <label>
                                  {t("WEB_LABELS.View_Sketches_by_Floor")}
                                </label>
                                <select
                                  className="form-control"
                                  onChange={this.onChangeFloor.bind(this)}
                                  value={this.state.selectedFloor}
                                  name="selectedFloor"
                                  onBlur={this.validateFloor.bind(this)}
                                >
                                  <option disabled selected>
                                    Select Floor
                                  </option>
                                  {this.props.floorList.map((tz, i) => (
                                    <option key={i} value={tz.floorIndex}>
                                      {tz.floorName}
                                    </option>
                                  ))}
                                </select>
                                {this.state.floorError.error && (
                                  <div className="form-error">
                                    {this.state.floorError.msg}
                                  </div>
                                )}
                              </div>
                            </div>
                          </li>
                        </IF>
                      </ul>

                      {this.props.measurements.currentStep !==
                        APPRAISAL_2D_FLOOR_PLAN &&
                        this.props.measurements.currentStep !==
                          APPRAISAL_MEASUREMENT && (
                          <div className="instructions-button-list">
                            <ul>
                              <li>
                                <button
                                  onClick={() => this.toggleInstruction()}
                                >
                                  <i className="icon-Instructions"></i>
                                  <div className="text">
                                    {t("WEB_LABELS.Instructions")}
                                  </div>
                                </button>
                              </li>
                            </ul>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.instructions && (
          <InstructionModel
            type={this.props.isStartAppraisalCaptureImageTabVisible ? "1" : "3"}
            toggleInstruction={() => this.toggleInstruction()}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = ({ measurements }) => {
  return {
    measurements: measurements,
  };
};

export default connect(mapStateToProps)(withTranslation()(MeasurementModal));
