import React, { Component } from "react";
import "./connectingUser.scss";
import { useTranslation } from "react-i18next";
export default function ConnectingUser(props) {
  const { t } = useTranslation();
  const isPowerBy =
    props.configs && props.configs.settings && props.configs.settings.isPowerBy;
  const clientDisplayName =
    props.configs &&
    props.configs.settings &&
    props.configs.settings.clientDisplayName;
  const logo = props.configs && props.configs.callLogo;
  const style = {};
  if (props.configs && props.configs.backgroundColor) {
    style.backgroundColor = props.configs.backgroundColor;
  }
  return (
    <>
      <div className="connecting-user">
        <div className="image" style={{ ...style }}>
          <img src={logo} />
        </div>
        <div className="connecting-loading">
          <h4>
            <div className="loader-spin"></div>{" "}
            <span>{t("WEB_LABELS.Connecting")}</span>
          </h4>
        </div>
        <div className="powered-by">
          <div className="site-logo">
            {isPowerBy && (
              <h4>
                {t("WEB_LABELS.Powered_by")} {clientDisplayName}
              </h4>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
