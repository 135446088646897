import * as actionTypes from "../actions/actionTypes";

const initialState = {
  camera: "",
  microphone: "",
  selectedCamera: {},
  selectedMicrophone: {},
  selectedSpeaker: {},
};

export default function me(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.MEDIA_PERMISSIONS:
      return {
        ...state,
        camera: action.payload.camera,
        microphone: action.payload.microphone,
      };
    case actionTypes.SELECT_CAMERA:
      return {
        ...state,
        selectedCamera: action.payload,
      };
    case actionTypes.SELECT_MICROPHONE:
      return {
        ...state,
        selectedMicrophone: action.payload,
      };
    case actionTypes.SELECT_SPEAKER:
      return {
        ...state,
        selectedSpeaker: action.payload,
      };
    default:
      return state;
  }
}
