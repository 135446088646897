import React, { Component } from "react";
import "./uploadingImageModel.scss";
import { useTranslation } from "react-i18next";
export default function UploadingImageModel(props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="alert-box">
        <div className="box upload-img">
          <span>
            {t("WEB_LABELS.Uploading_Image")}{" "}
            {`${props.captureImageUploadingCount} / ${props.captureImageTotalCount}`}{" "}
          </span>
          <button className="close-modal">
            <img src="/images/loader.svg" />
          </button>
        </div>
      </div>
    </>
  );
}
