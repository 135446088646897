import _ from "lodash";

export function dehydrateParticipant(data) {
  return _.omitBy(
    {
      email: data.email,
      message: data.message,
      phone: data.phone,
      templateId: data.templateId,
      name: data.name,
    },
    _.isUndefined
  );
}
