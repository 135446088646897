import { Component, createRef } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  deleteAttachment,
  downloadAttachment,
  uploadAttachment,
} from "../../../actions/attachmentAction";
import { appConstants } from "../../../constants";
import { ATTACHMENT_UPLOAD_SIZE_EXCEEDED } from "../../../constants/commonStrings";
import { getUTCToLocalDateTimeWithFormat, StorageUtil } from "../../../helpers";
import { customToast } from "../../../helpers/customToast";
import "./attachmentDocumentModal.scss";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
const AttachmentItem = ({
  fileName,
  user,
  time,
  role,
  isReceived,
  onDownloadAttachment,
  onDeleteAttachment,
}) => {
  const isPO = role === appConstants.USER_ROLES.PROPERTY_OWNER;
  const shouldAllowPO = isPO && !!!isReceived;

  return (
    <div className="box">
      <div className="title">
        <i className="icon-Attach-document"></i>
        {fileName}
      </div>
      <div className="bottom-list">
        <span className="user">
          <i className="icon-user-management"></i>
          {user}
        </span>
        <div className="rigit-info">
          <span className="time">
            <i className="icon-time"></i>
            {time}
          </span>
          <span className="delete-down">
            {(!!!isPO || (isPO && shouldAllowPO)) && (
              <button onClick={onDownloadAttachment}>
                <i className="icon-download"></i>
              </button>
            )}
            {/* <button onClick={onDeleteAttachment}>
              <i className="icon-fill-delete"></i>
            </button> */}
          </span>
        </div>
      </div>
    </div>
  );
};

class AttachmentDocumentModal extends Component {
  constructor() {
    super();
    this.state = {};
    this.uploadRef = createRef();
  }

  validateAttachDocumentType(file) {
    return (
      ["image/jpeg", "image/png", "application/pdf"].includes(file.type) &&
      (file.name.endsWith(".jpg") ||
        file.name.endsWith(".JPG") ||
        file.name.endsWith(".jpeg") ||
        file.name.endsWith(".JPEG") ||
        file.name.endsWith(".png") ||
        file.name.endsWith(".PNG") ||
        file.name.endsWith(".pdf"))
    );
  }

  onDrop(e) {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (!this.validateAttachDocumentType(file)) {
      return customToast.error(
        i18n.t("COMMON_STRING.ATTACHMENT_UPLOAD_UNSUPPORTED_FORMAT")
      );
    }
    if (file.size / (1024 * 1024) >= 10) {
      return customToast.error(
        i18n.t("COMMON_STRING.ATTACHMENT_UPLOAD_SIZE_EXCEEDED")
      );
    }
    this.uploadFile(file);
  }

  onSelectAttachment(e) {
    e.preventDefault();
    const file = e.target.files[0];
    if (!this.validateAttachDocumentType(file)) {
      return customToast.error(
        i18n.t("COMMON_STRING.ATTACHMENT_UPLOAD_UNSUPPORTED_FORMAT")
      );
    }
    if (file.size / (1024 * 1024) >= 10) {
      return customToast.error(
        i18n.t("COMMON_STRING.ATTACHMENT_UPLOAD_SIZE_EXCEEDED")
      );
    }
    this.uploadFile(file);
  }

  async uploadFile(file) {
    const fileName = file.name;
    const extraPayload = {
      attachmentDetails: JSON.stringify({
        joborderId: this.props.jobOrderId,
        updatedBy: StorageUtil.getUserName(),
      }),
    };
    const { payload } = await this.props.uploadAttachment(
      file,
      fileName,
      extraPayload
    );
    const dt = {
      updatedBy: payload.updatedBy,
      joborderId: this.props.jobOrderId,
      location: payload.location,
      fileName: payload.fileName,
      dateTime: payload.updatedAt,
      attachmentId: payload.attachmentId,
    };
    this.props.onUploadAttachment(dt);
  }

  formatTime = (date) => {
    return getUTCToLocalDateTimeWithFormat(
      date,
      appConstants.DATE_TIME_API_FORMAT,
      appConstants.TIME_SHORT_FORMAT
    );
  };

  downloadAttachment({ fileName, attachmentId }) {
    this.props.downloadAttachment(attachmentId).then((data) => {
      const blob = new Blob([data.data], {
        type: data.headers["content-type"],
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const url = URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  async deleteAttachment(attachmentId) {
    await this.props.deleteAttachment(attachmentId);
    this.props.fetchAttachments();
  }

  render() {
    const { attachments, uploading, me, t } = this.props;
    return (
      <div className="comman-modal left-side open">
        <div className="comman-modal-main">
          <div className="side-head">
            {t("WEB_LABELS.Attach_Document")}
            <div className="side-head-buttons">
              <button
                className="close-modal modal-button-effect"
                onClick={() => this.props.hideAttachmentDocumentModal()}
              >
                <i className="icon-close-image"></i>
              </button>
            </div>
          </div>
          <div className="comman-modal-body attach-document scroll-bar-style">
            <div
              className="attach-button"
              onDrop={(e) => this.onDrop(e)}
              onDragEnter={(e) => e.preventDefault()}
              onDragOver={(e) => e.preventDefault()}
            >
              <button
                className="drop-btn"
                onClick={() => {
                  this.uploadRef.current.click();
                }}
              >
                <i className="icon-attach"></i>
                {t("WEB_LABELS.Drop_Or_Select_Your_File")}{" "}
                {!!uploading && <div className="loader-spin"></div>}
              </button>
              <input
                hidden
                type="file"
                ref={this.uploadRef}
                onChange={this.onSelectAttachment.bind(this)}
              />
            </div>
            <div className="attach-list scroll-bar-style">
              {attachments.length > 0 &&
                attachments.map((item, i) => (
                  <AttachmentItem
                    key={item.attachmentId}
                    fileName={item.fileName}
                    user={item.updatedBy}
                    time={this.formatTime(item.updatedAt)}
                    role={_.get(me, ["role"], "")}
                    isReceived={item.isReceived ?? false}
                    onDownloadAttachment={() => this.downloadAttachment(item)}
                    onDeleteAttachment={() =>
                      this.deleteAttachment(item.attachmentId)
                    }
                  />
                ))}
              {attachments.length == 0 && (
                <div className="no-attachment">
                  {t("WEB_LABELS.There_are_no_Attachments_available")}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  uploadAttachment: (file, fileName, extraPayload) =>
    dispatch(uploadAttachment(file, fileName, extraPayload)),
  deleteAttachment: (attachmentId) => dispatch(deleteAttachment(attachmentId)),
  downloadAttachment: (attachmentId) =>
    dispatch(downloadAttachment(attachmentId)),
});

const mapStateToProps = ({ attachmentView, me }) => ({
  attachments: attachmentView.attachments || [],
  uploading: attachmentView.uploading,
  me,
});

const connectedAttachmentDocumentModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AttachmentDocumentModal));
export { connectedAttachmentDocumentModal as AttachmentDocumentModal };
