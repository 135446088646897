import React from "react";
import "./bottomMenu.scss";
import { connect } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import {
  capture_image,
  view_participant_list,
  flash_on_off,
  notes,
  chat,
  start_mark_up,
  start_pointer,
  call_settings,
  call_recording,
  attachment,
  start_appraiser_measurement,
  screen_share,
  bookmark,
  questionnaire,
} from "./../../constants/Permission";
import RBAC from "./../RBAC";
import classnames from "classnames";

function BottomMenu(props) {
  const { t } = useTranslation();

  return (
    <>
      <RBAC
        action={start_pointer}
        yes={
          props.ispinnedViewActive == true &&
          props.disbleCaptureImage == false && (
            <div
              className={`pointer-mobile ${
                props.ispinnedViewActive == true &&
                props.disbleCaptureImage == false
                  ? ""
                  : "disable"
              }`}
            >
              <button
                className={classnames({
                  active: props.isPointerStarted,
                })}
                onClick={() => {
                  props.toggleLivePointer();
                }}
              >
                <i className="icon-pointer"></i>
              </button>
            </div>
          )
        }
      />

      <div id="bottomView" ref={props.refNode}>
        <div className="bottom-menu">
          <div className="inner-bottom">
            <ul className="call-menu">
              <li className="mice">
                <Tooltip
                  title={
                    props.isLocalAudioTrackEnabled
                      ? t("TOOLTIP.Mute_My_Audio")
                      : t("TOOLTIP.Unmute_My_Audio")
                  }
                  arrow
                >
                  <button onClick={props.handleToggleAudio}>
                    {props.isLocalAudioTrackEnabled == true ? (
                      <i className="icon-microphone"></i>
                    ) : (
                      <i className="icon-mute-voice">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    )}
                  </button>
                </Tooltip>
              </li>
              <li className="video">
                <Tooltip
                  title={
                    props.isLocalVideoTrackEnabled
                      ? t("TOOLTIP.Stop_My_Video")
                      : t("TOOLTIP.Start_My_Video")
                  }
                  arrow
                >
                  <button onClick={props.handleToggleCamera}>
                    {props.isLocalVideoTrackEnabled == true ? (
                      <i className="icon-video"></i>
                    ) : (
                      <i className="icon-stop-video">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    )}
                  </button>
                </Tooltip>
              </li>

              <RBAC
                action={capture_image}
                yes={
                  <li
                    className={classnames("capture-image display-web-view", {
                      disable:
                        !(
                          props.ispinnedViewActive && !props.disbleCaptureImage
                        ) || props.isMeasurementOngoing,
                    })}
                  >
                    <Tooltip
                      title={t("TOOLTIP.Capture_Images_with_No_Labels")}
                      arrow
                    >
                      <button
                        onClick={() => {
                          if (
                            props.ispinnedViewActive &&
                            !props.disbleCaptureImage
                          ) {
                            props.captureImage();
                            props.hideBookmarkViewModel();
                          }
                        }}
                      >
                        <div className="icon">
                          <i className="icon-capture-image"></i>
                        </div>
                      </button>
                    </Tooltip>
                  </li>
                }
                no={null}
              />

              <RBAC
                action={start_appraiser_measurement}
                yes={
                  <Tooltip title={t("TOOLTIP.Start_Inspection")} arrow>
                    <li
                      className={classnames("start-inspection", {
                        // disable: props.isMeasurementFlow,
                      })}
                    >
                      <button
                        onClick={() => {
                          props.isMeasurementFlow
                            ? props.toggleInspectionProgressLoader()
                            : props.checkConfigurationForMeasurement();
                        }}
                      >
                        <div className="icon">
                          <i className="icon-measurement1"></i>
                        </div>
                      </button>
                    </li>
                  </Tooltip>
                }
                no={null}
              />

              {props.showBookmarkMenu && (
                <RBAC
                  action={bookmark}
                  yes={
                    <Tooltip title={t("BUTTONS.Labels")} arrow>
                      <li
                        className={classnames("chat-main display-web-view", {
                          disable: !(
                            props.ispinnedViewActive &&
                            !props.disbleCaptureImage
                          ),
                          active: props.isBookmarkViewModelVisible,
                        })}
                      >
                        <button
                          onClick={() => {
                            if (props.isBookmarkViewModelVisible) {
                              props.hideBookmarkViewModel();
                            } else {
                              props.showMoreOptions();
                              props.showBookmarkViewModel();
                            }
                          }}
                        >
                          <div className="icon">
                            <div className="notified">
                              {props.bookmarks.length}
                            </div>
                            <i className="icon-bookmark"></i>
                          </div>
                        </button>
                      </li>
                    </Tooltip>
                  }
                />
              )}

              <RBAC
                action={flash_on_off}
                yes={
                  <Tooltip
                    title={
                      props.isFlashEnable
                        ? props.isFlashEnable && props.isFlashActive
                          ? t("BUTTONS.Flash_Off")
                          : t("BUTTONS.Flash_On")
                        : t("BUTTONS.Flash_Light_Not_Supported")
                    }
                    arrow
                  >
                    <li
                      className={`chat-main flash-image ${
                        props.ispinnedViewActive
                          ? props.ispinnedViewActive && props.isFlashEnable
                            ? "active"
                            : "opacity"
                          : "disable"
                      }`}
                    >
                      <button
                        onClick={() => {
                          if (props.isFlashEnable && props.ispinnedViewActive) {
                            props.activeDeactiveFlash();
                          }
                        }}
                      >
                        <div className="icon">
                          <i
                            className={
                              props.isFlashEnable && props.isFlashActive
                                ? "icon-flash-on"
                                : "icon-flash-off"
                            }
                          ></i>
                        </div>
                      </button>
                    </li>
                  </Tooltip>
                }
              />

              <Tooltip title={t("TOOLTIP.More_Options")} arrow>
                <li
                  className={`more ${
                    props.isMoreMenuVisible ? "notified" : ""
                  }`}
                  onClick={props.showMoreOptions}
                >
                  <button>
                    <div className="count"></div>
                    <i className="icon-more"></i>
                  </button>
                </li>
              </Tooltip>
              <li className="disconnected">
                <Tooltip title={t("TOOLTIP.End_Call")} arrow>
                  <button onClick={props.handleDisconnectCall}>
                    <i className="icon-phone"></i>
                  </button>
                </Tooltip>
              </li>
            </ul>
            <ul
              className={
                "list-menu " +
                (props.isModalOpen == false && props.isMoreMenuVisible
                  ? "open"
                  : "")
              }
            >
              <RBAC
                action={chat}
                yes={
                  <li
                    className={classnames("chat-main ", {
                      disable:
                        props.totalParticipants < 2 ||
                        props.isMeasurementOngoing,
                      active: props.isChatModelVisible,
                      displayMobileView: !props.showBookmarkMenu,
                    })}
                  >
                    <button
                      onClick={() => {
                        if (props.isChatModelVisible == true) {
                          props.hideChatModel();
                        } else {
                          props.showMoreOptions();
                          props.showChatModel();
                        }
                      }}
                    >
                      <div className="icon">
                        <div
                          className={props.unreadMessageCount > 0 && "notified"}
                        >
                          {props.unreadMessageCount > 0 &&
                            props.unreadMessageCount}
                        </div>
                        <i className="icon-chat"></i>
                      </div>
                      <div className="text">{t("BUTTONS.Chat")}</div>
                    </button>
                  </li>
                }
              />
              <RBAC
                action={view_participant_list}
                yes={
                  <li
                    className={props.isListParticipantVisible ? "active" : ""}
                  >
                    <button
                      className="btn"
                      onClick={() => {
                        if (props.isListParticipantVisible) {
                          props.hideListParticipant();
                          props.showMoreOptions();
                        } else {
                          props.showMoreOptions();
                          props.hideBookmarkViewModel();
                          props.showListParticipant();
                        }
                      }}
                    >
                      <div className="icon">
                        <i className="icon-participants"></i>
                      </div>
                      <div className="text">
                        {t("BUTTONS.Participants")}{" "}
                        {props.totalParticipants > 0 &&
                          `(${props.totalParticipants})`}
                      </div>
                    </button>
                  </li>
                }
              />

              <RBAC
                action={notes}
                yes={
                  <li
                    className={classnames({
                      disable: props.totalParticipants < 2,
                      active: props.isNoteModelVisible == true,
                    })}
                  >
                    <button
                      onClick={() => {
                        if (props.isNoteModelVisible) {
                          props.hideNoteModel();
                          props.showMoreOptions();
                        } else {
                          props.showMoreOptions();
                          props.hideBookmarkViewModel();
                          props.showNoteModel();
                        }
                      }}
                    >
                      <div className="icon">
                        <i className="icon-note"></i>
                      </div>
                      <div className="text">
                        {t("BUTTONS.Notes")}{" "}
                        {props.noteCounts > 0 && `(${props.noteCounts})`}
                      </div>
                    </button>
                  </li>
                }
              />

              <RBAC
                action={attachment}
                yes={
                  <li
                    className={classnames({
                      disable: props.totalParticipants < 2,
                      active: props.isAttachmentDocumentModalVisible,
                    })}
                  >
                    <button
                      onClick={() => {
                        if (props.totalParticipants > 1) {
                          if (props.isAttachmentDocumentModalVisible) {
                            props.hideAttachmentDocumentModal();
                            props.showMoreOptions();
                          } else {
                            props.showMoreOptions();
                            props.showAttachmentDocumentModal();
                          }
                        }
                      }}
                    >
                      <div className="icon">
                        <i className="icon-Attach-document"></i>
                      </div>
                      <div className="text">
                        {t("BUTTONS.Attach_Document")}{" "}
                        {props.attachmentCounts > 0 &&
                          `(${props.attachmentCounts})`}
                      </div>
                    </button>
                  </li>
                }
              />
              <RBAC
                action={questionnaire}
                yes={
                  <li
                    className={classnames({
                      disable: props.totalParticipants < 2,
                      active: props.showQuestionnaire,
                    })}
                  >
                    <button onClick={props.toggleQuestionnaire}>
                      <div className="icon">
                        <i className="icon-questionnaire"></i>
                      </div>
                      <div className="text">{t("BUTTONS.Questionnaire")}</div>
                    </button>
                  </li>
                }
              />

              <RBAC
                action={start_pointer}
                yes={
                  <li
                    className={classnames("pointer", {
                      disable: !(
                        props.ispinnedViewActive == true &&
                        props.disbleCaptureImage == false
                      ),
                      active: props.isPointerStarted,
                    })}
                  >
                    <button
                      onClick={() => {
                        props.showMoreOptions();
                        props.toggleLivePointer();
                      }}
                    >
                      <div className="icon">
                        <i className="icon-pointer"></i>
                      </div>
                      <div className="text">{t("BUTTONS.Pointer")}</div>
                    </button>
                  </li>
                }
              />

              <RBAC
                action={start_mark_up}
                yes={
                  <li
                    className={
                      props.ispinnedViewActive == true ? "" : "disable"
                    }
                  >
                    <button
                      onClick={() => {
                        props.stopLivePointer();
                        props.showMarkupView();
                      }}
                    >
                      <div className="icon">
                        <i className="icon-markup"></i>
                      </div>
                      <div className="text">{t("BUTTONS.Markup")}</div>
                    </button>
                  </li>
                }
              />

              {!props.showBookmarkMenu && (
                <RBAC
                  action={chat}
                  yes={
                    <li
                      className={classnames("", {
                        disable:
                          props.totalParticipants < 2 ||
                          props.isMeasurementOngoing,
                        active: props.isChatModelVisible == true,
                      })}
                      onClick={() => {
                        if (props.isChatModelVisible == true) {
                          props.hideChatModel();
                        } else {
                          props.showChatModel();
                        }
                      }}
                    >
                      <button>
                        <div className="icon">
                          <div
                            className={
                              props.unreadMessageCount > 0 && "notified"
                            }
                          >
                            {props.unreadMessageCount > 0 &&
                              props.unreadMessageCount}
                          </div>
                          <i className="icon-chat"></i>
                        </div>
                        <div className="text">{t("TOOLTIP.Chat")}</div>
                      </button>
                    </li>
                  }
                />
              )}

              <RBAC
                action={call_recording}
                yes={
                  <li style={{ display: "none" }}>
                    <button>
                      <div className="icon">
                        <span className="icon-call-recording">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </span>
                      </div>
                      <div className="text">{t("BUTTONS.Call_Recording")}</div>
                    </button>
                  </li>
                }
              />
              <RBAC
                action={capture_image}
                yes={
                  <li
                    className={classnames("capture-image displayMobileView", {
                      disable: !(
                        props.ispinnedViewActive && !props.disbleCaptureImage
                      ),
                    })}
                  >
                    <Tooltip
                      title={t("TOOLTIP.Capture_Images_with_No_Labels")}
                      arrow
                    >
                      <button
                        onClick={() => {
                          if (
                            props.ispinnedViewActive &&
                            !props.disbleCaptureImage
                          ) {
                            props.captureImage();
                            props.hideBookmarkViewModel();
                          }
                        }}
                      >
                        <div className="icon">
                          <i className="icon-capture-image"></i>
                        </div>
                        <div className="text">{t("BUTTONS.Capture_Image")}</div>
                      </button>
                    </Tooltip>
                  </li>
                }
                no={null}
              />
              <RBAC
                action={screen_share}
                yes={
                  <li
                    className={`screen-share ${
                      props.totalParticipants > 1
                        ? props.isScreenShareOn
                          ? "active"
                          : ""
                        : "disable"
                    }`}
                    style={{ display: props.isMobile ? "none" : "" }}
                  >
                    <button
                      onClick={() => {
                        props.shareScreen();
                      }}
                    >
                      <div className="icon">
                        <i
                          className={
                            props.isScreenShareOn
                              ? "icon-stop-screen-share"
                              : "icon-screen-share-new"
                          }
                        ></i>
                      </div>
                      <div className="text">
                        {props.isScreenShareOn
                          ? t("BUTTONS.Stop_Screen_Share")
                          : t("BUTTONS.Screen_Share")}
                      </div>
                    </button>
                  </li>
                }
              />
              <RBAC
                action={call_settings}
                yes={
                  <li
                    className={classnames({
                      active: props.isCallSettingsVisible,
                    })}
                  >
                    <button
                      onClick={() => {
                        props.showCallSettings();
                        props.showMoreOptions();
                      }}
                    >
                      <div className="icon">
                        <i className="icon-call-setting"></i>
                      </div>
                      <div className="text">{t("BUTTONS.Call_Settings")}</div>
                    </button>
                  </li>
                }
                no={null}
              />
              {false && process.env.REACT_APP_APP_ENV === "qa" && (
                <li>
                  <button onClick={props.twilioRoomMonitor}>
                    <div className="icon">
                      <i className={"icon-information"}></i>
                    </div>
                    <div className="text">Room Monitor</div>
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    bookmarks: state.bookmarkView.bookmarks,
  };
};

export default connect(mapStateToProps)(BottomMenu);
