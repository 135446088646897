import api from "./apis/api";
import { getFileFormData } from "../helpers/commonJSFunction";
import {
  dehydrateCaptureImage,
  dyHydrateImageListForDelete,
} from "./transformers";

class CaptureImageService {
  upload(eitherCallUUIdOrJobOrderId, data, extraPayload = {}) {
    let extraFields = {};
    if (
      (extraPayload && extraPayload.bookmarkId) ||
      (extraPayload && extraPayload.isMapViewImage)
    ) {
      extraFields.bookmarkId = extraPayload.bookmarkId;
      extraFields.bookmarkLabel = extraPayload.bookmarkLabel;
    }
    const fileFormData = getFileFormData(data, "abc.jpg", extraFields);
    return api.picture
      .uploadCaptureImage(eitherCallUUIdOrJobOrderId, fileFormData)
      .then((res) => dehydrateCaptureImage(res.data));
  }

  deleteImage(data) {
    return api.picture.deleteImage(dyHydrateImageListForDelete(data));
  }
}

export default new CaptureImageService();
