export const TRANSLATIONS_Russian = {
  WEB_LABELS: {
    Secure_Video_Call_Session_Expired: "Сеанс безопасного видеозвонка истек",
    Secure_Video_Call_Ended: "Безопасный видеовызов завершен",
    Thank_you_for_connecting_with_us: "Спасибо, что связались с нами!",
    Connected_Waiting_for_Other_Participants:
      "Связаны. Ожидание других участников ...",
    Powered_by: "Питаться от",
    Connecting: "Подключение ...",
    Local_Pike: "Местная щука",
    Would_you_like_to_save_image: "Вы хотите сохранить изображение?",
    Message: "Сообщение",
    Location_Not_shared: "Местоположение: не опубликовано",
    Location: "Место нахождения",
    Tools: "Инструменты",
    Ask_PO_to_Turn_on_Location:
      "Попросите владельца собственности включить геолокацию",
    Preparing_image_for_markup: "Подготовка изображения к разметке ...",
    Download_Message: "Скачать приложение удаленной аттестации",
    Disclaimer: `Необходимо скачать приложение RemoteVal из App Store. После завершения загрузки повторно откройте свое текстовое / электронное сообщение с приглашением и еще раз щелкните ссылку, чтобы автоматически присоединиться к звонку.`,
    MeasurementLinkDisclaimer:
      "Необходимо скачать приложение RemoteVal из App Store. После завершения загрузки повторно откройте свое текстовое / электронное сообщение с приглашением и еще раз щелкните ссылку, чтобы автоматически начать проверку.",
    Zoom_Level: "Уровень масштабирования",
    enter_cell_phone: "введите сотовый телефон",
    Email: "Эл. адрес",
    Cell_Phone: "Сотовый телефон",
    Participant_Name: "Имя участника",
    Invite_Participant: "Пригласить участника",
    Join_secure_video_call_by_clicking_on_the_following_link:
      "Присоединяйтесь к защищенному видеовстрече, щелкнув следующую ссылку:",
    There_are_no_Attachments_available: "Нет доступных вложений.",
    Drop_Or_Select_Your_File: "Перетащите или выберите свой файл",
    Attach_Document: "Прикрепить документ",
    Enter_Your_Name: "Введите ваше имя",
    I_confirm_that_I_have_read_and_agree_to_the:
      "Я подтверждаю, что прочитал и согласен с",
    Search_or_Add_Captured_Image_Label:
      "Поиск или добавление метки захваченного изображения",
    Press_Enter_or_Click_on_Add_New_Label_to_add_new_Label:
      "Нажмите Enter или нажмите «Добавить новую метку», чтобы добавить новую метку.",
    To: "К",
    Everyone: "Все",
    Type_a_message: "Напишите сообщение",
    Are_you_sure_you_want_to_delete: "Вы уверены, что хотите удалить ?",
    Room_is_full_Please_try_again_later:
      "Комната заполнена. Пожалуйста, попробуйте позже",
    Comment: "Комментарий",
    Room_2_Measurement: "Комната 2 Измерение",
    Room_1_Measurement: "Комната 1 Измерение",
    Room_2_Image: "Комната 2 Изображение",
    Room_1_Image: "Комната 1 Изображение",
    No_of_Rooms: "Кол-во комнат",
    Location: "Место нахождения",
    Property_Type: "Тип недвижимости",
    House: "дом",
    Inspection_Form: "Форма проверки",
    Instructions: "инструкции",
    Guide_user_to_position_camera_on_the_target:
      "Помогите пользователю расположить камеру на цели",
    Click_on_the_Label_once_to_capture_labeled_image:
      "Щелкните этикетку один раз, чтобы сделать снимок с надписью.",
    Select_the_floor_of_the_Home_Room_Type_and_a_Room_Name:
      "Выберите этаж дома, тип комнаты и название комнаты.",
    Choose_GLA_or_Non_GLA: "Выберите GLA или Non-GLA.",
    Click_on_the_Start_Measurement_button: "Нажмите кнопку «Начать измерение».",
    Click_the_Auto_Corner_Detection_to_On_Off:
      "Нажмите «Автоопределение угла», чтобы включить / выключить.",
    Position_vertical_Green_Floor:
      "Разместите вертикальный зеленый маркер пола в углу комнаты и убедитесь, что он касается пола.",
    App_will_auto_mark_a_corner:
      "Приложение автоматически отметит угол или коснется кнопки «Захватить угол вручную».",
    Guide_the_user_to_walk_to_each_corner_of_the_room_to_mark_each_corner:
      "Помогите пользователю пройти в каждый угол комнаты, чтобы отметить каждый угол.",
    When_last_line_connects: `Когда последняя линия соединится с начальной точкой, она станет красной. Приложение автоматически подключится и завершит эскиз, или вы можете использовать кнопку «Соединить угол и закончить эскиз», чтобы завершить измерение.`,
    button_to_view_final_sketch:
      "Нажмите кнопку «Показать законченный эскиз плана этажа», чтобы просмотреть окончательный эскиз.",
    Are_You_Sure_You_Want_to_Leave_This_Call:
      "Вы уверены, что хотите покинуть этот звонок?",
    Street_View: "Просмотр улиц",
    Select_Measurement_Type: "Выберите тип измерения",
    Floor: "Пол",
    Ceiling: "Потолок",
    View_Sketches_by_Floor: "Посмотреть эскизы по этажам",
    Uploading_Image: "Загрузка изображения",
    Call_Setting: "Настройка вызова",
    Allow_access_to_your_Microphone_Click_the_padlock:
      "Разрешите доступ к вашему микрофону. Нажмите на замок",
    to_the_left_of_the_address_bar: "слева от адресной строки.",
    Speakers: "Динамики",
    Allow_access_to_your_Camera_Click_the_padlock:
      "Разрешите доступ к вашей камере. Нажмите на замок",
    to_the_address_bar: "в адресную строку.",
    Failed_to_start_the_video_camera_Please_select_another_video_camera:
      "Не удалось запустить видеокамеру. Пожалуйста, выберите другую видеокамеру.",
    Title: "Заголовок",
    New_Note: "Новая заметка ...",
    There_are_no_Notes_available: "Нет доступных заметок.",
    Are_you_sure_you_want_to_delete_this_note:
      "вы уверены, что хотите удалить эту заметку?",
    Network_Type: "Тип сети",
    Netword_Strength: "Сила Netword",
    To_share_live_video:
      "Чтобы поделиться живым видео. пожалуйста, включите разрешение камеры в настройках вашего браузера, затем",
    click_here: "кликните сюда",
    to_reload_the_page: "чтобы перезагрузить страницу.",
    we_cant_access_your_microphone:
      "мы не можем получить доступ к вашему микрофону. Чтобы предоставить доступ, перейдите в настройки вашего браузера, а затем",
    Questionnaire_Form: "Форма анкеты",
    No_images_found: "Изображения не найдены!",
    Image: "Изображение",
    Captured_Images: "Захваченные изображения",
    Share_your_screen: "Поделитесь своим экраном",
    Share_your_live_video: "Поделитесь своим живым видео?",
    This_will_stop_your_video:
      "Это остановит ваше видео. Вы хотите продолжить?",
    This_will_stop_screen_share:
      "Это остановит демонстрацию экрана. Вы хотите продолжить?",
    Floor: "Palapag",
    Room_Area_Label: "Этикетка комнаты / зоны",
    Room_Area_Name:
      "Название комнаты / зоны(например, спальня 1, ванная 2 и т. Д.)",
    Room_Area_Name_Placeholder: "Название комнаты / зоны",
    You: "Ты",
    Are_you_sure_you_want_to_delete_captured_image:
      "Вы уверены, что хотите удалить захваченное изображение?",
    Start_Over: "Начать сначала - Вы уверены, что хотите удалить планы этажей?",
    Are_You_Sure_You_Want_to_Exit_Measurement_process:
      "Вы уверены, что хотите выйти из процесса измерения?",
    Labels: "Этикетки",
    Select_Language: "Выбрать язык",
    This_call_is_being_recorded: "Этот видео -звонок записывается",
    This_call_is_being_recorded_by_your_appraiser_for_their_private_work_file:
      "Этот звонок записывается вашим оценщиком в его личный рабочий файл.",
    It_will_not_be_shared: `Он не будет передан посторонним лицам, включая вашу ипотеку.
    кредитора, и ваш оценщик не сможет скачать его из
    Сайт RemoteVal. Он будет использоваться исключительно оценщиком для завершения
    ваша оценка.`,
    If_you_do_not_wish_your_screen_to_be_recorded_please_inform_your_appraiser_now:
      "Если вы не хотите, чтобы ваш экран записывался, сообщите об этом своему оценщику.",

    Measurement: "Измерение",
    Create_Measurement: "Создать измерение",
    Fencing: "Фехтование",
    Show: "Показать",
    Hide: "Скрывать",
    Live_Floor_Plan: "Живой план этажа",
    Expand: "Расширять",
    Collapse: "Крах",
    Instruction_for_Call_Settings: "Инструкция по настройке звонков",
    Speaker_Mic_Camera_can_be_tested_via_call_settings:
      "Динамик, микрофон и камеру можно проверить в настройках звонка в меню «Еще».",
    To_Check_the_call_settings_now_click_on_the_below_button:
      "Чтобы проверить настройки вызова, нажмите кнопку ниже.",
    Call_recording_by_virtual_inspector:
      "Этот видеозвонок записывается вашим виртуальным инспектором для их личного рабочего файла и для целей внутреннего аудита организацией, которая его запросила. Это не будет делиться ни с какой внешней стороной.",
    Call_and_Screen_Recording_Opt_Out:
      "Если вы не хотите, чтобы ваш экран и этот звонок был записан, сообщите о своем виртуальном инспекторе сейчас.",
    Test_Audio_Video_Components_Button:
      "Динамик, микрофон и камера могут быть протестированы, нажав на кнопку ниже.",
  },
  BUTTONS: {
    Re_Connect: "Повторное подключение",
    End_Session: "Конец сеанса",
    Chat: "Чат",
    Participants: "Участников",
    Notes: "Примечания",
    Attach_Document: "Прикрепить документ",
    Questionnaire: "Анкета",
    Labels: "Этикетки",
    Pointer: "Указатель",
    Markup: "Разметка",
    Flash_Off: "Вспышка выключена",
    Flash_On: "Мигает",
    Call_Recording: "Запись звонков",
    Capture_Image: "Захват изображения",
    Screen_Share: "Поделиться экраном",
    Stop_Screen_Share: "Остановить демонстрацию экрана",
    Call_Settings: "Настройка режима звонков",
    Clear_All: "Очистить все",
    Exit: "Выход",
    Save: "Сохранить",
    Close: "Закрывать",
    Save_and_Exit: "Сохранить и выйти",
    Continue: "Продолжать",
    Markup: "Разметка",
    Street_View: "Просмотр улиц",
    Open_in_App: "Открыть в приложении",
    Privacy_Policy: "Политика конфиденциальности",
    Invite: "Приглашать",
    Join: "Присоединиться",
    Add_Captured_Image_Label: "Добавить метку захваченного изображения",
    No: "Нет",
    Yes: "да",
    Delete: "Удалить",
    Cancel: "Отмена",
    RETRY: "ПОВТОРИТЬ",
    OK: "Ok",
    Back: "Назад",
    Leave_Call: "Оставить звонок",
    Got_It: "Понятно",
    Start_New_Inspection: "Начать новую проверку",
    Resume_Existing_Inspection: "Возобновить существующую проверку",
    View_All_Current_Sketch: "Просмотреть все текущие эскизы",
    Open_Sketch: "Открыть эскиз",
    Auto_Corner_Detection_ON: "Автоматическое определение угла включено",
    Auto_Corner_Detection_OFF: "Автоматическое определение угла ВЫКЛ.",
    Manually_Capture_Corner: "Захватить угол вручную",
    Undo_Last_Corner_Capture: "Отменить захват последнего угла",
    Connect_Corner_Finish_Sketch: "Соединить угол и закончить эскиз",
    Capture_3D_Room_Height: "Захватить высоту комнаты в 3D",
    Set_Fixed_3D_Room_Height: "Установить фиксированную высоту 3D-комнаты",
    Capture_Door: "Дверь захвата",
    Set_Door: "Установить дверь",
    Show_Finished_Floor_Plan_Sketch: "Показать готовый эскиз плана этажа",
    Back_to_Previous_Screen: "Вернуться к предыдущему экрану",
    Add_Room: "Добавить комнату",
    Publish_the_floor_plan: "Опубликовать план этажа",
    Exit_Return_to_Inspection: "Выйти и вернуться к осмотру",
    Audio: "Аудио",
    Video: "видео",
    Microphone: "Микрофон",
    Microphone_not_found: "Микрофон не найден",
    Microphone_Blocked: "Микрофон заблокирован",
    Default: "Дефолт",
    Grant_Permission: "Предоставить разрешение",
    Speaker_Blocked: "Динамик заблокирован",
    Play_Test_Sound: "Воспроизвести тестовый звук",
    Preview: "Предварительный просмотр",
    Camera: "Камера",
    Camera_not_found: "Камера не найдена",
    Camera_Blocked: "Камера заблокирована",
    Done: "Выполнено",
    View: "Вид",
    Capture_Labeled_Images: "Захват помеченных изображений",
    Capture_Measurements: "Захват измерений",
    Instructions: "инструкции",
    Start_Measurement_Process: "Начать процесс измерения",
    Flash_Light_Not_Supported:
      "Удаленный фонарик не поддерживается на гостевом устройстве или не готова",
  },
  TOOLTIP: {
    Mute_My_Audio: "Отключить мой звук",
    Unmute_My_Audio: "Включить мой звук",
    Stop_My_Video: "Остановить мое видео",
    Start_My_Video: "Начать мое видео",
    Capture_Images_with_No_Labels: "Захват изображений без ярлыков",
    Start_Inspection: "Начать проверку",
    Chat: "Чат",
    More_Options: "Больше опций",
    End_Call: "Завершить звонок",
    Unpin_Video: "Открепить видео",
    Pin_Video: "Закрепить видео",
    Add_New_Note: "Добавить новую заметку",
    Refresh: "Обновить",
    View_More: "Посмотреть больше",
    Invite_Participant: "Пригласить участника",
    Delete_Image: "Удалить изображение",
  },
  COMMON_STRING: {
    INVITATION_SENT_SUCCESS_MESSAGE: "Приглашение было отправлено успешно",
    IOS_SAFARI_HELP:
      "Для достижения наилучших результатов мы рекомендуем использовать Safari.",
    UNEXPECTED_ERROR: "Произошли непредвиденные ошибки. Попробуйте позже.",
    CALL_LINK_NOT_FOUND: "Ссылка для вызова не найдена.",
    CREATE_BOOKMARK_MESSAGE: "Попросите администратора добавить закладку",
    ATTACHMENT_UPLOAD_SIZE_EXCEEDED: "Размер файла не должен превышать 10 МБ.",
    ATTACHMENT_UPLOAD_UNSUPPORTED_FORMAT: "Неподдерживаемый формат файла",
    LIVE_POINTER_STARTED:
      "Запущен живой указатель. Вы можете перемещать указатель, перетаскивая.",
    LIVE_POINTER_STOPPED: "Остановлен живой указатель",
    APPRAISAL_EXIT_APPROVED: "Выход подтвержден успешно.",
    APPRAISAL_EXIT_DENIED: "Выход запрещен.",
    MARKUP_CLOSED: "Разметка остановлена",
    PRIVACY_POLICY_FAIL:
      "Не найден действующий URL-адрес Политики конфиденциальности!",

    SWITCH_CAMERA_REQUEST_REJECTED:
      "Домовладелец не предоставил доступ к запрошенному виду камеры.",
  },
  ERROR_MESSAGES: {
    Please_Select_Cellphone_or_Email:
      "Пожалуйста, выберите мобильный телефон или электронную почту",
    This_field_is_required: "Это поле обязательно к заполнению",
    Cell_Phone_Must_Be_Valid: "Сотовый телефон должен быть действительным",
    Name_is_Required: "Имя обязательно",
    Name_Must_Be_Valid: "Имя должно быть действительным",
    Invalid_Text: "Неверный текст",
    Email_Must_Be_Valid: "Электронная почта должна быть действительной",
    Name_must_be: "Имя должно быть",
    characters_max: "Максимум символов",
    Please_select_the_Floor: "Пожалуйста, выберите этаж",
    Please_enter_the_Room_Area_Name:
      "Пожалуйста, введите название комнаты / зоны ",
    You_need_to_use_HTTPS_for_selecting_audio_output_device:
      "Вам необходимо использовать HTTPS для выбора устройства вывода звука",
    Something_went_wrong: "Что-то пошло не так. Пожалуйста, попробуйте позже.",
    Oops_Call_duration_is_over_and_session_is_ended:
      "Ой! Продолжительность разговора истекла, и сеанс завершен.",
    No_participant_has_joined_yet_Ending_the_call_session:
      "Ни один участник еще не присоединился! Завершение сеанса разговора",
  },
  starting_inspection: "Начало проверки",
  starting_measurement: "Начальное измерение",
  error: "Ошибка",
  contact_us: "Связаться с нами",
  support_popup_msg:
    "К сожалению, что-то пошло не так. Повторите попытку через некоторое время или сообщите о проблеме в нашу службу поддержки.",
  select_po: "Выберите владельца недвижимости, чтобы начать процесс проверки",
  request_po_for_camera:
    "Попросите владельца собственности включить камеру, чтобы начать процесс проверки..",
  wait_until_po_joins:
    "Подождите, пока владелец недвижимости присоединится к звонку, чтобы начать процесс проверки.",
  select_measurement_method: "Выберите метод измерения",
  inspection_inprogress: "Осмотр уже идет",
  model_msg_retry:
    "Не удалось установить канал связи с владельцем недвижимости. Повторите попытку.",
  model_msg_leaveCall:
    "Похоже, есть проблема с подключением к Интернету с обеих сторон, поэтому отключите текущий вызов и снова подключитесь.",
  please_wait: "Пожалуйста подождите...",
  loading_sketch: "Загрузка эскиза...",
  connection_timeout_msg:
    "Время соединения истекло. Произошло что-то необычное, и ответ занимает больше времени, чем ожидалось. Пожалуйста, попробуйте еще раз.",
  previous_measurement_prblm_msg:
    "Упс, что-то пошло не так с предыдущим измерением. Пожалуйста, попробуйте еще раз с новым процессом измерения.",
  measurement_retry_msg:
    "Процесс измерения не запущен. Пожалуйста, повторите попытку.",
  opensketch_retry_msg:
    "Процесс Open Sketch не запущен. Пожалуйста, повторите попытку.",
};
