import React from "react";
import i18n from "i18next";
const ConfirmRecordingModal = ({ handleLeaveCallBtn, handleGotItBtn }) => (
  <div className="error-popup open">
    <div className="error-info">
      <div className="content">
        <h3>{i18n.t("WEB_LABELS.This_call_is_being_recorded")}</h3>
        <p>{i18n.t("WEB_LABELS.Call_recording_by_virtual_inspector")}</p>
        <p>{i18n.t("WEB_LABELS.Call_and_Screen_Recording_Opt_Out")}</p>
      </div>
      <div className="bottom-btn">
        <button className="red-btn" onClick={handleLeaveCallBtn}>
          {i18n.t("BUTTONS.Leave_Call")}
        </button>
        <button className="green-btn" onClick={handleGotItBtn}>
          {i18n.t("BUTTONS.Continue")}
        </button>
      </div>
    </div>
  </div>
);

export default ConfirmRecordingModal;
