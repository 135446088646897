import React, { useEffect } from "react";
import { connect } from "react-redux";
import { JSEncrypt } from "jsencrypt";
import { fetchToken } from "../../actions/authenticationAction";
import { history } from "./../../helpers";

const publicKey =
  "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgaj70+XcoQbuqUUr/99mxbjX1qRodvCjwgyCChxQ9pN+86Q1cTz0LxrhFCsaLz2N66j2lg5UAjryPMHy1UMzNyFPZdkrTimFP/7q4yulhNt18+VaPwvHaWbfLYeEWHfwNN2sbyGQgkc48mOr9btSCt8gyWAjIGtlzX5oXFvP2tL1fdM63+/57ta9ZrmghZziZ5cFIX+AC21nvNVv9avPPZZchxyS4BVojVfWnEzijU0OtrnkaKe31v1qIMyerDGOIOwq4PxodqZqMeZzmWF/oY6iB1oBF6PsG0B+5yUx62qsa3HGMkDyfC/wk/hG56REpOPzbHgSRjbWjQcqohOtTQIDAQAB";

const ShortCallLink = (props) => {
  const id = props.match.params.id;
  id && localStorage.setItem("shortLink", id);
  let encryptedPass;
  const encryptLink = () => {
    let RSAEncrypt = new JSEncrypt();
    RSAEncrypt.setPublicKey(publicKey);
    encryptedPass = RSAEncrypt.encrypt(id);
  };

  useEffect(() => {
    if (id) {
      encryptLink();
      props.fetchToken(encryptedPass, history).then((data) => {
        // Replace the original URL with a different URL
        // Note: The commented code below replaces a specific URL with another URL
        // data.originalUrl = data.originalUrl.replace(
        //   "https://testverify.virtualsiteinspections.com",
        //   "http://localhost:3000"
        // );
        data && (window.location.href = data.originalUrl);
      });
    } else {
    }
  }, []);

  return <></>;
};

const mapDispatchToProps = (dispatch) => ({
  fetchToken: (data, history) => dispatch(fetchToken(data, history)),
});

const mapStateToProps = ({ me }) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ShortCallLink);
