import api from "./apis/api";

const TOKEN = "token";
class AuthService {
  getDetail() {
    return api.auth.getDetail();
  }
  fetchToken(payload) {
    return api.auth.fetchToken(payload);
  }
  setToken(value) {
    localStorage.setItem(TOKEN, value);
  }
}

export default new AuthService();
