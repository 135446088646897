import api from "./api";

export default class NoteAPI {
  // adding new note
  addNote(payload) {
    return api.post(`/notes/add`, payload);
  }

  getNotes(jobOrderId) {
    return api.get(`notes/get/${jobOrderId}`);
  }

  deleteNote(noteId) {
    return api.delete(`note/delete/${noteId}`);
  }

  updateNote(noteId, payload) {
    return api.put(`notes/update/${noteId}`, payload);
  }
}
