import _ from "lodash";

export function dehydrateCaptureMarkupImage(data) {
  return _.omitBy(
    {
      
      original: data.original,
      originalName: data.originalName,
      
      
    },
    _.isUndefined
  );
}
