import {
  START_LOAD_NOTE,
  LOAD_NOTE_SUCCESS,
  LOAD_NOTE_ERROR,
  START_CREATE_NOTE,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_ERROR,
  START_DELETE_NOTE,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_ERROR,
  START_UPDATE_NOTE,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_ERROR,
} from "./actionTypes";

import NoteService from "../services/noteService";

export const startLoadNote = () => {
  return {
    type: START_LOAD_NOTE,
  };
};

export const loadNoteSuccess = (payload) => {
  return {
    type: LOAD_NOTE_SUCCESS,
    payload,
  };
};

export const loadNoteError = (payload) => {
  return {
    type: LOAD_NOTE_ERROR,
    payload,
  };
};

export function loadNote(jobOrderId) {
  return (dispatch) => {
    dispatch(startLoadNote());
    return NoteService.getNotes(jobOrderId)
      .then(function (data) {
        return dispatch(loadNoteSuccess(data));
      })
      .catch((err) => {
        return dispatch(loadNoteError(err));
      });
  };
}

export const startCreatingNote = () => {
  return {
    type: START_CREATE_NOTE,
  };
};

export const createNoteSuccess = (payload) => {
  return {
    type: CREATE_NOTE_SUCCESS,
    payload,
  };
};

export const createNoteError = (payload) => {
  return {
    type: CREATE_NOTE_ERROR,
    payload,
  };
};

export function createNote(payload) {
  return (dispatch) => {
    dispatch(startCreatingNote());
    return NoteService.addNote(payload)
      .then(function (data) {
        dispatch(createNoteSuccess(data));
        return data;
      })
      .catch((err) => {
        return dispatch(createNoteError(err));
      });
  };
}

export const startDeletingNote = () => {
  return {
    type: START_DELETE_NOTE,
  };
};

export const deleteNoteSuccess = (payload) => {
  return {
    type: DELETE_NOTE_SUCCESS,
    payload,
  };
};

export const deleteNoteError = (payload) => {
  return {
    type: DELETE_NOTE_ERROR,
    payload,
  };
};

export function deleteNote(payload) {
  return (dispatch) => {
    dispatch(startDeletingNote());
    return NoteService.deleteNote(payload)
      .then(function (data) {
        dispatch(deleteNoteSuccess(data));
        return data;
      })
      .catch((err) => {
        return dispatch(deleteNoteError(err));
      });
  };
}

export const startUpdatingNote = () => {
  return {
    type: START_UPDATE_NOTE,
  };
};

export const updateNoteSuccess = (payload) => {
  return {
    type: UPDATE_NOTE_SUCCESS,
    payload,
  };
};

export const updateNoteError = (payload) => {
  return {
    type: UPDATE_NOTE_ERROR,
    payload,
  };
};

export function updateNote(noteId, payload) {
  return (dispatch) => {
    dispatch(startUpdatingNote());
    return NoteService.updateNote(noteId, payload)
      .then(function (data) {
        dispatch(updateNoteSuccess(data));
        return data;
      })
      .catch((err) => {
        return dispatch(updateNoteError(err));
      });
  };
}
