import { LocalStorage } from "./LocalStorage";

export class StorageUtil {
  //User Name
  static getUserName() {
    return LocalStorage.getItem(LocalStorage.USER_NAME);
  }

  static setuserName(params) {
    LocalStorage.setItem(LocalStorage.USER_NAME, params);
  }

  //ROLE
  static getRole() {
    return LocalStorage.getItem(LocalStorage.ROLE);
  }

  static setRole(params) {
    LocalStorage.setItem(LocalStorage.ROLE, params);
  }

  //Call UUID

  static getCallUUID() {
    return LocalStorage.getItem(LocalStorage.CALL_UUID);
  }

  static setCallUUID(params) {
    LocalStorage.setItem(LocalStorage.CALL_UUID, params);
  }

  static hasInviteUUID() {
    return !!LocalStorage.getItem(LocalStorage.INVITE_UUID);
  }
  static getInviteUUID() {
    return LocalStorage.getItem(LocalStorage.INVITE_UUID);
  }
  static setInviteUUID(params) {
    LocalStorage.setItem(LocalStorage.INVITE_UUID, params);
  }

  static clearCallUUID() {
    LocalStorage.clear(LocalStorage.CALL_UUID);
  }

  //Video data

  static getCallData() {
    return LocalStorage.getItem(LocalStorage.VIDEO_CALL_DATA);
  }

  static setCallData(params) {
    LocalStorage.setItem(LocalStorage.VIDEO_CALL_DATA, params);
  }

  static clearCallData() {
    LocalStorage.clear(LocalStorage.VIDEO_CALL_DATA);
  }

  static clearAll() {
    LocalStorage.clearAll();
  }
}
