import React, { useState, useEffect } from "react";
import { appConstants } from "./../../../constants/app.constants";
import { useTranslation } from "react-i18next";
const env = process.env.REACT_APP_APP_ENV;
const ErrorModal = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="error-popup open ">
        <div className="error-info">
          <div className="content">
            <p>
              <b>{t("error")}</b>
            </p>
            <p>{t("support_popup_msg")}</p>
          </div>
          <div className="bottom-btn">
            <a
              href={
                env == "dev"
                  ? `${appConstants.AppURLs.dev}support`
                  : `${appConstants.AppURLs.prod}support`
              }
              target="_blank"
              className="blue-btn"
            >
              {t("contact_us")}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorModal;
