import React, { Component, createRef } from "react";
import { fabric } from "fabric";
import "./LivePointer.scss";

class LivePointer extends Component {

  calculatedCanvasHeight = 0;
  calculatedCanvasWidth = 0;


  initialCanvasHeight = 0;
  initialCanvasWidth = 0;


  constructor() {
    super();
    this.livePointerCanvas = createRef();
    this.shapes = {};

    this.state = {
      width: 0,
      height: 0,
      livePointerDraggedData: null,
      localPointers: {},
      remotePointers: {},
    };

    this.drawAllPointers = this.drawAllPointers.bind(this);
    this.prepareCanvas = this.prepareCanvas.bind(this);
  }

  componentDidMount() {

    this.canvas = new fabric.Canvas("pointerCanvas");
    this.context = this.canvas.getContext("2d");
    this.prepareCanvas();
    this.canvas.on("mouse:move", (options) => {

      const pointer = this.canvas.getPointer(options);
      // console.log("pointer  - ", pointer);

      //const { type, changedTouches, x, y } = options.e;
      const { type, changedTouches } = options.e;
      let { x, y } = pointer;
      this.onmousemove(x, y);
     

    });


  }

  componentWillUnmount() {
    this.canvas.dispose();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { receivedLivePointerData } = this.props;

    


    if (this.props.zoomLevel != prevProps.zoomLevel) {

      this.calculatedCanvasHeight = this.initialCanvasHeight * this.props.zoomLevel;
      this.calculatedCanvasWidth = this.initialCanvasWidth * this.props.zoomLevel;
      this.canvas.setHeight(this.calculatedCanvasHeight);
      this.canvas.setWidth(this.calculatedCanvasWidth);
      
      this.canvas.clear();
      this.canvas.renderAll();
      this.shapes = {};


    }

    if (receivedLivePointerData !== prevProps.receivedLivePointerData) {
      if (
        receivedLivePointerData &&
        receivedLivePointerData.xPoint &&
        receivedLivePointerData.yPoint
      ) {
        if (this.context === null || this.context === undefined) {
          await this.prepareCanvas();
        }

        const {
          xPoint,
          yPoint,
          width,
          height,
          color,
          participantSid,
        } = receivedLivePointerData;

        let { remotePointers } = this.state;

        remotePointers[participantSid] = {
          xPoint: parseFloat(xPoint),
          yPoint: parseFloat(yPoint),
          width: parseFloat(width),
          height: parseFloat(height),
          color,
          participantSid,
        };

        this.setState({
          remotePointers,
        });



        this.drawAllPointers();
      }
    }





  }

  drawLocalPointer = () => {
    const { localPointers } = this.state;
    this.setState({localPointers,});
    localPointers && localPointers !== null && this.drawAPointer(localPointers);
  };

  drawRemotePointers = () => {
    const { remotePointers } = this.state;
    Object.keys(remotePointers).map((key) =>
      this.drawAPointer(remotePointers[key])
    );
  };
 
  drawAllPointers = () => {
    this.drawLocalPointer();
    this.drawRemotePointers();
  };

  onmousemove = async (x, y) => {
    const { livePointerColors, sid, participantIdentity } = this.props;
    
    // const el = document.getElementById("pointerCanvas");
    // const { clientWidth: w, clientHeight: h } = el;
    //const rect = el.getBoundingClientRect();

    const localPointers = {
      xPoint: x + "",
      yPoint: y + "",
      width: this.calculatedCanvasWidth + "",
      height: this.calculatedCanvasHeight + "",
      color: livePointerColors[sid],
      participantSid: sid,
      participantIdentity,
    };

    this.setState({
      localPointers,
    });
    this.props.emitLivePointerData(localPointers);
    this.drawAllPointers();
  };

  drawAPointer = (pointer) => {

    const { xPoint, yPoint, width, height, color, participantSid } = pointer;

    //let { clientWidth, clientHeight } = document.getElementById("pointerCanvas");

    let clientWidth = this.calculatedCanvasWidth;
    let clientHeight = this.calculatedCanvasHeight;

    let stpointx = parseFloat((xPoint * clientWidth) / width);
    let stpointy = parseFloat((yPoint * clientHeight) / height);

    const drawNewShape = () => {
      const circle = new fabric.Circle({
        absolutePositioned: true,
        left: stpointx,
        top: stpointy,
        startAngle: 0,
        // endAngle: 2 * Math.PI,
        height:10,
        width:10,
        radius: 10,
        stroke: color,
        strokeWidth: 4,
        fill: "",
        selectable: false,
       
      });
      this.shapes[participantSid] = circle;
      this.canvas.add(circle);
    };

    if (this.shapes && this.shapes !== null && this.shapes !== undefined) {
      const shape = this.shapes[participantSid];
      
      if (shape && shape !== null && shape !== undefined) {
        shape.set({
          left: stpointx,
          top: stpointy,
          height:10,
          width:10,
          radius: 10,
          
          
        });
        this.canvas.renderAll();
      } else {
        drawNewShape();
      }
    } else {
      drawNewShape();
    }
  };

  prepareCanvas = () => {
    const elems = document.getElementsByClassName("full-screen");
    let videoEl;
    if (elems && elems.length > 0) {
      const videoElems = elems[0].getElementsByTagName("video");
      if (videoElems && videoElems.length > 0) {
        videoEl = videoElems[0];
      }
    }

    if (!videoEl || videoEl === null) return;

    const { clientWidth, clientHeight } = videoEl;

    this.canvas.setHeight(clientHeight);
    this.canvas.setWidth(clientWidth);


    this.canvas.getElement().style.top = "50%";
    this.canvas.getElement().style.left = "50%";


    const upperCanvas = document.getElementsByClassName("upper-canvas");
    if (upperCanvas && upperCanvas.length > 0) {
      const uperCanvas = upperCanvas[0];
      uperCanvas.style.top = "50%";
      uperCanvas.style.left = "50%";
      uperCanvas.style.transform = "translate(-50%, -50%)";
      uperCanvas.style.zIndex = "19";
      const ct = uperCanvas.getContext("2d");
      ct.globalAlpha = 0.0;
    }

    this.livePointerCanvas.current.height = clientHeight;
    this.livePointerCanvas.current.width = clientWidth;


    this.initialCanvasWidth = clientWidth;
    this.initialCanvasHeight = clientHeight;


    this.calculatedCanvasWidth = clientWidth * this.props.zoomLevel;
    this.calculatedCanvasHeight = clientHeight * this.props.zoomLevel;

    this.canvas.setHeight(this.calculatedCanvasHeight);
    this.canvas.setWidth(this.calculatedCanvasWidth);





    document.getElementsByClassName("canvas-container")[0].style.position =
      "unset";
  };

  render() {
    return <canvas id="pointerCanvas" ref={this.livePointerCanvas}></canvas>;
  }
}

export default LivePointer;
