import React, { Component, createRef, useEffect } from "react";
import { connect } from "react-redux";
import Video, {
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalDataTrack,
  LocalVideoTrack,
} from "twilio-video";

import { loadBookmark } from "../../actions/bookmarkAction";
import { loadNote } from "../../actions/notesAction";
import {
  osName,
  osVersion,
  browserName,
  browserVersion,
  isMobile,
  isSafari,
  isAndroid,
  isIOS,
} from "react-device-detect";
import Tooltip from "@mui/material/Tooltip";
import {
  getVideoCallToken,
  inviteParticipant,
  listParticipant,
  updateParticipantTrackStatus,
  callParticipantAdd,
  callParticipantUpdate,
  callParticipantRemove,
  callParticipantClearAll,
  callEnd,
  getRoomMeasurementJSONForJobOrder,
  deleteFloorPlan,
} from "../../actions/videoCallAction";

import {
  uploadImage,
  addCapturedImages,
  deleteCapturedImage,
  deleteImage,
} from "../../actions/captureImageAction";

import { uploadMarkupImage } from "../../actions/captureMarkupImageAction";
import { sendMessage } from "../../actions/messagesAction";
import { fetchToken } from "../../actions/authenticationAction";
import { updateLocalUserRole } from "./../../actions/me";
import { LoadCallConfig } from "./../../actions/callConfigAction";
import {
  extractUserName,
  extractUserRole,
  extractUserUUID,
  dataURItoBlob,
  isAppraiserOrAssistant,
  isOwner,
  isAppraiser,
} from "./../../helpers/commonJSFunction";

import { getDateDifference } from "./../../helpers/dateFunction";

import {
  pinned_view,
  view_captured_images,
} from "./../../constants/Permission";
import RBAC from "./../RBAC";

import { markupforCaptureImg } from "./../../helpers/markups";

import { history, StorageUtil } from "../../helpers/";
import { getCurrentLocation } from "./../../helpers/geoLocation";

import { appConstants } from "../../constants/";

//Views

import ConnectingUser from "./ConnectingUser";
import ConnectingMember from "./ConnectingMember";
import RightMenu from "./RightMenu";
import BottomMenu from "./BottomMenu";
import ParticipantVideoView from "./ParticipantVideoView";
import ZoomOptions from "./ZoomOptions";
import MarkupView from "./MarkupView";
import PrpearingForMarkup from "./PrpearingForMarkup";
import DeleteModel from "./Models/DeleteModal";
import ConfirmDeleteFloorPlan from "./Models/ConfirmDeleteFloorPlan";
import ConfirmExitMeasurementProcess from "./Models/ConfirmExitMeasurementProcess";

import CallRemainingTimer from "./CallRemainingTimer";
import ShowDownloadAppPage from "./ShowDownloadAppPage";

// Models

import AddParticipantModel from "./Models/AddParticipantModel";
import SavedImagesModel from "./Models/SavedImagesModel";
import { ParticipantViewModel } from "./Models/ParticipantViewModel";
import BeforeJoiningModel from "./Models/BeforeJoiningModel";
import LeaveModel from "./Models/LeaveModel";

import ErrorModelMaxParticipant from "./Models/ErrorModelMaxParticipant";
import ErrorModelTimerExpired from "./Models/ErrorModelTimerExpired";
import ErrorPage from "./ErrorPage";
import PermissionPopup from "./Models/PermissionPopup";
import UploadingImageModel from "./Models/UploadingImageModel";

import "./videoCall.scss";
import _ from "lodash";
import { GalleryZoomModel } from "./Models/GalleryZoomModel";
import { BookMarkViewModel } from "./Models/BookMarkViewModel";
import MapViewModel from "./Models/MapViewModel";
import LivePointer from "./LivePointer";
import { ChatViewModel } from "./Models/ChatViewModel";
import ShareScreenConfirmationModel from "./Models/ShareScreenConfirmationModel";
import { NotesViewModel } from "./Models/NotesViewModel";
import { AttachmentDocumentModal } from "./Models/AttachmentDocumentModal";
import {
  attachmentReceivedSuccess,
  loadAttachments,
} from "../../actions/attachmentAction";
import { customToast } from "../../helpers/customToast";
import StartAppraisal from "./Models/StartAppraisal";
import MeasurementModal from "./Models/MeasurementModal";
import {
  setAppraisalStepOne,
  setAppraisalStepTwo,
  setAppraisalStepThree,
  setAppraisalStepFour,
} from "../../actions/measurementsAction";
import {
  APPRAISAL_MEASUREMENT,
  APPRAISAL_CORNER_MARKING,
  APPRAISAL_2D_FLOOR_PLAN,
} from "../../actions/actionTypes";
import classnames from "classnames";
import CallSettingsModel from "./Models/newCallSettingsModel";
import {
  updatePermission,
  selectDevice,
} from "./../../actions/callSettingsAction";
//var moment = require('moment'); // require
import moment from "moment";
import QuestionnaireModel from "./Models/QuestionnaireModel";
import {
  getQuestionnaire,
  saveQuestionnaire,
} from "./../../actions/questionnaireAction";
import ConfirmRecordingModal from "./Models/ConfirmRecordingModal";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import NetworkQualityModel from "./Models/networkQualityModel";
import AsyncImage from "../common/AsyncImage";
import POsForInspectionModel from "./Models/POsForInspectionModel";
import POInstructionModel from "./Models/POInstructionModel";
import CallSettingsInstructionsModel from "./Models/CallSettingsInstructionsModel";
import LoaderModel from "../common/LoaderModel";
import DistanceModel from "./Models/distanceModel";
import RequestEndCallModel from "./Models/RequestEndCallModel";
import CommonModel from "./Models/CommonModel";

const ZOOM_LEVEL = appConstants.ZOOM_LEVEL;

export const IOS_APP_STORE_LINK =
  "https://apps.apple.com/in/app/remoteval/id1580910748";
export const ANDROID_PLAY_STORE_LINK =
  "https://play.google.com/store/apps/details?id=com.incenteram.remoteval";
class VideoCall extends Component {
  trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  // Later this values will be dynamic.

  userData = {
    firstName: "Cuser",
    lastName: "User",
    profileImageUrl: "/images/no-video-image.svg",
  };

  remoteUserData = {
    firstName: "Participant",
    lastName: "1",
    lastName: "1",
    profileImageUrl: "/images/no-video-image.svg",
  };

  twillioRoom = null;
  participantData = {
    participant_1: {
      firstName: "Participant",
      lastName: "1",
      lastName: "1",
      profileImageUrl: "https://picsum.photos/200/300",
    },
    participant_1: {
      firstName: "Participant",
      lastName: "2",
      profileImageUrl: "https://picsum.photos/200/300",
    },
    participant_2: {},
    participant_3: {},
  };
  videoCallData = {};
  elem = document.documentElement;
  stream = null;
  checkRoomStatusRequesting = false;
  isCallScheduledTimePassed = false;

  identity = null;
  token = null;
  isDeviceListUpdating = false;

  videoRef = createRef();
  audioRef = createRef();
  imgcaptureCanvas = createRef();
  streetViewMapLayout = createRef();
  localVideoTrack = null;
  localAudioTrack = null;
  screenTrack = null;

  deviceTypeCalculated = appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_PC;

  audioDevices = [];
  videoDevices = [];

  frontCameraDeviceId = "";
  backCameraDeviceId = "";
  isCameraFlipped = false;

  speakerAudioDeviceId = "";
  defaultAudioDeviceId = "";
  headSetAudioDeviceId = "";

  isHeadSetAvailable = false;
  callUUID = "";
  callRecording = false;

  connection = null;
  connectionType = "-";

  isFlashEnable = false;
  isFlashDisabledByRemoteParticipant = false;

  isMapImageUploaded = false;

  streetViewPanoramaOptions = {};
  remoteParticipantLatLong = {};
  maxCallDuration = 0;
  callStartTimeStr = null;
  callStartTime = null;
  callEndTime = null;
  timer = null;
  waitingTimeForAnotherParticipant = 30; //This is in min.
  singleParticipantEndCallTime = null;
  singleParticipantWaitingTimer = null;
  remainingTimeAlertInSec = 600;
  measurementType = "1";
  sendFloorPlanData = {};
  isMeasurementFlow = false;
  selectedCamera = "";
  timeoutForAcknowledgement = null;
  waitForAcknowledgement = false;
  retryDTFailed = false;
  timeoutForRetryDT = null;
  selectedPOForMeasurementFromMultiplePO = false;
  addRoomLoading = false;
  timeoutForAddRoom = null;

  constructor(props) {
    super();
    // reset login status

    this.state = {
      showCallSettingsInstructionsModel: true,
      callStatus: appConstants.CALL_STATUS.CONNECTING,
      token: null,
      room: null,
      localRoom: null,
      isLocalVideoTrackEnabled: false,
      isLocalScreenShareTrackEnabled: false,
      isLocalAudioTrackEnabled: false,
      isCameraPermissionAllowed: true,
      isMicrophonePermissionAllowed: true,
      showMicorphonePermissionPopup: false,
      showCameraPermissionPopup: false,

      localVideoTracks: [],
      localAudioTracks: [],

      remoteParticipants: [],

      isFrontAndBackCameraAvailable: false,
      isBackCameraAvailable: false,
      isCameraAvailable: false,

      isSpeakerAvailable: false,
      isOnSpeakerMode: false,

      isFullScreenMode: false,

      networkStatus: "online",
      networkQualityLevel: 0,

      isLocalParticipantConnected: false,

      isModalOpen: false,
      isAddParticipantVisible: false,
      isListParticipantVisible: false,

      isSavedImagesVisible: false,
      isSavedImagesFullViewVisible: false,
      isAutoShowSavedImagesFullViewVisible: true,
      isScaleZoomVisible: false,
      zoomLevel: appConstants.ZOOM_LEVEL.ZOOM_LEVEL_1,

      isMoreMenuVisible: false,

      userName: "",
      isUserNameEntered: true,
      role: null,

      isMaxParticipantReached: false,
      participantTrackStatus: [],

      ispinnedView: false,
      pinnedParticipant: null,
      isImageCapturedAfterPOPinned: false,
      isPropertyOwnerPinned: false,
      pinnedPODeviceType: "",
      showCanvas: false,
      disbleCaptureImage: false,
      captureImageUploadingCount: 0,
      captureImageTotalCount: 0,

      isFlashActive: false,

      isGalleryZoomModelVisible: false,
      currentImageIndex: 0,

      isGalleryZoomModelVisible: false,
      galleryZoomImages: [],
      currentImageIndex: 0,

      isBookmarkViewModelVisible: false,

      isStreetViewOptionVisible: false,
      isStreetViewMode: false,
      pov: null,
      streetViewPositionLat: 0,
      streetViewPositionLong: 0,
      streetViewPositionZoom: 0,

      streetViewRectWidth: 640,
      streetViewRectHeight: 640,

      isShowLeaveModel: false,

      isPointerStarted: false,
      isPointerStartedByMe: false,
      livePointerColors: {},

      streetViewRectWidth: 640,
      streetViewRectHeight: 640,

      isPreparingForMarkupVisible: false,
      isMarkupViewVisible: false,
      incomingMarkupAnnotationObject: {},
      userMarkupObjectArray: [],
      userUndoObjectArray: [],
      globalUndoObjectArray: [],
      globalMarkupObjectArray: [],
      markupViewStartedBy: "",

      shareScreenAction: "", //START or CLOSE
      showShareScreenStartClosePrompt: false,
      isScreenShareOn: false,

      isChatModelVisible: false,

      receivedChatMessage: null,
      unreadMessageCount: 0,

      isNoteModelVisible: false,

      isDeleteImageModelVisible: false,
      deleteImageIndex: -1,
      deleteImageObj: {},

      isAttachmentDocumentModalVisible: false,
      isRemoteParticipantScreenShareOn: false,
      screenShareParticipantSid: null,

      areMeasurementButtonsEnabled: false,
      isStartAppraisalModalVisible: false,
      isStartMeasurementModalCollapsed: false,
      isMeasurementModalCollapsed: false,
      autoCornerStatus: false,
      isCallSettingsVisible: false,
      permissions: {},
      isTimerStarted: false,
      isTimerEnded: false,
      callRemainingTimeHours: 0,
      callRemainingTimeMins: 0,
      callRemainingTimeSecs: 0,
      floorList: [{ floorIndex: "", floorName: "" }],
      selectedFloor: 0,
      isPOJoined: false,
      isPOStartedVideoStream: false,
      isDeleteFloorPlanModelVisible: false,
      buttonType: "",
      buttonState: "",
      isStartAppraisalCaptureImageTabVisible: true,
      isLIDARViewVisible: false,
      lidarSelectedOption: "0",
      isShowDownloadAppPage: false,
      isUserSelectedGoToApp: false,
      showBeforeJoinModal: false,
      showErrorPage: false,
      errorPageMessage: "",
      showQuestionnaire: false,
      showConfirmRecordingModal: true,
      isMeasurementOngoing: false,
      isFencingEnabled: "0",
      isFloorPlanExpanded: "0",
      showhideLiveFloorPlanActions: false,
      isSwitchCameraRequested: false,
      switchCameraParticipantSid: [],
      liveFloorPlanData: [],
      isParticipantImageCaptured: false,
      showNetworkQualityModel: false,
      measurementLoading: false,
      inspectionLoading: false,
      requestPOForCamera: false,
      choosePOToStartInspection: false,
      remotePOsForInspection: [],
      inspectionProgressLoader: false,
      distanceBetweenLatLong: 0,
      distanceUnit: "feet",
      distanceModel: true,
      isRequestEndCallModel: false,
      isCommonModel: false,
      commonModelMsg: "",
      commonModelBtn: "",
      sidForRetry: "",
      loadingSketch: false,
      waitingForDT: false,
      msgForPOInstructionModel: "",
      autoSnap: false,
      isFlashSupported: false,
    };

    this.participantConnected = this.participantConnected.bind(this);
    this.participantDisconnected = this.participantDisconnected.bind(this);

    this.gotDevices = this.gotDevices.bind(this);
    this.checkDevices = this.checkDevices.bind(this);

    this.deviceListChanged = this.deviceListChanged.bind(this);
    this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
    this.connectCall = this.connectCall.bind(this);

    //Enable/Disabel camera
    this.toggleCameraClicked = this.toggleCameraClicked.bind(this);
    this.toggleCameraAction = this.toggleCameraAction.bind(this);

    this.createMyLocalVideoTrack = this.createMyLocalVideoTrack.bind(this);

    //Enable/Disabel Microphone
    this.toggleMicroPhoneClicked = this.toggleMicroPhoneClicked.bind(this);

    this.flipCamera = this.flipCamera.bind(this);

    //Cleaning up all the audio/video streams.
    this.cleanUpStreamsAndDisconnect =
      this.cleanUpStreamsAndDisconnect.bind(this);

    this.handleJoinClicked = this.handleJoinClicked.bind(this);

    // To show and hide particiapnts
    this.showAddParticipant = this.showAddParticipant.bind(this);
    this.hideAddParticipant = this.hideAddParticipant.bind(this);

    // To show and hide list particiapnts
    this.showListParticipant = this.showListParticipant.bind(this);
    this.hideListParticipant = this.hideListParticipant.bind(this);

    this.toggleSavedImageModel = this.toggleSavedImageModel.bind(this);
    this.hideSavedImages = this.hideSavedImages.bind(this);
    this.showSavedImages = this.showSavedImages.bind(this);
    this.updateZoomLevel = this.updateZoomLevel.bind(this);

    this.showMoreOptions = this.showMoreOptions.bind(this);

    this.retryConnect = this.retryConnect.bind(this);

    this.setRemoteParticipantTracks =
      this.setRemoteParticipantTracks.bind(this);
    this.localParticipantTrackPublished =
      this.localParticipantTrackPublished.bind(this);

    this.activeDeactiveFlash = this.activeDeactiveFlash.bind(this);

    this.captureMapViewImage = this.captureMapViewImage.bind(this);

    this.checkImageUpload = this.checkImageUpload.bind(this);

    this.toggleStreetViewOption = this.toggleStreetViewOption.bind(this);
    this.toggleStreetViewMap = this.toggleStreetViewMap.bind(this);
    this.onPovChanged = this.onPovChanged.bind(this);
    this.onZoomChanged = this.onZoomChanged.bind(this);
    this.onPositionChanged = this.onPositionChanged.bind(this);

    this.captureStreetViewImage = this.captureStreetViewImage.bind(this);

    this.showMarkupViewModel = this.showMarkupViewModel.bind(this);
    this.hideMarkupViewModel = this.hideMarkupViewModel.bind(this);
    this.captureMarkupImage = this.captureMarkupImage.bind(this);
    this.sendUploadingMarkupImageDataTrack =
      this.sendUploadingMarkupImageDataTrack.bind(this);
    this.sendUploadedMarkupImageDataTrack =
      this.sendUploadedMarkupImageDataTrack.bind(this);
    this.sendDownloadingMarkupImageDataTrack =
      this.sendDownloadingMarkupImageDataTrack.bind(this);
    this.sendStartedMarkupImageDataTrack =
      this.sendStartedMarkupImageDataTrack.bind(this);

    this.markupPathCreated = this.markupPathCreated.bind(this);
    this.markupShapeAdded = this.markupShapeAdded.bind(this);
    this.markupShapeMoved = this.markupShapeMoved.bind(this);
    this.markupUndo = this.markupUndo.bind(this);
    this.markupRedo = this.markupRedo.bind(this);
    this.markupClear = this.markupClear.bind(this);
    this.markupClose = this.markupClose.bind(this);
    this.saveMarkupImage = this.saveMarkupImage.bind(this);

    this.shareScreenHandler = this.shareScreenHandler.bind(this);
    this.hideShareScreenConfirmModel =
      this.hideShareScreenConfirmModel.bind(this);
    this.shareScreen = this.shareScreen.bind(this);
    this.onDeviceTap = this.onDeviceTap.bind(this);
    this.onStartMeasurement = this.onStartMeasurement.bind(this);
    this.onStopMeasurement = this.onStopMeasurement.bind(this);
    this.onShowStartAppraisal = this.onShowStartAppraisal.bind(this);
    this.onHideStartAppraisal = this.onHideStartAppraisal.bind(this);
    this.onSendFloorData = this.onSendFloorData.bind(this);
    this.onMeasurementsModalEvent = this.onMeasurementsModalEvent.bind(this);
    this.toggleCollapseStartMeasurementModal =
      this.toggleCollapseStartMeasurementModal.bind(this);
    this.toggleCollapseMeasurementModal =
      this.toggleCollapseMeasurementModal.bind(this);
    this.onAppraiserAddRoom = this.onAppraiserAddRoom.bind(this);
    this.onExitApprovedReceived = this.onExitApprovedReceived.bind(this);
    this.getRemotePOVideoUser = this.getRemotePOVideoUser.bind(this);
    this.captureImageBtnHandler = this.captureImageBtnHandler.bind(this);
    this.checkCallSettingsPermission =
      this.checkCallSettingsPermission.bind(this);

    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.updateCallDurationDates = this.updateCallDurationDates.bind(this);

    this.countDownSingleParticipant =
      this.countDownSingleParticipant.bind(this);
    this.disconnectCall = this.disconnectCall.bind(this);
    this.handleLocationNotshared = this.handleLocationNotshared.bind(this);
    this.deleteFloorPlanAndContinue =
      this.deleteFloorPlanAndContinue.bind(this);
    this.onPOScreenShareStarted = this.onPOScreenShareStarted.bind(this);

    this.viweFloorPlanByFloorName = this.viweFloorPlanByFloorName.bind(this);
    this.onResumeViewFloorPlanFloorSelection =
      this.onResumeViewFloorPlanFloorSelection.bind(this);
    this.viweOrResumeOpenSketch = this.viweOrResumeOpenSketch.bind(this);
    this.toggleCaptureImageMeasurement =
      this.toggleCaptureImageMeasurement.bind(this);
    this.exitMeasurementProcess = this.exitMeasurementProcess.bind(this);
    this.selectLIDAROption = this.selectLIDAROption.bind(this);

    this.minimiseBookmarkViewModel = this.minimiseBookmarkViewModel.bind(this);
    this.toggleBookmarkViewModel = this.toggleBookmarkViewModel.bind(this);
    this.createMyLocalVideoTrackWithDevice =
      this.createMyLocalVideoTrackWithDevice.bind(this);
    this.handleGoToAppClicked = this.handleGoToAppClicked.bind(this);
    this.handleDownloadAppClicked = this.handleDownloadAppClicked.bind(this);
    this.toggleQuestionnaire = this.toggleQuestionnaire.bind(this);
    this.handleFloorPlanExpandOption =
      this.handleFloorPlanExpandOption.bind(this);
    this.handleFencingVisibility = this.handleFencingVisibility.bind(this);
    this.toggleNetworkQualityModel = this.toggleNetworkQualityModel.bind(this);
    this.checkConfigurationForMeasurement =
      this.checkConfigurationForMeasurement.bind(this);
    this.togglePOsModal = this.togglePOsModal.bind(this);
    this.toggleRequestPOForCameraModel =
      this.toggleRequestPOForCameraModel.bind(this);
    this.toggleInspectionProgressLoader =
      this.toggleInspectionProgressLoader.bind(this);
    this.hideDistanceModel = this.hideDistanceModel.bind(this);
    this.toggleRequestEndCallModel = this.toggleRequestEndCallModel.bind(this);
    this.toggleCommonModel = this.toggleCommonModel.bind(this);
    this.retryForAPIError = this.retryForAPIError.bind(this);
    this.checkMeasurementStarted = this.checkMeasurementStarted.bind(this);
    this.startWaitForacknowledgement =
      this.startWaitForacknowledgement.bind(this);
    this.stopWaitForacknowledgement =
      this.stopWaitForacknowledgement.bind(this);
    this.setTimeoutForAcknowledgement =
      this.setTimeoutForAcknowledgement.bind(this);
    this.setTimeoutForRetryDT = this.setTimeoutForRetryDT.bind(this);
    this.selectPoFromMultiplePOs = this.selectPoFromMultiplePOs.bind(this);
    this.sendAcknowledgementForPinUnpin =
      this.sendAcknowledgementForPinUnpin.bind(this);
    this.toggleAutoSnap = this.toggleAutoSnap.bind(this);
    this.setTimeoutForAddRoom = this.setTimeoutForAddRoom.bind(this);
  }

  async componentDidMount() {
    const inviteUUID = this.props.match.params.inviteUUID;
    if (inviteUUID && inviteUUID.length == 8) {
      localStorage.clear();
      history.push(`/link/${inviteUUID}`);
      return;
    } else {
      this.setState(
        {
          showBeforeJoinModal: true,
        },
        () => {}
      );

      if (isIOS && isMobile && !isSafari) {
        alert(i18n.t("COMMON_STRING.IOS_SAFARI_HELP"));
      }

      const search = this.props.location.search;
      this.name = new URLSearchParams(search).get("name");
      this.enc = new URLSearchParams(search).get("enc");
      if (inviteUUID == null || inviteUUID == undefined) {
        history.replace({
          pathname: "/",
          state: {},
        });
      }

      this.inviteUUID = inviteUUID;
      // if (this.enc) {
      //   const payload = {
      //     actual:
      //       window.location.toString().indexOf("?name") != -1
      //         ? window.location
      //             .toString()
      //             .split("&")[0]
      //             .replace("http://localhost:3000", "https://video.v-site.xyz")
      //         : window.location
      //             .toString()
      //             .split("?")[0]
      //             .replace("http://localhost:3000", "https://video.v-site.xyz"),
      //     encoded: this.enc,
      //   };
      //   this.props.fetchToken(payload, history).then((data) => {
      //     this.props.LoadCallConfig(inviteUUID);
      //   });
      // } else {
      inviteUUID && this.props.LoadCallConfig(inviteUUID);
      // }

      let userName = StorageUtil.getUserName();

      if (userName.trim().length > 0) {
        this.setState(
          {
            isUserNameEntered: true,
            userName: userName,
          },
          () => {
            this.getCallUUIDAndConnect();
          }
        );
      } else {
        this.setState({ isUserNameEntered: false });
      }

      this.connection =
        navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection;
      this.connectionType = " - ";
      if (this.connection) {
        this.connectionType = this.connection.effectiveType;

        this.connection.addEventListener("change", this.updateConnectionStatus);
      }

      this.deviceTypeCalculated = isIOS
        ? appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_IOS
        : isAndroid
        ? appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_ANDROID
        : appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_PC;

      window.addEventListener("resize", this.updateDimensions);
      this.updateDimensions();
      this.checkCallSettingsPermission();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async checkCallSettingsPermission() {
    if (navigator.permissions != undefined) {
      let permissions = {
        camera: appConstants.mediaPermissions.prompt,
        microphone: appConstants.mediaPermissions.prompt,
      };
      await navigator.permissions
        .query({ name: "camera" })
        .then(function (result) {
          permissions.camera = result.state;
        });
      await navigator.permissions
        .query({ name: "microphone" })
        .then(function (result) {
          permissions.microphone = result.state;
        });
      this.props.updatePermission(permissions);
    } else {
    }
  }

  updateConnectionStatus = () => {
    this.connectionType = this.connection.effectiveType;

    if (this.isLocalParticipantConnected) {
      this.props.callParticipantUpdate({
        participantSid: this.state.localRoom.localParticipant.sid,
        networkType: this.connectionType,
      });

      const userDataTrack = this.buildUserDataTrack();
      this.state.dataTrack.send(JSON.stringify(userDataTrack));

      // this.state.dataTrack.send(JSON.stringify(datatrackobj));
    }
  };

  updateDimensions = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  async getCallUUIDAndConnect() {
    let inviteUUID = StorageUtil.getInviteUUID();

    if (inviteUUID !== this.inviteUUID) {
      StorageUtil.clearAll();
      let requestData = {
        identity: this.state.userName,
        inviteUUID: this.inviteUUID,
      };
      this.videoCallData = await this.props.getVideoCallToken(requestData);

      StorageUtil.setInviteUUID(this.inviteUUID);
      StorageUtil.setuserName(this.state.userName);
      StorageUtil.setCallUUID(this.videoCallData.callUUID);
      StorageUtil.setRole(this.videoCallData.role);
      StorageUtil.setCallData(JSON.stringify(this.videoCallData));
    } else {
      let requestData = {
        identity: this.state.userName,
        inviteUUID: this.inviteUUID,
      };
      this.videoCallData = await this.props.getVideoCallToken(requestData);
      StorageUtil.setCallData(JSON.stringify(this.videoCallData));
    }

    if (this.videoCallData.callStartTime) {
      this.callStartTimeStr = this.videoCallData.callStartTime;
      this.updateCallDurationDates();
    }

    // console.log("videoCallData - ", this.videoCallData);

    this.callUUID = this.videoCallData.callUUID;
    this.jobOrderId = this.videoCallData.jobOrderId;
    this.setState({ role: this.videoCallData.role });
    isOwner(this.videoCallData.role) && (this.isCameraFlipped = true);
    this.props.updateLocalUserRole(this.videoCallData.role);
    this.token = this.videoCallData.accessToken;
    this.identity = this.videoCallData.indentity;
    this.callRecording = this.videoCallData.callRecording;

    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    this.connectCall();
  }
  catch(err) {
    //TBD
  }

  fetchBookmark = () => {
    this.props.fetchBookmark(this.callUUID);
  };

  fetchNote = () => {
    this.props.fetchNote(_.get(this.props, "videoCall.data.jobOrderId", ""));
  };

  /**
   * Calculates the distance between two sets of latitude and longitude coordinates.
   * @param {number} lat1 - Latitude of the first point.
   * @param {number} lon1 - Longitude of the first point.
   * @param {number} lat2 - Latitude of the second point.
   * @param {number} lon2 - Longitude of the second point.
   */
  async distance(lat1, lon1, lat2, lon2) {
    // Conversion factor from degrees to radians
    const p = 0.017453292519943295; // Math.PI / 180

    // Earth's radius in kilometers
    const R = 6371; // km

    // Differences in latitude and longitude, converted to radians
    const dLat = (lat2 - lat1) * p;
    const dLon = (lon2 - lon1) * p;

    // Latitude values converted to radians
    const convertedLat1 = lat1 * p;
    const convertedLat2 = lat2 * p;

    // Haversine formula to calculate distance
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(convertedLat1) *
        Math.cos(convertedLat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Calculate distance in feet
    const distFeet = R * c * 3280.84; // Assuming 1 kilometer equals 3280.84 feet
    const distMeterToFeet = distFeet.toFixed(2);

    let finalDistance = 0;

    if (distMeterToFeet > 1000) {
      // Distance in miles rounded to 1 decimal place
      finalDistance = (distMeterToFeet / 5280).toFixed(1);
      this.setState({ distanceUnit: "mile(s)" });
    } else if (distFeet > 0) {
      // Distance in feet
      finalDistance = parseFloat(distMeterToFeet).toFixed(2);
    }

    // Update the state with the calculated distance
    this.setState({ distanceBetweenLatLong: finalDistance });
  }

  async hideDistanceModel() {
    this.setState({ distanceModel: false });
  }

  async connectCall() {
    try {
      const dataTrack = new LocalDataTrack();
      this.twillioRoom = await Video.connect(this.token, {
        tracks: [dataTrack],
        bandwidthProfile: {
          video: {
            contentPreferencesMode: "auto",
            mode: "presentation",
            maxSubscriptionBitrate: 2500000,
            maxVideoBitrate: 2000000,
            height: { max: 1080 },
            width: { max: 1920 },
          },
        },
        video: {
          height: 1920,
          frameRate: 30,
          width: 1080,
        },
        preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
        networkQuality: {
          local: 3,
          remote: 3,
        },
      }).catch((error) => {
        // If room is full then error is thrown
        if (error.code == appConstants.twilioMaxParticipantReachedErrorCode) {
          this.state.isMaxParticipantReached = true;
        }
      });

      //Setting up the Twilio events delegates
      this.twillioRoom.on("participantConnected", this.participantConnected);
      this.twillioRoom.on(
        "participantDisconnected",
        this.participantDisconnected
      );

      this.singleParticipantEndCallTime = moment(new Date()).add(
        this.waitingTimeForAnotherParticipant,
        "minutes"
      );

      // console.log("singleParticipantEndCallTime - ",this.singleParticipantEndCallTime);

      this.singleParticipantWaitingTimer = setInterval(
        this.countDownSingleParticipant,
        1000
      );

      this.twillioRoom.participants.forEach(this.participantConnected);

      try {
        getCurrentLocation()
          .then((position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const accuracy = position.coords.accuracy;

            this.setState(
              {
                geoLocation: { latitude, longitude },
                pov: {
                  heading: 100,
                  pitch: 0,
                  zoom: 1,
                },
                accuracy,
              },

              async () => {
                if (isOwner(this.state.role)) {
                  this.checkImageUpload();
                }

                //Just in case if there is delay in permission and location updates afterwards
                if (
                  this.state.localRoom &&
                  this.state.localRoom.localParticipant
                ) {
                  const userDataTrack = this.buildUserDataTrack();
                  console.log("dataTrack.send - ", userDataTrack);
                  this.state.dataTrack.send(JSON.stringify(userDataTrack));
                }
              }
            );

            this.startWatchingLocationPosition();

            //User has provided location access, We need to capture image
          })
          .catch((err) => {
            console.log("Geolocation err - ", err);
          });
      } catch (error) {
        console.log("error", error);
      }

      this.setState(
        {
          localRoom: this.twillioRoom,
          dataTrack,
          // isTimerEnded: false,
        },
        () => {
          this.createMyLocalAudioTrack();
          if (isAppraiserOrAssistant(this.state.role || "")) {
            this.setState({
              isLocalVideoTrackEnabled: false,
            });
          } else {
            this.createMyLocalVideoTrack();
          }
        }
      );

      /* update state with remote participant */
      /** set local Participant delegates */

      this.twillioRoom.localParticipant.on(
        "networkQualityLevelChanged",
        (networkQualityLevel, networkQualityStats) => {
          this.setState(
            {
              networkQualityLevel: networkQualityLevel,
            },
            () => {}
          );
          if (networkQualityLevel < 2) {
            this.toggleNetworkQualityModel();
          } else {
          }
          this.networkQualityLevelChanged(
            this.twillioRoom.localParticipant,
            networkQualityLevel,
            networkQualityStats
          );
        }
      );

      this.twillioRoom.localParticipant.on(
        "trackPublished",
        this.localParticipantTrackPublished
      );
      this.fetchBookmark();
      this.fetchNote();
    } catch (err) {
      StorageUtil.clearAll();
    }
  }
  get isLocalParticipantConnected() {
    return (
      (this.state.localRoom &&
        this.state.localRoom.localParticipant &&
        this.state.localRoom.localParticipant.state ===
          appConstants.TwilioConnectedStatus.CONNECTED) ||
      false
    );
  }

  async checkImageUpload() {
    if (
      this.state.remoteParticipants.length > 0 &&
      this.state.geoLocation &&
      this.state.geoLocation.longitude &&
      this.state.geoLocation.longitude &&
      this.isMapImageUploaded == false
    ) {
      this.isMapImageUploaded = true;
      setTimeout(() => {
        this.captureMapViewImage("roadmap", "15").then(() => {
          this.captureMapViewImage("satellite", "19").then(() => {});
        });
      }, 3000);
    }
  }

  async toggleStreetViewOption() {
    this.setState(
      {
        isStreetViewOptionVisible: !this.state.isStreetViewOptionVisible,
      },
      () => {}
    );
  }

  async toggleStreetViewMap() {
    this.setState(
      {
        isStreetViewMode: !this.state.isStreetViewMode,
        isStreetViewOptionVisible: false,
      },
      () => {
        if (!!this.state.isStreetViewMode) {
          this.hideBookmarkViewModel();
          this.hideListParticipant();
        }
      }
    );
  }

  async retryConnect() {
    this.setState(
      {
        isMaxParticipantReached: false,
      },
      () => {
        this.getCallUUIDAndConnect();
      }
    );
  }

  async setRemoteParticipantTracks(sid, track, status) {
    let participantTrack = {
      sid: sid,
      track: track,
      status: status,
      [track]: status,
    };

    this.props.updateParticipantTrackStatus(participantTrack);
  }

  async handleJoinClicked(userName) {
    this.setState(
      {
        isUserNameEntered: true,
        userName: userName,
      },
      () => {
        StorageUtil.setuserName(userName);
        this.getCallUUIDAndConnect();
      }
    );
  }

  async participantConnected(participant) {
    console.log("Participant Connected - ", participant);
    this.setState(
      {
        remoteParticipants: [...this.state.remoteParticipants, participant],
        isSavedImagesVisible: true,
      },
      () => {
        clearInterval(this.singleParticipantWaitingTimer);

        if (isOwner(this.state.role)) {
          this.checkImageUpload();
        }
      }
    );
    participant.on("trackSubscribed", (track) =>
      this.remoteTrackSubscribed(participant, track)
    );
    participant.on("trackUnsubscribed", (track) =>
      this.remoteTrackUnsubscribed(participant, track)
    );

    participant.on(
      "networkQualityLevelChanged",
      (networkQualityLevel, networkQualityStats) =>
        this.networkQualityLevelChanged(
          participant,
          networkQualityLevel,
          networkQualityStats
        )
    );
  }

  participantDisconnected = async (participant) => {
    let updatedParticipants = this.state.remoteParticipants.filter(
      (p) => p.sid !== participant.sid
    );

    const remotePO = this.getRemotePOVideoUser();

    console.log("Participant Disconnected - ", participant);
    console.log("remotePO on disconnect - ", remotePO);

    if (
      remotePO == null ||
      (remotePO &&
        remotePO.length == 1 &&
        remotePO[0].participant.participantSid == participant.sid)
    ) {
      if (updatedParticipants.length > 0) {
        updatedParticipants.forEach((participants) => {
          if (participants.videoTracks.size == 0) {
            this.setState({
              isPOJoined: false,
              isPOStartedVideoStream: false,
            });
          }
        });
      }
    }
    this.setState({
      remoteParticipants: updatedParticipants,
    });

    if (updatedParticipants.length == 0) {
      this.setState({
        isScaleZoomVisible: false,
        selctedFloorIndexOnResume: null,
        selctedFloorNameOnResume: null,
        selctedAreaTypeOnResume: null,
        selctedAreaNameOnResume: null,

        pinType: "",
        isExitFloorPlanByClose: false,
      });

      this.measurementType = null;
      this.isMeasurementFlow = false;
      this.onExitApprovedReceived(true);

      this.disconnectCall();
    }

    if (updatedParticipants.length == 0 || remotePO == null) {
      this.setState({
        selctedFloorIndexOnResume: null,
        selctedFloorNameOnResume: null,
        selctedAreaTypeOnResume: null,
        selctedAreaNameOnResume: null,

        pinType: "",
        isExitFloorPlanByClose: false,
      });

      this.measurementType = null;
      this.isMeasurementFlow = false;

      this.onExitApprovedReceived(true);
    }

    if (participant.sid == this.state.pinnedParticipant) {
      this.clearPinnedView();
    }

    // Clear state when screen sharing participant disconnects/kill the app
    if (participant.sid == this.state.screenShareParticipantSid) {
      this.setState({
        screenShareParticipantSid: null,
        isRemoteParticipantScreenShareOn: false,
      });
      this.onExitApprovedReceived(true);
    }

    this.props.callParticipantRemove(participant.sid);
  };

  async localParticipantTrackPublished(publication) {
    if (publication && publication.track && publication.track.kind == "data") {
      /* datatrack will be broadcast as local participant is connected. */

      const userDataTrack = this.buildUserDataTrack();
      console.log("dataTrack.send - ", userDataTrack);
      console.log("Local Participant Track Publish- ", publication);
      this.state.dataTrack.send(JSON.stringify(userDataTrack));

      this.props.callParticipantAdd(userDataTrack.data);
    }
  }

  async remoteTrackSubscribed(participant, track) {
    console.log("Remote Track Subscribe - Participant- ", participant);
    console.log("Remote Track Subscribe - Track- ", track);
    let userRole = extractUserRole(participant.identity) || "";
    if (userRole == appConstants.USER_ROLES.PROPERTY_OWNER) {
      this.setState(
        {
          isPOJoined: true,
        },
        () => {}
      );
    }
    if (track.kind == "video") {
      let userRole = extractUserRole(participant.identity) || "";
      if (userRole == appConstants.USER_ROLES.PROPERTY_OWNER) {
        this.setState(
          {
            isPOJoined: true,
            isPOStartedVideoStream: true,
          },
          () => {}
        );
      }

      this.props.callParticipantUpdate({
        participantSid: participant.sid,
        allowedCamera: appConstants.TRACK_STATUS.ENABLED,
      });
    } else if (track.kind == "audio") {
      this.props.callParticipantUpdate({
        participantSid: participant.sid,
        allowedMicrophone: appConstants.TRACK_STATUS.ENABLED,
      });
    } else if (track.kind == "data") {
      /* Participant connected so send data track of User */
      if (this.state.dataTrack) {
        const userDataTrack = this.buildUserDataTrack();
        console.log("dataTrack.send - ", userDataTrack);
        this.state.dataTrack.send(JSON.stringify(userDataTrack));

        //If pinned view, broadcast

        if (this.state.ispinnedView == true) {
          this.setPinnedViewandBroadCast(this.state.pinnedParticipant);
        }

        if (this.state.isPointerStarted && this.state.isPointerStartedByMe) {
          this.showLivePointer(participant.sid);
        }

        if (
          this.state.isMarkupViewVisible &&
          this.state.markupViewStartedBy ==
            this.state.localRoom.localParticipant.sid
        ) {
          this.sendUploadedMarkupImageDataTrack(
            this.state.markupImageURL,
            this.state.markupImageName,
            this.state.markupViewStartedBy
          );
        }
      } else {
      }

      track.on("message", (data, participant) => {
        this.handleDataTrackReceived(data, participant);
      });
    }
  }

  viweFloorPlanByFloorName = (floorData) => {
    // console.log("viweFloorPlanByFloorName - ", floorData);

    const identity = this.state.localRoom.localParticipant.identity || "";
    const participantSid = this.state.localRoom.localParticipant.sid || "";
    const screenSharedParticipantSid =
      this.state.screenShareParticipantSid || "";

    const dataTrackObj = {
      type: "1423",
      data: {
        participantSid,
        identity,
        screenSharedParticipantSid,
        ...floorData,
      },
    };

    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  };

  handleDataTrackReceived = (data, participant) => {
    const message = JSON.parse(data);

    console.log("handleDataTrackReceived - ", message);

    if (message.type == "201") {
      this.props.callParticipantAdd(message.data);

      if (message.data.allowedCamera == "0") {
        this.isFlashDisabledByRemoteParticipant = true;
      }

      if (
        message.data.callStartTime != null &&
        message.data.callStartTime != "" &&
        this.callStartTimeStr == null
      ) {
        this.callStartTimeStr = message.data.callStartTime;
        this.updateCallDurationDates();
      }
      if (
        this.state.distanceModel &&
        this.props.configs.latitude &&
        this.props.configs.longitude &&
        message.data.latitude &&
        message.data.longitude
      ) {
        this.distance(
          this.props.configs.latitude,
          this.props.configs.longitude,
          message.data.latitude,
          message.data.longitude
        );
      }
      let userRole = extractUserRole(message.data.participantIdentity) || "";
      if (userRole == appConstants.USER_ROLES.PROPERTY_OWNER) {
        let isStartMeasurementButtonEnable = true;

        if (message.data.allowedCamera == "0") {
          isStartMeasurementButtonEnable = false;
        }
        this.setState(
          {
            isStartMeasurementButtonEnable: isStartMeasurementButtonEnable,
            isPOStartedVideoStream: isStartMeasurementButtonEnable,
          },
          () => {}
        );
      }

      if (message.data.previousDataTrackType == "200") {
        clearTimeout(this.setTimeoutForRetryDT);
        this.setState({ waitingForDT: false });
        this.toggleRequestEndCallModel();
      } else {
      }
    } else if (message.type == "202") {
      this.props.callParticipantUpdate(message.data);
    } else if (message.type == "301") {
      this.sendAcknowledgementForPinUnpin("301", message.data.participantSid);
      this.setPinnedView(message.data.participantSid);
      // Update the state to indicate that the flash is inactive
      this.setState({ isFlashActive: false });
    } else if (message.type === "302") {
      this.sendAcknowledgementForPinUnpin("302", message.data.participantSid);
      if (this.state.role == appConstants.USER_ROLES.PROPERTY_OWNER) {
        // Get the local video track from the state
        const localVideoTrack = this.localVideoTrack;

        // Extract the media stream track from the video track
        const mediaStreamTrack = localVideoTrack?.mediaStreamTrack;

        // Set the flash state to "false" (indicating it is turned off)
        const isFlashOn = false;

        // Call the function to toggle the flash
        this.toggleFlash(mediaStreamTrack, isFlashOn);
      }
      this.clearPinnedView();
    } else if (message.type == "401") {
      const isFlashActive = message.data.isEnabled == "true";
      this.setFlashActiveDeactive(isFlashActive);
    } else if (message.type == "402") {
      let isFlashActive = true;
      this.isFlashDisabledByRemoteParticipant = false;
      if (message.data.flash_status == appConstants.FLASH_STATUS.ON) {
        this.isFlashEnable = true;
        isFlashActive = true;
      } else if (message.data.flash_status == appConstants.FLASH_STATUS.OFF) {
        isFlashActive = false;
      } else if (
        message.data.flash_status == appConstants.FLASH_STATUS.ENABLED
      ) {
        this.isFlashEnable = true;
        isFlashActive = false;
      } else if (
        message.data.flash_status == appConstants.FLASH_STATUS.DISABLED
      ) {
        this.isFlashEnable = false;
        this.isFlashDisabledByRemoteParticipant = true;
        isFlashActive = false;
      }

      this.setFlashActiveDeactive(isFlashActive);
    } else if (message.type == "500") {
      if (this.isLocalParticipantPinned) {
        this.captureImage(message.data);
      }
      /** disable the button for 2-3 seconds */
      this.disablecaptureImageBtn();
    } else if (message.type == "501") {
      if (!_.isEmpty(message.data)) {
        this.props.addCapturedImages(message.data);
        this.autoShowSavedImages();
        setTimeout(() => {
          if (!this.isMeasurementFlow) {
            this.setState(
              {
                isSavedImagesFullViewVisible: false,
                // isBookmarkViewModelOpen: false,
              },
              () => {}
            );
          }
        }, 2000);
      } else {
        this.setState({
          captureImageUploadingCount: 0,
          captureImageTotalCount: 0,
        });
      }
    } else if (message.type == "502") {
      const captureImageUploadingCount =
        message.data.captureImageUploadingCount;
      const captureImageTotalCount = message.data.captureImageTotalCount;

      this.setState({
        captureImageUploadingCount,
        captureImageTotalCount,
      });
    } else if (message.type == "601") {
      this.setZoomLevel(message.data.zoomLevel);
    } else if (message.type == "602") {
      if (
        message.data.zoom_status == appConstants.ZOOM_STATUS.DISABLED &&
        message.data.participantSid == this.state.pinnedParticipant
      ) {
        this.setState({
          isScaleZoomVisible: false,
        });
      }

      this.setZoomLevel(message.data.zoomLevel);
    } else if (message.type == "801") {
      if (message.data.pointer_status === appConstants.POINTER_STATUS.START) {
        this.setState({
          isPointerStarted: true,
          isPointerStartedByMe: false,
          livePointerColors: message.data.userColors,
        });
      } else if (
        message.data.pointer_status === appConstants.POINTER_STATUS.STOP
      ) {
        this.setState({
          isPointerStarted: false,
          isPointerStartedByMe: false,
          livePointerColors: {},
        });
      }
    } else if (message.type == "802") {
      this.setState({
        showLivePointerCanvas: true,
        receivedLivePointerData: message.data,
      });
    }
    // Check if the message type is "1431"
    else if (message.type == "1431") {
      // Update the flash enable status
      this.isFlashEnable = message.data.isFlashEnable;

      // Update the state to reflect the flash support and inactive flash status
      this.setState({
        isFlashSupported: message.data.isFlashEnable,
        isFlashActive: false,
      });
    }

    //NEEDS TO CHANGE AFTER DISCUSSION WIfTH TEAM
    else if (message.type == "701") {
      if (
        message.data.status ==
        appConstants.ANNOTATION_ACK.REQUEST_FOR_IMAGE_CAPTURE
      ) {
        if (this.isLocalParticipantPinned) {
          this.captureMarkupImage(message.data);
        }
      } else if (message.data.status == appConstants.ANNOTATION_ACK.UPLOADING) {
        this.setState({
          isMarkupViewVisible: false,
          isModalOpen: false,
          isPreparingForMarkupVisible: true,
          userMarkupObjectArray: [],
          userUndoObjectArray: [],
          globalMarkupObjectArray: [],
        });
      } else if (message.data.status == appConstants.ANNOTATION_ACK.UPLOADED) {
        this.setState({
          isMarkupViewVisible: true,
          isPreparingForMarkupVisible: true,
          isModalOpen: false,
          markupImageURL: message.data.original,
        });
      } else if (message.data.status == appConstants.ANNOTATION_ACK.CLEAR) {
        this.setState(
          {
            incomingMarkupAnnotationObject: message.data,
            userMarkupObjectArray: [],
            userUndoObjectArray: [],
            globalMarkupObjectArray: [],
          },
          () => {}
        );
      } else if (message.data.status == appConstants.ANNOTATION_ACK.STOPPED) {
        message.data.timestamp = new Date().getTime();

        if (
          this.state.markupViewStartedBy ==
          this.state.localRoom.localParticipant.sid
        ) {
          //show popup first

          message.data.timestamp = new Date().getTime();

          this.setState(
            {
              isPreparingForMarkupVisible: false,
              incomingMarkupAnnotationObject: message.data,
              userMarkupObjectArray: [],
              userUndoObjectArray: [],
              globalMarkupObjectArray: [],
            },
            () => {}
          );
        } else {
          this.hideMarkupViewModel();
        }
      } else if (
        message.data.status == appConstants.ANNOTATION_ACK.SERVERERROR
      ) {
        this.setState({
          isPreparingForMarkupVisible: false,
          isMarkupViewVisible: false,
          isModalOpen: false,
          markupViewStartedBy: "",
          markupImageURL: "",
        });
      }
    } else if (message.type == "702") {
      message.data.timestamp = new Date().getTime();

      if (message.data.statusShape == appConstants.SHAPE_STATUS.UNDO) {
        let globalMarkupObjectArray = this.state.globalMarkupObjectArray;
        let undoMatchingIndex = _.findIndex(
          globalMarkupObjectArray,
          function (o) {
            return o.objectId == message.data.objectId;
          }
        );

        let undoObject = globalMarkupObjectArray[undoMatchingIndex];
        if (undoMatchingIndex != -1) {
          globalMarkupObjectArray.splice(undoMatchingIndex, 1);
        }

        this.setState(
          {
            globalUndoObjectArray: [
              ...this.state.globalUndoObjectArray,
              undoObject,
            ],
            incomingMarkupAnnotationObject: message.data,
            globalMarkupObjectArray: [...globalMarkupObjectArray],
          },
          () => {}
        );
      } else if (message.data.statusShape == appConstants.SHAPE_STATUS.REDO) {
        let redObjectIndex = _.findIndex(
          this.state.globalUndoObjectArray,
          function (o) {
            return o.objectId == message.data.objectId;
          }
        );

        let globalUndoObjectArray = this.state.globalUndoObjectArray;
        let redObject = globalUndoObjectArray[redObjectIndex];

        if (redObjectIndex != -1) {
          globalUndoObjectArray.splice(redObjectIndex, 1);
        }

        this.setState(
          {
            globalUndoObjectArray: [...globalUndoObjectArray],
            incomingMarkupAnnotationObject: redObject,
            globalMarkupObjectArray: [
              ...this.state.globalMarkupObjectArray,
              redObject,
            ],
          },
          () => {}
        );
      } else {
        this.setState(
          {
            //incomingMarkupAnnotationObject: message.data.annotationPoint

            globalMarkupObjectArray: [
              ...this.state.globalMarkupObjectArray,
              message.data,
            ],
            incomingMarkupAnnotationObject: message.data,
          },
          () => {}
        );
      }
    } else if (message.type === "1200") {
      if (message.data.isScreenShareStarted == "true") {
        this.setState(
          {
            areMeasurementButtonsEnabled:
              message.data.isScreenShareStarted == "true",
            isRemoteParticipantScreenShareOn:
              message.data.isScreenShareStarted == "true",
            screenShareParticipantSid: message.data.participantSid,
          },

          () => {
            if (this.isMeasurementFlow == true) {
              this.onPOScreenShareStarted();
            }
          }
        );
      } else {
        this.isMeasurementFlow = false;
        this.setState(
          {
            isRemoteParticipantScreenShareOn: false,
          },

          () => {}
        );
      }
    }

    if (message.type === "1201") {
      this.setState(
        {
          isRemoteParticipantScreenShareOn:
            message.data.isScreenShareStarted == "true",
          screenShareParticipantSid: message.data.requestedParticipantSid,
        },
        () => {
          // check if my id and requestedParticipantSid are same then only do share screen
          if (
            this.state.screenShareParticipantSid ===
            this.state.localRoom.localParticipant.sid
          ) {
            this.shareScreen();
          }
        }
      );
    }

    if (message.type == "1101") {
      StorageUtil.clearAll();
      this.disconnectCall();
    }
    if (message.type == "1001") {
      let { unreadMessageCount } = this.state;
      if (!!!this.state.isChatModelVisible) {
        unreadMessageCount = this.state.unreadMessageCount + 1;
      }
      this.setState({
        unreadMessageCount,
      });
      this.props.sendMessage(message.data);
    }

    if (message.type == "901") {
      const { data } = message;
      const dt = {
        updatedBy: data.updatedBy,
        location: data.location,
        fileName: data.fileName,
        updatedAt: data.dateTime,
        attachmentId: data.attachmentId ? data.attachmentId : data.documentId,
      };
      this.props.attachmentReceivedSuccess(dt);
    }

    if (message.type === "1301") {
      const status = _.get(message, ["data", "status"], "");

      if (status === "3" || status === "4") {
        this.setState({
          areMeasurementButtonsEnabled: status === "3",
        });
      }

      if (status === "3") {
        this.setState({ isMeasurementOngoing: true });
        this.onHideStartAppraisal();
        this.props.setAppraisalStepThree();
      }

      if (status === "5") {
        this.setState({ isMeasurementOngoing: false });
        this.onExitApprovedReceived();
      }
    }
    if (message.type === "1401") {
      this.setState({
        areMeasurementButtonsEnabled: true,
      });
    }

    if (message.type === "1408") {
      const status = _.get(message, ["data", "autoCornerStatus"], "false");
      const autoCornerStatus = status === "true";
      this.setState({
        autoCornerStatus,
      });
    }

    if (message.type === "1411") {
      this.props.setAppraisalStepFour();

      this.setState({
        floorList: message.data.floorList,
        selectedIndex: message.data.selectedIndex
          ? message.data.selectedIndex
          : "",
        loadingSketch: false,
      });
    }

    if (message.type === "1415") {
      const status = _.get(message, ["data", "status"], "");
      if (status === "false") {
        customToast.error(i18n.t("COMMON_STRING.APPRAISAL_EXIT_DENIED"));
      } else {
        this.setState({
          selctedFloorIndexOnResume: null,
          selctedFloorNameOnResume: null,
          selctedAreaTypeOnResume: null,
          selctedAreaNameOnResume: null,

          pinType: "",
          isExitFloorPlanByClose: false,
        });

        this.measurementType = null;
        this.isMeasurementFlow = false;

        this.onExitApprovedReceived(true);
        customToast.success(i18n.t("COMMON_STRING.APPRAISAL_EXIT_APPROVED"));
      }
    }

    if (message.type === "1417") {
      this.measurementType = "2";
      this.onAppraiserAddRoom(false);
    }

    if (message.type === "1421") {
      if (message.data.buttonType == "7") {
        let isLidar = this.state.isLIDARViewVisible;
        if (message.data.buttonState == "1") {
          isLidar = true;
        } else if (message.data.buttonState == "2") {
          //hide or disable the drop down
        } else {
          isLidar = false;
        }
        this.setState(
          {
            isLIDARViewVisible: isLidar,
          },
          () => {}
        );
      } else if (message.data.buttonType == "10") {
        this.setState({
          showhideLiveFloorPlanActions:
            message.data.buttonState == "1" ? true : false,
        });
      } else {
        if (message.data.buttonType == "11" && this.addRoomLoading) {
          this.timeoutForAddRoom = null;
          this.addRoomLoading = false;
        } else {
        }
        this.setState(
          {
            buttonType: message.data.buttonType,
            buttonState: message.data.buttonState,
          },
          () => {}
        );
      }
    }

    if (message.type === "1424") {
      this.setState({
        lidarSelectedOption: message.data.measurementType,
      });
    }

    if (message.type === "1428") {
      this.setState({
        isFencingEnabled: _.get(message, ["data", "visibility"], "0"),
      });
    }
    if (message.type === "1430") {
      this.setState({
        isFloorPlanExpanded: _.get(message, ["data", "visibilityMode"], "0"),
      });
    }
    if (message.type === "1432") {
      this.setState({
        isSwitchCameraRequested: false,
        switchCameraParticipantSid:
          this.state.switchCameraParticipantSid.filter(
            (sid) => sid !== message.data.POSid
          ),
      });
      if (message.data.isAccepted === "0") {
        customToast.error(
          i18n.t("COMMON_STRING.SWITCH_CAMERA_REQUEST_REJECTED")
        );
      }
    }
    if (message.type === "1433") {
      this.setState({
        liveFloorPlanData: _.get(message, ["data"], []),
        isSavedImagesFullViewVisible: false,
      });
    }
    if (message.type === "1404") {
      this.setState({
        measurementLoading: false,
        loadingSketch: false,
      });
      this.toggleCommonModel();
      let msg;
      let btn;
      if (message.data.type == "1") {
        msg = "1404: " + i18n.t("connection_timeout_msg");
        btn = i18n.t("BUTTONS.RETRY");
      } else if (message.data.type == "2") {
        msg = "1404: " + i18n.t("previous_measurement_prblm_msg");
        btn = i18n.t("BUTTONS.OK");
      } else {
      }
      this.setState({
        commonModelMsg: msg,
        commonModelBtn: btn,
        sidForRetry: message.data.participantSid,
      });
    }
    if (message.type === "1000") {
      if (this.waitForAcknowledgement) {
        clearTimeout(this.timeoutForAcknowledgement);
        this.stopWaitForacknowledgement();
        let msg = "1000: " + i18n.t("connection_timeout_msg");
        let btn = i18n.t("BUTTONS.OK");
        if (message.data.type == "301") {
          if (message.data.status == "1") {
            let type = this.state.sidForRetry
              ? appConstants.PIN_VIDEO_TYPE.SCREEN_SHARE
              : appConstants.PIN_VIDEO_TYPE.CAMERA;
            this.setPinnedView(message.data.participantSid, type);
            this.setState({
              sidForRetry: 0,
              isFlashActive: false,
            });
          } else {
            if (!this.state.isCommonModel) {
              this.toggleCommonModel();
              this.setState({
                commonModelMsg: msg,
                commonModelBtn: btn,
              });
            } else {
            }
          }
        } else if (message.data.type == "302") {
          if (message.data.status == "1") {
            this.clearPinnedView();
            if (this.state.isPointerStarted) {
              this.stopLivePointer();
            }

            this.setState({
              isModalOpen: false,
              isPropertyOwnerPinned: false,
              isImageCapturedAfterPOPinned: false,
              isFlashActive: false,
            });
          } else {
            if (!this.state.isCommonModel) {
              this.toggleCommonModel();
              this.setState({
                commonModelMsg: msg,
                commonModelBtn: btn,
              });
            } else {
            }
          }
        } else {
        }
      } else {
      }
    }
  };

  onExitApprovedReceived(completeExit = false) {
    // console.log("onExitApprovedReceived - ", completeExit);

    this.clearPinnedViewandBroadCast();
    this.props.setAppraisalStepOne();
    this.onHideStartAppraisal();
    this.setState({
      autoCornerStatus: false, // FYI: resetting autoCornerStatus as we have exited/restarted process.
    });
    if (completeExit == true) {
      this.setState({
        pinType: "",
      });

      this.onStopMeasurement();
    } else {
      //When user clicks on back
      // FYI: below line will enable Start measurement button in bottom menu
      this.setState({
        areMeasurementButtonsEnabled: false,
        pinType: "camera",
      });

      this.setState({
        isStartAppraisalModalVisible: true,
        isStartMeasurementModalCollapsed: true,
        //isMeasurementModalCollapsed: true,
        isSavedImagesFullViewVisible: true,
        isAutoShowSavedImagesFullViewVisible: true,
      });

      this.props.setAppraisalStepOne();
      //Sending request to pin video view.
      const remotePO = this.getRemotePOVideoUser();
      let sid;
      if (!this.selectedPOForMeasurementFromMultiplePO) {
        if (remotePO && remotePO.length == 1) {
          sid = _.get(remotePO[0], ["participant", "participantSid"]);
        }
      } else {
        sid = this.selectedPOForMeasurementFromMultiplePO;
      }
      this.setState({
        pinnedParticipant: sid,
      });
      this.setPinnedViewandBroadCast(sid);
    }
  }

  async remoteTrackUnsubscribed(participant, track) {
    if (track.kind == "video") {
      if (!track.name.includes("s_")) {
        this.props.callParticipantUpdate({
          participantSid: participant.sid,
          allowedCamera: appConstants.TRACK_STATUS.DISABLED,
        });
      } else {
      }
    } else if (track.kind == "audio") {
      this.props.callParticipantUpdate({
        participantSid: participant.sid,
        allowedMicrophone: appConstants.TRACK_STATUS.DISABLED,
      });
    } else if (track.kind == "data") {
    }
  }

  async networkQualityLevelChanged(
    participant,
    networkQualityLevel,
    networkQualityStats
  ) {
    this.props.callParticipantUpdate({
      participantSid: participant.sid,
      networkQualityLevel: networkQualityLevel,
    });
  }

  async toggleMicroPhoneClicked() {
    if (this.state.isMicrophonePermissionAllowed) {
      if (this.state.isLocalAudioTrackEnabled == true) {
        this.clearMyLocalAudioTrack();
      } else {
        this.createMyLocalAudioTrack();
      }
    } else {
      this.setState({ showMicorphonePermissionPopup: true });
    }
  }

  hideMicorphonePermissionPopup = () => {
    this.setState({ showMicorphonePermissionPopup: false });
  };

  async toggleCameraClicked() {
    if (this.state.isScreenShareOn) {
      this.setState(
        {
          showShareScreenStartClosePrompt: true,
          shareScreenAction: "CLOSE",
        },
        () => {}
      );
    } else {
      this.toggleCameraAction();
    }
  }

  async toggleCameraAction() {
    if (this.state.localRoom && this.state.localRoom.localParticipant) {
      if (this.state.isCameraPermissionAllowed) {
        if (this.state.isLocalVideoTrackEnabled == true) {
          this.clearMyLocalVideoTrack();
          this.selectedCamera = "";
          this.state.role == appConstants.USER_ROLES.PROPERTY_OWNER &&
            (this.isCameraFlipped = true);
          if (this.state.isPointerStarted) {
            this.stopLivePointer();
          }
          if (this.isLocalParticipantPinned) {
            this.clearPinnedViewandBroadCast();
          }
        } else {
          this.createMyLocalVideoTrack();
        }
      } else {
        this.setState({ showCameraPermissionPopup: true });
      }
    }
  }

  hideCameraPermissionPopup = () => {
    this.setState({ showCameraPermissionPopup: false });
  };

  async flipCamera() {
    if (this.state.localRoom && this.state.localRoom.localParticipant) {
      if (this.state.isLocalVideoTrackEnabled == true) {
        // this.clearMyLocalVideoTrack();

        let deviceId = "";
        if (this.isCameraFlipped == false) {
          deviceId = this.backCameraDeviceId;
          const videoTrackOptions = {
            facingMode: "environment",
            frameRate: 30,
            height: { max: 1080 },
            width: { max: 1920 },
          };
          this.localVideoTrack.restart(videoTrackOptions);
          this.selectedCamera = "environment";
          // Call the function to switch the camera to the environment (rear) camera
          this.handleSwitchCamera(
            this.state.localRoom.localParticipant.sid,
            "environment"
          );
          // Update the state to indicate that flash is supported
          this.setState({ isFlashSupported: true });
        } else {
          deviceId = this.frontCameraDeviceId;
          const videoTrackOptions = {
            facingMode: "user",
            frameRate: 30,
            height: { max: 1080 },
            width: { max: 1920 },
          };
          this.localVideoTrack.restart(videoTrackOptions);
          this.selectedCamera = "user";
          // Call the function to switch the camera to the user (front) camera
          this.handleSwitchCamera(
            this.state.localRoom.localParticipant.sid,
            "user"
          );
          // Update the state to indicate that flash is not supported
          this.setState({ isFlashSupported: false });
        }
        this.isCameraFlipped = !this.isCameraFlipped;
      }
    }
  }

  async toggleSpeaker() {
    this.state.localRoom.localParticipant.unpublishTracks(
      this.state.localAudioTracks
    );

    //Separate function for detach stracks
    this.state.localAudioTracks.forEach(function (track) {
      if (track) {
        track.detach().forEach(function (detachedElement) {
          //
        });
      }
    });

    //Should have separate function for stop track

    this.state.localAudioTracks.forEach(function (track) {
      if (track) {
        track.stop();
      }
    });

    let deviceId = "";
    if (this.state.isOnSpeakerMode == false) {
      deviceId = this.speakerAudioDeviceId;
    } else {
      if (this.isHeadSetAvailable == true) {
        deviceId = this.headSetAudioDeviceId;
      } else {
        deviceId = this.defaultAudioDeviceId;
      }
    }

    let localAudioTrack = await Video.createLocalAudioTrack({
      deviceId: { exact: deviceId },
    }).then();

    await this.state.localRoom.localParticipant.publishTrack(localAudioTrack);

    this.setState(
      {
        localAudioTracks: [localAudioTrack],
        isLocalAudioTrackEnabled: localAudioTrack.isEnabled,
        isOnSpeakerMode: !this.state.isOnSpeakerMode,
      },
      () => {
        localAudioTrack.attach(this.audioRef.current);
      }
    );
  }

  async cleanUpStreamsAndDisconnect() {
    this.clearMyLocalAudioTrack();
    this.clearMyLocalVideoTrack();
    this.state.localRoom.disconnect();

    this.props.callParticipantClearAll();
  }

  broadCastEndSessionDataTrack = () => {
    const dataTrackObj = {
      type: "1101",
      data: {},
    };
    if (this.state.dataTrack) {
      console.log("dataTrack.send - ", dataTrackObj);
      this.state.dataTrack.send(JSON.stringify(dataTrackObj));
    }
  };

  async disconnectCall(endcall = false) {
    let localParticipantTracks;
    let participant;

    if (endcall) {
      /** send Data track */
      this.broadCastEndSessionDataTrack();
      // call BE API to call end
      this.props.endSession(this.inviteUUID);

      StorageUtil.clearAll();
    }

    if (this.state.localRoom != null) {
      clearInterval(this.timer);
      clearInterval(this.singleParticipantWaitingTimer);

      localParticipantTracks = Array.from(
        this.state.localRoom.localParticipant.tracks.values()
      );
      participant = this.state.localRoom.localParticipant;
      StorageUtil.clearCallData();

      setTimeout(
        () => {
          this.cleanUpStreamsAndDisconnect();
          history.replace({
            pathname: "/thanks",
            state: {},
          });
        },
        endcall ? 1500 : 10
      );
    } else if (this.state.isMaxParticipantReached) {
      clearInterval(this.timer);
      clearInterval(this.singleParticipantWaitingTimer);
      StorageUtil.clearCallData();

      setTimeout(
        () => {
          this.props.callParticipantClearAll();
          history.replace({
            pathname: "/thanks",
            state: {},
          });
        },
        endcall ? 1500 : 10
      );
    }
  }

  async confirmStartShareScreen() {}

  async confirmCloseShareScreen() {
    this.setState(
      {
        shareScreenAction: "CLOSE",
        showShareScreenStartClosePrompt: false,
      },
      () => {
        this.shareScreenHandler();
      }
    );
  }

  async shareScreen() {
    if (this.state.shareScreenAction == "") {
      if (this.state.isLocalVideoTrackEnabled == true) {
        this.setState(
          {
            shareScreenAction: "START",
            showShareScreenStartClosePrompt: true,
          },
          () => {}
        );
      } else {
        this.shareScreenHandler();
      }
    } else {
      this.setState(
        {
          shareScreenAction: "CLOSE",
          showShareScreenStartClosePrompt: false,
        },
        () => {
          this.shareScreenHandler();
        }
      );
    }
  }

  async hideShareScreenConfirmModel() {
    this.setState(
      {
        showShareScreenStartClosePrompt: false,
        shareScreenAction: "",
      },
      () => {}
    );
  }

  buildScreenShareDataTrack = (screenShareOnOffFlag) => {
    const screenShareDataTrack = {
      type: "1200",
      data: {
        participantSid: _.get(
          this.state,
          ["localRoom", "localParticipant", "sid"],
          ""
        ),
        isScreenShareStarted: screenShareOnOffFlag + "",
      },
    };
    return screenShareDataTrack;
  };

  async shareScreenHandler() {
    if (!this.screenTrack) {
      navigator.mediaDevices
        .getDisplayMedia()
        .then(async (stream) => {
          let options = {
            name: "s_" + new Date().getTime(),
          };

          this.screenTrack = new LocalVideoTrack(
            stream.getTracks()[0],
            options
          );

          this.screenTrack.mediaStreamTrack.onended = () => {
            this.shareScreenHandler();
          };

          if (this.state.isLocalVideoTrackEnabled == true) {
            this.clearMyLocalVideoTrack();
          }

          this.setState(
            {
              isLocalScreenShareTrackEnabled: true,
              isScreenShareOn: true,
              showShareScreenStartClosePrompt: false,
              shareScreenAction: "CLOSE",
            },
            () => {
              this.screenTrack.attach(this.videoRef.current);
              this.state.localRoom.localParticipant.publishTrack(
                this.screenTrack
              );
            }
          );
          const screenShareDataTrack = this.buildScreenShareDataTrack(true);

          console.log("dataTrack.send - ", screenShareDataTrack);
          this.state.dataTrack.send(JSON.stringify(screenShareDataTrack));
          // this.localVideoTrack.attach(this.videoRef.current);
          // this.state.localRoom.localParticipant.publishTrack(this.localVideoTrack);

          //this.screenTrack.mediaStreamTrack.onended = () => { this.shareScreenHandler() };
        })
        .catch((err) => {
          console.log("Could not share the screen.", err);
        });
    } else {
      if (this.state.isLocalVideoTrackEnabled) {
        this.localVideoTrack.attach(this.videoRef.current);
        this.state.localRoom.localParticipant.publishTrack(
          this.localVideoTrack
        );
      }

      this.setState(
        {
          isLocalScreenShareTrackEnabled: false,
          isScreenShareOn: false,
          showShareScreenStartClosePrompt: false,
          shareScreenAction: "",
        },
        () => {
          this.state.localRoom.localParticipant.unpublishTrack(
            this.screenTrack
          );
          this.screenTrack.stop();
          this.screenTrack = null;
        }
      );
      const screenShareDataTrack = this.buildScreenShareDataTrack(false);
      console.log("dataTrack.send - ", screenShareDataTrack);
      this.state.dataTrack.send(JSON.stringify(screenShareDataTrack));
    }
  }

  async toggleFullScreenMode() {
    if (this.state.isFullScreenMode == false) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (window.document.exitFullscreen) {
        window.document.exitFullscreen();
      } else if (window.document.mozCancelFullScreen) {
        /* Firefox */
        window.document.mozCancelFullScreen();
      } else if (window.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        window.document.webkitExitFullscreen();
      } else if (window.document.msExitFullscreen) {
        /* IE/Edge */
        window.document.msExitFullscreen();
      }
    }

    this.setState(
      {
        isFullScreenMode: !this.state.isFullScreenMode,
      },
      () => {}
    );
  }

  async checkDevices() {
    await navigator.mediaDevices.enumerateDevices().then(this.gotDevices);
  }

  async gotDevices(mediaDevices) {
    let isFrontCameraAvailable = false;
    let isBackCameraAvailable = false;

    let isFrontAndBackCameraAvailable = false;
    let isSpeakerAvailable = false;
    let isOnSpeakerMode = false;
    let isCameraAvailable = this.state.isCameraAvailable;

    this.audioDevices = [];
    this.videoDevices = [];

    mediaDevices.forEach((mediaDevice) => {
      if (mediaDevice.kind === "videoinput") {
        let matchResultFront = mediaDevice.label.match(/front/gim);
        let matchResultCamera = mediaDevice.label.match(/camera/gim);

        let matchResultBack = mediaDevice.label.match(/back/gim);

        if (isMobile == true) {
          if (matchResultFront != null && matchResultFront.length > 0) {
            isFrontCameraAvailable = true;
            this.frontCameraDeviceId = mediaDevice.deviceId;
          }

          if (matchResultBack != null && matchResultBack.length > 0) {
            isBackCameraAvailable = true;
            this.backCameraDeviceId = mediaDevice.deviceId;
          }
        } else {
          if (
            (matchResultFront != null && matchResultFront.length > 0) ||
            (matchResultCamera != null && matchResultCamera.length > 0)
          ) {
            isFrontCameraAvailable = true;
            this.frontCameraDeviceId = mediaDevice.deviceId;
          }
        }

        this.videoDevices.push(mediaDevice);
      } else if (mediaDevice.kind === "audioinput") {
        let matchResultSpeaker = mediaDevice.label.match(/speaker/gim);
        let matchResultDefault = mediaDevice.label.match(/default/gim);
        let matchResultHeadset = mediaDevice.label.match(/headset/gim);

        if (matchResultSpeaker != null && matchResultSpeaker.length > 0) {
          isSpeakerAvailable = true;
          this.speakerAudioDeviceId = mediaDevice.deviceId;
        } else if (
          matchResultDefault != null &&
          matchResultDefault.length > 0
        ) {
          this.defaultAudioDeviceId = mediaDevice.deviceId;
        } else if (
          matchResultHeadset != null &&
          matchResultHeadset.length > 0
        ) {
          let matchResultWiredHeadset = mediaDevice.label.match(/wired/gim);

          this.isHeadSetAvailable = true;
          this.headSetAudioDeviceId = mediaDevice.deviceId;

          if (
            matchResultWiredHeadset != null &&
            matchResultWiredHeadset.length > 0
          ) {
            //TBD for wired headset
            isOnSpeakerMode = false;
          } else {
            isOnSpeakerMode = true;
          }
        }

        this.audioDevices.push(mediaDevice);
      }
    });

    this.isDeviceListUpdating = false;

    if (isFrontCameraAvailable == true && isBackCameraAvailable == true) {
      isFrontAndBackCameraAvailable = true;
    }

    if (this.isHeadSetAvailable == false && isSpeakerAvailable == true) {
      this.defaultAudioDeviceId = this.speakerAudioDeviceId;
      isOnSpeakerMode = true;
    }

    this.setState(
      {
        isCameraAvailable:
          isFrontCameraAvailable == true || isBackCameraAvailable == true
            ? true
            : false,
        isFrontAndBackCameraAvailable: isFrontAndBackCameraAvailable,
        isSpeakerAvailable: isSpeakerAvailable,
        isOnSpeakerMode: isOnSpeakerMode,
        isBackCameraAvailable: isBackCameraAvailable,
      },
      () => {}
    );
  }

  async deviceListChanged(event) {
    if (this.isDeviceListUpdating == false) {
      this.isDeviceListUpdating = true;
      this.checkDevices();
    }
  }

  async updateOnlineStatus() {
    let condition = navigator.onLine ? "online" : "offline";

    if (navigator.onLine) {
      this.connectCall();
    }

    this.setState(
      {
        networkStatus: condition,
      },
      () => {}
    );
  }

  async createMyLocalAudioTrack() {
    try {
      const localAudioTrack = await createLocalAudioTrack();
      //First we need to attach/detach audio, after update state
      localAudioTrack.attach(this.audioRef.current);
      this.localAudioTrack = localAudioTrack;
      this.state.localRoom.localParticipant.publishTrack(localAudioTrack);

      this.setState(
        {
          isLocalAudioTrackEnabled: true,
          isMicrophonePermissionAllowed: true,
        },
        () => {
          this.props.callParticipantUpdate({
            participantSid: this.state.localRoom.localParticipant.sid,
            allowedMicrophone: appConstants.TRACK_STATUS.ENABLED,
          });
          this.checkCallSettingsPermission();
        }
      );
    } catch (error) {
      if (
        error.name == "NotAllowedError" ||
        error.message == "Permission denied"
      ) {
        this.setState({
          isMicrophonePermissionAllowed: false,
          isLocalAudioTrackEnabled: false,
          showMicorphonePermissionPopup: true,
        });
      }
    }
  }

  async createMyLocalAudioTrackWithDeviceId(deviceId) {
    try {
      const localAudioTrack = await createLocalAudioTrack({
        deviceId: { exact: deviceId },
      });
      //First we need to attach/detach audio, after update state
      localAudioTrack.attach(this.audioRef.current);
      this.localAudioTrack = localAudioTrack;
      this.state.localRoom.localParticipant.publishTrack(localAudioTrack);

      this.setState(
        {
          isLocalAudioTrackEnabled: true,
          isMicrophonePermissionAllowed: true,
        },
        () => {
          this.props.callParticipantUpdate({
            participantSid: this.state.localRoom.localParticipant.sid,
            allowedMicrophone: appConstants.TRACK_STATUS.ENABLED,
          });
          this.checkCallSettingsPermission();
        }
      );
    } catch (error) {
      if (
        error.name == "NotAllowedError" ||
        error.message == "Permission denied"
      ) {
        this.setState({
          isMicrophonePermissionAllowed: false,
          isLocalAudioTrackEnabled: false,
          showMicorphonePermissionPopup: true,
        });
      }
    }
  }

  async clearMyLocalAudioTrack() {
    if (
      this.state.localRoom &&
      this.state.localRoom.localParticipant &&
      this.state.localRoom.localParticipant.audioTracks
    ) {
      this.state.localRoom.localParticipant.audioTracks.forEach(
        (publication) => {
          publication.track.stop();
          publication.unpublish();
        }
      );
      this.setState({ isLocalAudioTrackEnabled: false });

      this.props.callParticipantUpdate({
        participantSid: this.state.localRoom.localParticipant.sid,
        allowedMicrophone: appConstants.TRACK_STATUS.DISABLED,
      });
    } else {
    }
  }

  async createMyLocalVideoTrack() {
    try {
      if (this.state.isBackCameraAvailable) {
        let deviceId = "";
        if (
          this.isCameraFlipped ||
          this.state.role == appConstants.USER_ROLES.PROPERTY_OWNER
        ) {
          deviceId = this.backCameraDeviceId;
          // Set the selected camera to "environment" (indicating the rear camera)
          this.selectedCamera = "environment";
        } else {
          deviceId = this.frontCameraDeviceId;
          this.selectedCamera = deviceId;
        }
        this.localVideoTrack = await Video.createLocalVideoTrack({
          deviceId: { exact: deviceId },
          frameRate: 30,
          height: { max: 1080 },
          width: { max: 1920 },
        }).then();
      } else {
        let videoTrackOptions = {};
        if (isMobile && isOwner(this.state.role)) {
          videoTrackOptions = {
            facingMode: "environment",
            frameRate: 30,
            height: { max: 1080 },
            width: { max: 1920 },
          };
          if (this.state.role == appConstants.USER_ROLES.PROPERTY_OWNER) {
            this.isCameraFlipped = true;
          } else {
            this.isCameraFlipped = false;
          }
          this.setPinnedViewandBroadCast(
            this.state.localRoom.localParticipant.sid
          );
        }
        this.selectedCamera = "environment";
        this.localVideoTrack = await createLocalVideoTrack(videoTrackOptions);
      }

      this.setState(
        {
          isLocalVideoTrackEnabled: this.localVideoTrack.isEnabled,
          isCameraPermissionAllowed: true,
        },
        () => {
          this.localVideoTrack.attach(this.videoRef.current);
          this.state.localRoom.localParticipant.publishTrack(
            this.localVideoTrack
          );

          navigator.mediaDevices.enumerateDevices().then(this.gotDevices);
          if (this.state.role == appConstants.USER_ROLES.PROPERTY_OWNER) {
            // Get the local video track from the state
            const localVideoTrack = this.localVideoTrack;

            // Extract the media stream track from the video track
            const mediaStreamTrack = localVideoTrack?.mediaStreamTrack;

            // Set the flash state to "false" (indicating it is turned off)
            const isFlashOn = false;

            // Call the function to toggle the flash
            this.toggleFlash(mediaStreamTrack, isFlashOn);
          }

          this.props.callParticipantUpdate({
            participantSid: this.state.localRoom.localParticipant.sid,
            allowedCamera: appConstants.TRACK_STATUS.ENABLED,
          });
          this.checkCallSettingsPermission();
        }
      );
    } catch (error) {
      if (
        error.name == "NotAllowedError" ||
        error.message == "Permission denied"
      ) {
        this.setState({
          isCameraPermissionAllowed: false,
          isLocalVideoTrackEnabled: false,
          showCameraPermissionPopup: true,
        });
      }
    }
  }

  async createMyLocalVideoTrackWithDevice(device) {
    try {
      this.selectedCamera = device.deviceId;
      this.localVideoTrack = await Video.createLocalVideoTrack({
        deviceId: { exact: device.deviceId },
        frameRate: 30,
        height: { max: 1080 },
        width: { max: 1920 },
      }).then();

      this.setState(
        {
          isLocalVideoTrackEnabled: this.localVideoTrack.isEnabled,
          isCameraPermissionAllowed: true,
        },
        () => {
          this.localVideoTrack.attach(this.videoRef.current);
          this.state.localRoom.localParticipant.publishTrack(
            this.localVideoTrack
          );

          navigator.mediaDevices.enumerateDevices().then(this.gotDevices);

          this.props.callParticipantUpdate({
            participantSid: this.state.localRoom.localParticipant.sid,
            allowedCamera: appConstants.TRACK_STATUS.ENABLED,
          });
          this.checkCallSettingsPermission();
        }
      );
    } catch (error) {
      if (
        error.name == "NotAllowedError" ||
        error.message == "Permission denied"
      ) {
        this.setState({
          isCameraPermissionAllowed: false,
          isLocalVideoTrackEnabled: false,
          showCameraPermissionPopup: true,
        });
      }
    }
  }

  async clearMyLocalVideoTrack() {
    if (
      this.state.localRoom &&
      this.state.localRoom.localParticipant &&
      this.state.localRoom.localParticipant.videoTracks
    ) {
      this.state.localRoom.localParticipant.videoTracks.forEach(
        (publication) => {
          publication.track.stop();
          publication.unpublish();
        }
      );
      this.setState({ isLocalVideoTrackEnabled: false });

      this.props.callParticipantUpdate({
        participantSid: this.state.localRoom.localParticipant.sid,
        allowedCamera: appConstants.TRACK_STATUS.DISABLED,
      });
    }
  }

  // Add Particiant View

  async showAddParticipant() {
    this.setState(
      {
        isChatModelVisible: false,
        isMoreMenuVisible: false,
        isModalOpen: true,
        isSavedImagesFullViewVisible: false,
        isAttachmentDocumentModalVisible: false,
        isListParticipantVisible: false,
        isNoteModelVisible: false,
        isAddParticipantVisible: true,
        showQuestionnaire: false,
      },
      () => {}
    );
  }
  async hideAddParticipant() {
    this.setState(
      {
        isModalOpen: false,
        isAddParticipantVisible: false,
      },
      () => {}
    );
  }

  //Show Participant List

  async showListParticipant() {
    this.setState(
      {
        isChatModelVisible: false,
        isMoreMenuVisible: false,
        isSavedImagesFullViewVisible: false,
        isAddParticipantVisible: false,
        isAttachmentDocumentModalVisible: false,
        isListParticipantVisible: true,
        isNoteModelVisible: false,
        isModalOpen: true,
        showQuestionnaire: false,
      },
      () => {}
    );
  }
  async hideListParticipant() {
    this.setState(
      {
        isModalOpen: false,
        isListParticipantVisible: false,
      },
      () => {}
    );
  }
  async hideSavedImages() {
    this.setState(
      {
        isSavedImagesFullViewVisible: false,
        isAutoShowSavedImagesFullViewVisible: false,
      },
      () => {}
    );
  }

  async updateZoomLevel(zoomLevel) {
    this.setState(
      {
        zoomLevel: zoomLevel,
      },
      () => {
        this.setZoomLevelAndBroadCast(
          this.state.localRoom.localParticipant.sid,
          zoomLevel
        );
      }
    );
  }

  // Function to toggle flash on a video track
  toggleFlash(videoTrack, isFlashOn) {
    let isFlashOnSuccessfully = false;

    // Check if the device is a web-based Android device
    if (
      videoTrack &&
      this.deviceTypeCalculated ===
        appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_ANDROID
    ) {
      const imageCapture = new ImageCapture(videoTrack);

      if (imageCapture) {
        // Get camera capabilities
        imageCapture
          .getPhotoCapabilities()
          .then((capabilities) => {
            if (
              capabilities &&
              capabilities.fillLightMode &&
              capabilities.fillLightMode.length > 0 &&
              capabilities.fillLightMode.includes("flash")
            ) {
              // Apply constraints to enable/disable flash
              videoTrack
                .applyConstraints({
                  advanced: [{ torch: isFlashOn }],
                })
                .then(() => {
                  isFlashOnSuccessfully = true;
                });
            }
          })
          .catch(() => {
            isFlashOnSuccessfully = false;
          });
      } else {
        isFlashOnSuccessfully = false;
      }

      // Update the state to reflect flash status
      if (isFlashOnSuccessfully) {
        this.setState({ isFlashActive: isFlashOn });
      }
    }
  }

  async activeDeactiveFlash() {
    if (this.isFlashEnable == true) {
      this.setState(
        {
          isFlashActive: !this.state.isFlashActive,
        },
        () => {
          // Get the local video track from the state
          const localVideoTrack = this.localVideoTrack;

          // Extract the media stream track from the video track
          const mediaStreamTrack = localVideoTrack?.mediaStreamTrack;

          // Get the current state of the flash (on/off)
          const isFlashOn = this.state.isFlashActive;

          // Call the function to toggle the flash
          this.toggleFlash(mediaStreamTrack, isFlashOn);

          this.setActiveDeactiveFlashAndBroadCast(
            this.state.localRoom.localParticipant.sid,
            this.state.isFlashActive
          );
        }
      );
    }
  }

  async toggleSavedImageModel() {
    this.setState(
      {
        isSavedImagesFullViewVisible: !this.state.isSavedImagesFullViewVisible,
        isAutoShowSavedImagesFullViewVisible:
          !this.state.isSavedImagesFullViewVisible,
        isMoreMenuVisible: false,
      },
      () => {}
    );
  }

  async showSavedImages() {
    this.setState(
      {
        isSavedImagesFullViewVisible: true,
        isMoreMenuVisible: false,
      },
      () => {}
    );
  }

  async autoShowSavedImages() {
    if (this.state.isAutoShowSavedImagesFullViewVisible) {
      this.showSavedImages();
    }
  }

  async showMoreOptions() {
    const { isMoreMenuVisible } = this.state;
    if (!isMoreMenuVisible) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState(
      {
        isMoreMenuVisible: !isMoreMenuVisible,
        isModalOpen: false,
        // isListParticipantVisible: false,
        // isAddParticipantVisible: false,
      },
      () => {}
    );
  }

  handleOutsideClick = (e) => {
    if (!this.node.contains(e.target)) {
      this.showMoreOptions();
    }
  };

  setPinnedViewandBroadCast = async (
    participantSid,
    type = appConstants.PIN_VIDEO_TYPE.CAMERA
  ) => {
    const datatrackobj = {
      type: "301",
      data: {
        participantSid: "" + participantSid,
        type,
      },
    };

    console.log("dataTrack.send - ", datatrackobj);
    this.state.dataTrack.send(JSON.stringify(datatrackobj));
    !this.waitForAcknowledgement && this.setPinnedView(participantSid, type);
  };

  clearPinnedViewandBroadCast = async () => {
    const datatrackobj = {
      type: "302",
      data: {},
    };

    console.log("dataTrack.send - ", datatrackobj);
    this.state.dataTrack.send(JSON.stringify(datatrackobj));
    if (!this.waitForAcknowledgement) {
      this.clearPinnedView();
      if (this.state.isPointerStarted) {
        this.stopLivePointer();
      }

      this.setState({
        isModalOpen: false,
        isPropertyOwnerPinned: false,
        isImageCapturedAfterPOPinned: false,
        isFlashActive: false,
      });
    }
  };

  setPinnedView = async (
    participantSid,
    pinType = appConstants.PIN_VIDEO_TYPE.CAMERA
  ) => {
    let isPropertyOwnerPinned = false;
    let pinnedPODeviceType = "";
    if (this.state.localRoom.localParticipant.sid == participantSid) {
      if (this.state.role == appConstants.USER_ROLES.PROPERTY_OWNER) {
        isPropertyOwnerPinned = true;
      }
    } else {
      const connnectedRemoteParticipant = this.getConnectedParticipants();

      connnectedRemoteParticipant.forEach((participant) => {
        const remoteParticipantVal = participant.participant;

        if (
          remoteParticipantVal.participantSid == participantSid &&
          isOwner(remoteParticipantVal.role)
        ) {
          pinnedPODeviceType = remoteParticipantVal.deviceType;
          isPropertyOwnerPinned = true;
        }
        if (
          participant.participant.isFlashSupported != null &&
          participant.participant.isFlashSupported != undefined &&
          participant.participant.role ===
            appConstants.USER_ROLES.PROPERTY_OWNER
        ) {
          this.setState({
            isFlashSupported: participant.participant.isFlashSupported,
          });
        }
      });
    }

    this.setState({
      ispinnedView: true,
      pinnedParticipant: participantSid,
      isScaleZoomVisible: true,
      isStreetViewOptionVisible: false,
      pinType: pinType,
      isPropertyOwnerPinned: isPropertyOwnerPinned,
      pinnedPODeviceType: pinnedPODeviceType,
    });
  };

  clearPinnedView = async () => {
    this.setState({
      ispinnedView: false,
      pinnedParticipant: null,
      isScaleZoomVisible: false,
      zoomLevel: appConstants.ZOOM_LEVEL.ZOOM_LEVEL_1,
      isStreetViewOptionVisible: false,
      isBookmarkViewModelVisible: false,
      isBookmarkViewModelOpen: false,
      isPropertyOwnerPinned: false,
      isImageCapturedAfterPOPinned: false,
    });
  };

  setZoomLevelAndBroadCast = async (participantSid, zoomLevel) => {
    const datatrackobj = {
      type: "601",
      data: {
        participantSid: "" + participantSid,
        zoomLevel: zoomLevel,
      },
    };

    console.log("dataTrack.send - ", datatrackobj);
    this.state.dataTrack.send(JSON.stringify(datatrackobj));

    this.setZoomLevel(zoomLevel);
  };

  setZoomLevel = async (zoomLevel) => {
    this.setState({
      zoomLevel: zoomLevel,
    });
  };

  setActiveDeactiveFlashAndBroadCast = async (
    participantSid,
    isFlashActive
  ) => {
    const datatrackobj = {
      type: "401",
      data: {
        participantSid: "" + this.state.pinnedParticipant,
        isEnabled: "" + isFlashActive,
      },
    };

    console.log("dataTrack.send - ", datatrackobj);
    this.state.dataTrack.send(JSON.stringify(datatrackobj));
  };

  setFlashActiveDeactive = async (isActive) => {
    this.setState({
      isFlashActive: isActive,
    });
    // Get the local video track from the state
    const localVideoTrack = this.localVideoTrack;

    // Extract the media stream track from the video track
    const mediaStreamTrack = localVideoTrack?.mediaStreamTrack;

    // Call the function to toggle the flash
    this.toggleFlash(mediaStreamTrack, isActive);
  };

  get isLocalParticipantPinned() {
    const islocal =
      this.state.ispinnedView &&
      this.state.pinnedParticipant ==
        _.get(this.state, ["localRoom", "localParticipant", "sid"], "");
    return islocal;
  }

  captureImage = async (extraPayload = {}) => {
    this.requestForCaptureImageBroadCast();

    const height = document.getElementById("video-cam").videoHeight;
    const width = document.getElementById("video-cam").videoWidth;

    let nooflines = 1;
    if (width <= 620) {
      nooflines = 2;
    }
    const extraheight = _.isEmpty(extraPayload)
      ? 28 * nooflines
      : 28 * nooflines + 18;

    this.imgcaptureCanvas.current.height = height + extraheight;
    this.imgcaptureCanvas.current.width = width;
    const latitude = _.get(this.state, ["geoLocation", "latitude"]);
    const longitude = _.get(this.state, ["geoLocation", "longitude"]);
    const bookMark = extraPayload?.bookmarkLabel || null;
    let context = this.imgcaptureCanvas.current.getContext("2d");
    context.drawImage(this.videoRef.current, 0, 0, width, height);
    const canvasheight = height + extraheight;
    const canvaswidth = width;
    const markup = markupforCaptureImg(
      canvasheight,
      canvaswidth,
      latitude,
      longitude,
      nooflines,
      bookMark
    );
    const image = new Image();
    image.onload = () => {
      context.drawImage(image, 0, 0);
      let data = this.imgcaptureCanvas.current.toDataURL("image/jpeg");
      const uploadimage = dataURItoBlob(data);
      this.props.uploadImage(this.jobOrderId, uploadimage, extraPayload);
    };
    image.onerror = (err) => {
      /** if error please upload without markup */
      let data = this.imgcaptureCanvas.current.toDataURL("image/jpeg");
      const uploadimage = dataURItoBlob(data);
      this.props.uploadImage(this.jobOrderId, uploadimage, extraPayload);
    };
    image.src = markup;
    this.autoShowSavedImages();

    if (this.state.isPropertyOwnerPinned == true) {
      setTimeout(() => {
        this.setState({ isImageCapturedAfterPOPinned: true });
      }, 5000);
    }
  };

  captureMapViewImage = async (mapType, zoomLevel) => {
    return new Promise(
      function (resolve, reject) {
        /*stuff using username, password*/

        let canvasHeight = 640;
        let canvasWidth = 640;

        let latitude = this.state.geoLocation.latitude;
        let longitude = this.state.geoLocation.longitude;

        const imageLabel = mapType === "roadmap" ? "Map View" : "Aerial View";

        this.imgcaptureCanvas.current.height = canvasHeight;
        this.imgcaptureCanvas.current.width = canvasWidth;

        let marker = "";
        if (latitude != 0 && longitude != 0) {
          marker = "markers=color:red%7Clabel:L%7C";
        }

        let imgurl =
          "https://maps.googleapis.com/maps/api/staticmap?&maptype=" +
          mapType +
          "&center=" +
          latitude +
          "," +
          longitude +
          "&size=" +
          canvasWidth +
          "x" +
          canvasHeight +
          "&zoom=" +
          zoomLevel +
          "&" +
          marker +
          latitude +
          "," +
          longitude +
          "&key=" +
          appConstants.API_KEYS.GOOGLE_MAP_API_KEY;

        let xhr = new XMLHttpRequest();
        xhr.open("GET", imgurl, true);
        xhr.responseType = "blob";
        xhr.send(null);

        xhr.onload = function (response) {
          let blob = xhr.response;

          //this.props.uploadImage(this.callUUID, blob);

          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = function () {
            var base64data = reader.result;
            let mapImg = new Image();
            var base64data = reader.result;
            mapImg.src = base64data;

            let context = this.imgcaptureCanvas.current.getContext("2d");

            mapImg.onload = function () {
              context.drawImage(mapImg, 0, 0);

              const markup = markupforCaptureImg(
                canvasHeight,
                canvasWidth,
                latitude,
                longitude,
                1,
                imageLabel
              );

              const image = new Image();
              image.onload = () => {
                context.drawImage(image, 0, 0);

                let data =
                  this.imgcaptureCanvas.current.toDataURL("image/jpeg");
                const uploadimage = dataURItoBlob(data);

                this.props.uploadImage(this.jobOrderId, uploadimage, {
                  bookmarkLabel:
                    mapType === "roadmap" ? "Map View" : "Aerial View",
                  bookmarkId: "",
                  isMapViewImage: true,
                });
                resolve(true);
              };
              image.src = markup;
            }.bind(this);
          }.bind(this);
        }.bind(this);
      }.bind(this)
    );
  };

  captureStreetViewImage = async () => {
    return new Promise(
      function (resolve, reject) {
        // var canvasHeight = this.state.streetViewRectHeight;
        // var canvasWidth = this.state.streetViewRectWidth;

        let canvasHeight = 640;
        let canvasWidth = 640;

        let latitude =
          this.state.streetViewPositionLat == 0
            ? this.streetViewPanoramaOptions.position.lat
            : this.state.streetViewPositionLat;
        let longitude =
          this.state.streetViewPositionLong == 0
            ? this.streetViewPanoramaOptions.position.lng
            : this.state.streetViewPositionLong;

        let zoom =
          this.state.streetViewPositionZoom == 0
            ? this.state.pov.zoom
            : this.state.streetViewPositionZoom;
        let fov = (Math.atan(Math.pow(2, 1 - zoom)) * 360) / Math.PI;
        let fov1 = 90 / Math.pow(2, zoom);

        this.imgcaptureCanvas.current.height = canvasHeight;
        this.imgcaptureCanvas.current.width = canvasWidth;

        let imgurl =
          "https://maps.googleapis.com/maps/api/streetview?scale=2" +
          "&location=" +
          latitude +
          "," +
          longitude +
          "&fov=" +
          fov +
          "&size=" +
          canvasWidth +
          "x" +
          canvasHeight +
          "&zoom=" +
          zoom +
          "&heading=" +
          this.state.pov.heading +
          "&pitch=" +
          this.state.pov.pitch +
          "&key=" +
          appConstants.API_KEYS.GOOGLE_MAP_API_KEY;

        let xhr = new XMLHttpRequest();
        xhr.open("GET", imgurl, true);
        xhr.responseType = "blob";
        xhr.send(null);

        xhr.onload = function (response) {
          let blob = xhr.response;

          //this.props.uploadImage(this.callUUID, blob);

          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = function () {
            var base64data = reader.result;
            let mapImg = new Image();
            var base64data = reader.result;
            mapImg.src = base64data;

            let context = this.imgcaptureCanvas.current.getContext("2d");

            mapImg.onload = function () {
              context.drawImage(mapImg, 0, 0);

              const markup = markupforCaptureImg(
                canvasHeight,
                canvasWidth,
                latitude,
                longitude,
                1
              );

              const image = new Image();
              image.onload = () => {
                context.drawImage(image, 0, 0);

                let data =
                  this.imgcaptureCanvas.current.toDataURL("image/jpeg");
                const uploadimage = dataURItoBlob(data);

                this.props.uploadImage(this.jobOrderId, uploadimage);
                resolve(true);
              };
              image.src = markup;
            }.bind(this);
          }.bind(this);
        }.bind(this);
      }.bind(this)
    );
  };

  onPovChanged = async (pov) => {
    this.setState(
      {
        pov: pov,
      },
      () => {}
    );
  };

  onZoomChanged = async (zoom) => {
    this.setState(
      {
        streetViewPositionZoom: zoom,
      },
      () => {}
    );
  };

  onPositionChanged = async (position, streetViewRect) => {
    this.setState(
      {
        streetViewPositionLat: position.lat(),
        streetViewPositionLong: position.lng(),

        streetViewRectWidth: streetViewRect.width,
        streetViewRectHeight: streetViewRect.height,
      },
      () => {}
    );
  };

  requestForCaptureMarkerImageBroadCast = (extraPayload = {}) => {
    const dataTrackObj = {
      type: "701",
      data: {
        latitude:
          this.state.geoLocation && this.state.geoLocation.latitude
            ? "" + this.state.geoLocation.latitude
            : "",
        longitude:
          this.state.geoLocation && this.state.geoLocation.longitude
            ? "" + this.state.geoLocation.longitude
            : "",

        participantIdentity:
          this.state.localRoom.localParticipant.identity || "",
        participantSid: "" + this.state.localRoom.localParticipant.sid || "",
        status: appConstants.ANNOTATION_ACK.REQUEST_FOR_IMAGE_CAPTURE,
        deviceType: this.deviceTypeCalculated,
      },
    };
    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  };

  sendUploadingMarkupImageDataTrack = () => {
    let userDataTrack = {
      type: "701",
      data: {
        latitude:
          this.state.geoLocation && this.state.geoLocation.latitude
            ? "" + this.state.geoLocation.latitude
            : "",
        longitude:
          this.state.geoLocation && this.state.geoLocation.longitude
            ? "" + this.state.geoLocation.longitude
            : "",

        participantIdentity:
          this.state.localRoom.localParticipant.identity || "",
        participantSid: "" + this.state.localRoom.localParticipant.sid || "",
        status: appConstants.ANNOTATION_ACK.UPLOADING,
        deviceType: this.deviceTypeCalculated,
      },
    };
    console.log("dataTrack.send - ", userDataTrack);
    this.state.dataTrack.send(JSON.stringify(userDataTrack));
  };

  sendUploadedMarkupImageDataTrack = (
    image_url,
    image_name = "",
    markupViewStartedBy
  ) => {
    let userDataTrack = {
      type: "701",
      data: {
        latitude:
          this.state.geoLocation && this.state.geoLocation.latitude
            ? "" + this.state.geoLocation.latitude
            : "",
        longitude:
          this.state.geoLocation && this.state.geoLocation.longitude
            ? "" + this.state.geoLocation.longitude
            : "",

        participantIdentity:
          this.state.localRoom.localParticipant.identity || "",
        // participantSid: "" + this.state.localRoom.localParticipant.sid || "",
        participantSid: markupViewStartedBy,
        status: appConstants.ANNOTATION_ACK.UPLOADED,
        deviceType: this.deviceTypeCalculated,
        original: image_url,
        originalName: image_name,
      },
    };
    console.log("dataTrack.send - ", userDataTrack);
    this.state.dataTrack.send(JSON.stringify(userDataTrack));
  };

  sendDownloadingMarkupImageDataTrack = () => {
    let userDataTrack = {
      type: "701",
      data: {
        latitude:
          this.state.geoLocation && this.state.geoLocation.latitude
            ? "" + this.state.geoLocation.latitude
            : "",
        longitude:
          this.state.geoLocation && this.state.geoLocation.longitude
            ? "" + this.state.geoLocation.longitude
            : "",

        participantIdentity:
          this.state.localRoom.localParticipant.identity || "",
        participantSid: "" + this.state.localRoom.localParticipant.sid || "",

        status: appConstants.ANNOTATION_ACK.DOWNLOADING,
        deviceType: this.deviceTypeCalculated,
        original: this.state.markupImageURL,
        originalName: this.state.markupImageName,
      },
    };
    console.log("dataTrack.send - ", userDataTrack);
    this.state.dataTrack.send(JSON.stringify(userDataTrack));
  };
  sendStartedMarkupImageDataTrack = () => {
    this.setState({
      isPreparingForMarkupVisible: false,
      isMarkupViewVisible: true,
      isModalOpen: false,
    });

    let userDataTrack = {
      type: "701",
      data: {
        latitude:
          this.state.geoLocation && this.state.geoLocation.latitude
            ? "" + this.state.geoLocation.latitude
            : "",
        longitude:
          this.state.geoLocation && this.state.geoLocation.longitude
            ? "" + this.state.geoLocation.longitude
            : "",

        participantIdentity:
          this.state.localRoom.localParticipant.identity || "",
        participantSid: "" + this.state.localRoom.localParticipant.sid || "",

        status: appConstants.ANNOTATION_ACK.STARTED,
        deviceType: this.deviceTypeCalculated,
      },
    };
    console.log("dataTrack.send - ", userDataTrack);
    this.state.dataTrack.send(JSON.stringify(userDataTrack));
  };

  sendUploadedFailedMarkupImageDataTrack = (markupViewStartedBy) => {
    let userDataTrack = {
      type: "701",
      data: {
        latitude:
          this.state.geoLocation && this.state.geoLocation.latitude
            ? "" + this.state.geoLocation.latitude
            : "",
        longitude:
          this.state.geoLocation && this.state.geoLocation.longitude
            ? "" + this.state.geoLocation.longitude
            : "",

        participantIdentity:
          this.state.localRoom.localParticipant.identity || "",
        // participantSid: "" + this.state.localRoom.localParticipant.sid || "",
        participantSid: markupViewStartedBy,
        status: appConstants.ANNOTATION_ACK.SERVERERROR,
        deviceType: this.deviceTypeCalculated,
        original: "",
      },
    };
    console.log("dataTrack.send - ", userDataTrack);
    this.state.dataTrack.send(JSON.stringify(userDataTrack));
  };

  captureMarkupImage = async (extraPayload = {}) => {
    this.setState({
      isMarkupViewVisible: false,
      isModalOpen: false,
      isPreparingForMarkupVisible: true,
    });

    this.sendUploadingMarkupImageDataTrack();

    const height = document.getElementById("video-cam").videoHeight;
    const width = document.getElementById("video-cam").videoWidth;

    this.imgcaptureCanvas.current.height = height;
    this.imgcaptureCanvas.current.width = width;

    let context = this.imgcaptureCanvas.current.getContext("2d");

    context.drawImage(this.videoRef.current, 0, 0, width, height);

    let data = this.imgcaptureCanvas.current.toDataURL("image/jpeg");
    const uploadimage = dataURItoBlob(data);

    let markupViewStartedBy = extraPayload.markupViewStartedBy
      ? extraPayload.markupViewStartedBy
      : this.state.localRoom.localParticipant.sid;

    try {
      let markupImageData = await this.props.uploadMarkupImage(
        this.callUUID,
        uploadimage,
        extraPayload
      );

      this.sendUploadedMarkupImageDataTrack(
        markupImageData.payload.original,
        markupImageData.payload.originalName,
        markupViewStartedBy
      );

      this.setState({
        isPreparingForMarkupVisible: true,
        isMarkupViewVisible: true,
        isModalOpen: false,
        markupViewStartedBy: markupViewStartedBy,
        markupImageURL: markupImageData.payload.original,
        markupImageName: markupImageData.payload.originalName,
      });
    } catch (err) {
      //Image uploading failed - send DT about it

      this.sendUploadedFailedMarkupImageDataTrack(markupViewStartedBy);

      this.setState({
        isPreparingForMarkupVisible: false,
        isMarkupViewVisible: false,
        isModalOpen: false,
        markupViewStartedBy: "",
        markupImageURL: "",
      });
    }
  };

  onZoomChanged = async (zoom) => {
    this.setState(
      {
        streetViewPositionZoom: zoom,
      },
      () => {}
    );
  };

  onPositionChanged = async (position, streetViewRect) => {
    this.setState(
      {
        streetViewPositionLat: position.lat(),
        streetViewPositionLong: position.lng(),

        streetViewRectWidth: streetViewRect.width,
        streetViewRectHeight: streetViewRect.height,
      },
      () => {}
    );
  };

  saveMarkupImage = async (dataURL, extraPayload = {}) => {
    const { clientWidth, clientHeight } =
      document.getElementById("markupcanvas");
    const height = clientHeight;
    const width = clientWidth;
    let nooflines = 1;
    if (width <= 620) {
      nooflines = 2;
    }
    const extraheight = _.isEmpty(extraPayload)
      ? 28 * nooflines
      : 28 * nooflines + 18;

    this.imgcaptureCanvas.current.height = height + extraheight;
    this.imgcaptureCanvas.current.width = width;
    const latitude = _.get(this.state, ["geoLocation", "latitude"]);
    const longitude = _.get(this.state, ["geoLocation", "longitude"]);
    const bookMark = extraPayload?.bookmarkLabel || null;
    // var context = this.imgcaptureCanvas.current.getContext("2d");

    //context.drawImage(this.videoRef.current, 0, 0, width, height);

    const markupImage = new Image();
    markupImage.src = dataURL;
    markupImage.onload = () => {
      let context = this.imgcaptureCanvas.current.getContext("2d");
      context.drawImage(markupImage, 0, 0, width, height);

      const canvasheight = height + extraheight;
      const canvaswidth = width;
      const markup = markupforCaptureImg(
        canvasheight,
        canvaswidth,
        latitude,
        longitude,
        nooflines,
        bookMark
      );
      const image = new Image();
      image.onload = () => {
        context.drawImage(image, 0, 0);
        let data = this.imgcaptureCanvas.current.toDataURL("image/jpeg");

        const uploadimage = dataURItoBlob(data);
        this.props.uploadImage(this.jobOrderId, uploadimage, extraPayload);
      };
      image.onerror = (err) => {
        /** if error please upload without markup */

        let data = this.imgcaptureCanvas.current.toDataURL("image/jpeg");
        const uploadimage = dataURItoBlob(data);
        this.props.uploadImage(this.jobOrderId, uploadimage, extraPayload);
      };
      image.src = markup;
      this.autoShowSavedImages();
    };

    markupImage.onerror = (err) => {
      console.log("saveMarkupImage err ", err);
    };
  };

  getConnectedParticipants = () => {
    const remoteParticipants = this.state.remoteParticipants || [];
    const callParticipants = this.props.callParticipants || [];
    const connectedParticpants = _.map(
      remoteParticipants,
      function (remoteParticipant) {
        return {
          remoteParticipant,
          participant: _.get(callParticipants, [remoteParticipant.sid], {}),
        };
      }
    );
    return connectedParticpants;
  };

  getDeviceType = () => {
    return isIOS
      ? appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_IOS
      : isAndroid
      ? appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_ANDROID
      : appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_PC;
  };

  buildUserDataTrack = () => {
    const userDataTrack = {
      type: "201",
      data: {
        latitude:
          this.state.geoLocation && this.state.geoLocation.latitude
            ? "" + this.state.geoLocation.latitude
            : "",
        longitude:
          this.state.geoLocation && this.state.geoLocation.longitude
            ? "" + this.state.geoLocation.longitude
            : "",

        participantIdentity:
          this.state.localRoom.localParticipant.identity || "",
        participantSid: "" + this.state.localRoom.localParticipant.sid || "",
        userName:
          extractUserName(this.state.localRoom.localParticipant.identity) || "",
        role:
          extractUserRole(this.state.localRoom.localParticipant.identity) || "",
        uuid:
          extractUserUUID(this.state.localRoom.localParticipant.identity) || "",
        deviceModel: "browser",
        deviceType: this.getDeviceType() || this.deviceTypeCalculated,
        osName: osName,
        osVersion: osVersion,
        browserName,
        browserVersion,
        batteryLevel: "",
        audioTrackStatus: this.state.isLocalAudioTrackEnabled ? "enabled" : "",
        videoTrackStatus: this.state.isLocalVideoTrackEnabled ? "enabled" : "",

        allowedCamera: this.state.isLocalVideoTrackEnabled ? "1" : "0",
        allowedMicrophone: this.state.isLocalAudioTrackEnabled ? "1" : "0",
        cameraType: "",
        networkQualityLevel: "" + this.state.networkQualityLevel,
        networkType: navigator.connection
          ? navigator.connection.effectiveType
          : "",
        callStartTime: this.videoCallData.callStartTime
          ? this.videoCallData.callStartTime
          : "",
        isFlashSupported: this.isFlashEnable,
      },
    };

    return userDataTrack;
  };

  requestForCaptureImageBroadCast = (extraPayload) => {
    const dataTrackObj = {
      type: "500",
      data: extraPayload,
    };

    this.setState({
      isParticipantImageCaptured: true,
    });

    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  };

  captureImageBtnHandler = (extraPayload = {}) => {
    if (this.isLocalParticipantPinned) {
      this.captureImage(extraPayload);
    } else {
      this.setState({
        isParticipantImageCaptured: false,
      });
      this.requestForCaptureImageBroadCast(extraPayload);
    }
    this.disablecaptureImageBtn();
  };

  disablecaptureImageBtn = () => {
    this.setState({ disbleCaptureImage: true });
    setTimeout(() => {
      if (this.state.isPropertyOwnerPinned == true) {
        this.setState({ isImageCapturedAfterPOPinned: true });
      }
      this.setState({ disbleCaptureImage: false });
    }, 4000);
  };

  capturedImageBroadCast = () => {
    const dataTrackObj = {
      type: "501",
      data: {
        ...this.props.captureImageUploaded,
      },
    };
    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  };

  captureImageProcessStatusBroadCast = (isClear = false) => {
    const captureImageUploadingCount = isClear
      ? "0"
      : this.props.captureImageUploadingCount.toString();
    const captureImageTotalCount = isClear
      ? "0"
      : this.props.captureImageTotalCount.toString();
    const dataTrackObj = {
      type: "502",
      data: {
        captureImageUploadingCount,
        captureImageTotalCount,
      },
    };
    this.setState({
      captureImageUploadingCount,
      captureImageTotalCount,
    });

    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  };

  componentDidUpdate(prevProps, prevState) {
    /** identify the captured image upload success*/
    if (
      this.props.captureImageUploaded !== prevProps.captureImageUploaded &&
      !_.isEmpty(_.get(this, ["props", "captureImageUploaded"], {}))
    ) {
      this.capturedImageBroadCast();
    }

    /** identify that captured image process count update */
    if (
      (_.get(this, ["props", "captureImageTotalCount"]) !==
        _.get(prevProps, ["captureImageTotalCount"]) ||
        _.get(this, ["props", "captureImageUploadingCount"]) !==
          _.get(prevProps, ["captureImageUploadingCount"])) &&
      _.get(this, ["props", "captureImageTotalCount"], 0) >=
        _.get(this, ["props", "captureImageUploadingCount"]) &&
      _.get(this, ["props", "captureImageTotalCount"], 0) != 0
    ) {
      this.captureImageProcessStatusBroadCast();
    } else if (
      (_.get(this, ["props", "captureImageTotalCount"]) !==
        _.get(prevProps, ["captureImageTotalCount"]) ||
        _.get(this, ["props", "captureImageUploadingCount"]) !==
          _.get(prevProps, ["captureImageUploadingCount"])) &&
      (_.get(this, ["props", "captureImageTotalCount"], 0) <=
        _.get(this, ["props", "captureImageUploadingCount"]) ||
        _.get(this, ["props", "captureImageTotalCount"], 0) == 0)
    ) {
      this.captureImageProcessStatusBroadCast(true);
    }

    // console.log("this.props.configs - ", this.props.configs);
    // console.log("this.props.me.configError - ", this.props.configError);
    // console.log("prevProps.configError - ", prevProps.configError);
    if (prevProps.configLoaded == false && this.props.configLoaded == true) {
      this.maxCallDuration = parseInt(this.props.configs.maxCallDuration);
    }

    if (prevProps.configError == false && this.props.configError == true) {
      this.setState(
        {
          showErrorPage: true,
          errorPageMessage: i18n.t("ERROR_MESSAGES.Something_went_wrong"),
        },
        () => {}
      );
    }
  }

  showGalleryZoomModel(images, currentImageIndex) {
    this.setState({
      isGalleryZoomModelVisible: true,
      currentImageIndex,
    });
  }

  hideGalleryZoomModel() {
    this.setState({
      isGalleryZoomModelVisible: false,
      currentImageIndex: 0,
    });
  }

  showBookmarkViewModel() {
    this.setState({
      isBookmarkViewModelVisible: true,
      isBookmarkViewModelOpen: true,
      isChatModelVisible: false,
      isSavedImagesFullViewVisible: false,
      isListParticipantVisible: false,
      isNoteModelVisible: false,
      isModalOpen: true,
      showQuestionnaire: false,
    });
  }

  toggleBookmarkViewModel() {
    this.setState({
      isBookmarkViewModelOpen: !this.state.isBookmarkViewModelOpen,
    });
  }

  minimiseBookmarkViewModel() {
    this.setState({
      isBookmarkViewModelOpen: false,
    });
  }

  hideBookmarkViewModel() {
    this.setState({
      isBookmarkViewModelVisible: false,
      isBookmarkViewModelOpen: false,
      isModalOpen: false,
    });
  }

  showLeaveModel() {
    if (this.state.remoteParticipants.length > 0) {
      this.setState({ isShowLeaveModel: true });
    } else {
      this.disconnectCall();
    }
  }
  hideLeaveModel() {
    this.setState({ isShowLeaveModel: false });
  }

  toggleLivePointer() {
    if (!!this.state.isPointerStarted) {
      this.stopLivePointer();
      customToast.success(i18n.t("COMMON_STRING.LIVE_POINTER_STOPPED"));
    } else {
      this.showLivePointer();
      customToast.success(i18n.t("COMMON_STRING.LIVE_POINTER_STARTED"));
    }
  }

  stopLivePointer() {
    this.setState({
      isPointerStarted: false,
      isPointerStartedByMe: false,
      livePointerColors: {},
    });

    const data = {
      pointer_status: appConstants.POINTER_STATUS.STOP,
      userColors: {},
      deviceType: this.getDeviceType(),
      participantIdentity: this.state.localRoom.localParticipant.identity || "",
      participantSid: "" + this.state.localRoom.localParticipant.sid || "",
    };
    this.toggleLivePointerBroadCast(data);
  }

  prepareColors(newParticipantSid) {
    const colors = appConstants.POINTER_COLORS;
    const particiapnts = this.props.callParticipants;

    let count = 0;

    const userColors = {};
    Object.keys(particiapnts).map((key, i) => {
      userColors[key] = colors[i];
      count = i;
    });

    if (newParticipantSid && newParticipantSid !== null) {
      userColors[newParticipantSid] = colors[count + 1];
    }

    return userColors;
  }

  showLivePointer(newParticipantSid = null) {
    const userColors = this.prepareColors(newParticipantSid);

    const data = {
      pointer_status: appConstants.POINTER_STATUS.START,
      userColors,
      deviceType: this.getDeviceType(),
      participantIdentity: this.state.localRoom.localParticipant.identity || "",
      participantSid: "" + this.state.localRoom.localParticipant.sid || "",
    };

    this.updateZoomLevel(ZOOM_LEVEL.ZOOM_LEVEL_1);

    this.setState({
      isPointerStarted: true,
      isPointerStartedByMe: true,
      livePointerColors: userColors,
    });
    this.toggleLivePointerBroadCast(data);
  }

  toggleLivePointerBroadCast = (pointerData) => {
    const dataTrackObj = {
      type: "801",
      data: {
        ...pointerData,
      },
    };
    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  };

  livePointerBroadCast = (pointerData) => {
    const dataTrackObj = {
      type: "802",
      data: {
        ...pointerData,
      },
    };

    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  };

  markupPathCreated(annotationPoint, objectId) {
    //For test, send event to start the markup view

    const userDataTrack = {
      type: "702",
      data: {
        participantIdentity:
          this.state.localRoom.localParticipant.identity || "",
        participantSid: "" + this.state.localRoom.localParticipant.sid || "",
        borderWidth: "" + annotationPoint[0].borderWidth,
        deviceType: "" + this.deviceTypeCalculated,
        annotationPoint: JSON.stringify(annotationPoint),
        objectId: "" + objectId,
        sessionid: "",
        shapeType: "" + appConstants.SHAPE_TYPE.PENCIL_DRAW,
        statusShape: "" + appConstants.SHAPE_STATUS.DRAW,

        text_str: "",
      },
    };

    this.setState(
      {
        userMarkupObjectArray: [
          ...this.state.userMarkupObjectArray,
          userDataTrack,
        ],
      },
      () => {}
    );

    console.log("dataTrack.send - ", userDataTrack);
    setTimeout(() => {
      this.state.dataTrack.send(JSON.stringify(userDataTrack));
    }, 200);
  }

  markupShapeAdded(annotationPoint, objectId, shapeType) {
    //For test, send event to start the markup view

    const userDataTrack = {
      type: "702",
      data: {
        participantIdentity:
          this.state.localRoom.localParticipant.identity || "",
        participantSid: "" + this.state.localRoom.localParticipant.sid || "",
        borderWidth: "",
        deviceType: "" + this.deviceTypeCalculated,
        annotationPoint: JSON.stringify(annotationPoint),
        objectId: "" + objectId,
        sessionid: "",
        shapeType: "" + shapeType,
        statusShape: "" + appConstants.SHAPE_STATUS.DRAW,

        text_str: "",
      },
    };

    this.setState(
      {
        userMarkupObjectArray: [
          ...this.state.userMarkupObjectArray,
          userDataTrack,
        ],
      },
      () => {}
    );

    console.log("dataTrack.send - ", userDataTrack);

    setTimeout(() => {
      this.state.dataTrack.send(JSON.stringify(userDataTrack));
    }, 200);
  }

  markupShapeMoved(annotationPoint, objectId) {
    //For test, send event to start the markup view

    const userDataTrack = {
      type: "702",
      data: {
        participantIdentity:
          this.state.localRoom.localParticipant.identity || "",
        participantSid: "" + this.state.localRoom.localParticipant.sid || "",
        borderWidth: "",
        deviceType: "" + this.deviceTypeCalculated,
        annotationPoint: JSON.stringify(annotationPoint),
        objectId: "" + objectId,
        sessionid: "",
        shapeType: "",
        statusShape: "" + appConstants.SHAPE_STATUS.MOVE,

        text_str: "",
      },
    };

    this.setState(
      {
        userMarkupObjectArray: [
          ...this.state.userMarkupObjectArray,
          userDataTrack,
        ],
      },
      () => {}
    );

    console.log("dataTrack.send - ", userDataTrack);

    setTimeout(() => {
      this.state.dataTrack.send(JSON.stringify(userDataTrack));
    }, 200);
  }

  markupUndo(userDataTrack) {
    let matchIndex = -1;
    // userDataTrack.data.statusShape = appConstants.SHAPE_STATUS.UNDO;

    let userMarkupObjectArray = this.state.userMarkupObjectArray;
    userMarkupObjectArray.splice(-1, 1);
    let copyOfUserMarkupObject;

    for (let index = userMarkupObjectArray.length - 1; index >= 0; index--) {
      if (
        userMarkupObjectArray[index].data.objectId ==
        userDataTrack.data.objectId
      ) {
        matchIndex = index;
        break;
      }
    }
    if (matchIndex != -1) {
      copyOfUserMarkupObject = _.cloneDeep(userMarkupObjectArray[matchIndex]);
      copyOfUserMarkupObject = {
        data: {
          ...copyOfUserMarkupObject.data,
          statusShape: appConstants.SHAPE_STATUS.MOVE,
          timestamp: new Date().getTime(),
        },
      };
      this.setState({
        incomingMarkupAnnotationObject: { ...copyOfUserMarkupObject.data },
      });
    } else {
      copyOfUserMarkupObject = {
        data: {
          ...userDataTrack.data,
          statusShape: appConstants.SHAPE_STATUS.UNDO,
          timestamp: new Date().getTime(),
        },
      };
      this.setState({
        incomingMarkupAnnotationObject: { ...copyOfUserMarkupObject.data },
      });
    }
    this.setState(
      {
        userMarkupObjectArray: [...userMarkupObjectArray],
        userUndoObjectArray: [...this.state.userUndoObjectArray, userDataTrack],
      },
      () => {
        let dataTrack = _.cloneDeep(userDataTrack);
        dataTrack.data = {
          annotationPoint: "[]",
          ...copyOfUserMarkupObject.data,
        };

        console.log("dataTrack.send - ", dataTrack);
        this.state.dataTrack.send(JSON.stringify(dataTrack));
      }
    );
  }

  markupRedo(userDataTrack) {
    let userUndoObjectArray = this.state.userUndoObjectArray;
    userUndoObjectArray.pop();
    let copyOfUserUndoObject = _.cloneDeep(userDataTrack);
    copyOfUserUndoObject = {
      data: {
        ...userDataTrack.data,
        timestamp: new Date().getTime(),
      },
    };
    this.setState({
      incomingMarkupAnnotationObject: { ...copyOfUserUndoObject.data },
    });
    this.setState(
      {
        userMarkupObjectArray: [
          ...this.state.userMarkupObjectArray,
          userDataTrack,
        ],
        userUndoObjectArray: [...userUndoObjectArray],
      },
      () => {}
    );

    let dataTrack = _.cloneDeep(userDataTrack);
    dataTrack.data = {
      annotationPoint: "[]",
      ...copyOfUserUndoObject.data,
    };
    console.log("dataTrack.send - ", dataTrack);
    this.state.dataTrack.send(JSON.stringify(dataTrack));
  }
  markupClear() {
    const userDataTrack = {
      type: "701",
      data: {
        participantIdentity:
          this.state.localRoom.localParticipant.identity || "",
        participantSid: "" + this.state.localRoom.localParticipant.sid || "",
        deviceType: "" + this.deviceTypeCalculated,
        objectId: "" + new Date().getTime(),
        sessionid: "",
        shapeType: "" + appConstants.SHAPE_TYPE.PENCIL_DRAW,
        status: "" + appConstants.ANNOTATION_ACK.CLEAR,
        text_str: "",
      },
    };

    this.setState(
      {
        userMarkupObjectArray: [],
        userUndoObjectArray: [],
        globalMarkupObjectArray: [],
      },
      () => {}
    );

    console.log("dataTrack.send - ", userDataTrack);
    this.state.dataTrack.send(JSON.stringify(userDataTrack));
  }

  markupClose() {
    this.hideMarkupViewModel();

    const userDataTrack = {
      type: "701",
      data: {
        participantIdentity:
          this.state.localRoom.localParticipant.identity || "",
        participantSid: "" + this.state.localRoom.localParticipant.sid || "",
        deviceType: "" + this.deviceTypeCalculated,
        objectId: "" + new Date().getTime(),
        sessionid: "",
        shapeType: "",
        status: "" + appConstants.ANNOTATION_ACK.STOPPED,
        text_str: "",
      },
    };

    this.setState(
      {
        userMarkupObjectArray: [],
        userUndoObjectArray: [],
        globalMarkupObjectArray: [],
      },
      () => {}
    );

    console.log("dataTrack.send - ", userDataTrack);
    this.state.dataTrack.send(JSON.stringify(userDataTrack));
  }

  hideMarkupViewModel() {
    this.setState({
      isMarkupViewVisible: false,
      isModalOpen: false,
      markupViewStartedBy: "",
    });
  }

  async showMarkupViewModel(extraPayload = {}) {
    if (this.isLocalParticipantPinned) {
      this.captureMarkupImage();
    } else {
      extraPayload = {
        markupViewStartedBy: this.state.localRoom.localParticipant.sid,
        participantIdentity:
          this.state.localRoom.localParticipant.identity || "",
      };

      this.requestForCaptureMarkerImageBroadCast(extraPayload);
    }

    this.setState(
      {
        userMarkupObjectArray: [],
        userUndoObjectArray: [],
        globalMarkupObjectArray: [],
        markupViewStartedBy: this.state.localRoom.localParticipant.sid,
      },
      () => {}
    );
  }

  showChatModel() {
    this.setState({
      isChatModelVisible: true,
      isModalOpen: true,
      unreadMessageCount: 0,
      isNoteModelVisible: false,
      isMoreMenuVisible: false,
      isSavedImagesFullViewVisible: false,
      isAddParticipantVisible: false,
      isListParticipantVisible: false,
      isAttachmentDocumentModalVisible: false,
      showQuestionnaire: false,
    });
  }

  hideChatModel() {
    this.setState({
      isChatModelVisible: false,
      isModalOpen: false,
    });
  }

  onMessageSend(data) {
    const dataTrackObj = {
      type: "1001",
      data,
    };

    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
    this.props.sendMessage(data);
  }

  showNoteModel() {
    this.state.isStartAppraisalModalVisible &&
      this.state.isStartMeasurementModalCollapsed &&
      this.toggleCollapseStartMeasurementModal();
    this.setState({
      isNoteModelVisible: true,
      isModalOpen: true,
      isChatModelVisible: false,
      isSavedImagesFullViewVisible: false,
      isAddParticipantVisible: false,
      isListParticipantVisible: false,
      isAttachmentDocumentModalVisible: false,
      showQuestionnaire: false,
    });
  }

  hideNoteModel() {
    this.setState({
      isNoteModelVisible: false,
      isModalOpen: false,
    });
  }

  showDeleteImageModel = (index, img) => {
    this.setState({
      isDeleteImageModelVisible: true,
      deleteImageIndex: index,
      deleteImageObj: img,
    });
  };

  hideDeleteImageModel = () => {
    this.setState({
      isDeleteImageModelVisible: false,
      deleteImageIndex: -1,
      deleteImageObj: {},
    });
  };

  onDeleteCapturedImage = () => {
    if (this.state.deleteImageIndex != -1) {
      var data = [this.state.deleteImageObj.originalName];
      this.props.deleteImage(data).then((res) => {
        this.props.deleteCapturedImage(this.state.deleteImageIndex);
        this.hideDeleteImageModel();
      });
    }
  };

  cancelDeleteFloorPlan = () => {
    this.setState({
      isDeleteFloorPlanModelVisible: false,
    });
  };

  deleteFloorPlanAndContinue = async () => {
    let requestData = {
      jobOrderId: this.videoCallData.jobOrderId,
      authorization: "",
    };

    try {
      await this.props.deleteFloorPlan(requestData);
    } catch (err) {
      // console.log("Need to handle error - ");
      // console.log("err - ", err);
    }

    this.measurementType = "1";
    this.setState({
      isDeleteFloorPlanModelVisible: false,
      isStartAppraisalModalVisible: true,
      isStartMeasurementModalCollapsed: true,

      isSavedImagesFullViewVisible: true,
      isAutoShowSavedImagesFullViewVisible: true,
      isMeasurementModalCollapsed: false,
    });
  };

  cancelExitMeasurementProcess = () => {
    this.setState({
      isExitFloorPlanByClose: false,
    });
  };

  confirmExitMeasurementProcess = async () => {
    let data = {};
    const type = "1414";
    const identity = this.state.localRoom.localParticipant.identity || "";
    const participantSid = this.state.localRoom.localParticipant.sid || "";
    //const screenSharedParticipantSid = this.state.screenShareParticipantSid || this.state.pinnedParticipant;
    const screenSharedParticipantSid = this.state.pinnedParticipant || "";
    let dataTrackObj = {
      type,
      data: {
        participantSid,
        identity,
        screenSharedParticipantSid,
        ...data,
      },
    };

    // console.log("confirmExitMeasurementProcess - ", dataTrackObj);

    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  };

  selectLIDAROption = (lidarOption) => {
    let type = "1424";

    let data = {};

    const identity = this.state.localRoom.localParticipant.identity || "";
    const participantSid = this.state.localRoom.localParticipant.sid || "";
    const screenSharedParticipantSid =
      this.state.screenShareParticipantSid || "";
    const measurementType = lidarOption;

    let dataTrackObj = {
      type,
      data: {
        participantSid,
        identity,
        screenSharedParticipantSid,
        measurementType,
        ...data,
      },
    };

    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));

    this.setState(
      {
        lidarSelectedOption: lidarOption,
      },
      () => {}
    );
  };

  showAttachmentDocumentModal = () => {
    this.setState({
      isNoteModelVisible: false,
      isModalOpen: true,
      isChatModelVisible: false,
      isSavedImagesFullViewVisible: false,
      isAddParticipantVisible: false,
      isListParticipantVisible: false,
      isAttachmentDocumentModalVisible: true,
      showQuestionnaire: false,
    });
  };

  hideAttachmentDocumentModal = () => {
    this.setState({
      isAttachmentDocumentModalVisible: false,
    });
  };

  onUploadAttachment(data) {
    const dataTrackObj = {
      type: "901",
      data,
    };

    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  }

  startWatchingLocationPosition() {
    window.navigator.geolocation.watchPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const accuracy = position.coords.accuracy;
        if (accuracy < this.state.accuracy) {
          this.setState(
            {
              geoLocation: { latitude, longitude },
              accuracy,
            },
            () => {
              const userDataTrack = this.buildUserDataTrack();
              console.log("dataTrack.send - ", userDataTrack);
              this.state.dataTrack.send(JSON.stringify(userDataTrack));
            }
          );
        }
      },
      (err) => console.error(err),
      {
        enableHighAccuracy: true,
      }
    );
  }

  onDeviceTap(status) {
    const identity = this.state.localRoom.localParticipant.identity || "";
    const participantSid = this.state.localRoom.localParticipant.sid || "";
    const screenSharedParticipantSid =
      this.state.screenShareParticipantSid || "";
    const dataTrackObj = {
      type: "1301",
      data: {
        status,
        participantSid,
        identity,
        screenSharedParticipantSid,
      },
    };
    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  }

  onStartMeasurement() {
    const identity = this.state.localRoom.localParticipant.identity || "";
    const participantSid = this.state.localRoom.localParticipant.sid || "";
    const screenSharedParticipantSid =
      this.state.screenShareParticipantSid || "";
    const dataTrackObj = {
      type: "1401",
      data: {
        participantSid,
        identity,
        screenSharedParticipantSid,
      },
    };
    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  }

  onStopMeasurement() {
    this.setState({
      areMeasurementButtonsEnabled: false,
      measurementLoading: false,
      loadingSketch: false,
    });
  }

  async checkConfigurationForMeasurement() {
    if (this.state.isPOJoined) {
      const remotePO = this.getRemotePOVideoUser();
      let sid;
      if (remotePO && remotePO.length == 1) {
        sid = _.get(remotePO[0], ["participant", "participantSid"]);
        let videoEnabled = _.get(remotePO[0], ["participant", "allowedCamera"]);
        if (videoEnabled == "1") {
          this.onShowStartAppraisal(sid);
        } else {
          this.setState({
            requestPOForCamera: true,
            msgForPOInstructionModel: i18n.t("request_po_for_camera"),
          });
        }
      } else if (remotePO && remotePO.length > 1) {
        let remotePOs = remotePO.filter(
          (participant) =>
            participant &&
            participant.participant &&
            participant.participant.allowedCamera == "1"
        );
        if (remotePOs.length == 0) {
          this.setState({
            requestPOForCamera: true,
            msgForPOInstructionModel: i18n.t("request_po_for_camera"),
          });
        } else if (remotePOs.length == 1) {
          sid = _.get(remotePOs[0], ["participant", "participantSid"]);
          this.onShowStartAppraisal(sid);
          this.selectPoFromMultiplePOs(sid);
        } else {
          let remotePOsForInspection = [];
          remotePOs.map((po) => {
            remotePOsForInspection.push({
              sid: po.participant.participantSid,
              identity: po.participant.userName,
            });
          });
          this.setState({
            choosePOToStartInspection: true,
            remotePOsForInspection: remotePOsForInspection,
          });
        }
      } else {
        this.toggleRequestEndCallModel();
      }
    } else {
      this.setState({
        requestPOForCamera: true,
        msgForPOInstructionModel: i18n.t("wait_until_po_joins"),
      });
    }
  }

  selectPoFromMultiplePOs(sid) {
    this.selectedPOForMeasurementFromMultiplePO = sid;
  }

  async toggleInspectionProgressLoader() {
    this.setState({
      inspectionProgressLoader: true,
    });
    setTimeout(() => {
      this.setState({
        inspectionProgressLoader: false,
      });
    }, 3000);
  }

  async togglePOsModal() {
    this.setState({
      choosePOToStartInspection: false,
    });
  }

  async onShowStartAppraisal(selectedPOsid) {
    this.setState({
      inspectionLoading: true,
    });
    //this.measurementData = await this.props.getRoomMeasurementJSONForJobOrder("ff070b5b-4686-4906-b82a-20c576feef3d");
    this.measurementData = await this.props.getRoomMeasurementJSONForJobOrder(
      this.videoCallData.jobOrderId
    );

    // console.log("this.measurementData - ", this.measurementData);

    let jsonObject = this.measurementData.jsonData;
    let floorArray = [];
    let roomArray = [];
    let areaNameArray = [];
    let roomDataAvailable = false;
    let isStartAppraisalModalVisible = false;
    let isStartMeasurementModalCollapsed = false;

    let isSavedImagesFullViewVisible = this.state.isSavedImagesFullViewVisible;
    let isAutoShowSavedImagesFullViewVisible =
      this.state.isAutoShowSavedImagesFullViewVisible;

    // originalFloorPlan = json;
    //updatedFloorPlan = _.cloneDeep(originalFloorPlan);

    if (jsonObject == null || jsonObject.EAGLEVIEW_EXPORT.STRUCTURES == null) {
      //If room data is not available start new measurement.
      isStartAppraisalModalVisible = true;
      isStartMeasurementModalCollapsed = true;
      isSavedImagesFullViewVisible = true;
      isAutoShowSavedImagesFullViewVisible = true;
      this.measurementType = "1";
    } else {
      let roofObject = [jsonObject.EAGLEVIEW_EXPORT.STRUCTURES.ROOF];
      let roofPoints = [jsonObject.EAGLEVIEW_EXPORT.STRUCTURES.ROOF.POINTS];
      let roofPointsArray = roofPoints[0]["POINT"];
      let lines = [jsonObject.EAGLEVIEW_EXPORT.STRUCTURES.ROOF.LINES];
      let linesArray = lines[0].LINE;

      let faceArray = roofObject[0]["FACES"]["FACE"];

      let designatorWiseObjectArray = {};

      let roomChildrenArray = [];

      for (
        let faceArrayIndex = 0;
        faceArrayIndex < faceArray.length;
        faceArrayIndex++
      ) {
        let roomFaceObject = faceArray[faceArrayIndex];

        if (
          designatorWiseObjectArray[roomFaceObject["@id"]] == null &&
          roomFaceObject["@type"] == "ROOM"
        ) {
          //Get walls of room

          roomChildrenArray = [];
          designatorWiseObjectArray[roomFaceObject["@id"]] = [];

          designatorWiseObjectArray[roomFaceObject["@id"]].push(roomFaceObject);

          let children = roomFaceObject["@children"];
          roomChildrenArray = children.split(",");

          //Not iterate room child...

          for (
            let roomChildrenIndex = 0;
            roomChildrenIndex < roomChildrenArray.length;
            roomChildrenIndex++
          ) {
            let roomId = roomChildrenArray[roomChildrenIndex];
            //Loop to iterate face object again. Later we'll manage processed ids

            for (
              let wallFaceObjectIndex = 0;
              wallFaceObjectIndex < faceArray.length;
              wallFaceObjectIndex++
            ) {
              let faceObject = faceArray[wallFaceObjectIndex];

              if (
                faceObject["POLYGON"]["@path"].trim().length > 0 &&
                roomId == faceObject["@id"]
              ) {
                let lineId = faceObject["POLYGON"]["@path"];
                //Find Line
                for (
                  let lineArrayIndex = 0;
                  lineArrayIndex < linesArray.length;
                  lineArrayIndex++
                ) {
                  let lineObject = linesArray[lineArrayIndex];
                  if (lineObject["@id"] == lineId) {
                    let linePointObjectArray = [];
                    let linePoints = lineObject["@path"].split(",");
                    for (
                      let linePointsIndex = 0;
                      linePointsIndex < linePoints.length;
                      linePointsIndex++
                    ) {
                      for (
                        let roofPointsArrayIndex = 0;
                        roofPointsArrayIndex < roofPointsArray.length;
                        roofPointsArrayIndex++
                      ) {
                        let roofPointObject =
                          roofPointsArray[roofPointsArrayIndex];
                        if (
                          roofPointObject["@id"] == linePoints[linePointsIndex]
                        ) {
                          linePointObjectArray.push(roofPointObject);

                          break;
                        }
                      }
                    }

                    lineObject["linePointObjectArray"] = linePointObjectArray;
                    linesArray[lineArrayIndex] = lineObject;

                    faceObject["LINE"] = lineObject;
                    faceArray[faceArrayIndex] = faceObject;
                  }
                }

                designatorWiseObjectArray[roomFaceObject["@id"]].push(
                  faceObject
                );
              }
            }
          }
        } else {
          //TBD. NOT IN USE.
        }
      }

      roomArray = Object.keys(designatorWiseObjectArray);

      for (
        let roomArrayIndex = 0;
        roomArrayIndex < roomArray.length;
        roomArrayIndex++
      ) {
        let roomObjectArray =
          designatorWiseObjectArray[roomArray[roomArrayIndex]];
        // console.log("roomObject - ", roomObjectArray);

        for (
          let roomObjectArrayIndex = 0;
          roomObjectArrayIndex < roomObjectArray.length;
          roomObjectArrayIndex++
        ) {
          let wallObject = roomObjectArray[roomObjectArrayIndex];

          if (wallObject.LINE == null) {
            areaNameArray.push(wallObject["@areaname"]);

            floorArray.push({
              floorIndex: parseInt(
                wallObject["@floorindex"] ? wallObject["@floorindex"] : "0"
              ),
              floorName: wallObject["@floor"],
              areaName: wallObject["@areaname"],
              areaType: wallObject["@measuretype"],
            });
            continue;
          }
        }
      }

      floorArray = _.uniq(floorArray);
      floorArray = _.uniqBy(floorArray, "floorIndex");
      floorArray = _.sortBy(floorArray, ["floorIndex"]);

      areaNameArray = _.uniq(areaNameArray);

      isStartMeasurementModalCollapsed = false;
      roomDataAvailable = true;
      this.props.setAppraisalStepTwo();
    }

    // console.log("onShowStartAppraisal floorList - ", floorArray);

    this.setState({
      isStartAppraisalModalVisible: isStartAppraisalModalVisible,
      isStartMeasurementModalCollapsed: isStartMeasurementModalCollapsed,
      floorList: floorArray,
      selectedFloor: 0,
      roomDataAvailable: roomDataAvailable,
      areaNameArray: areaNameArray,
      isMeasurementModalCollapsed: true,
      isResumeOrViewInspection: false,

      selctedFloorIndexOnResume: null,
      selctedFloorNameOnResume: null,
      selctedAreaTypeOnResume: null,
      selctedAreaNameOnResume: null,
      isSavedImagesFullViewVisible: isSavedImagesFullViewVisible,
      isAutoShowSavedImagesFullViewVisible:
        isAutoShowSavedImagesFullViewVisible,
      isStartAppraisalCaptureImageTabVisible: true,
      inspectionLoading: false,
    });
    this.isMeasurementFlow = true;

    this.setState({
      pinnedParticipant: selectedPOsid,
    });
    this.setPinnedViewandBroadCast(selectedPOsid);
  }

  toggleRequestEndCallModel() {
    this.setState({
      isRequestEndCallModel: !this.state.isRequestEndCallModel,
    });
  }

  onHideStartAppraisal() {
    this.setState({
      isStartAppraisalModalVisible: false,
    });
  }

  clearPinnedScreenshareandBroadCast = () => {
    const identity = this.state.localRoom.localParticipant.identity || "";
    const participantSid = this.state.localRoom.localParticipant.sid || "";
    const dataTrackObj = {
      type: "1200",
      data: {
        participantSid,
        identity,
        isScreenShareStarted: "false",
      },
    };
    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  };

  setPinnedScreenshareandBroadCast = (requestedParticipantSid) => {
    // console.log("setPinnedScreenshareandBroadCast - ",requestedParticipantSid);

    const identity = this.state.localRoom.localParticipant.identity || "";
    const participantSid = this.state.localRoom.localParticipant.sid || "";

    let isRemoteParticipantScreenShareOn = true;
    if (!this.isMeasurementFlow) {
      isRemoteParticipantScreenShareOn =
        !this.state.isRemoteParticipantScreenShareOn;
    }

    // const isRemoteParticipantScreenShareOn = true;

    const dataTrackObj = {
      type: "1201",
      data: {
        participantSid,
        identity,
        requestedParticipantSid,
        isScreenShareStarted: isRemoteParticipantScreenShareOn + "",
      },
    };

    // console.log("setPinnedScreenshareandBroadCast dataTrackObj - ",dataTrackObj);
    // console.log("this.isMeasurementFlow  - ",this.isMeasurementFlow);

    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  };

  onSendFloorData(data) {
    //Clear current pinned view

    this.setState({
      selctedFloorIndexOnResume: data.floorIndex,
      selctedFloorNameOnResume: data.floorName,
      selctedAreaTypeOnResume: data.areaType,
      selctedAreaNameOnResume: data.areaName,
      lidarSelectedOption: "0",
      measurementLoading: true,
    });

    this.sendFloorPlanData = data;
    if (
      this.state.isRemoteParticipantScreenShareOn == true &&
      this.state.screenShareParticipantSid != null
    ) {
      this.onPOScreenShareStarted();
    } else {
      this.setPinnedScreenshareandBroadCast(this.state.pinnedParticipant);
    }

    // console.log("disable button");
  }

  async onPOScreenShareStarted() {
    setTimeout(() => {
      const identity = this.state.localRoom.localParticipant.identity || "";
      const participantSid = this.state.localRoom.localParticipant.sid || "";
      const screenSharedParticipantSid =
        this.state.screenShareParticipantSid || "";
      const measurementType = this.measurementType;
      const isAutoSnap = this.state.autoSnap;

      const dataTrackObj = {
        type: "1403",
        data: {
          participantSid,
          identity,
          screenSharedParticipantSid,
          measurementType,
          isAutoSnap,
          ...this.sendFloorPlanData,
        },
      };

      // console.log("onSendFloorData dataTrackObj - ", dataTrackObj);

      console.log("dataTrack.send - ", dataTrackObj);
      this.state.dataTrack.send(JSON.stringify(dataTrackObj));

      this.setState({
        isFencingEnabled: "0",
        isFloorPlanExpanded: "0",
        showhideLiveFloorPlanActions: false,
        liveFloorPlanData: [],
        isMeasurementModalCollapsed: true,
        autoCornerStatus: false, // FYI: This we have to reset as we are redirecting to appraisal step2
        measurementLoading: false,
      });

      setTimeout(() => {
        //console.log("this.state.screenShareParticipantSid - ",this.state.screenShareParticipantSid);

        this.setPinnedViewandBroadCast(
          this.state.screenShareParticipantSid,
          appConstants.PIN_VIDEO_TYPE.SCREEN_SHARE
        );
      }, 3000);
    }, 200);
  }

  async viweOrResumeOpenSketch() {
    // console.log("viweOrResumeOpenSketch - ", this.sendFloorPlanData);

    if (this.measurementType == "2") {
      this.setState({
        isDeleteFloorPlanModelVisible: false,
        isStartAppraisalModalVisible: true,

        isSavedImagesFullViewVisible: true,
        isAutoShowSavedImagesFullViewVisible: true,

        isStartMeasurementModalCollapsed: true,
        selctedFloorIndexOnResume: this.sendFloorPlanData.floorIndex,
        selctedFloorNameOnResume: this.sendFloorPlanData.floorName,
        selctedAreaTypeOnResume: this.sendFloorPlanData.areaType,
        selctedAreaNameOnResume: this.sendFloorPlanData.areaName,
        isStartAppraisalCaptureImageTabVisible: false,
      });
    } else {
      if (this.sendFloorPlanData && this.sendFloorPlanData.floorIndex != null) {
        this.setState({ loadingSketch: true });
        if (
          this.state.isRemoteParticipantScreenShareOn == true &&
          this.state.screenShareParticipantSid != null
        ) {
          this.onPOScreenShareStarted();
        } else {
          this.setPinnedScreenshareandBroadCast(this.state.pinnedParticipant);
          setTimeout(() => {
            this.checkMeasurementStarted();
          }, 30000);
        }
      }
    }
  }

  async onResumeViewFloorPlanFloorSelection(data) {
    // console.log("onResumeViewFloorPlanFloorSelection data - ", data);
    // console.log("this.state.pinnedParticipant - ",this.state.pinnedParticipant);
    // console.log("this.state.isRemoteParticipantScreenShareOn - ",this.state.isRemoteParticipantScreenShareOn);
    // console.log("this.state.screenShareParticipantSid - ",this.state.screenShareParticipantSid);

    //this.clearPinnedViewandBroadCast();

    this.sendFloorPlanData = data;
    /*
    if (this.state.isRemoteParticipantScreenShareOn == true && this.state.screenShareParticipantSid != null) {
      this.onPOScreenShareStarted();
    } else {
      this.setPinnedScreenshareandBroadCast(this.state.pinnedParticipant);
    }
    */
  }

  onMeasurementsModalEvent(eventType, extraPayload = {}) {
    let type;
    let data = {};

    // console.log("onMeasurementsModalEvent eventType - ", eventType);

    switch (eventType) {
      case "NEW": {
        // type = "1405";
        // data["identity"] = null;

        // console.log("New measurement");
        this.measurementType = "1";
        this.setState(
          {
            isDeleteFloorPlanModelVisible: true,
            lidarSelectedOption: "0",
          },
          () => {}
        );

        break;
      }

      case "RESUME": {
        // console.log("RESUME measurement");
        this.measurementType = "2";

        this.setState({
          isDeleteFloorPlanModelVisible: false,
          isStartAppraisalModalVisible: true,
          isStartMeasurementModalCollapsed: true,
          isSavedImagesFullViewVisible: true,
          isAutoShowSavedImagesFullViewVisible: true,
          lidarSelectedOption: "0",
          isMeasurementModalCollapsed: false,
        });

        // this.setState(
        //   {
        //     isResumeOrViewInspection: true,
        //   },
        //   () => { }
        // );

        // type = "1406";
        // data["identity"] = null;
        break;
      }
      case "EDIT": {
        // console.log("EDIT measurement", this.sendFloorPlanData);
        this.measurementType = "3";

        this.setState(
          {
            isResumeOrViewInspection: true,
          },
          () => {}
        );

        // type = "1407";
        // data["identity"] = null;
        break;
      }

      case "AUTO_ON": {
        type = "1408";
        this.setState({
          autoCornerStatus: extraPayload.autoCornerStatus,
        });
        data["autoCornerStatus"] = extraPayload.autoCornerStatus + "";
        break;
      }

      case "TAP_ON_DEVICE": {
        return this.onDeviceTap("1");
        break;
      }

      case "UNDO": {
        return this.onDeviceTap("2");
        break;
      }

      case "DONE": {
        type = "1409";
        break;
      }

      case "FLOOR_PLAN": {
        type = "1410";
        break;
      }

      case "CAPTURE_3D_ROOM_HEIGHT": {
        type = "1418";
        break;
      }

      case "ADD_ROOM": {
        type = "1413";
        this.measurementType = "2";
        return this.onAppraiserAddRoom();
        break;
      }

      case "PUBLISH": {
        type = "1412";
        break;
      }

      case "EXIT": {
        type = "1414";
        break;
      }

      case "BACK_TO_PREVIOUS": {
        type = "1419";
        break;
      }

      case "CAPTURE_DOOR": {
        type = "1425";
        break;
      }

      case "SET_DOOR": {
        type = "1426";
        break;
      }

      case "ADD_ROOM_AREA": {
        type = "1434";
        this.addRoomLoading = true;
        this.setTimeoutForAddRoom();
        break;
      }

      case "START_ADD_ROOM": {
        type = "1435";
        data["status"] = "1";
        break;
      }

      case "CANCEL_ADD_ROOM": {
        type = "1435";
        data["status"] = "2";
        break;
      }

      default:
        return;
    }

    const identity = this.state.localRoom.localParticipant.identity || "";
    const participantSid = this.state.localRoom.localParticipant.sid || "";
    const screenSharedParticipantSid =
      this.state.screenShareParticipantSid || "";
    let dataTrackObj = {
      type,
      data: {
        participantSid,
        identity,
        screenSharedParticipantSid,
        ...data,
      },
    };

    // console.log("onMeasurementsModalEvent dataTrackObj - ",dataTrackObj);

    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  }

  toggleCaptureImageMeasurement(showLabelledCaptureImage) {
    this.setState({
      isStartAppraisalCaptureImageTabVisible: showLabelledCaptureImage,
    });
  }

  toggleCollapseStartMeasurementModal() {
    const { isStartMeasurementModalCollapsed } = this.state;
    this.setState({
      isStartMeasurementModalCollapsed: !isStartMeasurementModalCollapsed,
    });
  }

  toggleCollapseMeasurementModal() {
    const { isMeasurementModalCollapsed } = this.state;
    this.setState({
      isMeasurementModalCollapsed: !isMeasurementModalCollapsed,
    });
  }

  exitMeasurementProcess() {
    this.setState({
      isExitFloorPlanByClose: true,
    });
  }

  onAppraiserAddRoom(sendAddRoomDT = true) {
    const identity = this.state.localRoom.localParticipant.identity || "";
    const participantSid = this.state.localRoom.localParticipant.sid || "";
    const screenSharedParticipantSid =
      this.state.screenShareParticipantSid || "";
    this.setState({ isMeasurementOngoing: false });
    if (sendAddRoomDT) {
      let dataTrackObj = {
        type: "1413",
        data: {
          participantSid,
          identity,
          screenSharedParticipantSid,
        },
      };
      // console.log("onAppraiserAddRoom - ",dataTrackObj);
      console.log("dataTrack.send - ", dataTrackObj);
      this.state.dataTrack.send(JSON.stringify(dataTrackObj));
    } else {
      setTimeout(() => {
        // console.log("onAppraiserAddRoom - ",sendAddRoomDT);

        this.clearPinnedViewandBroadCast();
        this.props.setAppraisalStepOne();
        setTimeout(() => {
          //this.onShowStartAppraisal();

          // console.log("Add room");
          this.props.setAppraisalStepOne();
          //Sending request to pin video view.
          const remotePO = this.getRemotePOVideoUser();
          let sid;
          if (!this.selectedPOForMeasurementFromMultiplePO) {
            if (remotePO && remotePO.length == 1) {
              sid = _.get(remotePO[0], ["participant", "participantSid"]);
            }
          } else {
            sid = this.selectedPOForMeasurementFromMultiplePO;
          }
          this.setState({
            pinnedParticipant: sid,
          });
          this.setPinnedViewandBroadCast(sid);

          this.setState({
            isStartAppraisalModalVisible: true,
            isStartMeasurementModalCollapsed: true,
            isSavedImagesFullViewVisible: true,
            isAutoShowSavedImagesFullViewVisible: true,
            isStartAppraisalCaptureImageTabVisible: true,
            lidarSelectedOption: "0",
          });
        }, 200);
      }, 200);
    }
  }

  setPinnedRemotePO() {
    const connnectedRemoteParticipant = this.getConnectedParticipants();
    const isMobilePO = connnectedRemoteParticipant.filter((participant) => {
      const isMobileDevice =
        participant.participant.deviceType ==
          appConstants.DEVICE_TYPE.DEVICE_TYPE_IOS ||
        participant.participant.deviceType ==
          appConstants.DEVICE_TYPE.DEVICE_TYPE_ANDROID;

      const isPO =
        participant.participant.role === "PROPERTY_OWNER" ||
        participant.participant.role === "2";

      return isMobileDevice && isPO;
    });
    return isMobilePO.length > 0 ? isMobilePO[0] : null;
  }

  getRemotePOVideoUser() {
    const connnectedRemoteParticipant = this.getConnectedParticipants();
    const screenSharedParticipantSid =
      this.state.screenShareParticipantSid || "";

    const isMobilePO = connnectedRemoteParticipant.filter((participant) => {
      const isMobileDevice =
        participant.participant.deviceType ==
          appConstants.DEVICE_TYPE.DEVICE_TYPE_IOS ||
        participant.participant.deviceType ==
          appConstants.DEVICE_TYPE.DEVICE_TYPE_ANDROID;

      const isPO =
        participant.participant.role === "PROPERTY_OWNER" ||
        participant.participant.role === "2";

      // FYI: need only participants with video enabled
      // const isVideoParticipant =
      //   participant.participant.videoTrackStatus === "enabled";

      // FYI: filtering participants with non-screenshared participant for ios
      if (
        participant.participant.deviceType ==
        appConstants.DEVICE_TYPE.DEVICE_TYPE_IOS
      ) {
        const isntScreenSharedParticipant =
          participant.participant.participantSid !== screenSharedParticipantSid;
        return isMobileDevice && isPO && isntScreenSharedParticipant;
      } else {
        return isMobileDevice && isPO;
      }
    });

    if (isMobilePO.length > 0) {
      return isMobilePO;
    } else {
      return null;
    }
  }

  async showCallSettings() {
    this.setState(
      {
        isListParticipantVisible: false,
        isChatModelVisible: false,
        isNoteModelVisible: false,
        isAttachmentDocumentModalVisible: false,
      },
      () => {}
    );
    this.setState({
      isCallSettingsVisible: !this.state.isCallSettingsVisible,
    });
  }

  updateCallDurationDates() {
    this.callStartTime = moment
      .utc(this.callStartTimeStr, "YYYY-MM-DD HH:mm:ss")
      .local();
    this.callEndTime = _.cloneDeep(this.callStartTime);
    this.callEndTime.add(this.maxCallDuration, "minutes");

    this.startTimer();
  }

  startTimer() {
    if (this.timer == null) {
      this.setState(
        {
          isTimerStarted: true,
          isTimerEnded: false,
        },
        () => {}
      );

      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    let remainingTime = getDateDifference(
      this.callEndTime,
      "YYYY-MM-DD HH:mm:ss",
      "seconds"
    );

    // console.log("countDown - remainingTime ",remainingTime);

    // Check if we're at zero.
    if (remainingTime <= 0) {
      this.setState(
        {
          isTimerEnded: true,
          timerErrorMessage: i18n.t(
            "ERROR_MESSAGES.Oops_Call_duration_is_over_and_session_is_ended"
          ),

          isLocalVideoTrackEnabled: false,
          isLocalAudioTrackEnabled: false,
          remoteParticipants: [],
        },
        () => {}
      );

      clearInterval(this.timer);
    } else {
      let remainingTimeInSecs = remainingTime;
      let hours = Math.floor(remainingTime / 3600);
      remainingTime = remainingTime % 3600;
      let minutes = Math.floor(remainingTime / 60);
      let seconds = remainingTime % 60;

      if (hours < 10) {
        hours = "0" + hours;
      }

      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      this.setState(
        {
          callRemainingTimeHours: hours,
          callRemainingTimeMins: minutes,
          callRemainingTimeSecs: seconds,
          remainingTimeInSecs: remainingTimeInSecs,
          remainingTimeAlertInSec: this.remainingTimeAlertInSec,
        },
        () => {}
      );
    }
  }

  countDownSingleParticipant() {
    let currentDate = moment(new Date());
    let timeDifference = this.singleParticipantEndCallTime.diff(
      currentDate,
      "seconds"
    );

    // console.log("countDownSingleParticipant timeDifference - ",timeDifference);

    // Check if we're at zero.
    if (timeDifference <= 0) {
      clearInterval(this.singleParticipantWaitingTimer);

      this.setState(
        {
          isTimerEnded: true,
          timerErrorMessage: i18n.t(
            "ERROR_MESSAGES.No_participant_has_joined_yet_Ending_the_call_session"
          ),
        },
        () => {}
      );
    } else {
      //Continue waiting
    }
  }

  handleLocationNotshared() {
    const identity = this.state.localRoom.localParticipant.identity || "";
    const participantSid = this.state.localRoom.localParticipant.sid || "";
    let dataTrackObj = {
      type: 1420,
      data: {
        participantSid,
        identity,
      },
    };
    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  }

  handleFencingVisibility(isFencingEnabled) {
    this.setState({ isFencingEnabled: isFencingEnabled });
    const screenSharedParticipantSid =
      this.state.screenShareParticipantSid || "";
    let dataTrackObj = {
      type: 1427,
      data: {
        screenSharedParticipantSid: screenSharedParticipantSid,
        visibility: isFencingEnabled,
      },
    };
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  }

  handleFloorPlanExpandOption(isFloorPlanExpanded) {
    this.setState({ isFloorPlanExpanded: isFloorPlanExpanded });
    const screenSharedParticipantSid =
      this.state.screenShareParticipantSid || "";
    let dataTrackObj = {
      type: 1429,
      data: {
        screenSharedParticipantSid: screenSharedParticipantSid,
        visibilityMode: isFloorPlanExpanded,
      },
    };
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  }

  handleGoToAppClicked() {
    let appURL = window.location.href;
    let id = localStorage.getItem("shortLink");

    if (isIOS) {
      // appURL =
      //   "vsite://remoteval/joincall/" +
      //   this.inviteUUID +
      //   "?name=" +
      //   this.name +
      //   "&enc=" +
      //   this.enc;
      appURL = "vsite://remoteval/joincall/" + id;
      window.location = appURL;
    } else if (isAndroid) {
      //appURL ="intent://" + process.env.REACT_APP_ANDROID_APP_INTENT +"/joincall/" +this.inviteUUID +"?name=" +this.name +"#Intent;scheme=https;end";
      //appURL ="deeplinktest://open.my.deeplink.app"+"/joincall/" +this.inviteUUID +"?name=" +this.name;
      // appURL =
      //   "remoteval://open.remoteval" +
      //   "/joincall/" +
      //   this.inviteUUID +
      //   "?name=" +
      //   this.name +
      //   "&enc=" +
      //   this.enc;
      appURL = "remoteval://open.remoteval/joincall/" + id;
      setTimeout(() => {
        //console.log("nothing to here...");
      }, 1500);
      window.location = appURL;
    } else {
      console.log("appURL - ", appURL);
      window.location.href = appURL;
    }

    setTimeout(() => {}, 1000);
  }

  async handleDownloadAppClicked() {
    let appURL = isAndroid ? ANDROID_PLAY_STORE_LINK : IOS_APP_STORE_LINK;

    setTimeout(() => {
      window.location.href = appURL;
    }, 1000);
  }

  toggleQuestionnaire() {
    this.showMoreOptions();
    !this.state.showQuestionnaire &&
      this.setState({
        isNoteModelVisible: false,
        isMoreMenuVisible: false,
        isModalOpen: true,
        isChatModelVisible: false,
        isSavedImagesFullViewVisible: false,
        isAddParticipantVisible: false,
        isListParticipantVisible: false,
        isAttachmentDocumentModalVisible: false,
        showQuestionnaire: true,
      });
    this.state.showQuestionnaire &&
      this.setState({
        showQuestionnaire: false,
      });
  }

  handleLeaveCallBtn = () => {
    this.disconnectCall();
    this.setState({
      showConfirmRecordingModal: false,
    });
  };

  handleGotItBtn = () => {
    this.setState({
      showConfirmRecordingModal: false,
    });
  };

  handleSwitchCamera = (participantSid, selectedCamera) => {
    this.setState({
      isFlashActive: false,
      isSwitchCameraRequested: true,
      switchCameraParticipantSid: [
        ...this.state.switchCameraParticipantSid,
        participantSid,
      ],
    });
    const dataTrackObj = {
      type: "1431",
      data: {
        POSid: participantSid,
        isFlashEnable:
          this.deviceTypeCalculated ==
            appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_ANDROID &&
          selectedCamera === "environment",
      },
    };
    console.log("dataTrack.send - ", dataTrackObj);
    this.state.dataTrack.send(JSON.stringify(dataTrackObj));
  };

  toggleNetworkQualityModel = () => {
    this.setState({
      showNetworkQualityModel: !this.state.showNetworkQualityModel,
    });
  };

  toggleRequestPOForCameraModel = () => {
    this.setState({
      requestPOForCamera: false,
    });
  };

  toggleCommonModel = () => {
    this.setState({
      isCommonModel: !this.state.isCommonModel,
    });
  };

  retryForAPIError = () => {
    // this.setPinnedViewandBroadCast();
    const datatrackobj = {
      type: "301",
      data: {
        participantSid: "" + this.state.sidForRetry,
        type: appConstants.PIN_VIDEO_TYPE.SCREEN_SHARE,
      },
    };

    console.log("dataTrack.send - ", datatrackobj);
    this.state.dataTrack.send(JSON.stringify(datatrackobj));
    this.toggleCommonModel();
  };

  checkMeasurementStarted() {
    if (this.state.measurementLoading || this.state.loadingSketch) {
      let msg;
      if (this.state.measurementLoading) {
        msg = i18n.t("measurement_retry_msg");
      } else if (this.state.loadingSketch) {
        msg = i18n.t("opensketch_retry_msg");
      } else {
      }
      this.setState({
        measurementLoading: false,
        loadingSketch: false,
        commonModelMsg: msg,
        commonModelBtn: i18n.t("BUTTONS.OK"),
      });
      this.toggleCommonModel();
    } else {
    }
  }

  startWaitForacknowledgement = () => {
    this.waitForAcknowledgement = true;
  };

  stopWaitForacknowledgement = () => {
    this.waitForAcknowledgement = false;
  };

  setTimeoutForAcknowledgement = () => {
    this.timeoutForAcknowledgement = setTimeout(() => {
      this.stopWaitForacknowledgement();
      this.setState({
        commonModelMsg: "301/302: " + i18n.t("connection_timeout_msg"),
        commonModelBtn: i18n.t("BUTTONS.OK"),
      });
      this.toggleCommonModel();
    }, 15000);
  };

  setTimeoutForRetryDT = () => {
    this.setState({ waitingForDT: true });
    const datatrackobj = {
      type: "200",
      data: {},
    };
    console.log("dataTrack.send - ", datatrackobj);
    this.state.dataTrack.send(JSON.stringify(datatrackobj));
    this.timeoutForRetryDT = setTimeout(() => {
      this.retryDTFailed = true;
      this.setState({ waitingForDT: false });
    }, 15000);
  };

  sendAcknowledgementForPinUnpin = (type, participantSid) => {
    const datatrackobj = {
      type: "1000",
      data: {
        participantSid: "" + participantSid,
        type: type,
        status: "1",
      },
    };
    console.log("dataTrack.send - ", datatrackobj);
    this.state.dataTrack.send(JSON.stringify(datatrackobj));
  };

  toggleAutoSnap = (value) => {
    this.setState({ autoSnap: value });
  };

  setTimeoutForAddRoom = () => {
    this.timeoutForAddRoom = setTimeout(() => {
      if (this.addRoomLoading) {
        this.addRoomLoading = false;
        this.timeoutForAddRoom = null;
        this.toggleCommonModel();
        this.setState({
          commonModelMsg: "1434: " + i18n.t("connection_timeout_msg"),
          commonModelBtn: i18n.t("BUTTONS.OK"),
        });
      } else {
      }
    }, 15000);
  };

  render() {
    //console.log("showErrorPage - ", this.state.showErrorPage, this.state.errorPageMessage);

    const { configLoaded, t } = this.props;

    if (this.state.showErrorPage) {
      return (
        <>
          {this.state.showErrorPage && (
            <ErrorPage errorMessage={this.state.errorPageMessage} />
          )}
        </>
      );
    }

    if (!configLoaded) {
      return null;
    }

    const connnectedRemoteParticipant = this.getConnectedParticipants();

    let lastCapturedImageURL = "";
    if (this.props.images.length > 0) {
      lastCapturedImageURL =
        this.props.images[this.props.images.length - 1].original;
    }

    const classNameForZoom =
      this.state.zoomLevel == appConstants.ZOOM_LEVEL.ZOOM_LEVEL_1
        ? "scale1"
        : this.state.zoomLevel == appConstants.ZOOM_LEVEL.ZOOM_LEVEL_2
        ? "scale2"
        : "scale3";

    const isLocalParticipantPinned =
      this.state.ispinnedView &&
      this.state.pinnedParticipant ==
        _.get(this.state, ["localRoom", "localParticipant", "sid"], "");

    let LocationLabel = "";
    let streetViewLayout = "";
    if (isLocalParticipantPinned) {
      if (
        this.state.geoLocation &&
        _.isNumber(this.state.geoLocation.latitude) &&
        _.isNumber(this.state.geoLocation.longitude)
      ) {
        LocationLabel +=
          ", " +
          t("WEB_LABELS.Location") +
          ": " +
          parseFloat(this.state.geoLocation.latitude).toFixed(6) +
          ", " +
          parseFloat(this.state.geoLocation.longitude).toFixed(6);
      } else {
        LocationLabel += ", " + t("WEB_LABELS.Location_Not_shared");
      }
    }

    const localParticipantWrapperClass = isLocalParticipantPinned
      ? "participants-view full-screen"
      : "participants-view";
    let localParticipant = "";
    if (this.state.localRoom && this.state.localRoom.localParticipant) {
      let localUserVideoDisplay = "";

      if (
        this.state.isLocalVideoTrackEnabled == true ||
        this.state.isLocalScreenShareTrackEnabled
      ) {
        localUserVideoDisplay = (
          <>
            <div className="live-stream">
              <video
                id="video-cam"
                ref={this.videoRef}
                autoPlay={true}
                className={classNameForZoom}
              />
              <audio ref={this.audioRef} autoPlay={true} muted={false} />
            </div>

            {lastCapturedImageURL.trim().length > 0 &&
              this.state.role == appConstants.USER_ROLES.PROPERTY_OWNER &&
              this.props.configs.settings.capture_image_panel &&
              this.state.isImageCapturedAfterPOPinned && (
                <div className="captured-stream">
                  {this.props.configs.settings.isAwsImage ? (
                    <img
                      src={lastCapturedImageURL}
                      alt="Loading Captured Pic..."
                    />
                  ) : (
                    <AsyncImage imageUrl={lastCapturedImageURL} />
                  )}
                </div>
              )}
          </>
        );
      } else {
        let userNameInitials = "Test user1";

        localUserVideoDisplay = (
          <>
            <div className="generalize-text">
              <div className="common-text">{t("WEB_LABELS.You")}</div>
            </div>
          </>
        );
      }

      //var isLocalParticipantPinned= (this.state.ispinnedView && this.state.pinnedParticipant == this.state.localRoom.localParticipant.sid)
      const classForPinnedicon = isLocalParticipantPinned
        ? "icon-return-to-gallery"
        : "icon-pin-video";

      localParticipant = (
        <>
          <div className={localParticipantWrapperClass}>
            <div className="name">
              <i
                className={
                  this.state.isLocalAudioTrackEnabled == true
                    ? "icon-microphone"
                    : "icon-mute-microphone"
                }
              ></i>
              {(this.state.isLocalVideoTrackEnabled ||
                this.state.isLocalScreenShareTrackEnabled) && (
                <span className="user-name">{t("WEB_LABELS.You")}</span>
              )}

              <span
                className="location"
                style={{ display: isLocalParticipantPinned ? "" : "none" }}
              >
                {LocationLabel}
              </span>
              <div style={{ display: "none" }} className="street-view">
                <button
                  onClick={() => {
                    this.toggleStreetViewOption();
                  }}
                >
                  <i className="icon-more-vertical"></i>
                </button>
                <div
                  style={{
                    display: this.state.isStreetViewOptionVisible
                      ? "block"
                      : "none",
                  }}
                  className="street-drop"
                >
                  <button
                    onClick={() => {
                      this.toggleStreetViewMap();
                    }}
                  >
                    {t("BUTTONS.Street_View")}
                  </button>
                </div>
              </div>
            </div>
            <>
              <div className="pin-video-share">
                {this.state.isLocalVideoTrackEnabled == true && (
                  <RBAC
                    action={pinned_view}
                    yes={
                      <Tooltip
                        title={
                          isLocalParticipantPinned
                            ? t("TOOLTIP.Unpin_Video")
                            : t("TOOLTIP.Pin_Video")
                        }
                        arrow
                      >
                        <button
                          onClick={() => {
                            if (isLocalParticipantPinned) {
                              this.clearPinnedViewandBroadCast();
                            } else {
                              this.setPinnedViewandBroadCast(
                                this.state.localRoom.localParticipant.sid
                              );
                            }
                          }}
                        >
                          <i className={classForPinnedicon}></i>
                        </button>
                      </Tooltip>
                    }
                  />
                )}
                {/* <RBAC
                  action={start_appraiser_measurement}
                  yes={
                    <Tooltip title={"Screenshare"} arrow>
                      <button
                        className={classnames({
                          active: !!this.state.isRemoteParticipantScreenShareOn,
                        })}
                        onClick={() => {
                          if (isLocalParticipantPinned) {
                            this.clearPinnedScreenshareandBroadCast();
                          } else {
                            this.setPinnedScreenshareandBroadCast(
                              this.state.localRoom.localParticipant.sid
                            );
                          }
                        }}
                      >
                        <i className="icon-share-screen"></i>
                      </button>
                    </Tooltip>
                  }
                /> */}
              </div>
            </>
            {streetViewLayout}
            <div
              className={classnames("video-img", {
                "live-captured-stream":
                  this.props.configs.settings.capture_image_panel &&
                  this.state.isImageCapturedAfterPOPinned,
              })}
            >
              {localUserVideoDisplay}
            </div>
          </div>
        </>
      );
    }

    let remoteParticipants = [];
    connnectedRemoteParticipant.forEach((participant) => {
      const remoteParticipantVal = participant.participant;

      const isRemoteParticipantPinned =
        this.state.ispinnedView &&
        this.state.pinnedParticipant ==
          _.get(participant, ["remoteParticipant", "sid"], "");

      const isScreenShared =
        this.state.isRemoteParticipantScreenShareOn &&
        this.state.screenShareParticipantSid ==
          _.get(participant, ["remoteParticipant", "sid"], "");

      const isMobileDevice =
        participant.participant.deviceType ==
          appConstants.DEVICE_TYPE.DEVICE_TYPE_IOS ||
        participant.participant.deviceType ==
          appConstants.DEVICE_TYPE.DEVICE_TYPE_ANDROID;

      if (isRemoteParticipantPinned && this.state.isStreetViewMode) {
        this.streetViewPanoramaOptions = {
          position: {
            lat: this.props.configs.latitude
              ? parseFloat(this.props.configs.latitude)
              : parseFloat(remoteParticipantVal.latitude),
            lng: this.props.configs.longitude
              ? parseFloat(this.props.configs.longitude)
              : parseFloat(remoteParticipantVal.longitude),
          },

          pov: { heading: 100, pitch: 0 },
          zoom: 1,
          disableDefaultUI: false,
          clickToGo: true,
          addressControl: false,
          fullscreenControl: false,
          zoomControl: true,
        };
        this.remoteParticipantLatLong = {
          lat: this.props.configs.latitude
            ? parseFloat(this.props.configs.latitude)
            : parseFloat(remoteParticipantVal.latitude),
          long: this.props.configs.longitude
            ? parseFloat(this.props.configs.longitude)
            : parseFloat(remoteParticipantVal.longitude),
        };
      }
      // Check if the local participant is pinned, the selected camera is the environment (rear) camera,
      // the device type is a web-based Android device, and the pinned view is active
      if (
        isLocalParticipantPinned &&
        this.selectedCamera === "environment" &&
        this.deviceTypeCalculated ==
          appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_ANDROID &&
        this.state.ispinnedView
      ) {
        // Enable flash
        this.isFlashEnable = true;
      }
      // Check if the local participant is not pinned or the selected camera is not the environment (rear) camera,
      // and the device type is a web-based Android device
      else if (
        (!isLocalParticipantPinned || this.selectedCamera !== "environment") &&
        this.deviceTypeCalculated ==
          appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_ANDROID
      ) {
        // Disable flash
        this.isFlashEnable = false;
      }

      // Check if the remote participant is pinned, flash is supported, the device type is a web-based Android device,
      // and the pinned view is active
      if (
        isRemoteParticipantPinned &&
        this.state.isFlashSupported &&
        remoteParticipantVal.deviceType ==
          appConstants.DEVICE_TYPE.DEVICE_TYPE_WEB_ANDROID &&
        this.state.ispinnedView
      ) {
        // Enable flash
        this.isFlashEnable = true;
      }
      // Check if the remote participant is not pinned, the pinned view is not active,
      // the remote participant has the role of a property owner, and the camera is disabled
      else if (
        !isRemoteParticipantPinned &&
        !this.state.ispinnedView &&
        remoteParticipantVal?.role == appConstants.USER_ROLES.PROPERTY_OWNER &&
        remoteParticipantVal.allowedCamera == appConstants.TRACK_STATUS.DISABLED
      ) {
        // Disable flash
        this.isFlashEnable = false;
      }

      remoteParticipants.push(
        <ParticipantVideoView
          isAwsImage={this.props.configs.settings.isAwsImage}
          screenShareParticipantSid={this.state.screenShareParticipantSid}
          key={_.get(participant, ["remoteParticipant", "sid"], "")}
          remoteParticipant={participant.remoteParticipant || {}}
          participant={participant.participant || {}}
          userData={this.remoteUserData}
          ispinnedViewActive={this.state.ispinnedView}
          isPinned={isRemoteParticipantPinned}
          zoomLevel={this.state.zoomLevel}
          isMobileDevice={isMobileDevice}
          latitude={participant.participant.latitude || ""}
          longitude={participant.participant.longitude || ""}
          setPinnedViewandBroadCast={(data) =>
            this.setPinnedViewandBroadCast(data)
          }
          clearPinnedViewandBroadCast={() => this.clearPinnedViewandBroadCast()}
          isStreetViewMode={this.state.isStreetViewMode}
          isStreetViewOptionVisible={this.state.isStreetViewOptionVisible}
          toggleStreetViewOption={() => this.toggleStreetViewOption()}
          toggleStreetViewMap={() => this.toggleStreetViewMap()}
          isCurrentUserAppraiserOrAssistant={isAppraiserOrAssistant(
            this.state.role
          )}
          isOwner={isOwner(participant.participant.role)}
          isLocalParticipantConnected={this.isLocalParticipantConnected}
          showAddParticipant={() => this.showAddParticipant()}
          hideAddParticipant={() => this.hideAddParticipant()}
          isAddParticipantVisible={this.state.isAddParticipantVisible}
          isFrontAndBackCameraAvailable={
            this.state.isFrontAndBackCameraAvailable
          }
          flipCamera={() => this.flipCamera()}
          isLocalVideoTrackEnabled={this.state.isLocalVideoTrackEnabled}
          clearPinnedScreenshareandBroadCast={() =>
            this.clearPinnedScreenshareandBroadCast()
          }
          setPinnedScreenshareandBroadCast={(data) =>
            this.setPinnedScreenshareandBroadCast(data)
          }
          isRemoteParticipantScreenShareOn={
            this.state.isRemoteParticipantScreenShareOn
          }
          isScreenShared={isScreenShared}
          pinType={this.state.pinType}
          lastCapturedImageURL={lastCapturedImageURL}
          captureImagePanel={this.props.configs.settings.capture_image_panel}
          isImageCapturedAfterPOPinned={this.state.isImageCapturedAfterPOPinned}
          showCaptureImagePanel={
            this.props.configs.settings.capture_image_panel &&
            this.state.isImageCapturedAfterPOPinned
          }
          handleLocationNotshared={() => this.handleLocationNotshared()}
          isAppraiser={isAppraiser(this.state.role)}
          isPropertyOwnerPinned={this.state.isPropertyOwnerPinned}
          handleSwitchCamera={(participantSid) =>
            this.handleSwitchCamera(participantSid)
          }
          isSwitchCameraRequested={this.state.isSwitchCameraRequested}
          switchCameraParticipantSid={this.state.switchCameraParticipantSid}
          liveFloorPlanData={this.state.liveFloorPlanData}
          areMeasurementButtonsEnabled={this.state.areMeasurementButtonsEnabled}
          isParticipantImageCaptured={this.state.isParticipantImageCaptured}
          startWaitForacknowledgement={this.startWaitForacknowledgement}
          setTimeoutForAcknowledgement={this.setTimeoutForAcknowledgement}
          waitForAcknowledgement={this.waitForAcknowledgement}
          isPointerStarted={this.state.isPointerStarted}
        />
      );
    });

    let zoomLevel =
      this.state.zoomLevel == "1(1x)"
        ? 1
        : this.state.zoomLevel == "2(2x)"
        ? 1.5
        : 1.75;

    return (
      <>
        <section
          className={
            "videocall-wapper " +
            (this.state.isModalOpen ? "" : "") +
            (this.state.isTimerStarted &&
            this.state.remoteParticipants.length > 0
              ? "call-timer"
              : "")
          }
        >
          {this.props.configs.settings.install_open_app == true &&
            isMobile &&
            this.state.isUserSelectedGoToApp == false && (
              <ShowDownloadAppPage
                isMobile={isMobile}
                isSafari={isSafari}
                isAndroid={isAndroid}
                isIOS={isIOS}
                handleGoToAppClicked={() => this.handleGoToAppClicked()}
                handleDownloadAppClicked={() => this.handleDownloadAppClicked()}
                bodyBackgroundColor={
                  this.props.configs.lenderDetails.bodyBackgroundColor
                    ? this.props.configs.lenderDetails.bodyBackgroundColor
                    : "#1C375C"
                }
                bodyTextColor={
                  this.props.configs.lenderDetails.bodyTextColor
                    ? this.props.configs.lenderDetails.bodyTextColor
                    : "#000000"
                }
                buttonBackgroundColor={
                  this.props.configs.lenderDetails.buttonBackgroundColor
                    ? this.props.configs.lenderDetails.buttonBackgroundColor
                    : "#1C375C"
                }
                buttonTextColor={
                  this.props.configs.lenderDetails.buttonTextColor
                    ? this.props.configs.lenderDetails.buttonTextColor
                    : "#FFFFFF"
                }
                headerBackgroundColor={
                  this.props.configs.lenderDetails.headerBackgroundColor
                    ? this.props.configs.lenderDetails.headerBackgroundColor
                    : "#E6E6FA"
                }
                headerTextColor={
                  this.props.configs.lenderDetails.headerTextColor
                    ? this.props.configs.lenderDetails.headerTextColor
                    : "#C6C6CA"
                }
                lenderId={
                  this.props.configs.lenderDetails.lenderId
                    ? this.props.configs.lenderDetails.lenderId
                    : "2432ba86-fc90-45b4-96ae-968af307fec1"
                }
                logoFile={
                  this.props.configs.lenderDetails.logoFile
                    ? this.props.configs.lenderDetails.logoFile
                    : null
                }
                logoPath={
                  this.props.configs.lenderDetails.logoPath
                    ? this.props.configs.lenderDetails.logoPath
                    : "https://image.shutterstock.com/image-illustration/illustration-television-test-picture-scan-600w-1929817118.jpg"
                }
                name={
                  this.props.configs.lenderDetails.name
                    ? this.props.configs.lenderDetails.name
                    : "VSITE"
                }
                privacyPolicyFile={
                  this.props.configs.lenderDetails.privacyPolicyFile
                    ? this.props.configs.lenderDetails.privacyPolicyFile
                    : null
                }
                privacyPolicyPath={
                  this.props.configs.lenderDetails.privacyPolicyPath
                    ? this.props.configs.lenderDetails.privacyPolicyPath
                    : "https://www.freeprivacypolicy.com/blog/privacy-policy-url/"
                }
              />
            )}

          {/* <!-- Connecting Member --> */}

          {this.state.isPreparingForMarkupVisible && <PrpearingForMarkup />}
          {this.state.isUserNameEntered == false &&
            this.state.showBeforeJoinModal == true && (
              <BeforeJoiningModel
                handleJoinClicked={this.handleJoinClicked}
                callConfig={this.props.configs}
                showCallSettings={() => this.showCallSettings()}
                isCallSettingsVisible={this.state.isCallSettingsVisible}
                name={this.name}
              />
            )}
          {this.isLocalParticipantConnected == false &&
            this.state.isMaxParticipantReached == false && (
              <ConnectingUser configs={this.props.configs} />
            )}

          {/* <!-- Connecting Member --> */}

          {this.isLocalParticipantConnected == true &&
            this.state.remoteParticipants.length == 0 && (
              <ConnectingMember configs={this.props.configs} />
            )}

          {/* <!-- Top Right Menu --> */}

          {this.isLocalParticipantConnected == true && (
            <RightMenu
              showAddParticipant={() => this.showAddParticipant()}
              hideAddParticipant={() => this.hideAddParticipant()}
              isAddParticipantVisible={this.state.isAddParticipantVisible}
              isFrontAndBackCameraAvailable={
                this.state.isFrontAndBackCameraAvailable
              }
              flipCamera={() => this.flipCamera()}
              isLocalVideoTrackEnabled={this.state.isLocalVideoTrackEnabled}
              isLocalParticipantPinned={isLocalParticipantPinned}
              ispinnedViewActive={this.state.ispinnedView}
            />
          )}

          {/* <!-- Participants Video View --> */}

          <div
            className={`participants-main video-participants${
              this.state.remoteParticipants.length + 1
            }`}
          >
            {localParticipant}
            {remoteParticipants}
          </div>

          {this.state.isPointerStarted && (
            <LivePointer
              showLivePointerCanvas={this.state.isPointerStarted}
              emitLivePointerData={this.livePointerBroadCast}
              receivedLivePointerData={this.state.receivedLivePointerData}
              participantIdentity={
                this.state.localRoom.localParticipant.identity
              }
              sid={_.get(
                this.state,
                ["localRoom", "localParticipant", "sid"],
                ""
              )}
              isLocalParticipantPinned={isLocalParticipantPinned}
              livePointerColors={this.state.livePointerColors}
              zoomLevel={zoomLevel}
            />
          )}

          {/* <!-- Bottom Menu --> */}

          {this.isLocalParticipantConnected && (
            <BottomMenu
              refNode={(node) => (this.node = node)}
              handleDisconnectCall={() => this.showLeaveModel()}
              handleToggleCamera={() => this.toggleCameraClicked()}
              handleToggleAudio={() => this.toggleMicroPhoneClicked()}
              isLocalVideoTrackEnabled={this.state.isLocalVideoTrackEnabled}
              isLocalAudioTrackEnabled={this.state.isLocalAudioTrackEnabled}
              showListParticipant={() => this.showListParticipant()}
              hideListParticipant={() => this.hideListParticipant()}
              showSavedImages={() => this.showSavedImages()}
              showMoreOptions={() => this.showMoreOptions()}
              isModalOpen={this.state.isModalOpen}
              isMoreMenuVisible={this.state.isMoreMenuVisible}
              isListParticipantVisible={this.state.isListParticipantVisible}
              totalParticipants={this.state.remoteParticipants.length + 1}
              captureImage={this.captureImageBtnHandler}
              ispinnedViewActive={this.state.ispinnedView}
              disbleCaptureImage={this.state.disbleCaptureImage}
              isFlashEnable={this.isFlashEnable}
              isFlashActive={this.state.isFlashActive}
              isAppraiserOrAssistant={isAppraiserOrAssistant(this.state.role)}
              activeDeactiveFlash={() => this.activeDeactiveFlash()}
              isBookmarkViewModelVisible={this.state.isBookmarkViewModelVisible}
              showBookmarkViewModel={() => this.showBookmarkViewModel()}
              hideBookmarkViewModel={() => this.hideBookmarkViewModel()}
              minimiseBookmarkViewModel={() => this.minimiseBookmarkViewModel()}
              isPointerStarted={this.state.isPointerStarted}
              toggleLivePointer={() => this.toggleLivePointer()}
              stopLivePointer={() => this.stopLivePointer()}
              showMarkupView={() => this.showMarkupViewModel()}
              shareScreen={() => this.shareScreen()}
              isScreenShareOn={this.state.isScreenShareOn}
              isMobile={isMobile}
              showChatModel={() => this.showChatModel()}
              hideChatModel={() => this.hideChatModel()}
              isChatModelVisible={this.state.isChatModelVisible}
              unreadMessageCount={this.state.unreadMessageCount}
              showNoteModel={() => this.showNoteModel()}
              hideNoteModel={() => this.hideNoteModel()}
              isNoteModelVisible={this.state.isNoteModelVisible}
              noteCounts={_.get(this, ["props", "notes", "length"])}
              showAttachmentDocumentModal={() =>
                this.showAttachmentDocumentModal()
              }
              hideAttachmentDocumentModal={() =>
                this.hideAttachmentDocumentModal()
              }
              isAttachmentDocumentModalVisible={
                this.state.isAttachmentDocumentModalVisible
              }
              attachmentCounts={_.get(this, ["props", "attachments", "length"])}
              onDeviceTap={this.onDeviceTap}
              onShowStartAppraisal={this.onShowStartAppraisal}
              onHideStartAppraisal={this.onHideStartAppraisal}
              // isStartMeasurementButtonDisable={
              //   !this.state.areMeasurementButtonsEnabled ||
              //   !!this.state.isStartAppraisalModalVisible ||
              //   [
              //     APPRAISAL_MEASUREMENT,
              //     APPRAISAL_CORNER_MARKING,
              //     APPRAISAL_2D_FLOOR_PLAN,
              //   ].includes(this.props.measurements.currentStep)
              // }

              isStartMeasurementButtonEnable={
                this.state.isPOJoined && this.state.isPOStartedVideoStream
              }
              showBookmarkMenu={
                _.get(this.props, ["configs", "settings", "bookmark"]) &&
                !!!_.get(this.props, [
                  "configs",
                  "settings",
                  "start_appraiser_measurement",
                ])
              }
              showCallSettings={() => this.showCallSettings()}
              isCallSettingsVisible={this.state.isCallSettingsVisible}
              showQuestionnaire={this.state.showQuestionnaire}
              toggleQuestionnaire={this.toggleQuestionnaire}
              isMeasurementOngoing={this.state.isMeasurementOngoing}
              checkConfigurationForMeasurement={
                this.checkConfigurationForMeasurement
              }
              isMeasurementFlow={this.isMeasurementFlow}
              toggleInspectionProgressLoader={
                this.toggleInspectionProgressLoader
              }
            />
          )}
        </section>

        {/* <!-- ADD PARTICIPANT MODAL --> */}
        {this.state.isAddParticipantVisible && (
          <AddParticipantModel
            hideAddParticipant={() => this.hideAddParticipant()}
            sendInvitation={this.sendInvitation}
            addParticipantSelectedTab={this.state.addParticipantSelectedTab}
            callUUID={this.callUUID}
            inviteParticipant={this.props.inviteParticipant}
            identity={this.identity}
            videoCall={this.props.videoCall}
          />
        )}

        {/* <!-- PARTICIPANTS List MODAL --> */}

        {this.state.isListParticipantVisible && (
          <ParticipantViewModel
            callUUID={this.callUUID}
            identity={this.state.localRoom.localParticipant.sid}
            hideListParticipant={() => this.hideListParticipant()}
            showAddParticipant={() => this.showAddParticipant()}
          />
        )}

        {/* Saved Images Modal */}
        {this.state.isSavedImagesVisible && (
          <RBAC
            action={view_captured_images}
            yes={
              <SavedImagesModel
                closeModel={this.hideSavedImages}
                toggleModel={this.toggleSavedImageModel}
                updateZoomLevel={this.updateZoomLevel}
                isSavedImagesFullViewVisible={
                  this.state.isSavedImagesFullViewVisible
                }
                isScaleZoomVisible={this.state.isScaleZoomVisible}
                zoomLevel={this.state.zoomLevel}
                isAppraiserOrAssistant={isAppraiserOrAssistant(this.state.role)}
                showGalleryZoomModel={({ images, currentImageIndex }) =>
                  this.showGalleryZoomModel(images, currentImageIndex)
                }
                isAutoShowSavedImagesFullViewVisible={
                  this.state.isAutoShowSavedImagesFullViewVisible
                }
                showDeleteImageModel={(index, img) => {
                  this.showDeleteImageModel(index, img);
                }}
              />
            }
          />
        )}

        {this.state.isDeleteImageModelVisible && (
          <DeleteModel
            title={t(
              "WEB_LABELS.Are_you_sure_you_want_to_delete_captured_image"
            )}
            Cancel={() => this.hideDeleteImageModel()}
            Confirm={() => this.onDeleteCapturedImage()}
          />
        )}

        {!!!this.state.isRemoteParticipantScreenShareOn &&
          !isOwner(this.state.role) && (
            <ZoomOptions
              isSavedImagesFullViewVisible={
                this.state.isSavedImagesFullViewVisible
              }
              updateZoomLevel={this.updateZoomLevel}
              isScaleZoomVisible={this.state.isScaleZoomVisible}
              zoomLevel={this.state.zoomLevel}
              isAppraiserOrAssistant={isAppraiserOrAssistant(this.state.role)}
            />
          )}
        {this.state.isDeleteFloorPlanModelVisible && (
          <ConfirmDeleteFloorPlan
            title={t("WEB_LABELS.Start_Over")}
            CancelDeleteFloorPlan={() => this.cancelDeleteFloorPlan()}
            DeleteFloorPlanAndContinue={() => this.deleteFloorPlanAndContinue()}
          />
        )}

        {this.state.isExitFloorPlanByClose && (
          <ConfirmExitMeasurementProcess
            title={t(
              "WEB_LABELS.Are_You_Sure_You_Want_to_Exit_Measurement_process"
            )}
            cancelExitMeasurementProcess={() =>
              this.cancelExitMeasurementProcess()
            }
            confirmExitMeasurementProcess={() =>
              this.confirmExitMeasurementProcess()
            }
          />
        )}

        {/* Gallery zoom model */}
        {this.state.isGalleryZoomModelVisible && (
          <GalleryZoomModel
            isAwsImage={this.props.configs.settings.isAwsImage}
            currentImageIndex={this.state.currentImageIndex}
            hideGalleryZoomModel={() => this.hideGalleryZoomModel()}
          />
        )}

        {/* Bookmark view model */}
        {this.state.isBookmarkViewModelVisible && (
          <BookMarkViewModel
            images={this.props.images}
            fetchBookmark={this.fetchBookmark}
            callUUID={this.callUUID}
            captureImage={this.captureImageBtnHandler}
            showSavedImages={() => this.showSavedImages()}
            hideBookmarkViewModel={() => this.hideBookmarkViewModel()}
            minimiseBookmarkViewModel={() => this.minimiseBookmarkViewModel()}
            toggleBookmarkViewModel={() => this.toggleBookmarkViewModel()}
            isBookmarkViewModelOpen={this.state.isBookmarkViewModelOpen}
          />
        )}

        {this.state.isTimerEnded && (
          <ErrorModelTimerExpired
            errorMessage={this.state.timerErrorMessage}
            // disconnectCall={(endCall) => this.disconnectCall}
            disconnectCall={this.disconnectCall}
          />
        )}

        {this.state.isTimerStarted &&
          this.state.remoteParticipants.length > 0 && (
            <CallRemainingTimer
              hours={this.state.callRemainingTimeHours}
              minutes={this.state.callRemainingTimeMins}
              seconds={this.state.callRemainingTimeSecs}
              remainingTimeInSecs={this.state.remainingTimeInSecs}
              remainingTimeAlertInSec={this.remainingTimeAlertInSec}
            />
          )}
        <canvas
          style={{ display: this.state.showCanvas ? "block" : "none" }}
          ref={this.imgcaptureCanvas}
        ></canvas>

        {this.state.remoteParticipants.length > 0 &&
          this.props.configs.settings.call_recording &&
          this.callRecording && (
            <div className="screen-recording">
              <span className="point"></span>REC
            </div>
          )}

        {this.state.showShareScreenStartClosePrompt && (
          <ShareScreenConfirmationModel
            hideShareScreenConfirmModel={() =>
              this.hideShareScreenConfirmModel()
            }
            shareScreenHandler={() => {
              this.shareScreenHandler();
            }}
            shareScreenAction={this.state.shareScreenAction}
            toggleCameraAction={() => {
              this.toggleCameraAction();
            }}
          />
        )}

        {this.state.isMaxParticipantReached && (
          <ErrorModelMaxParticipant
            retryConnect={() => this.retryConnect()}
            disconnectCall={() => this.disconnectCall()}
          />
        )}

        {this.state.isShowLeaveModel && (
          <LeaveModel
            Cancel={() => this.hideLeaveModel()}
            Confirm={() => {
              this.hideLeaveModel();
              this.disconnectCall();
            }}
            endCall={() => {
              this.hideLeaveModel();
              this.disconnectCall(true);
            }}
            role={this.state.role}
          />
        )}

        {this.state.showCameraPermissionPopup && (
          <PermissionPopup
            type="camera"
            close={this.hideCameraPermissionPopup}
          />
        )}

        {this.state.showMicorphonePermissionPopup && (
          <PermissionPopup
            type="microphone"
            close={this.hideMicorphonePermissionPopup}
          />
        )}

        {this.state.isStreetViewMode && (
          <MapViewModel
            captureStreetViewImage={this.captureStreetViewImage}
            onPovChanged={this.onPovChanged}
            onZoomChanged={this.onZoomChanged}
            onPositionChanged={this.onPositionChanged}
            toggleStreetViewMap={this.toggleStreetViewMap}
            streetViewPanoramaOptions={this.streetViewPanoramaOptions}
            remoteParticipantLatLong={this.remoteParticipantLatLong}
          />
        )}

        {this.state.captureImageTotalCount > 0 && (
          <UploadingImageModel
            captureImageUploadingCount={this.state.captureImageUploadingCount}
            captureImageTotalCount={this.state.captureImageTotalCount}
          />
        )}

        {this.state.isMarkupViewVisible && (
          <MarkupView
            incomingMarkupAnnotationObject={
              this.state.incomingMarkupAnnotationObject
            }
            markupPathCreated={this.markupPathCreated}
            markupShapeAdded={this.markupShapeAdded}
            markupShapeMoved={this.markupShapeMoved}
            markupUndo={this.markupUndo}
            markupRedo={this.markupRedo}
            markupClear={this.markupClear}
            markupClose={this.markupClose}
            userMarkupObjectArray={this.state.userMarkupObjectArray}
            globalMarkupObjectArray={this.state.globalMarkupObjectArray}
            userUndoObjectArray={this.state.userUndoObjectArray}
            markupViewStartedBy={this.state.markupViewStartedBy}
            participatnSid={this.state.localRoom.localParticipant.sid}
            userName={this.state.userName}
            markupImageURL={this.state.markupImageURL}
            saveMarkupImage={this.saveMarkupImage}
            sendDownloadingMarkupImageDataTrack={
              this.sendDownloadingMarkupImageDataTrack
            }
            sendStartedMarkupImageDataTrack={
              this.sendStartedMarkupImageDataTrack
            }
            role={this.state.role}
          />
        )}

        {this.state.isChatModelVisible && (
          <ChatViewModel
            hideChatModel={() => this.hideChatModel()}
            participantIdentity={
              this.state.localRoom.localParticipant.identity || ""
            }
            participantSid={this.state.localRoom.localParticipant.sid || ""}
            onMessageSend={(message) => this.onMessageSend(message)}
            callParticipants={this.props.callParticipants}
          />
        )}

        {this.state.isNoteModelVisible && (
          <NotesViewModel
            hideNoteModel={() => this.hideNoteModel()}
            jobOrderId={_.get(this.props, "videoCall.data.jobOrderId", "")}
            fetchNote={this.fetchNote}
            inviteUUID={this.inviteUUID}
          />
        )}

        {this.state.isAttachmentDocumentModalVisible && (
          <AttachmentDocumentModal
            jobOrderId={_.get(this.props, "videoCall.data.jobOrderId", "")}
            hideAttachmentDocumentModal={() =>
              this.hideAttachmentDocumentModal()
            }
            onUploadAttachment={this.onUploadAttachment.bind(this)}
          />
        )}

        {this.state.isStartAppraisalModalVisible && (
          <StartAppraisal
            onSendFloorData={this.onSendFloorData}
            images={this.props.images}
            fetchBookmark={this.fetchBookmark}
            callUUID={this.callUUID}
            captureImage={this.captureImageBtnHandler}
            showSavedImages={() => this.showSavedImages()}
            hideBookmarkViewModel={() => this.hideBookmarkViewModel()}
            isStartMeasurementModalCollapsed={
              this.state.isStartMeasurementModalCollapsed
            }
            toggleCollapseStartMeasurementModal={
              this.toggleCollapseStartMeasurementModal
            }
            isStartAppraisalCaptureImageTabVisible={
              this.state.isStartAppraisalCaptureImageTabVisible
            }
            toggleCaptureImageMeasurement={this.toggleCaptureImageMeasurement}
            selctedFloorIndexOnResume={this.state.selctedFloorIndexOnResume}
            selctedFloorNameOnResume={this.state.selctedFloorNameOnResume}
            selctedAreaTypeOnResume={this.state.selctedAreaTypeOnResume}
            selctedAreaNameOnResume={this.state.selctedAreaNameOnResume}
            exitMeasurementProcess={this.exitMeasurementProcess}
            isLocalParticipantPinned={this.state.ispinnedView}
            checkMeasurementStarted={this.checkMeasurementStarted}
            autoSnap={this.state.autoSnap}
            toggleAutoSnap={this.toggleAutoSnap}
          />
        )}

        {[
          APPRAISAL_MEASUREMENT,
          APPRAISAL_CORNER_MARKING,
          APPRAISAL_2D_FLOOR_PLAN,
        ].includes(this.props.measurements.currentStep) && (
          <MeasurementModal
            autoCornerStatus={this.state.autoCornerStatus}
            onMeasurementsModalEvent={this.onMeasurementsModalEvent}
            isMeasurementModalCollapsed={this.state.isMeasurementModalCollapsed}
            toggleCollapseMeasurementModal={this.toggleCollapseMeasurementModal}
            viweFloorPlanByFloorName={this.viweFloorPlanByFloorName}
            onResumeViewFloorPlanFloorSelection={
              this.onResumeViewFloorPlanFloorSelection
            }
            viweOrResumeOpenSketch={this.viweOrResumeOpenSketch}
            floorList={this.state.floorList}
            buttonType={this.state.buttonType}
            buttonState={this.state.buttonState}
            isResumeOrViewInspection={this.state.isResumeOrViewInspection}
            exitMeasurementProcess={this.exitMeasurementProcess}
            selectedIndex={this.state.selectedIndex}
            isLIDARViewVisible={this.state.isLIDARViewVisible}
            selectLIDAROption={this.selectLIDAROption}
            lidarSelectedOption={this.state.lidarSelectedOption}
            isFencingEnabled={this.state.isFencingEnabled}
            isFloorPlanExpanded={this.state.isFloorPlanExpanded}
            showhideLiveFloorPlanActions={
              this.state.showhideLiveFloorPlanActions
            }
            handleFloorPlanExpandOption={this.handleFloorPlanExpandOption}
            handleFencingVisibility={this.handleFencingVisibility}
            pinnedPODeviceType={this.state.pinnedPODeviceType}
            toggleCaptureImageMeasurement={this.toggleCaptureImageMeasurement}
            isStartAppraisalCaptureImageTabVisible={
              this.state.isStartAppraisalCaptureImageTabVisible
            }
            images={this.props.images}
            fetchBookmark={this.fetchBookmark}
            callUUID={this.callUUID}
            captureImage={this.captureImageBtnHandler}
            showSavedImages={() => this.showSavedImages()}
            hideBookmarkViewModel={() => this.hideBookmarkViewModel()}
            isLocalParticipantPinned={this.state.ispinnedView}
            areMeasurementButtonsEnabled={
              this.state.areMeasurementButtonsEnabled
            }
            loadingSketch={this.state.loadingSketch}
          />
        )}
        {this.state.isCallSettingsVisible && (
          <CallSettingsModel
            showCallSettings={() => this.showCallSettings()}
            permissions={this.state.permissions}
            isLocalVideoTrackEnabled={this.state.isLocalVideoTrackEnabled}
            isLocalAudioTrackEnabled={this.state.isLocalAudioTrackEnabled}
            localAudioTracks={this.localAudioTrack}
            localVideoTracks={this.localVideoTrack}
            isMobile={isMobile}
            isCameraFlipped={this.isCameraFlipped}
            flipCamera={() => this.flipCamera()}
            videoRef={this.videoRef}
            createMyLocalAudioTrackWithDeviceId={(d) =>
              this.createMyLocalAudioTrackWithDeviceId(d)
            }
            clearMyLocalAudioTrack={this.clearMyLocalAudioTrack}
            checkCallSettingsPermission={this.checkCallSettingsPermission}
            updatePermission={this.props.updatePermission}
            callSettings={this.props.callSettings}
            selectDevice={this.props.selectDevice}
            selectedCamera={this.selectedCamera}
            createMyLocalVideoTrackWithDevice={(d) =>
              this.createMyLocalVideoTrackWithDevice(d)
            }
          ></CallSettingsModel>
        )}

        {/* Call Settings Instructions Model */}
        {this.isLocalParticipantConnected && (
          <CallSettingsInstructionsModel
            showCallSettingsInstructionsModel={
              this.state.showCallSettingsInstructionsModel
            }
            showCallSettings={() => {
              this.setState({
                isCallSettingsVisible: true,
              });
            }}
            hideCallSettingsInstructionsModel={() => {
              this.setState({
                showCallSettingsInstructionsModel: false,
              });
            }}
          />
        )}

        {this.state.showQuestionnaire && (
          <QuestionnaireModel
            showQuestionnaire={this.state.showQuestionnaire}
            toggleQuestionnaire={this.toggleQuestionnaire}
            getQuestionnaire={this.props.getQuestionnaire}
            saveQuestionnaire={this.props.saveQuestionnaire}
            jobOrderId={this.props.configs.jobOrderId}
          ></QuestionnaireModel>
        )}

        {isOwner(this.state.role) &&
          this.state.showConfirmRecordingModal &&
          this.state.remoteParticipants.length > 0 &&
          this.props.configs.settings.call_recording &&
          this.callRecording && (
            <ConfirmRecordingModal
              handleLeaveCallBtn={this.handleLeaveCallBtn}
              handleGotItBtn={this.handleGotItBtn}
            />
          )}
        {this.state.showNetworkQualityModel && (
          <NetworkQualityModel
            toggleNetworkQualityModel={this.toggleNetworkQualityModel}
          />
        )}

        {this.state.requestPOForCamera && (
          <POInstructionModel
            toggleRequestPOForCameraModel={this.toggleRequestPOForCameraModel}
            msgForPOInstructionModel={this.state.msgForPOInstructionModel}
          />
        )}
        {this.state.choosePOToStartInspection && (
          <POsForInspectionModel
            remotePOsForInspection={this.state.remotePOsForInspection}
            togglePOsModal={this.togglePOsModal}
            onShowStartAppraisal={this.onShowStartAppraisal}
            selectPoFromMultiplePOs={this.selectPoFromMultiplePOs}
          />
        )}
        {(this.state.measurementLoading ||
          this.state.inspectionLoading ||
          this.state.inspectionProgressLoader ||
          this.waitForAcknowledgement ||
          this.state.loadingSketch ||
          this.addRoomLoading) && (
          <LoaderModel
            inspectionProgressLoader={this.state.inspectionProgressLoader}
            measurementLoading={this.state.measurementLoading}
            inspectionLoading={this.state.inspectionLoading}
            waitForAcknowledgement={this.waitForAcknowledgement}
            loadingSketch={this.state.loadingSketch}
            addRoomLoading={this.addRoomLoading}
          />
        )}
        {this.state.isRequestEndCallModel && (
          <RequestEndCallModel
            toggleRequestEndCallModel={this.toggleRequestEndCallModel}
            retryDTFailed={this.retryDTFailed}
            setTimeoutForRetryDT={this.setTimeoutForRetryDT}
            disconnectCall={this.disconnectCall}
            waitingForDT={this.state.waitingForDT}
          />
        )}

        {this.state.isCommonModel && (
          <CommonModel
            toggleCommonModel={this.toggleCommonModel}
            retryForAPIError={this.retryForAPIError}
            commonModelMsg={this.state.commonModelMsg}
            commonModelBtn={this.state.commonModelBtn}
          />
        )}
        {isAppraiser(this.state.role) &&
          this.state.distanceModel &&
          this.state.distanceBetweenLatLong > 0 && (
            <DistanceModel
              hideDistanceModel={this.hideDistanceModel}
              distanceBetweenLatLong={this.state.distanceBetweenLatLong}
              distanceUnit={this.state.distanceUnit}
            />
          )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getVideoCallToken: (requestData) => dispatch(getVideoCallToken(requestData)),
  getRoomMeasurementJSONForJobOrder: (requestData) =>
    dispatch(getRoomMeasurementJSONForJobOrder(requestData)),
  deleteFloorPlan: (requestData) => dispatch(deleteFloorPlan(requestData)),
  inviteParticipant: (callUUID, requestData) =>
    dispatch(inviteParticipant(callUUID, requestData)),
  listParticipant: (callUUID, isUpdate) =>
    dispatch(listParticipant(callUUID, isUpdate)),
  updateParticipantTrackStatus: (data) =>
    dispatch(updateParticipantTrackStatus(data)),
  callParticipantAdd: (data) => dispatch(callParticipantAdd(data)),
  callParticipantUpdate: (data) => dispatch(callParticipantUpdate(data)),
  callParticipantRemove: (data) => dispatch(callParticipantRemove(data)),
  callParticipantClearAll: () => dispatch(callParticipantClearAll()),
  // fixme: rename eitherCallUUIdOrJobOrderId to jobOrderId
  uploadImage: (eitherCallUUIdOrJobOrderId, data, extraPayload) =>
    dispatch(uploadImage(eitherCallUUIdOrJobOrderId, data, extraPayload)),
  addCapturedImages: (data) => dispatch(addCapturedImages(data)),
  deleteCapturedImage: (data) => dispatch(deleteCapturedImage(data)),
  updateLocalUserRole: (type, permission) =>
    dispatch(updateLocalUserRole(type, permission)),
  LoadCallConfig: (data) => dispatch(LoadCallConfig(data)),
  fetchBookmark: (data) => dispatch(loadBookmark(data)),
  uploadMarkupImage: (callUUID, data, extraPayload) =>
    dispatch(uploadMarkupImage(callUUID, data, extraPayload)),
  sendMessage: (data) => dispatch(sendMessage(data)),
  fetchNote: (data) => dispatch(loadNote(data)),
  fetchAttachments: (data) => dispatch(loadAttachments(data)),
  endSession: (data) => dispatch(callEnd(data)),
  attachmentReceivedSuccess: (data) =>
    dispatch(attachmentReceivedSuccess(data)),
  setAppraisalStepOne: () => dispatch(setAppraisalStepOne()),
  setAppraisalStepTwo: () => dispatch(setAppraisalStepTwo()),
  setAppraisalStepThree: () => dispatch(setAppraisalStepThree()),
  setAppraisalStepFour: () => dispatch(setAppraisalStepFour()),
  updatePermission: (data) => dispatch(updatePermission(data)),
  selectDevice: (type, device) => dispatch(selectDevice(type, device)),
  getQuestionnaire: (jobOrderId) => dispatch(getQuestionnaire(jobOrderId)),
  saveQuestionnaire: (data) => dispatch(saveQuestionnaire(data)),
  deleteImage: (data) => dispatch(deleteImage(data)),
  fetchToken: (payload, history) => dispatch(fetchToken(payload, history)),
});

const mapStateToProps = ({
  listParticipant,
  callParticipants,
  captureImages,
  me,
  messages,
  videoCall,
  noteView,
  attachmentView,
  measurements,
  callSettings,
}) => ({
  participantList: listParticipant.data,
  callParticipants: callParticipants.data,
  images: captureImages.images || [],
  captureImageUploaded: captureImages.uploaded || {},
  captureImageTotalCount: captureImages.totalCount || 0,
  captureImageUploadingCount: captureImages.uploading
    ? (captureImages.successCount || 0) + (captureImages.failedCount || 0) + 1
    : 0,
  configLoaded:
    me && me.configsLoaded && _.isEmpty(me.configsError) ? true : false,
  configs: _.get(me, ["configs"], {}),
  configError: _.isEmpty(me.configsError) ? false : true,
  messages: messages || [],
  videoCall: videoCall || {},
  notes: noteView.notes,
  attachments: attachmentView.attachments,
  measurements: measurements,
  callSettings: callSettings,
});

const connectedVideoCall = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(VideoCall));
export { connectedVideoCall as VideoCall };
