import * as actionTypes from "./actionTypes";
import videoCallService from "../services/videoCallService";
import _ from "lodash";
import { customToast } from "./../helpers/customToast";
import { history } from "./../helpers/";
import i18n from "i18next";
const startGetVideoCallToken = () => {
  return {
    type: actionTypes.GET_VIDEO_CALL_TOKEN_START,
  };
};

const successGeVideoCalltToken = (data) => {
  return {
    type: actionTypes.GET_VIDEO_CALL_TOKEN_SUCCESS,
    payload: data,
  };
};
const errorGetVideoCallToken = (error) => {
  return {
    type: actionTypes.GET_VIDEO_CALL_TOKEN_ERROR,
    payload: error,
  };
};

export function getVideoCallToken(requestData) {
  return (dispatch) => {
    dispatch(startGetVideoCallToken());

    return videoCallService
      .getVideoCallToken(requestData)
      .then(function (data) {
        dispatch(successGeVideoCalltToken(data));
        return data;
      })
      .catch((err) => {
        dispatch(errorGetVideoCallToken(err));
        if (_.get(err, ["response", "data", "message"])) {
          customToast.error(_.get(err, ["response", "data", "message"]));
          history.replace({
            pathname: "/",
            state: {},
          });
        } else if (_.get(err, ["response", "status"]) == 400) {
          customToast.error(i18n.t("COMMON_STRING.CALL_LINK_NOT_FOUND"));
          history.replace({
            pathname: "/",
            state: {},
          });
        }
        throw err;
      });
  };
}

//Invite participant

const startInviteParticipant = () => {
  return {
    type: actionTypes.INVITE_PARTICIPANT_START,
  };
};

const successInviteParticipant = (data) => {
  return {
    type: actionTypes.INVITE_PARTICIPANT_SUCCESS,
    payload: data,
  };
};
const errorInviteParticipant = (error) => {
  return {
    type: actionTypes.INVITE_PARTICIPANT_ERROR,
    payload: error,
  };
};

export function inviteParticipant(callUUID, requestData) {
  return (dispatch) => {
    dispatch(startInviteParticipant());

    return videoCallService
      .inviteParticipant(callUUID, requestData)
      .then(function (data) {
        dispatch(successInviteParticipant(data));
        return data;
      })
      .catch((err) => {
        dispatch(errorInviteParticipant(err));
        throw err;
      });
  };
}

//List participant

const startListParticipant = () => {
  return {
    type: actionTypes.LIST_PARTICIPANT_START,
  };
};

const successListParticipant = (data) => {
  return {
    type: actionTypes.LIST_PARTICIPANT_SUCCESS,
    payload: data,
  };
};
const errorListParticipant = (error) => {
  return {
    type: actionTypes.LIST_PARTICIPANT_ERROR,
    payload: error,
  };
};

export function listParticipant(callUUID, isUpdate = false) {
  return (dispatch) => {
    if (!isUpdate) {
      dispatch(startListParticipant());
    }

    return videoCallService
      .listParticipant(callUUID)
      .then(function (data) {
        dispatch(successListParticipant(data.data.participants));
        return data.data.participants;
      })
      .catch((err) => {
        if (!isUpdate) {
          dispatch(errorListParticipant(err));
          throw err;
        }
      });
  };
}

//Update track

const updateParticipantTrackSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_PARTICIPATN_TRACK_STATUS,
    payload: data,
  };
};

export function updateParticipantTrackStatus(data) {
  return (dispatch) => {
    dispatch(updateParticipantTrackSuccess(data));
  };
}

export const callParticipantAdd = (data) => {
  return {
    type: actionTypes.ADD_CALL_PARTICIPANT,
    payload: data,
  };
};

export const callParticipantUpdate = (data) => {
  return {
    type: actionTypes.UPDATE_CALL_PARTICIPANT,
    payload: data,
  };
};

export const callParticipantRemove = (participantSid) => {
  return {
    type: actionTypes.REMOVE_CALL_PARTICIPANT,
    payload: participantSid,
  };
};

export const callParticipantClearAll = () => {
  return {
    type: actionTypes.CLEAR_CALL_PARTICIPANT,
  };
};

export function callEnd(callUUID) {
  return (dispatch) => {
    return videoCallService.callEnd(callUUID).then(function (data) {
      return data.data;
    });
  };
}

//MeasurementData

const startGetMeasurementJSON = () => {
  return {
    type: actionTypes.GET_MEASUREMENT_JSON_START,
  };
};

const successGetMeasurementJSON = (data) => {
  return {
    type: actionTypes.GET_MEASUREMENT_JSON_SUCCESS,
    payload: data,
  };
};
const errorGetMeasurementJSON = (error) => {
  return {
    type: actionTypes.GET_MEASUREMENT_JSON_ERROR,
    payload: error,
  };
};

export function getRoomMeasurementJSONForJobOrder(jobOrderId) {
  return (dispatch) => {
    dispatch(startGetMeasurementJSON());

    return videoCallService
      .getMeasurementJSON(jobOrderId)
      .then(function (data) {
        dispatch(successGetMeasurementJSON(data));
        return data;
      })
      .catch((err) => {
        dispatch(errorGetMeasurementJSON(err));

        throw err;
      });
  };
}

// deleteFloorPlan

const startDeleteFloorPlan = () => {
  return {
    type: actionTypes.DELETE_FLOOR_PLAN_START,
  };
};

const successDeleteFloorPlan = (data) => {
  return {
    type: actionTypes.DELETE_FLOOR_PLAN_SUCCESS,
    payload: data,
  };
};
const errorDeleteFloorPlan = (error) => {
  return {
    type: actionTypes.DELETE_FLOOR_PLAN_ERROR,
    payload: error,
  };
};

export function deleteFloorPlan(requestData) {
  return (dispatch) => {
    dispatch(startDeleteFloorPlan());

    return videoCallService
      .deleteFloorPlan(requestData)
      .then(function (data) {
        dispatch(successDeleteFloorPlan(data));
        return data;
      })
      .catch((err) => {
        dispatch(errorDeleteFloorPlan(err));

        throw err;
      });
  };
}
