export const TRANSLATIONS_Mandarin = {
  WEB_LABELS: {
    Secure_Video_Call_Session_Expired: "安全视频通话会话已过期",
    Secure_Video_Call_Ended: "安全视频通话结束",
    Thank_you_for_connecting_with_us: "感谢您与我们联系！",
    Connected_Waiting_for_Other_Participants: "连接的。等待其他参与者...",
    Powered_by: "供电",
    Connecting: "正在连接...",
    Local_Pike: "本地派克",
    Would_you_like_to_save_image: "您要保存图像吗？",
    Message: "信息",
    Location_Not_shared: "地点：未共享",
    Location: "地点",
    Tools: "结尾",
    Ask_PO_to_Turn_on_Location: "要求业主开启定位功能",
    Preparing_image_for_markup: "正在为标记准备图像...",
    Download_Message: "下载远程评估应用程序",
    Disclaimer:
      "需要从 App Store 下载 RemoteVal 应用程序。下载完成后，请重新打开您的短信/电子邮件邀请，并再次单击该链接以自动加入通话。",
    MeasurementLinkDisclaimer:
      "需要从 App Store 下载 RemoteVal 应用程序。 下载完成后，请重新打开您的短信/电子邮件邀请，并再次单击该链接以自动开始检查。",
    Zoom_Level: "缩放级别",
    enter_cell_phone: "输入手机",
    Email: "电子邮件",
    Cell_Phone: "手机",
    Participant_Name: "参加者姓名",
    Invite_Participant: "邀请参与者",
    Join_secure_video_call_by_clicking_on_the_following_link:
      "单击以下链接加入安全视频通话：",
    There_are_no_Attachments_available: "没有可用的附件。",
    Drop_Or_Select_Your_File: "删除或选择您的文件",
    Attach_Document: "附加文件",
    Enter_Your_Name: "输入你的名字",
    I_confirm_that_I_have_read_and_agree_to_the: "我确认我已阅读并同意",
    Search_or_Add_Captured_Image_Label: "搜索或添加捕获的图像标签",
    Press_Enter_or_Click_on_Add_New_Label_to_add_new_Label:
      "按 Enter 键或单击使用新标签将新标签添加到捕获的图像",
    To: "到",
    Everyone: "每个人",
    Type_a_message: "输入消息",
    Are_you_sure_you_want_to_delete: "你确定你要删除 ？",
    Room_is_full_Please_try_again_later: "房间已满。请稍后再试",
    Comment: "评论",
    Room_2_Measurement: "房间 2 测量",
    Room_1_Measurement: "房间 1 测量",
    Room_2_Image: "房间 2 图片",
    Room_1_Image: "房间 1 图片",
    No_of_Rooms: "房间数",
    Property_Type: "财产种类",
    House: "房子",
    Inspection_Form: "检查表",
    Instructions: "指示",
    Guide_user_to_position_camera_on_the_target: "引导用户将相机定位在目标上",
    Click_on_the_Label_once_to_capture_labeled_image:
      "单击标签一次以捕获带标签的图像。",
    Select_the_floor_of_the_Home_Room_Type_and_a_Room_Name:
      "选择家的楼层、房间类型和房间名称。",
    Choose_GLA_or_Non_GLA: "选择 GLA 或非 GLA。",
    Click_on_the_Start_Measurement_button: "单击开始测量按钮",
    Click_the_Auto_Corner_Detection_to_On_Off: "点击“自动角点检测”开/关",
    Position_vertical_Green_Floor:
      "在房间角落放置垂直的绿色地板标记，并确保它接触地板。",
    App_will_auto_mark_a_corner:
      "应用程序将自动标记角落或点击“手动捕获角落”按钮",
    Guide_the_user_to_walk_to_each_corner_of_the_room_to_mark_each_corner:
      "引导用户走到房间的每个角落标记每个角落",
    When_last_line_connects:
      "当最后一条线连接到起点时，它会变成红色。应用程序将自动连接并完成草图，或者您可以使用“连接角并完成草图”按钮来完成测量。",
    button_to_view_final_sketch:
      "单击“显示完成的平面图草图”按钮以查看最终草图。",
    Are_You_Sure_You_Want_to_Leave_This_Call: "您确定要离开这个电话吗？",
    Street_View: "街景",
    Select_Measurement_Type: "选择测量类型",
    Floor: "地面",
    Ceiling: "天花板",
    View_Sketches_by_Floor: "按楼层查看草图",
    Uploading_Image: "上传图片",
    Call_Setting: "通话设置",
    Allow_access_to_your_Microphone_Click_the_padlock:
      "允许访问您的麦克风。点击挂锁",
    to_the_left_of_the_address_bar: "地址栏左侧。",
    Speakers: "扬声器",
    Allow_access_to_your_Camera_Click_the_padlock: "允许访问您的相机。点击挂锁",
    to_the_address_bar: "到地址栏。",
    Failed_to_start_the_video_camera_Please_select_another_video_camera:
      "无法启动摄像机。请选择其他摄像机。",
    Title: "标题",
    New_Note: "新笔记...",
    There_are_no_Notes_available: "没有可用的注释。",
    Are_you_sure_you_want_to_delete_this_note: "你确定要删除这条笔记么？",
    Network_Type: "网络类型",
    Netword_Strength: "网络实力",
    To_share_live_video: "分享实时视频。请在浏览器设置中打开相机权限然后",
    click_here: "点击这里",
    to_reload_the_page: "重新加载页面。",
    we_cant_access_your_microphone:
      "我们无法访问您的麦克风。要授予访问权限，请转到浏览器设置，然后",
    Questionnaire_Form: "问卷表",
    No_images_found: "没有找到图片！",
    Image: "图片",
    Captured_Images: "捕获的图像",
    Share_your_screen: "共享您的屏幕",
    Share_your_live_video: "分享您的直播视频？",
    This_will_stop_your_video: "这将停止您的视频。你要继续吗？",
    This_will_stop_screen_share: "这将停止屏幕共享。你要继续吗？",
    Room_Area_Label: "房间/区域标签",
    Room_Area_Name: "房间/区域名称（例如卧室 1、浴室 2 等）",
    Room_Area_Name_Placeholder: "房间/区域名称",
    You: "你",
    Are_you_sure_you_want_to_delete_captured_image:
      "您确定要删除捕获的图像吗？",
    Start_Over: "重新开始 - 您确定要删除平面图吗？",
    Are_You_Sure_You_Want_to_Exit_Measurement_process:
      "您确定要退出测量过程吗？",
    Labels: "标签",
    Select_Language: "选择语言",
    This_call_is_being_recorded: "该视频通话正在录制",
    This_call_is_being_recorded_by_your_appraiser_for_their_private_work_file:
      "您的评估师正在为他们的私人工作文件记录此通话。",
    It_will_not_be_shared: `它不会与任何外部方共享，包括您的抵押贷款
    贷方，您的评估师将无法从
    RemoteVal 站点。它将仅供评估师完成
    你的评价。`,
    If_you_do_not_wish_your_screen_to_be_recorded_please_inform_your_appraiser_now:
      "如果您不希望记录您的屏幕，请立即通知您的评估师。",
    Measurement: "测量",
    Create_Measurement: "创建测量",
    Fencing: "击剑",
    Show: "展示",
    Hide: "隐藏",
    Live_Floor_Plan: "现场平面图",
    Expand: "扩张",
    Collapse: "坍塌",
    Instruction_for_Call_Settings: "通话设置说明",
    Speaker_Mic_Camera_can_be_tested_via_call_settings:
      "扬声器、麦克风和摄像头可以通过“更多”菜单中的通话设置进行测试",
    To_Check_the_call_settings_now_click_on_the_below_button:
      "要检查通话设置，请单击下面的按钮。",
    Call_recording_by_virtual_inspector:
      "您的虚拟检查员记录了此视频呼叫，以供其私人工作文件，并由要求它的组织进行内部审核。它不会与任何外部聚会共享。",
    Call_and_Screen_Recording_Opt_Out:
      "如果您不希望您的屏幕和此通话记录，请立即通知您的虚拟检查员。",
    Test_Audio_Video_Components_Button:
      "可以通过单击下面的按钮来测试扬声器，麦克风和相机。",
  },
  BUTTONS: {
    Re_Connect: "重新连接",
    End_Session: "结束会话",
    Chat: "聊天",
    Participants: "参与者",
    Notes: "笔记",
    Attach_Document: "附加文件",
    Questionnaire: "问卷",
    Labels: "标签",
    Pointer: "指针",
    Markup: "标记",
    Flash_Off: "闪光灯关",
    Flash_On: "闪光灯开",
    Call_Recording: "通话录音",
    Capture_Image: "捕获图像",
    Screen_Share: "屏幕共享",
    Stop_Screen_Share: "停止屏幕共享",
    Call_Settings: "通话设置",
    Clear_All: "清除所有",
    Exit: "出口",
    Save: "节省",
    Close: "关闭",
    Save_and_Exit: "保存并退出",
    Continue: "继续",
    Street_View: "街景",
    Open_in_App: "在应用程序中打开",
    Privacy_Policy: "隐私政策",
    Invite: "邀请",
    Join: "加入",
    Add_Captured_Image_Label: "添加捕获的图像标签",
    No: "不",
    Yes: "是的",
    Delete: "删除",
    Cancel: "取消",
    RETRY: "重试",
    OK: "好的",
    Back: "后退",
    Leave_Call: "离开电话",
    Got_It: "知道了",
    Start_New_Inspection: "开始新的检查",
    Resume_Existing_Inspection: "恢复现有检查",
    View_All_Current_Sketch: "查看所有当前草图",
    Open_Sketch: "打开草图",
    Auto_Corner_Detection_ON: "自动转角检测开启",
    Auto_Corner_Detection_OFF: "自动转角检测关闭",
    Manually_Capture_Corner: "手动捕捉角",
    Undo_Last_Corner_Capture: "撤消最后一个角捕获",
    Connect_Corner_Finish_Sketch: "连接角和完成草图",
    Capture_3D_Room_Height: "捕捉 3D 房间高度",
    Set_Fixed_3D_Room_Height: "设置固定 3D 房间高度",
    Capture_Door: "捕获门",
    Set_Door: "设置门",
    Show_Finished_Floor_Plan_Sketch: "显示完成的平面图草图",
    Back_to_Previous_Screen: "返回上一屏幕",
    Add_Room: "添加房间",
    Publish_the_floor_plan: "发布平面图",
    Exit_Return_to_Inspection: "退出和返回检查",
    Audio: "声音的",
    Video: "视频",
    Microphone: "麦克风",
    Microphone_not_found: "未找到麦克风",
    Microphone_Blocked: "麦克风被阻止",
    Default: "默认",
    Grant_Permission: "授予权限",
    Speaker_Blocked: "扬声器被阻止",
    Play_Test_Sound: "播放测试声音",
    Preview: "预览",
    Camera: "相机",
    Camera_not_found: "未找到相机",
    Camera_Blocked: "摄像头被阻挡",
    Done: "完毕",
    View: "看法",
    Capture_Labeled_Images: "捕获带标签的图像",
    Capture_Measurements: "捕获测量",
    Instructions: "指示",
    Start_Measurement_Process: "开始测量过程",
    Flash_Light_Not_Supported: "远程手电筒不支持客人设备或未准备就绪",
  },
  TOOLTIP: {
    Mute_My_Audio: "静音我的音频",
    Unmute_My_Audio: "取消静音我的音频",
    Stop_My_Video: "停止我的视频",
    Start_My_Video: "开始我的视频",
    Capture_Images_with_No_Labels: "捕捉没有标签的图像",
    Start_Inspection: "开始检查",
    Chat: "聊天",
    More_Options: "更多选择",
    End_Call: "结束通话",
    Unpin_Video: "取消固定视频",
    Pin_Video: "固定视频",
    Add_New_Note: "添加新笔记",
    Refresh: "刷新",
    View_More: "查看更多",
    Invite_Participant: "邀请参与者",
    Delete_Image: "删除图片",
  },
  COMMON_STRING: {
    INVITATION_SENT_SUCCESS_MESSAGE: "邀请已成功发送",
    IOS_SAFARI_HELP: "为获得最佳效果，我们建议使用 Safari",
    UNEXPECTED_ERROR: "发生意外错误。稍后再试。",
    CALL_LINK_NOT_FOUND: "未找到呼叫链接。",
    CREATE_BOOKMARK_MESSAGE: "请让管理员添加书签",
    ATTACHMENT_UPLOAD_SIZE_EXCEEDED: "文件大小应小于 10MB",
    ATTACHMENT_UPLOAD_UNSUPPORTED_FORMAT: "不支持的文件格式",
    LIVE_POINTER_STARTED: "开始实时指针。您可以通过拖动来移动指针。",
    LIVE_POINTER_STOPPED: "停止的实时指针",
    APPRAISAL_EXIT_APPROVED: "退出确认成功。",
    APPRAISAL_EXIT_DENIED: "退出被拒绝。",
    MARKUP_CLOSED: "标记已停止",
    PRIVACY_POLICY_FAIL: "找不到有效的隐私政策 URL！",
    SWITCH_CAMERA_REQUEST_REJECTED: "房主未共享请求的相机视图。",
  },
  ERROR_MESSAGES: {
    Please_Select_Cellphone_or_Email: "请选择手机或邮箱",
    This_field_is_required: "此字段是必需的",
    Cell_Phone_Must_Be_Valid: "手机必须有效",
    Name_is_Required: "姓名为必填项",
    Name_Must_Be_Valid: "姓名必须有效",
    Invalid_Text: "无效文本",
    Email_Must_Be_Valid: "电子邮件必须有效",
    Name_must_be: "名字必须是",
    characters_max: "最大字符数",
    Please_select_the_Floor: "请选择楼层",
    Please_enter_the_Room_Area_Name: "请输入房间/区域名称",
    You_need_to_use_HTTPS_for_selecting_audio_output_device:
      "您需要使用 HTTPS 来选择音频输出设备",
    Something_went_wrong: "出了些问题。请稍后再试。",
    Oops_Call_duration_is_over_and_session_is_ended:
      "哎呀！通话时间结束，会话结束。",
    No_participant_has_joined_yet_Ending_the_call_session:
      "还没有参与者加入！结束通话会话",
  },
  starting_inspection: "开始检查",
  starting_measurement: "开始测量",
  error: "错误",
  contact_us: "联系我们",
  support_popup_msg: "糟糕，出了点问题，请稍后重试或向我们的支持人员报告问题。",
  select_po: "选择业主开始检查过程",
  request_po_for_camera: "请要求业主打开相机视图以开始检查过程。",
  wait_until_po_joins: "请等到业主加入电话以开始检查过程",
  select_measurement_method: "选择测量方法",
  inspection_inprogress: "检查已经在进行",
  model_msg_retry: "无法与业主建立沟通渠道，请重试。",
  model_msg_leaveCall:
    "双方似乎都存在互联网连接问题，因此请断开当前通话并重新连接。",
  please_wait: "请稍等...",
  loading_sketch: "加载草图...",
  connection_timeout_msg:
    "连接已超时。发生了一些不寻常的事情，响应时间比预期的要长。请再试一次。",
  previous_measurement_prblm_msg:
    "操作，之前的测量出了点问题。请使用新的测量过程重试。",
  measurement_retry_msg: "测量过程尚未开始。请重试。",
  opensketch_retry_msg: "Open Sketch 进程尚未开始。 请重试。",
};
