import React from "react";
import { useTranslation } from "react-i18next";
const InstructionModel = (props) => {
  const { t } = useTranslation();
  return (
    <div className="error-popup open">
      <div className="error-info text-left">
        <div className="content">
          <button className="close-modal" onClick={props.toggleInstruction}>
            <i className="icon-close-image"></i>
          </button>
          <p>
            <b>{t("WEB_LABELS.Instructions")}</b>
          </p>
          {props.type == "1" && (
            <ul className="instructions-list">
              <li>
                <p>
                  {t("WEB_LABELS.Guide_user_to_position_camera_on_the_target")}
                </p>
              </li>
              <li>
                <p>
                  {t(
                    "WEB_LABELS.Click_on_the_Label_once_to_capture_labeled_image"
                  )}
                </p>
              </li>
            </ul>
          )}
          {props.type == "2" && (
            <ul className="instructions-list">
              <li>
                <p>
                  {t(
                    "WEB_LABELS.Select_the_floor_of_the_Home_Room_Type_and_a_Room_Name"
                  )}
                </p>
              </li>
              <li>
                <p>{t("WEB_LABELS.Choose_GLA_or_Non_GLA")}</p>
              </li>
              <li>
                <p>{t("WEB_LABELS.Click_on_the_Start_Measurement_button")}</p>
              </li>
            </ul>
          )}
          {props.type == "3" && (
            <ul className="instructions-list">
              <li>
                <p>
                  {t("WEB_LABELS.Click_the_Auto_Corner_Detection_to_On_Off")}
                </p>
              </li>
              <li>
                <p>{t("WEB_LABELS.Position_vertical_Green_Floor")}</p>
              </li>
              <li>
                <p>{t("WEB_LABELS.App_will_auto_mark_a_corner")}</p>
              </li>
              <li>
                <p>
                  {t(
                    "WEB_LABELS.Guide_the_user_to_walk_to_each_corner_of_the_room_to_mark_each_corner"
                  )}
                </p>
              </li>
              <li>
                <p>{t("WEB_LABELS.When_last_line_connects")}</p>
              </li>
              <li>
                <p>{t("WEB_LABELS.button_to_view_final_sketch")}</p>
              </li>
            </ul>
          )}
        </div>
        {/* <div className="bottom-btn">
              <button className="red-btn">Ok</button>  <button className="green-btn">Yes</button> <button className="blue-btn" style="display:none">Yes</button>
            </div> */}
      </div>
    </div>
  );
};

export default InstructionModel;
