import React, { useState, useEffect } from "react";
import "./newCallSettingsModel.scss";
import { appConstants } from "./../../../constants/app.constants";
import _, { size } from "lodash";
import audioFile from "./../../../audio/User_Join.mp3";
import classnames from "classnames";
import Video, {
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalDataTrack,
  LocalVideoTrack,
} from "twilio-video";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const TABS = {
  MICROPHONE: "MICROPHONE",
  CAMERA: "CAMERA",
};

const CallSettingsModel = (props) => {
  const { t } = useTranslation();

  const [active, setactive] = React.useState(TABS.MICROPHONE);
  const [microphonePermission, setMicrophonePermission] = useState(
    props.callSettings.microphone
  );
  const [cameraPermission, setCameraPermission] = useState(
    props.callSettings.camera
  );
  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);
  const [cameraNotFoundError, setCameraNotFoundError] = useState(false);
  const [deviceNotFound, setDeviceNotFound] = useState(false);

  let vidElement;
  let javascriptNode = "";
  useEffect(() => {
    if (props.isLocalVideoTrackEnabled && props.isLocalAudioTrackEnabled) {
      getDevices()
        .then((deviceInfo) => {
          if (deviceInfo) {
            activateAudioTrackMicTesting();
            showLocalVideoInCallSettings("");
            assignSelectedDevices();
          } else {
          }
        })
        .catch((err) => {});
    } else if (
      props.callSettings.camera == appConstants.mediaPermissions.granted ||
      props.callSettings.microphone == appConstants.mediaPermissions.granted
    ) {
      askPermission();
    } else {
    }
    return () => {
      removeCurrentMediaStream();
    };
  }, []);

  const askPermission = () => {
    let fromWhere = null;
    if (props.isLocalVideoTrackEnabled && !props.isLocalAudioTrackEnabled) {
      fromWhere = "audioOnly";
    }
    if (props.isLocalAudioTrackEnabled && !props.isLocalVideoTrackEnabled) {
      fromWhere = "videoOnly";
    }

    getCurrentPermission(fromWhere);
  };

  const clickTabHandler = (value) => {
    setactive(value);
  };

  const getCurrentPermission = (fromWhere, device) => {
    if (window.stream) {
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    let constraints = {};
    let audioSource = null;
    let videoSource = null;

    device && device.kind == "videoinput"
      ? (videoSource = device.deviceId)
      : props.callSettings.selectedCamera
      ? (videoSource = props.callSettings.selectedCamera.deviceId)
      : (videoSource = null);
    if (cameraPermission != appConstants.mediaPermissions.denied) {
      if (videoSource) {
        constraints.video = { deviceId: { exact: videoSource } };
      } else {
        if (props.isCameraFlipped || props.isMobile) {
          constraints.video = { facingMode: "user" };
        } else {
          constraints.video = { facingMode: "environment" };
        }
      }
    }

    device && device.kind == "audioinput"
      ? (audioSource = device.deviceId)
      : props.callSettings.selectedMicrophone
      ? (audioSource = props.callSettings.selectedMicrophone.deviceId)
      : (audioSource = null);
    if (microphonePermission != appConstants.mediaPermissions.denied) {
      constraints.audio = {
        deviceId: audioSource ? { exact: audioSource } : undefined,
      };
    }

    if (fromWhere && fromWhere == "audioOnly") {
      constraints.video = false;
    }
    if (fromWhere && fromWhere == "videoOnly") {
      constraints.audio = false;
    }
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (stream) {
        getDevices();
        props.checkCallSettingsPermission();
        if (microphonePermission != appConstants.mediaPermissions.denied) {
          setMicrophonePermission(appConstants.mediaPermissions.granted);
        }

        if (cameraPermission != appConstants.mediaPermissions.denied) {
          setCameraPermission(appConstants.mediaPermissions.granted);
        }
        window.stream = stream;
        vidElement = document.querySelector("video#callSettingVideo");
        vidElement.srcObject = stream;
        vidElement.controls = false;

        if (props.isLocalAudioTrackEnabled) {
          activateAudioTrackMicTesting();
        } else {
          startMicTesting(stream);
        }
        if (props.isLocalVideoTrackEnabled) {
          vidElement.srcObject = null;
          let objStream = new MediaStream();
          objStream.addTrack(props.localVideoTracks.mediaStreamTrack);
          vidElement.srcObject = objStream;
        } else {
        }
      })
      .catch(function (err) {
        props.checkCallSettingsPermission();
        if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError") {
          setDeviceNotFound(true);
        } else if (
          err.message == "Permission denied" ||
          err.name == "NotAllowedError"
        ) {
          (cameraPermission == appConstants.mediaPermissions.granted ||
            microphonePermission == appConstants.mediaPermissions.granted) &&
            getDevices();
          cameraPermission != appConstants.mediaPermissions.granted &&
            setCameraPermission(appConstants.mediaPermissions.denied);
          microphonePermission != appConstants.mediaPermissions.granted &&
            setMicrophonePermission(appConstants.mediaPermissions.denied);
        } else if (
          err.name == "NotReadableError" ||
          err.message == "Could not start video source"
        ) {
          setCameraNotFoundError(true);
          constraints.audio && !constraints.audio.deviceId && getDevices();
        } else {
        }
      });
  };

  const assignSelectedDevices = function () {
    if (_.size(videoDevices) > 0) {
      if (_.isEmpty(props.callSettings.selectedCamera)) {
        props.selectDevice(appConstants.mediaType.camera, videoDevices[0]);
      }
    }
    if (_.size(audioInputDevices) > 0) {
      if (_.isEmpty(props.callSettings.selectedMicrophone)) {
        props.selectDevice(
          appConstants.mediaType.microphone,
          audioInputDevices[0]
        );
      }
    }
    if (_.size(audioOutputDevices) > 0 && props.isMobile) {
      if (_.isEmpty(props.callSettings.selectedSpeaker)) {
        props.videoDevices(
          appConstants.mediaType.speaker,
          audioOutputDevices[0]
        );
      }
    }
  };

  const showLocalVideoInCallSettings = async (device) => {
    vidElement = document.querySelector("video#callSettingVideo");
    vidElement.srcObject = null;
    let objStream = new MediaStream();
    let videoTrackOptions = {};
    let newLocalVideoTrack = {};
    if (device) {
      navigator.mediaDevices
        .getUserMedia({ video: { deviceId: { exact: device.deviceId } } })
        .then(function (stream) {
          window.stream = stream;
          vidElement.srcObject = stream;
          vidElement.controls = false;
        });
    } else {
      newLocalVideoTrack = { ...props.localVideoTracks };
      objStream.addTrack(newLocalVideoTrack.mediaStreamTrack);
      vidElement.srcObject = objStream;
    }
  };

  const startMicTesting = (stream) => {
    let AudioContext = window.AudioContext || window.webkitAudioContext;
    let audioContext = new AudioContext();
    let analyser = audioContext.createAnalyser();
    let microphone = audioContext.createMediaStreamSource(stream);
    javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(javascriptNode);
    javascriptNode.connect(audioContext.destination);
    javascriptNode.onaudioprocess = function () {
      let array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      let values = 0;

      let length = array.length;
      for (let i = 0; i < length; i++) {
        values += array[i];
      }
      let average = values / length;
      setActivateColorBars(average);
    };
  };

  const activateAudioTrackMicTesting = function () {
    let objStream = new MediaStream();
    objStream.addTrack(props.localAudioTracks.mediaStreamTrack);
    startMicTesting(objStream);
  };

  const setActivateColorBars = (vol) => {
    let all_pids = document.querySelectorAll(".check-microphone-step li");
    let activePids = Math.round(vol / 10);
    for (var i = 0; i < all_pids.length; i++) {
      all_pids[i].classList && all_pids[i].classList.remove("active");
    }
    for (var i = 0; i < activePids; i++) {
      all_pids[i] && all_pids[i].classList.add("active");
    }
  };

  const getDevices = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        reject("");
      }
      // w/o media permissions, browsers do not return device Ids and/or labels.
      navigator.mediaDevices
        .enumerateDevices()
        .then(function (deviceInfos) {
          let audioInputDevices1 = _.filter(
            deviceInfos,
            (device) => device.kind == "audioinput"
          );
          setAudioInputDevices(audioInputDevices1);
          let videoDevices1 = _.filter(
            deviceInfos,
            (device) => device.kind == "videoinput"
          );
          setVideoDevices(videoDevices1);
          let audioOutputDevices1 = _.filter(
            deviceInfos,
            (device) => device.kind == "audiooutput"
          );
          setAudioOutputDevices(audioOutputDevices1);
          if (
            props.callSettings.selectedMicrophone &&
            Object.keys(props.callSettings.selectedMicrophone).length === 0 &&
            props.callSettings.selectedMicrophone.constructor === Object
          ) {
            props.selectDevice(
              appConstants.mediaType.microphone,
              audioInputDevices1[0]
            );
          }
          if (props.selectedCamera && props.isMobile) {
            let Camera;
            if (props.selectedCamera == "environment") {
              Camera = _.find(videoDevices1, (d) => d.label.match(/back/gim));
            } else if (props.selectedCamera == "user") {
              Camera = _.find(videoDevices1, (d) => d.label.match(/front/gim));
            } else {
              Camera = _.find(
                videoDevices1,
                (d) => d.deviceId == props.selectedCamera
              );
            }
            props.selectDevice(appConstants.mediaType.camera, Camera);
          } else {
            if (
              props.callSettings.selectedCamera &&
              Object.keys(props.callSettings.selectedCamera).length === 0 &&
              props.callSettings.selectedCamera.constructor === Object
            ) {
              props.selectDevice(
                appConstants.mediaType.camera,
                videoDevices1[0]
              );
            } else {
            }
          }

          if (
            props.callSettings.selectedSpeaker &&
            Object.keys(props.callSettings.selectedSpeaker).length === 0 &&
            props.callSettings.selectedSpeaker.constructor === Object
          ) {
            props.selectDevice(
              appConstants.mediaType.speaker,
              audioOutputDevices1[0]
            );
          }
          resolve(deviceInfos);
        })
        .catch(function (err) {
          reject(err);
        });
    });
  };

  const playTestSound = () => {
    let sound = new Audio(audioFile);
    sound.play();
  };

  const removeCurrentMediaStream = () => {
    if (window.stream) {
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    vidElement && (vidElement.srcObject = null);

    if (javascriptNode) {
      javascriptNode.disconnect();
      javascriptNode = null;
    }
  };

  const changeSpeaker = (sinkId) => {
    let videoElement = document.querySelector("video#callSettingVideo");
    if (videoElement && typeof videoElement.sinkId !== "undefined") {
      videoElement
        .setSinkId(sinkId)
        .then(() => {})
        .catch((error) => {
          let errorMessage = error;
          if (error.name === "SecurityError") {
            errorMessage =
              i18n.t(
                "ERROR_MESSAGES.You_need_to_use_HTTPS_for_selecting_audio_output_device"
              ) + `: ${error}`;
          }
        });
    } else {
    }
  };

  const createAudioTrackForCallSettings = async (deviceId) => {
    const newLocalAudioTrack = await createLocalAudioTrack({
      deviceId: { exact: deviceId },
    });
    let objStream = new MediaStream();
    objStream.addTrack(newLocalAudioTrack.mediaStreamTrack);
    startMicTesting(objStream);
  };

  const changeDevice = (type, e) => {
    let device;
    if (type == appConstants.mediaType.camera) {
      device = _.find(videoDevices, (d) => d.deviceId == e.target.value);
      props.selectDevice(appConstants.mediaType.camera, device);
      if (props.isLocalVideoTrackEnabled) {
        if (props.isMobile) {
          props.flipCamera();
        } else {
          props.createMyLocalVideoTrackWithDevice(device);
        }
        showLocalVideoInCallSettings(device);
      } else if (props.isLocalAudioTrackEnabled) {
        getCurrentPermission("videoOnly");
      } else {
        getCurrentPermission("", device);
      }
    } else if (type == appConstants.mediaType.microphone) {
      device = _.find(audioInputDevices, (d) => d.deviceId == e.target.value);
      props.selectDevice(appConstants.mediaType.microphone, device);
      if (props.isLocalAudioTrackEnabled) {
        props.createMyLocalAudioTrackWithDeviceId(device.deviceId);
        createAudioTrackForCallSettings(device.deviceId);
      } else if (props.isLocalVideoTrackEnabled) {
        getCurrentPermission("audioOnly");
      } else {
        getCurrentPermission("", device);
      }
    } else if (type == appConstants.mediaType.speaker) {
      device = _.find(audioOutputDevices, (d) => d.deviceId == e.target.value);
      props.selectDevice(appConstants.mediaType.speaker, device);
      changeSpeaker(device.deviceId);
    } else {
    }
  };

  return (
    <div className="comman-modal center-model call-setting-modal back-layer open">
      <div className="comman-modal-main">
        <div className="comman-modal-body scroll-bar-style">
          <div className="inner-setting-model">
            <div className="call-setting-tab">
              <div className="side-head">
                {t("WEB_LABELS.Call_Setting")}
                <div className="side-head-buttons">
                  <button
                    className="close-modal modal-button-effect"
                    onClick={() => props.showCallSettings()}
                  >
                    <i className="icon-close-image"></i>
                  </button>
                </div>
              </div>
              <div className="setting-tab-list">
                <ul>
                  <li
                    className={`${active == TABS.MICROPHONE ? "active" : ""}`}
                  >
                    <button onClick={() => clickTabHandler(TABS.MICROPHONE)}>
                      <i className="icon-call-audio"></i> {t("BUTTONS.Audio")}{" "}
                    </button>
                  </li>
                  <li className={`${active == TABS.CAMERA ? "active" : ""}`}>
                    <button onClick={() => clickTabHandler(TABS.CAMERA)}>
                      <i className="icon-call-video"></i> {t("BUTTONS.Video")}{" "}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="call-setting-content">
              <div className="tab-close">
                <button
                  onClick={() => props.showCallSettings()}
                  className="close-modal"
                >
                  <i className="icon-close-image"></i>
                </button>
              </div>
              <div className="inner-setting-content">
                <div
                  className={`${
                    active == TABS.MICROPHONE
                      ? "tab-content active"
                      : "tab-content"
                  }`}
                >
                  <div className="form-group">
                    <label for="cars">{t("BUTTONS.Microphone")}</label>
                    {(deviceNotFound || _.size(audioInputDevices) == 0) &&
                      microphonePermission !=
                        appConstants.mediaPermissions.prompt && (
                        <button className="form-control disable">
                          {t("BUTTONS.Microphone_not_found")}
                        </button>
                      )}
                    {!deviceNotFound &&
                      microphonePermission ==
                        appConstants.mediaPermissions.prompt && (
                        <button
                          onClick={getCurrentPermission}
                          className="form-control"
                        >
                          {t("BUTTONS.Grant_Permission")}
                        </button>
                      )}
                    {!deviceNotFound &&
                      (microphonePermission ==
                        appConstants.mediaPermissions.denied ||
                        microphonePermission ==
                          appConstants.mediaPermissions.error) && (
                        <button
                          className={classnames("form-control", {
                            disable:
                              microphonePermission ==
                                appConstants.mediaPermissions.denied ||
                              microphonePermission ==
                                appConstants.mediaPermissions.error,
                          })}
                        >
                          {t("BUTTONS.Microphone_Blocked")}
                        </button>
                      )}
                    {!deviceNotFound &&
                      microphonePermission ==
                        appConstants.mediaPermissions.granted &&
                      _.size(audioInputDevices) > 0 && (
                        <select
                          id="cars"
                          className="form-control"
                          value={props.callSettings.selectedMicrophone.deviceId}
                          onChange={(e) =>
                            changeDevice(appConstants.mediaType.microphone, e)
                          }
                        >
                          {audioInputDevices.map((id, i) => (
                            <option key={i} value={id.deviceId}>
                              {id.label}
                            </option>
                          ))}
                        </select>
                      )}
                    {microphonePermission ==
                      appConstants.mediaPermissions.denied &&
                      microphonePermission !=
                        appConstants.mediaPermissions.error && (
                        <div className="form-error">
                          {t(
                            "WEB_LABELS.Allow_access_to_your_Microphone_Click_the_padlock"
                          )}{" "}
                          <i className="icon-lock"></i>{" "}
                          {t("WEB_LABELS.to_the_left_of_the_address_bar")}
                        </div>
                      )}
                  </div>
                  {microphonePermission ==
                    appConstants.mediaPermissions.granted && (
                    <div className="check-microphone-step">
                      <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  )}
                  <div className="form-group">
                    <label for="cars">{t("WEB_LABELS.Speakers")}</label>
                    {microphonePermission ==
                      appConstants.mediaPermissions.granted &&
                      _.size(audioOutputDevices) == 0 && (
                        <button
                          onClick={getCurrentPermission}
                          className="form-control"
                        >
                          {t("BUTTONS.Default")}
                        </button>
                      )}
                    {microphonePermission ==
                      appConstants.mediaPermissions.prompt && (
                      <button
                        onClick={getCurrentPermission}
                        className="form-control"
                      >
                        {t("BUTTONS.Grant_Permission")}
                      </button>
                    )}
                    {(microphonePermission ==
                      appConstants.mediaPermissions.denied ||
                      microphonePermission ==
                        appConstants.mediaPermissions.error) && (
                      <button
                        className={classnames("form-control", {
                          disable:
                            microphonePermission ==
                              appConstants.mediaPermissions.denied ||
                            microphonePermission ==
                              appConstants.mediaPermissions.error,
                        })}
                      >
                        {t("BUTTONS.Speaker_Blocked")}
                      </button>
                    )}
                    {!deviceNotFound &&
                      microphonePermission ==
                        appConstants.mediaPermissions.granted &&
                      _.size(audioOutputDevices) > 0 && (
                        <select
                          id="cars3"
                          className="form-control"
                          value={props.callSettings.selectedSpeaker.deviceId}
                          onChange={(e) =>
                            changeDevice(appConstants.mediaType.speaker, e)
                          }
                        >
                          {audioOutputDevices.map((id, i) => (
                            <option key={i} value={id.deviceId}>
                              {id.label}
                            </option>
                          ))}
                        </select>
                      )}
                  </div>
                  <button
                    type="button"
                    onClick={() => playTestSound()}
                    className="green-btn"
                  >
                    {t("BUTTONS.Play_Test_Sound")}
                  </button>
                </div>
                <div
                  className={`${
                    active == TABS.CAMERA ? "tab-content active" : "tab-content"
                  }`}
                >
                  <label for="cars">{t("BUTTONS.Preview")}</label>
                  {cameraPermission == appConstants.mediaPermissions.denied &&
                    cameraPermission != appConstants.mediaPermissions.error && (
                      <div className="acccess-camera-box">
                        <i className="icon-camera-off"></i>
                        <p>
                          {t(
                            "WEB_LABELS.Allow_access_to_your_Camera_Click_the_padlock"
                          )}{" "}
                          <i className="icon-lock"></i>{" "}
                          {t("WEB_LABELS.to_the_address_bar")}
                        </p>
                      </div>
                    )}
                  {cameraPermission != appConstants.mediaPermissions.denied &&
                    cameraPermission != appConstants.mediaPermissions.error && (
                      <div className="acccess-camera-box">
                        <video
                          id="callSettingVideo"
                          width="100%"
                          playsInline
                          autoPlay
                        ></video>
                      </div>
                    )}
                  <div className="form-group">
                    <label htmlFor="cars">{t("BUTTONS.Camera")}</label>
                    {(_.size(videoDevices) == 0 ||
                      cameraNotFoundError ||
                      deviceNotFound) &&
                      cameraPermission !=
                        appConstants.mediaPermissions.prompt && (
                        <button className="form-control disable">
                          {t("BUTTONS.Camera_not_found")}
                        </button>
                      )}
                    {!deviceNotFound &&
                      cameraPermission ==
                        appConstants.mediaPermissions.prompt && (
                        <button
                          onClick={getCurrentPermission}
                          className="form-control"
                        >
                          {t("BUTTONS.Grant_Permission")}
                        </button>
                      )}
                    {!deviceNotFound &&
                      (cameraPermission ==
                        appConstants.mediaPermissions.denied ||
                        cameraPermission ==
                          appConstants.mediaPermissions.error) && (
                        <button
                          className={classnames("form-control", {
                            disable:
                              cameraPermission ==
                                appConstants.mediaPermissions.denied ||
                              cameraPermission ==
                                appConstants.mediaPermissions.error,
                          })}
                        >
                          {t("BUTTONS.Camera_Blocked")}
                        </button>
                      )}
                    {!deviceNotFound &&
                      !cameraNotFoundError &&
                      cameraPermission ==
                        appConstants.mediaPermissions.granted &&
                      _.size(videoDevices) > 0 && (
                        <select
                          id="cars"
                          className="form-control"
                          value={props.callSettings.selectedCamera.deviceId}
                          onChange={(e) =>
                            changeDevice(appConstants.mediaType.camera, e)
                          }
                        >
                          {videoDevices.map((id, i) => (
                            <option key={i} value={id.deviceId}>
                              {id.label}
                            </option>
                          ))}
                        </select>
                      )}
                  </div>
                  {cameraNotFoundError && (
                    <div className="form-error">
                      {t(
                        "WEB_LABELS.Failed_to_start_the_video_camera_Please_select_another_video_camera"
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallSettingsModel;
