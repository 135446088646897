import { useEffect } from "react";
import axios from "axios";
import { addCachedCapturedImage } from "../../actions/captureImageAction";
import { connect } from "react-redux";

const AsyncImage = (props) => {
  useEffect(() => {
    if (props.cachedCapturedImages[props.imageUrl] === undefined) {
      axios
        .get(props.imageUrl, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          responseType: "blob",
        })
        .then((v) => {
          const reader = new window.FileReader();
          reader.readAsDataURL(v.data);
          reader.onload = () => {
            props.addCachedCapturedImage(props.imageUrl, reader.result);
          };
        });
    }
  });

  return (
    <img
      src={
        props.cachedCapturedImages[props.imageUrl] === undefined
          ? ""
          : props.cachedCapturedImages[props.imageUrl]
      }
      id="img"
      alt={props.alt ? props.alt : "Loading Captured Pic..."}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    cachedCapturedImages: state.cachedCapturedImages.cachedCapturedImages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCachedCapturedImage: (key, value) =>
      dispatch(addCachedCapturedImage(key, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AsyncImage);
